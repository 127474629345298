//List
ul {
    &.list-style-inside {
        list-style: inside;
    }
    &.list-style-none {
        list-style: none;
    }
    li {
        &::marker {
            font-size: 23px;
        }
        a {
            text-decoration: none;
        }
    }
    &.list-dotsbetween {
        display: inline-block; 
        padding: 0; 
        text-align: center;
        li {
            display: inline;
            &::after {
                content:" "; 
                letter-spacing:1em;  
                background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwAAADsABataJCQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xMkMEa+wAAAAnSURBVBhXY/Dz89MA4sNA/B9Ka4AEYQIwfBgkiCwAxjhVopnppwEApxQqhnyQ+VkAAAAASUVORK5CYII=) center center no-repeat;
            }
            a {
                white-space: nowrap;
            }
        }
    }
    &.list-linebetween {
        display: inline-block; 
        padding: 0; 
        text-align: center;
        > li {
            display: inline;
            &:not(:last-child) {
                &::after {
                    content:" "; 
                    border-left: 2px solid #ececec;
                }
            }
            a {
                white-space: nowrap;
            }
        }
    }
    &.list-style-is-check {
        li {
            &::before {
                content: '\F633';
                font-family: $bootstrapicons;
                margin-right: 1rem;
            }
        }
    }
    @each $key, $value in $colors {
        &.list-style-circle-is-#{$key} {
            li {
                &::marker {
                    color: $value;                
                }
            }
        }
        &.list-style-square-is-#{$key} {
            li {
                &::marker {
                    color: $value;
                }
            }
        }
        &.list-style-check-is-#{$key} {
            li {
                &::marker {
                    color: $value;
                }
            }
        }
    }
    //
    &.list-inline {
        display: inline-block;
        &.with-dotsbetween {
            li {
                display: inline-flex;
                align-items: center;
                @include media-breakpoint-up(md) {
                    &:not(:last-child) {
                        &::after {
                            content: "\F309"; 
                            font-family: $bootstrapicons;
                            margin: 0 10px;
                            font-size: 30px;
                        }
                    }
                }
                @include media-breakpoint-down(md) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}