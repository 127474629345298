//Font Size
@for $i from 10 through 100 {
    .font-size-#{$i} {
        font-size: #{$i}#{"px"};
    }
}

@each $media in $medias {
	@include media-breakpoint-up($media) {
		@for $i from 10 through 100 {
			.font-size-#{$media}-#{$i} {
				font-size: #{$i}#{"px"};
			}
		}
	}
}

//Responsive
@include media-breakpoint-down(lg) {
	.font-size-50 {
		font-size: 30px;
	}
	.font-size-51 {
		font-size: 31px;
	}
	.font-size-52 {
		font-size: 32px;
	}
	.font-size-53 {
		font-size: 33px;
	}
	.font-size-54 {
		font-size: 34px;
	}
	.font-size-55 {
		font-size: 35px;
	}
	.font-size-56 {
		font-size: 36px;
	}
	.font-size-40 {
		font-size: 28px;
	}
	.font-size-24, .font-size-23 {
		font-size: 18px;
	}
	.font-size-17 {
		font-size: 14px;
	}
}
