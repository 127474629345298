//Footer
#footer {
    /*position: fixed;
    left: 0;
    bottom: 0;*/
    a {
        text-decoration: none;
        color: $text;
        transition: color 0.5s ease;
        letter-spacing: 1.26px;
        &:hover {
            color: $primary-color;
        }
    }
}