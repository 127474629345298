//Couleurs partenaires
body {
    //--Kolibri
	/*&.kolibri {
        --bs-primary: #ff97ad;
	}
    //--unim
	&.unim {
        --bs-primary: #ff97ad!important;
	}*/
    //--Tego
    &.tego,&.agpm {
        --bs-primary: #122b63!important;
    }
}
