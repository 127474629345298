//Top
@for $i from 0 through 100 {
    .t-#{$i} {
        top: #{$i}#{"%"};
    }
    .t-#{$i}px {
        top: #{$i}#{"px"};
    }
    .tn-#{$i}px {
        top: #{"-"}#{$i}#{"px"};
    }
}