//Colors
$medias: ("xs", "sm", "md", "lg", "xl", "xxl");

@each $key,
$value in $colors {

    //Colors
    .is-#{$key} {
        color: $value;
    }

    //Background
    .bg-is-#{$key} {
        background-color: $value;
    }

    @each $media in $medias {
        @include media-breakpoint-up($media) {
            .bg-is-#{$key}-#{$media} {
                background-color: $value;
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        .bg-is-#{$key}-half-bottom-xl {
            background: linear-gradient(180deg, transparent 50%, $value 50%);
        }
    }
    @include media-breakpoint-up(lg) {
        .bg-is-#{$key}-half-bottom {
            background: linear-gradient(180deg, transparent 50%, $value 50%);
        }
        .bg-is-#{$key}-half-bottom-lg {
            background: linear-gradient(180deg, transparent 50%, $value 50%);
        }
    }

    .bg-is-#{$key}-before {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $value;
            z-index: -1;
        }
    }

    //Border
    .border-is-#{$key} {
        border-color: $value!important;
    }
    .border-top-is-#{$key} {
        border-top-color: $value !important;
    }
    .border-right-is-#{$key} {
        border-right-color: $value !important;
    }
    .border-bottom-is-#{$key} {
        border-bottom-color: $value !important;
    }
    .border-left-is-#{$key} {
        border-left-color: $value !important;
    }
    //Outline
    .outline-is-#{$key} {
        outline: 1px solid $value;
    }
}

//Bg degrade 
@include media-breakpoint-up(lg) {
    .bg-is-third-fourteenth {
        background: linear-gradient(90deg, $third-color 36%, $fourteenth-color 36%);
    }
    .bg-is-fourteenth-fourth {
        background: linear-gradient(90deg, $fourteenth-color 52%, $fourth-color 52%);
    }
    .bg-is-eighth-nineth {
        background: linear-gradient(90deg, $eighth-color 52%, $nineth-color 52%);
    }
    .bg-is-partenaire {
        background: linear-gradient(90deg, #1C467E 36%, $sixteenth-color 36%);
    }
    .bg-is-markwhite {
        background: linear-gradient(90deg, $fifth-color 36%, $thirteenth-color 36%);
    }
    .bg-transparent-lg {
        background-color: transparent;
    }
}

//Filter 
.filter-is-white {
    filter: $filterWhite;
}

@include media-breakpoint-up(lg) {
    .bg-lg-white {
        background-color: white!important;
    }
}