//Margin 
@use "sass:math";

@for $i from 6 through 50 {
    //Top
    .mt-#{$i} {
        margin-top: #{math.div($i, 2)+0.5}#{"rem"};
    }
    //Bottom
    .mb-#{$i} {
        margin-bottom: #{math.div($i, 2)+0.5}#{"rem"};
    }
    //Gutter 
    .gx-#{$i} {
        --bs-gutter-x: #{math.div($i, 2)+0.5}#{"rem"};
    }
    @include media-breakpoint-up(xl) {
        .gx-xl-#{$i} {
            --bs-gutter-x: #{math.div($i, 2)+0.5}#{"rem"};
        }
    }
    @include media-breakpoint-up(lg) {
        .gx-lg-#{$i} {
            --bs-gutter-x: #{math.div($i, 2)+0.5}#{"rem"};
        }
    }

}

@for $i from 6 through 50 {
    .mt-n#{$i} {
        margin-top: #{"-"}#{math.div($i, 2)+0.5}#{"rem"};
    }
    .mt-n#{$i}px {
        margin-top: #{"-"}#{$i}#{"px"};
    }
    @include media-breakpoint-up(xl) {
        .mt-xl-n#{$i} {
            margin-top: #{"-"}#{math.div($i, 2)+0.5}#{"rem"};
        }
    }
    @include media-breakpoint-up(lg) {
        .mt-lg-n#{$i} {
            margin-top: #{"-"}#{math.div($i, 2)+0.5}#{"rem"};
        }
        .mt-lg-n#{$i}px {
            margin-top: #{"-"}#{$i}#{"px"};
        }
    }
}