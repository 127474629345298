//Modals
/* purgecss start ignore */

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
    &.show {
        opacity: var(--bs-backdrop-opacity);
    }
}

#modalAvisGen {
    .modal-dialog {
        display: flex;
        align-items: center;
        min-height: calc(100% - 1rem);
        @include media-breakpoint-up(sm) {
            min-height: calc(100% - 3.5rem);
        }
        .rating {
            border: none;
            i {
                font-size: 22px;
            }
            label {
                transition: all .2s ease-in-out;
                transform: scale(1);
                &:hover {
                    transform: scale(1.2);
                    /*i {
                        --bs-text-opacity: 1;
                        color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
                    }*/
                }
            }
            input + label {
                color: #FFD700;
            }
            input:checked ~ input:not(:checked) ~ label {
                color: #ddd;
            }
            input:checked ~ input:not(:checked) + label:hover ~ label {
                color: #ddd;
            }
            &:hover input + label, &:hover input:checked + label {
                color: #FFD700;
            }
            &:hover input:checked ~ input:not(:checked) ~ label:hover, &:hover input:checked ~ input:not(:checked) + label {
                color: #FFD700;
            }
            &:hover input:checked ~ input:not(:checked) ~ label:hover ~ label {
                color: #ddd !important;
            }
            input + label:hover ~ label {
                color: #ddd !important;
            }
        }
    }
}
/* purgecss end ignore */