//Card
.imageSplited {
    .diagonal {
        &.diagonal-image {
            @include media-breakpoint-up(lg) {
                position: relative;
                overflow: hidden;
                transition: 0.5s all;

                &::before {
                    content: "";
                    box-sizing: border-box;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    //background: #94BBC3;
                    background-origin: content-box;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-attachment: fixed;
                    top: 0;
                    left: 0;

                    border-right: 300px solid $secondary-color;
                    border-top: 80vh solid rgba(0, 0, 0, 0);
                    transition: 0.1s all;
                }
            }
        }

        &.right {
            background-color: $secondary-color;
        }
    }
}
.testimonial-right-bloc{
    padding-top:0 !important;
}

//Responsive
/*@media screen and (max-width: 600px) {
    .imageSplited {
        .diagonal {
            min-height: auto;
            padding-top: 45px !important;
        }
        .diagonal-image {
            position: relative;
            overflow: hidden;
            transition: 0.5s all;
            background-color: #94bbc3;
            background-size: 100% 100%;
            min-height: 50vh;
        }
    }
}*/
.bg-is-lightblue,
.highlight_search {
    white-space: nowrap;
    background-color: #fff686;
}

.card-discover {
    .discover-link {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: black;
        }
    }
}

//Card fiche
.cardFiche {
    img {
        width: 100%;
        height: 240px;
        position: relative;
        display: flex;
        overflow: hidden;
        &::before {
            content: "";
            background-image: url('/images/05.webp');
            width: 100%;
            height: inherit;
            display: block;
            margin: auto;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            left: 0;
            right: 0;
        }
    }
}
@media screen and (max-width:767px) {
    .testimonial-right-bloc{
        padding-top:1.5rem !important;
    }
}
