//Badge
.is-badge {
    &::after {
        content:'';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: solid 15px $primary-color;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
    }
    &.shadow-is-primary {
        box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(7 89 148 / 90%) !important;
    }
    &.btn-is-light {
        background-color: #F9F2EF;
        color: $primary-color;
    }
    &.shadow-is-light {
        box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(249 242 239 / 90%) !important;
        &::after {
            border-top-color: #F9F2EF;
        }
    }
}
