//Bandeau
.big-bandeau {
    min-height: 839px;
    overflow: hidden;
    &.home {
        background-image: url('/images/01.webp');
    }
    form {
        .btn {
            width: 200px;
        }
    }
    @include media-breakpoint-down(lg) {
        min-height: unset;
        &.home {
            background-size: cover;
        }
        &.results {
            &.home {
                background-size: contain;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &.home {
            background-size: 165% auto;
            background-image: url(/images/responsive-01.webp) !important;
            background-repeat: no-repeat;
            background-position: -150px 0px;
            background-color: $nineteenth-color;
        }
        &.home {
            .container {
                margin-top: 325px;
                display: block;
                background: $nineteenth-color;
            }
        }

    }
}
#searchbar-home{
    background-repeat-y: no-repeat;
    .page-link{
        cursor: pointer;
    }
}

.bandeau-account{
    position: relative;
    background-image: url(/images/account01.webp) !important;
    background-repeat: no-repeat;
    background-position: -450px 50%;
    background-size: cover;
    flex-grow: 1;

    &:before{
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg,#fbf2df, hsla(42,78%,86%,.65) 20%, hsla(42,78%,86%,.65) 40%,#fbf2df 60%,#fbf2df 100%);
    }
}