//SliderSlick
/* purgecss start ignore */
$colorDots: #D0DCE5;

.wrapper {
    padding: 70px 0;
    overflow-x: hidden;

    @include media-breakpoint-down(lg) {
        &.declaration {
            padding: 35px 0;
            padding-bottom: 22px;
        }
    }

    .slick-dots {
        &.is-other {
            position: relative;
            bottom: 0;
            width: auto;

            .slick-dots {
                position: relative;
                bottom: 0;
                width: auto;

                li {
                    width: 14px;
                    margin: 0 14px;
                    top: 1px;

                    button {
                        width: 14px;
                        height: 14px;
                        transform: scale(0.4);
                        &::before {
                            display: block;
                            font-size: 20px;
                            color: unset;
                            border-radius: 50%;
                            background-color: $colorDots;
                            width: 14px;
                            height: 14px;
                        }
                    }

                    &.slick-active {
                        button {
                            transform: scale(1);
                            &::before {
                                display: block;
                                border: 0;
                                background-color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.carousel {
    &.listCat {
        .cardIcon {
            transition: opacity 1s ease-out;
            opacity: 0;
            height: 0 !important;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                opacity: 1;
                height: inherit !important;
            }
        }
    }

    margin: 0px auto;
    overflow: hidden;

    &:not(.slick-initialized) {
        display: flex;

        .card {
            display: none;
        }

        ~.slider-controls {
            display: none;
        }
    }

    &.notslicked {
        .card {
            display: flex;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-breakpoint-down(md) {
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            .card {
                display: block;
            }
        }
    }

    .card {
        display: flex;
        transition: 0.4s ease-out;

        &:not(&.discoverlist) {
            img {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1rem;
                //height: 100%;
                height: 240px !important;
                background-color: $fourth-color;

                &.hide-src {
                    object-position: -99999px 99999px;
                }

                $filtersCat: (administratives: 'administratif', immobilier: 'immobilier', proprietaire: 'immobilier', travail: 'travail', automobilistes: 'automobiliste', consommateurs: 'consommateur', assurances: 'consommateur', telecoms: 'consommateur', banques: 'consommateur', service: 'consommateur', bien: 'consommateur', entreprises: 'entreprise', familles: 'famille', etrangers: 'etranger');

                @each $key,
                $value in $filtersCat {
                    &.#{$key} {
                        content: "";
                        background-image: url('/images/icones/#{$value}#{".svg"}');
                        width: 100%;
                        height: inherit;
                        display: block;
                        margin: auto;
                        background-repeat: no-repeat;
                        background-size: auto;
                        background-position: center;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }


        .content {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .nbdoc {
            font-size: 75px;
            line-height: 1.1,
        }

        .price {
            color: #FFF;
        }

        &:hover {
            background-color: #E6F4F6;

            .price {
                background-color: #FFF;
                color: $primary-color;
                border: 1px solid $primary-color;
            }

            .card-title {
                text-decoration: underline;
            }
        }
    }
}

.slick-track {
    display: flex !important;
    align-items: stretch;
    justify-content: center;
}

.slick-slide {
    margin: 10px;
    opacity: 0.8;
    transition: all 300ms ease;
    height: inherit !important;

    &.slick-active {
        opacity: 1;
    }

    img {
        width: 100%
    }
}

/* the slides */
.slick-slide {
    margin: 0 22px;
}

/* the parent */
.slick-list {
    margin: 0 -14px;
}

/*.slide-arrow{
    position: absolute;
    top: 50%;
    margin-top: -15px;
    &:hover {
        cursor: pointer;
    }
}*/

.prev-arrow,
.next-arrow {
    background: none;
    border: 0;
    z-index: 2;

    i {
        font-size: 35px;
    }
}

/*@include media-breakpoint-up(md) {
    .wrapper {
        .slick-dots {
            position: relative;
            bottom: 0;
            width: auto;

            li {
                width: 9px;
                //height: 100%;
                margin: 0 9px;

                button {
                    width: 9px;
                    height: 9px;

                    &::before {
                        font-size: 20px;
                        color: unset;
                        border-radius: 50%;
                        background-color: $colorDots;
                        width: 9px;
                        height: 9px;
                    }

                    &:hover {
                        &::before {
                            background-color: $primary-color;
                        }
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            border: 0;
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}*/

//Responsive
@include media-breakpoint-down(md) {
    .wrapper {
        padding-top: 0rem;
        padding-bottom: 0rem;
        overflow-y: hidden;

        /*.slider-controls {
            &:not(.is-default) {
                padding: 0 2rem;
                position: relative;
                top: -108px;
                margin-bottom: -108px;
                overflow-y: hidden;
            }

            &.demarches {
                top: -70px;
                margin-bottom: 0;
            }
        }

        .slick-dots-container.demarches {
            top: 62px;
            position: relative;
        }*/
    }

    //
    /*.slick-dots-container {
        width: 5.5rem;
        overflow: hidden;
        display: block;
        padding: 0;
        margin: 0.625rem auto;
        height: 0.875rem;
        position: relative;
        top: -18px;

        >ul {
            padding: 0;
            display: flex;
            transition: all 0.25s;
            position: relative;
            margin: 0;
            list-style: none;
            transform: translateX(0);
            align-items: center;
            bottom: unset;
            height: 100%;

            li {
                width: 0.625rem;
                height: 0.625rem;
                margin: 0 0.25rem;
                background-color: $colorDots;
                border: none;
                border-radius: 50%;

                button {
                    font-size: 0;
                    line-height: 0;
                    display: block;
                    width: 1.25rem;
                    height: 1.25rem;
                    padding: 0.3125rem;
                    cursor: pointer;
                    color: transparent;
                    border: 0;
                    outline: 0;
                    background: 0 0;
                }

                &.p-small-1,
                &.n-small-1 {
                    transform: scale(0.8);
                }

                &.slick-active {
                    transform: scale(1.3);
                    transform-origin: center;
                    background: $primary-color;
                }
            }
        }
    }*/

    /*/.slick-dots li button:before {
        display: none;
    }*/
}

///
/// .main_slider,
.slider_dots {
    width: 200px;
    display: flex;
}

.slide img {
    display: block;
    width: 100%;
    height: auto;
}

.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;

    li {
        margin: 0 0.25rem;

        &.slick-active button {
            background-color: $primary-color;
        }
    }

    button {
        display: block;
        width: 1rem;
        height: 1rem;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: grey;
        text-indent: -9999px;
    }
}

.slider_dots .slider_navigators {
    height: 14px !important;
    width: 14px !important;
    background-color: $colorDots;
    border-radius: 50%;
    margin: 10px;
    transform: scale(0.4);
    outline: none;
    cursor: pointer;
}

.slider_dots .slider_navigators.slick-active {
    transform: scale(0.70);
}

.slider_navigators.slick-active.slick-current {
    transform: scale(1);
    background-color: $primary-color;
}

// 
.main_slider {
    width: 100%;

    .slide img {
        display: block;
        width: 100%;
        height: auto;
    }
}

/* purgecss end ignore */
