//Left
@for $i from 0 through 100 {
    .l-#{$i} {
        left: #{$i}#{"%"};
    }
    .ln-#{$i} {
        left: #{"-"}#{$i}#{"%"};
    }
    .l-#{$i}px {
        left: #{$i}#{"px"};
    }
    .ln-#{$i}px {
        left: #{"-"}#{$i}#{"px"};
    }
}