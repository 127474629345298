//Slick Theme
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0,-50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 0;
	background: 0 0;
	right: -25px;
	&:focus {
		color: transparent;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:hover {
		color: transparent;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:before {
		font-family: slick;
		font-size: 20px;
		line-height: 1;
		opacity: .75;
		color: #fff;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '→';
	}
}
.slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0,-50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 0;
	background: 0 0;
	left: -25px;
	&:focus {
		color: transparent;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:hover {
		color: transparent;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:before {
		font-family: slick;
		font-size: 20px;
		line-height: 1;
		opacity: .75;
		color: #fff;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '←';
	}
}
.slick-next.slick-disabled {
	&:before {
		opacity: .25;
	}
}
.slick-prev.slick-disabled {
	&:before {
		opacity: .25;
	}
}
[dir=rtl] {
	.slick-prev {
		right: -25px;
		left: auto;
		&:before {
			content: '→';
		}
	}
	.slick-next {
		right: auto;
		left: -25px;
		&:before {
			content: '←';
		}
	}
}
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}
.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: 0;
			background: 0 0;
			&:focus {
				outline: 0;
				&:before {
					opacity: 1;
				}
			}
			&:hover {
				outline: 0;
				&:before {
					opacity: 1;
				}
			}
			&:before {
				font-family: slick;
				font-size: 6px;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				content: '•';
				text-align: center;
				opacity: 1;
				color: #000;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
	}
	li.slick-active {
		button {
			&:before {
				opacity: .75;
				color: #000;
			}
		}
	}
}
