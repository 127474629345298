// Body
/* purgecss start ignore */

$body-bg: #FFF;

// Typography
$font-size-base: 0.938rem;
$line-height-base: 1.6;

// Typography
//--Regular
$neo: 'AllianzNeo', sans-serif;
.is-neo {
    font-family: $neo;
}
//--Bold
$neoBold: 'AllianzNeoBold', sans-serif;
.is-neoBold {
    font-family: $neoBold;
}
//--Bold Italic
$neoBoldItalic: 'AllianzNeoBoldItalic', sans-serif;
.is-neoBoldItalic {
    font-family: $neoBoldItalic;
}
//--Italic
$neoItalic: 'AllianzNeoItalic', sans-serif;
.is-neoItalic {
    font-family: $neoItalic;
}
//--Light
$neoLight: 'AllianzNeoLight', sans-serif;
.is-neoLight {
    font-family: $neoLight;
}
//--Light Italic
$neoLightItalic: 'AllianzNeoLightItalic', sans-serif;
.is-neoLightItalic {
    font-family: $neoLightItalic;
}
//--SemiBold
$neoSemiBold: 'AllianzNeoSemiBold', sans-serif;
.is-neoSemiBold {
    font-family: $neoSemiBold;
}
//--Light Italic
$neoSemiBoldItalic: 'AllianzNeoSemiBoldItalic', sans-serif;
.is-neoSemiBoldItalic {
    font-family: $neoSemiBoldItalic;
}
//--Bootstrap Icons 
$bootstrapicons: 'bootstrap-icons';

// Colors
$primary-color: var(--bs-primary);
$secondary-color: #F7E6BF;
$third-color: #F89c97;
$fourth-color: #f9f2ef;
$fifth-color: #003780;
$sixth-color: #00aff7;
$seventh-color: #FBE4D5;
$eighth-color: #E6F4F6;
$nineth-color: #F5F5F5;
$tenth-color: #F83329;
$eleventh-color: #DDECD3;
$twelveth-color: #F7CAAB;
$thirteenth-color: #3165A0;
$fourteenth-color: #FFDAD8;
$fifteenth-color: #c1ebfb;
$sixteenth-color: #3486c1;
$seventeenth-color: #a6b7c5;
$eightenth-color: #ececec;
$nineteenth-color: #e6eef4;

$filterWhite: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(103%) contrast(102%);

$text: #414141;
$textlight: #686868;
$amiable: #9cce7b;
$clos: #f89c97;
$judiciaire: #ce987b;

$colors: (primary: $primary-color, secondary: $secondary-color, third: $third-color, fourth: $fourth-color,
    fifth: $fifth-color, sixth: $sixth-color, seventh: $seventh-color, eighth: $eighth-color, nineth: $nineth-color, 
    tenth: $tenth-color, eleventh: $eleventh-color, twelveth: $twelveth-color, thirteenth: $thirteenth-color, fourteenth: $fourteenth-color,
    fifteenth: $fifteenth-color,  sixteenth: $sixteenth-color,  seventeenth: $seventeenth-color, eightenth: $eightenth-color, nineteenth: $nineteenth-color, text: $text, textlight: $textlight, amiable: $amiable, clos: $clos, judiciaire: $judiciaire);

//Responsive
$media_1200: 1200px;
$media_1024: 1024px;
$media_991: 991px;
$media_768: 768px;
$media_575: 575px;
$media_400: 400px;

$enable-negative-margins: true;
$enable-rounded: true;

/* purgecss end ignore */
