//Fonts

//--Allianz Neo
//----Regular
@font-face {
    font-family: 'AllianzNeo';
    src: local('AllianzNeo'), local('AllianzNeo'),
        url('/fonts/neo/Allianz-Neo.woff2') format('woff2');
    font-display: swap;
}
//----Bold
@font-face {
    font-family: 'AllianzNeoBold';
    src: local('AllianzNeoBold'), local('AllianzNeoBold'),
        url('/fonts/neo/Allianz-Neo-Bold.woff2') format('woff2');
    font-display: swap;
}
//----Bold Italic
@font-face {
    font-family: 'AllianzNeoBoldItalic';
    src: local('AllianzNeoBoldItalic'), local('AllianzNeoBoldItalic'),
        url('/fonts/neo/Allianz-Neo-Bold-Italic.woff2') format('woff2');
    font-display: swap;
}
//----Italic
@font-face {
    font-family: 'AllianzNeoItalic';
    src: local('AllianzNeoItalic'), local('AllianzNeoItalic'),
        url('/fonts/neo/Allianz-Neo-Italic.woff2') format('woff2');
    font-display: swap;
}
//----Light
@font-face {
    font-family: 'AllianzNeoLight';
    src: local('AllianzNeoLight'), local('AllianzNeoLight'),
        url('/fonts/neo/Allianz-Neo-Light.woff2') format('woff2');
    font-display: swap;
}
//----Light Italic
@font-face {
    font-family: 'AllianzNeoLightItalic';
    src: local('AllianzNeoLightItalic'), local('AllianzNeoLightItalic'),
        url('/fonts/neo/Allianz-Neo-Light-Italic.woff2') format('woff2');
    font-display: swap;
}
//----Semi Bold
@font-face {
    font-family: 'AllianzNeoSemiBold';
    src: local('AllianzNeoSemiBold'), local('AllianzNeoSemiBold'),
        url('/fonts/neo/Allianz-Neo-Semibold.woff2') format('woff2');
    font-display: swap;
}
//----Semi Bold Italic
@font-face {
    font-family: 'AllianzNeoSemiBoldItalic';
    src: local('AllianzNeoSemiBoldItalic'), local('AllianzNeoSemiBoldItalic'),
        url('/fonts/neo/Allianz-Neo-Semibold-Italic.woff2') format('woff2');
    font-display: swap;
}


//Bootstrap Icons
@font-face {
    font-display: swap;
    font-family: 'bootstrap-icons';
    src: local('bootstrap-icons'), local('bootstrap-icons'),
        url('/fonts/vendor/bootstrap-icons/bootstrap-icons.woff22?a13b815539b49de48d2358b4281b2f1a') format('woff22');
}