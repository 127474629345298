//Buttons
/* purgecss start ignore */
.btn {
    transition: all 0.33s!important;
}
@each $key, $value in $colors {
    //Pleins
    .btn-is-#{$key} {
        color: #FFF;
        background-color: $value;
        border-color: $value!important;
        &.is-primary {
            color: $primary-color;
        }
    }
    .is-hover-color-#{$key} {
        &:hover {
            color: $value!important;
        }
    }
}

//Buttons
button {
    &:focus-visible {
        outline: none;
    }
    &.nextBtn,&.prevBtn,#buttonAvisModal,#clickmodalAvisGen{
        border: 1px solid $primary-color;
        color: $primary-color;
        cursor:pointer;
        --bs-border-width: 2px;
        margin: 3rem 0;
        font-weight: var(--bs-btn-font-weight) !important;
        line-height: var(--bs-btn-line-height) !important;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
        &.float-right{
            float: right;
        }
        &.float-left{
            float: left;
        }
        &:hover{
            background-color: $primary-color;
            color: #fff;
            i{
                color: #fff;
            }
        }
    }
    //Filters
    &.filter-button {
        background-color: #FFF;
        color: $text;
        border: 1px solid #DADADA;
        transition: all 0.33s;

        &:not(.database) {

            $filtersCat: (administratives: 'administratif', immobilier: 'immobilier', travail: 'travail', automobilistes: 'automobiliste', consommateurs: 'consommateur', entreprises: 'entreprise', familles: 'famille', etrangers: 'etranger');

            span {
                @each $key, $value in $filtersCat {
                    &.#{$key} {
                        background-image: url('/images/icones/#{$value}#{".svg"}');
                        width: 15px;
                        height: 19px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                }
            }
        }

        &.database {
            $filtersCatBasedoc: (administration: "\f62e", consommation: "\f634", droit-penal: url("/images/icones/scale-balanced.svg"), famille: "\f4d0", fiscalite-impots: "\f4e9", immobilier-construction: "\f423", professionnels-et-entreprises: "\f1dd", travail: "\f1cc");
            span {
                @each $key, $value in $filtersCatBasedoc {
                    &.#{$key} {
                        &::before {
                            content: $value;
                            font-size: 12px;
                            font-family: $bootstrapicons;
                            width: 15px;
                        }
                    }
                }
            }
        }


        &:hover, &.active {
            background-color: $primary-color;
            color: #FFF;
            font-weight: bold;
            span {
                filter: $filterWhite;
            }
        }
    }
}
.no-check-paiement{
    &:hover{
        color:#fff !important;
        background-color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity)) !important;
    }
}
.bloc-promo-input{
    button{
        background-color: #fff;
        border: 1px solid $primary-color;
        color: $primary-color;
        cursor:pointer;
        border-radius: 3px;
        font-weight: var(--bs-btn-font-weight) !important;
        line-height: var(--bs-btn-line-height) !important;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
        text-transform: uppercase;
        i{
            color: $primary-color;
        }
        &:hover{
            background-color: $primary-color;
            color: #fff;
            i{
                color: #fff;
            }
        }
    }
}
#previewDoc{
    background-color: $fifteenth-color;
    border: 1px solid $fifteenth-color;
    color: $primary-color;
    cursor:pointer;
    font-weight: var(--bs-btn-font-weight) !important;
    line-height: var(--bs-btn-line-height) !important;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
    text-transform: uppercase;
    i{
        color: $primary-color;
    }
    &:hover{
        background-color: $primary-color;
        color: $fifteenth-color;
        i{
            color: $fifteenth-color;
        }
    }
}
#procedPaiement{
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
    cursor:pointer;
    font-weight: var(--bs-btn-font-weight) !important;
    line-height: var(--bs-btn-line-height) !important;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
    text-transform: uppercase;
    i{
        color: #fff;
    }
    &:hover{
        background-color: #fff;
        color: $primary-color;
        i{
            color: $primary-color;
        }
    }
}
.lettreRecommande a, #buttonAvisModal,#clickmodalAvisGen{
    border: 1px solid $primary-color;
    color: $primary-color;
    cursor:pointer;
    --bs-border-width: 2px;
    margin: 3rem 0;
    font-weight: var(--bs-btn-font-weight) !important;
    line-height: var(--bs-btn-line-height) !important;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
    &:hover{
        background-color: $primary-color;
        color: #fff;
        i{
            color: #fff;
        }
    }
}
.btn {
    &:focus {
        box-shadow: none;
    }

    //Effects
    &.effect1 {
        @each $key, $value in $colors {
            //Pleins
            &.btn-is-#{$key} {
                &:hover {
                    color: $value;
                    background-color: #FFF;
                }
            }
            //Hover border
            &.is-hover-border-#{$key} {
                border-color: $value;
            }
        }
    }

    //Borders
    @each $key, $value in $colors {
        &.btn-is-border-#{$key} {
            border: 1px solid $value;
            &:hover {
                background-color: $value;
                color: #FFF;
            }
        }
    }
}
/* purgecss end ignore */