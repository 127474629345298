//Width 
$medias: ("xs", "sm", "md", "lg", "xl", "xxl");

@for $i from 0 through 100 {
    .w-#{$i} {
        width: #{$i}#{"%"};
    }

    .w-#{$i}px {
        width: #{$i}#{"px"};
    }
}
.w-fit-content {
    width: fit-content;
}
.min-w-100 {
    min-width: 100%;
}
@each $media in $medias {
    @include media-breakpoint-up($media) {
        .w-#{$media}-auto {
            width: auto!important;
        }
        @for $i from 0 through 1000 {
            .w-#{$media}-#{$i}px {
                width: #{$i}#{"px"}!important;
            }
        }
    }
}