//Body

/* purgecss start ignore */
* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    position: relative;
    min-height: 100%;
    &:focus {
        outline: none;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $neo;
}

body {
    background-color: $body-bg;
    font-size: $font-size-base;
    //Par défaut
    --bs-primary: #075994;

    height: 100%;
	position: relative;

    &:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, .5);
		transition: .3s all ease;
		opacity: 0;
		visibility: hidden;
		z-index: 1;
	}

    &.offcanvas-menu {
		&::before {
			opacity: 1;
			z-index: 1002;
			visibility: visible;
		}
	}

}

//Partenaires Couleurs
@import "../partenaires/allpartenaires";

//Objectfit
.objectfitcover {
    object-fit: cover;
}
.z-index-1 {
    z-index: 1;
}
//Text with hr right
.hrtext {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hrtext span {
    flex-grow: 0;
}

.headerhr {
    flex-grow: 1;
    height: 2px;
    margin: 0 0 0 2rem;
    background-color: #e6e6e6;
}
.flex-inherit {
    flex-direction: inherit;
}
//Align text 
.text-justify {
    text-align: justify;
}
//Icones
i {
	font-size: $font-size-base;
}

//Meadia Breakingpoint Table 
$medias: ("xs", "sm", "md", "lg", "xl", "xxl");

@include media-breakpoint-up(lg) {
    .h-lg-100 {
        height: 100%;
    }
    .position-lg-relative {
        position: relative!important;
    }
    .d-lg-unset {
        display: unset;
    }
}
@include media-breakpoint-up(md) {
    .h-md-100 {
        height: 100%;
    }
}

//Tarteaucitron 
.tarteaucitronTitle,
.tarteaucitronDetails {
    background: #000 !important;
}

#tarteaucitronIcon {
    left: 0;
    right: unset;
}

//Appearlazy - Lazyload
.appear[data-src], .appear[data-bkg], .appear [data-src], .appear [data-bkg] {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.is-lazyload[data-src], .is-lazyload[data-bkg], .is-lazyload [data-src], .is-lazyload [data-bkg] {
    opacity: 0;
    transition: opacity 0.33s ease-in;
}

.appeared[data-src], .appeared[data-bkg], .appeared [data-src], .appeared [data-bkg],
.lazyloaded[data-src], .lazyloaded[data-bkg], .lazyloaded [data-src], .lazyloaded [data-bkg] {
    opacity: 1;
}

@include media-breakpoint-between(xs, xl){
    .img-fluid-xs-xl{
        width: 100%;
        height: auto;
    }
}

//Background position 
.bg-position-bottom {
    background-position: bottom;
}
.bg-position-top {
    background-position: top;
}
@include media-breakpoint-up(sm) {
    .d-sm-flex {
        display: flex!important;
    }
}
/* purgecss end ignore */
