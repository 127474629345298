//Loader form send-reporting
/* purgecss start ignore */
.send-reporting-loader{
    .blockUI{
        top: 0;
        left: 0;
    }

    .blockMsg{
        width: 171px;
        margin-left: -86px !important;
        margin-top: -15px !important;
        z-index: 1011;
    }

    .blockOverlay{
        background: rgb(255, 255, 255, 0.5);
        z-index: 10;
    }

    .blockElement{
        left: 50%;
        top: 50%;
    }
}

//Iframe Loading 
.loading {
    /* Absolute position */
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* Center */
    align-items: center;
    display: flex;
    justify-content: center;
}
/* purgecss end ignore */