@charset "UTF-8";
@font-face {
  font-family: "AllianzNeo";
  src: local("AllianzNeo"), local("AllianzNeo"), url("/fonts/neo/Allianz-Neo.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoBold";
  src: local("AllianzNeoBold"), local("AllianzNeoBold"), url("/fonts/neo/Allianz-Neo-Bold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoBoldItalic";
  src: local("AllianzNeoBoldItalic"), local("AllianzNeoBoldItalic"), url("/fonts/neo/Allianz-Neo-Bold-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoItalic";
  src: local("AllianzNeoItalic"), local("AllianzNeoItalic"), url("/fonts/neo/Allianz-Neo-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoLight";
  src: local("AllianzNeoLight"), local("AllianzNeoLight"), url("/fonts/neo/Allianz-Neo-Light.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoLightItalic";
  src: local("AllianzNeoLightItalic"), local("AllianzNeoLightItalic"), url("/fonts/neo/Allianz-Neo-Light-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoSemiBold";
  src: local("AllianzNeoSemiBold"), local("AllianzNeoSemiBold"), url("/fonts/neo/Allianz-Neo-Semibold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AllianzNeoSemiBoldItalic";
  src: local("AllianzNeoSemiBoldItalic"), local("AllianzNeoSemiBoldItalic"), url("/fonts/neo/Allianz-Neo-Semibold-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-display: swap;
  font-family: "bootstrap-icons";
  src: local("bootstrap-icons"), local("bootstrap-icons"), url("/fonts/vendor/bootstrap-icons/bootstrap-icons.woff22?a13b815539b49de48d2358b4281b2f1a") format("woff22");
}
/* purgecss start ignore */
.is-neo {
  font-family: "AllianzNeo", sans-serif;
}

.is-neoBold {
  font-family: "AllianzNeoBold", sans-serif;
}

.is-neoBoldItalic {
  font-family: "AllianzNeoBoldItalic", sans-serif;
}

.is-neoItalic {
  font-family: "AllianzNeoItalic", sans-serif;
}

.is-neoLight {
  font-family: "AllianzNeoLight", sans-serif;
}

.is-neoLightItalic {
  font-family: "AllianzNeoLightItalic", sans-serif;
}

.is-neoSemiBold {
  font-family: "AllianzNeoSemiBold", sans-serif;
}

.is-neoSemiBoldItalic {
  font-family: "AllianzNeoSemiBoldItalic", sans-serif;
}

/* purgecss end ignore */
/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("./fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

.bi-alphabet-uppercase::before {
  content: "\f2a5";
}

.bi-alphabet::before {
  content: "\f68a";
}

.bi-amazon::before {
  content: "\f68d";
}

.bi-arrows-collapse-vertical::before {
  content: "\f690";
}

.bi-arrows-expand-vertical::before {
  content: "\f695";
}

.bi-arrows-vertical::before {
  content: "\f698";
}

.bi-arrows::before {
  content: "\f6a2";
}

.bi-ban-fill::before {
  content: "\f6a3";
}

.bi-ban::before {
  content: "\f6b6";
}

.bi-bing::before {
  content: "\f6c2";
}

.bi-cake::before {
  content: "\f6e0";
}

.bi-cake2::before {
  content: "\f6ed";
}

.bi-cookie::before {
  content: "\f6ee";
}

.bi-copy::before {
  content: "\f759";
}

.bi-crosshair::before {
  content: "\f769";
}

.bi-crosshair2::before {
  content: "\f794";
}

.bi-emoji-astonished-fill::before {
  content: "\f795";
}

.bi-emoji-astonished::before {
  content: "\f79a";
}

.bi-emoji-grimace-fill::before {
  content: "\f79b";
}

.bi-emoji-grimace::before {
  content: "\f7a0";
}

.bi-emoji-grin-fill::before {
  content: "\f7a1";
}

.bi-emoji-grin::before {
  content: "\f7a6";
}

.bi-emoji-surprise-fill::before {
  content: "\f7a7";
}

.bi-emoji-surprise::before {
  content: "\f7ac";
}

.bi-emoji-tear-fill::before {
  content: "\f7ad";
}

.bi-emoji-tear::before {
  content: "\f7b2";
}

.bi-envelope-arrow-down-fill::before {
  content: "\f7b3";
}

.bi-envelope-arrow-down::before {
  content: "\f7b8";
}

.bi-envelope-arrow-up-fill::before {
  content: "\f7b9";
}

.bi-envelope-arrow-up::before {
  content: "\f7be";
}

.bi-feather::before {
  content: "\f7bf";
}

.bi-feather2::before {
  content: "\f7c4";
}

.bi-floppy-fill::before {
  content: "\f7c5";
}

.bi-floppy::before {
  content: "\f7d8";
}

.bi-floppy2-fill::before {
  content: "\f7d9";
}

.bi-floppy2::before {
  content: "\f7e4";
}

.bi-gitlab::before {
  content: "\f7e5";
}

.bi-highlighter::before {
  content: "\f7f8";
}

.bi-marker-tip::before {
  content: "\f802";
}

.bi-nvme-fill::before {
  content: "\f803";
}

.bi-nvme::before {
  content: "\f80c";
}

.bi-opencollective::before {
  content: "\f80d";
}

.bi-pci-card-network::before {
  content: "\f8cd";
}

.bi-pci-card-sound::before {
  content: "\f8ce";
}

.bi-radar::before {
  content: "\f8cf";
}

.bi-send-arrow-down-fill::before {
  content: "\f8d0";
}

.bi-send-arrow-down::before {
  content: "\f8d1";
}

.bi-send-arrow-up-fill::before {
  content: "\f8d2";
}

.bi-send-arrow-up::before {
  content: "\f8d3";
}

.bi-sim-slash-fill::before {
  content: "\f8d4";
}

.bi-sim-slash::before {
  content: "\f8d5";
}

.bi-sourceforge::before {
  content: "\f8d6";
}

.bi-substack::before {
  content: "\f8d7";
}

.bi-threads-fill::before {
  content: "\f8d8";
}

.bi-threads::before {
  content: "\f8d9";
}

.bi-transparency::before {
  content: "\f8da";
}

.bi-twitter-x::before {
  content: "\f8db";
}

.bi-type-h4::before {
  content: "\f8dc";
}

.bi-type-h5::before {
  content: "\f8dd";
}

.bi-type-h6::before {
  content: "\f8de";
}

.bi-backpack-fill::before {
  content: "\f8df";
}

.bi-backpack::before {
  content: "\f8e0";
}

.bi-backpack2-fill::before {
  content: "\f8e1";
}

.bi-backpack2::before {
  content: "\f8e2";
}

.bi-backpack3-fill::before {
  content: "\f8e3";
}

.bi-backpack3::before {
  content: "\f8e4";
}

.bi-backpack4-fill::before {
  content: "\f8e5";
}

.bi-backpack4::before {
  content: "\f8e6";
}

.bi-brilliance::before {
  content: "\f8e7";
}

.bi-cake-fill::before {
  content: "\f8e8";
}

.bi-cake2-fill::before {
  content: "\f8e9";
}

.bi-duffle-fill::before {
  content: "\f8ea";
}

.bi-duffle::before {
  content: "\f8eb";
}

.bi-exposure::before {
  content: "\f8ec";
}

.bi-gender-neuter::before {
  content: "\f8ed";
}

.bi-highlights::before {
  content: "\f8ee";
}

.bi-luggage-fill::before {
  content: "\f8ef";
}

.bi-luggage::before {
  content: "\f8f0";
}

.bi-mailbox-flag::before {
  content: "\f8f1";
}

.bi-mailbox2-flag::before {
  content: "\f8f2";
}

.bi-noise-reduction::before {
  content: "\f8f3";
}

.bi-passport-fill::before {
  content: "\f8f4";
}

.bi-passport::before {
  content: "\f8f5";
}

.bi-person-arms-up::before {
  content: "\f8f6";
}

.bi-person-raised-hand::before {
  content: "\f8f7";
}

.bi-person-standing-dress::before {
  content: "\f8f8";
}

.bi-person-standing::before {
  content: "\f8f9";
}

.bi-person-walking::before {
  content: "\f8fa";
}

.bi-person-wheelchair::before {
  content: "\f8fb";
}

.bi-shadows::before {
  content: "\f8fc";
}

.bi-suitcase-fill::before {
  content: "\f8fd";
}

.bi-suitcase-lg-fill::before {
  content: "\f8fe";
}

.bi-suitcase-lg::before {
  content: "\f8ff";
}

.bi-suitcase::before {
  content: "豈";
}

.bi-suitcase2-fill::before {
  content: "更";
}

.bi-suitcase2::before {
  content: "車";
}

.bi-vignette::before {
  content: "賈";
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-primary: var(--bs-primary);
  --bs-secondary: #F7E6BF;
  --bs-third: #F89c97;
  --bs-fourth: #f9f2ef;
  --bs-fifth: #003780;
  --bs-sixth: #00aff7;
  --bs-seventh: #FBE4D5;
  --bs-eighth: #E6F4F6;
  --bs-nineth: #F5F5F5;
  --bs-tenth: #F83329;
  --bs-eleventh: #DDECD3;
  --bs-twelveth: #F7CAAB;
  --bs-thirteenth: #3165A0;
  --bs-fourteenth: #FFDAD8;
  --bs-fifteenth: #c1ebfb;
  --bs-sixteenth: #3486c1;
  --bs-seventeenth: #a6b7c5;
  --bs-eightenth: #ececec;
  --bs-nineteenth: #e6eef4;
  --bs-text: #414141;
  --bs-textlight: #686868;
  --bs-amiable: #9cce7b;
  --bs-clos: #f89c97;
  --bs-judiciaire: #ce987b;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.938rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #FFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3595rem + 1.314vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.345rem;
  }
}

h2, .h2 {
  font-size: calc(1.3126rem + 0.7512vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.876rem;
  }
}

h3, .h3 {
  font-size: calc(1.28915rem + 0.4698vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.6415rem;
  }
}

h4, .h4 {
  font-size: calc(1.2657rem + 0.1884vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.407rem;
  }
}

h5, .h5 {
  font-size: 1.1725rem;
}

h6, .h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.1725rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.1725rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.1725rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.82075rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.6em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.82075rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.1725rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82075rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.1725rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5008rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.1725rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.82075rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82075rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82075rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.938rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.1725rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.82075rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.938rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.82075rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3124rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.1725rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.1725rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.938rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.938rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.1725rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.82075rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.7035rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.6;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.82075rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.82075rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.938rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.6;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3595rem + 1.314vw) !important;
}

.fs-2 {
  font-size: calc(1.3126rem + 0.7512vw) !important;
}

.fs-3 {
  font-size: calc(1.28915rem + 0.4698vw) !important;
}

.fs-4 {
  font-size: calc(1.2657rem + 0.1884vw) !important;
}

.fs-5 {
  font-size: 1.1725rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.345rem !important;
  }
  .fs-2 {
    font-size: 1.876rem !important;
  }
  .fs-3 {
    font-size: 1.6415rem !important;
  }
  .fs-4 {
    font-size: 1.407rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.is-primary {
  color: var(--bs-primary);
}

.bg-is-primary {
  background-color: var(--bs-primary);
}

.bg-is-primary-xs {
  background-color: var(--bs-primary);
}

@media (min-width: 576px) {
  .bg-is-primary-sm {
    background-color: var(--bs-primary);
  }
}
@media (min-width: 768px) {
  .bg-is-primary-md {
    background-color: var(--bs-primary);
  }
}
@media (min-width: 992px) {
  .bg-is-primary-lg {
    background-color: var(--bs-primary);
  }
}
@media (min-width: 1200px) {
  .bg-is-primary-xl {
    background-color: var(--bs-primary);
  }
}
@media (min-width: 1400px) {
  .bg-is-primary-xxl {
    background-color: var(--bs-primary);
  }
}
@media (min-width: 1200px) {
  .bg-is-primary-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, var(--bs-primary) 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-primary-half-bottom {
    background: linear-gradient(180deg, transparent 50%, var(--bs-primary) 50%);
  }
  .bg-is-primary-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, var(--bs-primary) 50%);
  }
}
.bg-is-primary-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-primary);
  z-index: -1;
}

.border-is-primary {
  border-color: var(--bs-primary) !important;
}

.border-top-is-primary {
  border-top-color: var(--bs-primary) !important;
}

.border-right-is-primary {
  border-right-color: var(--bs-primary) !important;
}

.border-bottom-is-primary {
  border-bottom-color: var(--bs-primary) !important;
}

.border-left-is-primary {
  border-left-color: var(--bs-primary) !important;
}

.outline-is-primary {
  outline: 1px solid var(--bs-primary);
}

.is-secondary {
  color: #F7E6BF;
}

.bg-is-secondary {
  background-color: #F7E6BF;
}

.bg-is-secondary-xs {
  background-color: #F7E6BF;
}

@media (min-width: 576px) {
  .bg-is-secondary-sm {
    background-color: #F7E6BF;
  }
}
@media (min-width: 768px) {
  .bg-is-secondary-md {
    background-color: #F7E6BF;
  }
}
@media (min-width: 992px) {
  .bg-is-secondary-lg {
    background-color: #F7E6BF;
  }
}
@media (min-width: 1200px) {
  .bg-is-secondary-xl {
    background-color: #F7E6BF;
  }
}
@media (min-width: 1400px) {
  .bg-is-secondary-xxl {
    background-color: #F7E6BF;
  }
}
@media (min-width: 1200px) {
  .bg-is-secondary-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #F7E6BF 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-secondary-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #F7E6BF 50%);
  }
  .bg-is-secondary-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #F7E6BF 50%);
  }
}
.bg-is-secondary-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F7E6BF;
  z-index: -1;
}

.border-is-secondary {
  border-color: #F7E6BF !important;
}

.border-top-is-secondary {
  border-top-color: #F7E6BF !important;
}

.border-right-is-secondary {
  border-right-color: #F7E6BF !important;
}

.border-bottom-is-secondary {
  border-bottom-color: #F7E6BF !important;
}

.border-left-is-secondary {
  border-left-color: #F7E6BF !important;
}

.outline-is-secondary {
  outline: 1px solid #F7E6BF;
}

.is-third {
  color: #F89c97;
}

.bg-is-third {
  background-color: #F89c97;
}

.bg-is-third-xs {
  background-color: #F89c97;
}

@media (min-width: 576px) {
  .bg-is-third-sm {
    background-color: #F89c97;
  }
}
@media (min-width: 768px) {
  .bg-is-third-md {
    background-color: #F89c97;
  }
}
@media (min-width: 992px) {
  .bg-is-third-lg {
    background-color: #F89c97;
  }
}
@media (min-width: 1200px) {
  .bg-is-third-xl {
    background-color: #F89c97;
  }
}
@media (min-width: 1400px) {
  .bg-is-third-xxl {
    background-color: #F89c97;
  }
}
@media (min-width: 1200px) {
  .bg-is-third-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #F89c97 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-third-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #F89c97 50%);
  }
  .bg-is-third-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #F89c97 50%);
  }
}
.bg-is-third-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F89c97;
  z-index: -1;
}

.border-is-third {
  border-color: #F89c97 !important;
}

.border-top-is-third {
  border-top-color: #F89c97 !important;
}

.border-right-is-third {
  border-right-color: #F89c97 !important;
}

.border-bottom-is-third {
  border-bottom-color: #F89c97 !important;
}

.border-left-is-third {
  border-left-color: #F89c97 !important;
}

.outline-is-third {
  outline: 1px solid #F89c97;
}

.is-fourth {
  color: #f9f2ef;
}

.bg-is-fourth {
  background-color: #f9f2ef;
}

.bg-is-fourth-xs {
  background-color: #f9f2ef;
}

@media (min-width: 576px) {
  .bg-is-fourth-sm {
    background-color: #f9f2ef;
  }
}
@media (min-width: 768px) {
  .bg-is-fourth-md {
    background-color: #f9f2ef;
  }
}
@media (min-width: 992px) {
  .bg-is-fourth-lg {
    background-color: #f9f2ef;
  }
}
@media (min-width: 1200px) {
  .bg-is-fourth-xl {
    background-color: #f9f2ef;
  }
}
@media (min-width: 1400px) {
  .bg-is-fourth-xxl {
    background-color: #f9f2ef;
  }
}
@media (min-width: 1200px) {
  .bg-is-fourth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #f9f2ef 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-fourth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #f9f2ef 50%);
  }
  .bg-is-fourth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #f9f2ef 50%);
  }
}
.bg-is-fourth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f2ef;
  z-index: -1;
}

.border-is-fourth {
  border-color: #f9f2ef !important;
}

.border-top-is-fourth {
  border-top-color: #f9f2ef !important;
}

.border-right-is-fourth {
  border-right-color: #f9f2ef !important;
}

.border-bottom-is-fourth {
  border-bottom-color: #f9f2ef !important;
}

.border-left-is-fourth {
  border-left-color: #f9f2ef !important;
}

.outline-is-fourth {
  outline: 1px solid #f9f2ef;
}

.is-fifth {
  color: #003780;
}

.bg-is-fifth {
  background-color: #003780;
}

.bg-is-fifth-xs {
  background-color: #003780;
}

@media (min-width: 576px) {
  .bg-is-fifth-sm {
    background-color: #003780;
  }
}
@media (min-width: 768px) {
  .bg-is-fifth-md {
    background-color: #003780;
  }
}
@media (min-width: 992px) {
  .bg-is-fifth-lg {
    background-color: #003780;
  }
}
@media (min-width: 1200px) {
  .bg-is-fifth-xl {
    background-color: #003780;
  }
}
@media (min-width: 1400px) {
  .bg-is-fifth-xxl {
    background-color: #003780;
  }
}
@media (min-width: 1200px) {
  .bg-is-fifth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #003780 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-fifth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #003780 50%);
  }
  .bg-is-fifth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #003780 50%);
  }
}
.bg-is-fifth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #003780;
  z-index: -1;
}

.border-is-fifth {
  border-color: #003780 !important;
}

.border-top-is-fifth {
  border-top-color: #003780 !important;
}

.border-right-is-fifth {
  border-right-color: #003780 !important;
}

.border-bottom-is-fifth {
  border-bottom-color: #003780 !important;
}

.border-left-is-fifth {
  border-left-color: #003780 !important;
}

.outline-is-fifth {
  outline: 1px solid #003780;
}

.is-sixth {
  color: #00aff7;
}

.bg-is-sixth {
  background-color: #00aff7;
}

.bg-is-sixth-xs {
  background-color: #00aff7;
}

@media (min-width: 576px) {
  .bg-is-sixth-sm {
    background-color: #00aff7;
  }
}
@media (min-width: 768px) {
  .bg-is-sixth-md {
    background-color: #00aff7;
  }
}
@media (min-width: 992px) {
  .bg-is-sixth-lg {
    background-color: #00aff7;
  }
}
@media (min-width: 1200px) {
  .bg-is-sixth-xl {
    background-color: #00aff7;
  }
}
@media (min-width: 1400px) {
  .bg-is-sixth-xxl {
    background-color: #00aff7;
  }
}
@media (min-width: 1200px) {
  .bg-is-sixth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #00aff7 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-sixth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #00aff7 50%);
  }
  .bg-is-sixth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #00aff7 50%);
  }
}
.bg-is-sixth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00aff7;
  z-index: -1;
}

.border-is-sixth {
  border-color: #00aff7 !important;
}

.border-top-is-sixth {
  border-top-color: #00aff7 !important;
}

.border-right-is-sixth {
  border-right-color: #00aff7 !important;
}

.border-bottom-is-sixth {
  border-bottom-color: #00aff7 !important;
}

.border-left-is-sixth {
  border-left-color: #00aff7 !important;
}

.outline-is-sixth {
  outline: 1px solid #00aff7;
}

.is-seventh {
  color: #FBE4D5;
}

.bg-is-seventh {
  background-color: #FBE4D5;
}

.bg-is-seventh-xs {
  background-color: #FBE4D5;
}

@media (min-width: 576px) {
  .bg-is-seventh-sm {
    background-color: #FBE4D5;
  }
}
@media (min-width: 768px) {
  .bg-is-seventh-md {
    background-color: #FBE4D5;
  }
}
@media (min-width: 992px) {
  .bg-is-seventh-lg {
    background-color: #FBE4D5;
  }
}
@media (min-width: 1200px) {
  .bg-is-seventh-xl {
    background-color: #FBE4D5;
  }
}
@media (min-width: 1400px) {
  .bg-is-seventh-xxl {
    background-color: #FBE4D5;
  }
}
@media (min-width: 1200px) {
  .bg-is-seventh-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #FBE4D5 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-seventh-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #FBE4D5 50%);
  }
  .bg-is-seventh-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #FBE4D5 50%);
  }
}
.bg-is-seventh-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FBE4D5;
  z-index: -1;
}

.border-is-seventh {
  border-color: #FBE4D5 !important;
}

.border-top-is-seventh {
  border-top-color: #FBE4D5 !important;
}

.border-right-is-seventh {
  border-right-color: #FBE4D5 !important;
}

.border-bottom-is-seventh {
  border-bottom-color: #FBE4D5 !important;
}

.border-left-is-seventh {
  border-left-color: #FBE4D5 !important;
}

.outline-is-seventh {
  outline: 1px solid #FBE4D5;
}

.is-eighth {
  color: #E6F4F6;
}

.bg-is-eighth {
  background-color: #E6F4F6;
}

.bg-is-eighth-xs {
  background-color: #E6F4F6;
}

@media (min-width: 576px) {
  .bg-is-eighth-sm {
    background-color: #E6F4F6;
  }
}
@media (min-width: 768px) {
  .bg-is-eighth-md {
    background-color: #E6F4F6;
  }
}
@media (min-width: 992px) {
  .bg-is-eighth-lg {
    background-color: #E6F4F6;
  }
}
@media (min-width: 1200px) {
  .bg-is-eighth-xl {
    background-color: #E6F4F6;
  }
}
@media (min-width: 1400px) {
  .bg-is-eighth-xxl {
    background-color: #E6F4F6;
  }
}
@media (min-width: 1200px) {
  .bg-is-eighth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #E6F4F6 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-eighth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #E6F4F6 50%);
  }
  .bg-is-eighth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #E6F4F6 50%);
  }
}
.bg-is-eighth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E6F4F6;
  z-index: -1;
}

.border-is-eighth {
  border-color: #E6F4F6 !important;
}

.border-top-is-eighth {
  border-top-color: #E6F4F6 !important;
}

.border-right-is-eighth {
  border-right-color: #E6F4F6 !important;
}

.border-bottom-is-eighth {
  border-bottom-color: #E6F4F6 !important;
}

.border-left-is-eighth {
  border-left-color: #E6F4F6 !important;
}

.outline-is-eighth {
  outline: 1px solid #E6F4F6;
}

.is-nineth {
  color: #F5F5F5;
}

.bg-is-nineth {
  background-color: #F5F5F5;
}

.bg-is-nineth-xs {
  background-color: #F5F5F5;
}

@media (min-width: 576px) {
  .bg-is-nineth-sm {
    background-color: #F5F5F5;
  }
}
@media (min-width: 768px) {
  .bg-is-nineth-md {
    background-color: #F5F5F5;
  }
}
@media (min-width: 992px) {
  .bg-is-nineth-lg {
    background-color: #F5F5F5;
  }
}
@media (min-width: 1200px) {
  .bg-is-nineth-xl {
    background-color: #F5F5F5;
  }
}
@media (min-width: 1400px) {
  .bg-is-nineth-xxl {
    background-color: #F5F5F5;
  }
}
@media (min-width: 1200px) {
  .bg-is-nineth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #F5F5F5 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-nineth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #F5F5F5 50%);
  }
  .bg-is-nineth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #F5F5F5 50%);
  }
}
.bg-is-nineth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
  z-index: -1;
}

.border-is-nineth {
  border-color: #F5F5F5 !important;
}

.border-top-is-nineth {
  border-top-color: #F5F5F5 !important;
}

.border-right-is-nineth {
  border-right-color: #F5F5F5 !important;
}

.border-bottom-is-nineth {
  border-bottom-color: #F5F5F5 !important;
}

.border-left-is-nineth {
  border-left-color: #F5F5F5 !important;
}

.outline-is-nineth {
  outline: 1px solid #F5F5F5;
}

.is-tenth {
  color: #F83329;
}

.bg-is-tenth {
  background-color: #F83329;
}

.bg-is-tenth-xs {
  background-color: #F83329;
}

@media (min-width: 576px) {
  .bg-is-tenth-sm {
    background-color: #F83329;
  }
}
@media (min-width: 768px) {
  .bg-is-tenth-md {
    background-color: #F83329;
  }
}
@media (min-width: 992px) {
  .bg-is-tenth-lg {
    background-color: #F83329;
  }
}
@media (min-width: 1200px) {
  .bg-is-tenth-xl {
    background-color: #F83329;
  }
}
@media (min-width: 1400px) {
  .bg-is-tenth-xxl {
    background-color: #F83329;
  }
}
@media (min-width: 1200px) {
  .bg-is-tenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #F83329 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-tenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #F83329 50%);
  }
  .bg-is-tenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #F83329 50%);
  }
}
.bg-is-tenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F83329;
  z-index: -1;
}

.border-is-tenth {
  border-color: #F83329 !important;
}

.border-top-is-tenth {
  border-top-color: #F83329 !important;
}

.border-right-is-tenth {
  border-right-color: #F83329 !important;
}

.border-bottom-is-tenth {
  border-bottom-color: #F83329 !important;
}

.border-left-is-tenth {
  border-left-color: #F83329 !important;
}

.outline-is-tenth {
  outline: 1px solid #F83329;
}

.is-eleventh {
  color: #DDECD3;
}

.bg-is-eleventh {
  background-color: #DDECD3;
}

.bg-is-eleventh-xs {
  background-color: #DDECD3;
}

@media (min-width: 576px) {
  .bg-is-eleventh-sm {
    background-color: #DDECD3;
  }
}
@media (min-width: 768px) {
  .bg-is-eleventh-md {
    background-color: #DDECD3;
  }
}
@media (min-width: 992px) {
  .bg-is-eleventh-lg {
    background-color: #DDECD3;
  }
}
@media (min-width: 1200px) {
  .bg-is-eleventh-xl {
    background-color: #DDECD3;
  }
}
@media (min-width: 1400px) {
  .bg-is-eleventh-xxl {
    background-color: #DDECD3;
  }
}
@media (min-width: 1200px) {
  .bg-is-eleventh-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #DDECD3 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-eleventh-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #DDECD3 50%);
  }
  .bg-is-eleventh-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #DDECD3 50%);
  }
}
.bg-is-eleventh-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #DDECD3;
  z-index: -1;
}

.border-is-eleventh {
  border-color: #DDECD3 !important;
}

.border-top-is-eleventh {
  border-top-color: #DDECD3 !important;
}

.border-right-is-eleventh {
  border-right-color: #DDECD3 !important;
}

.border-bottom-is-eleventh {
  border-bottom-color: #DDECD3 !important;
}

.border-left-is-eleventh {
  border-left-color: #DDECD3 !important;
}

.outline-is-eleventh {
  outline: 1px solid #DDECD3;
}

.is-twelveth {
  color: #F7CAAB;
}

.bg-is-twelveth {
  background-color: #F7CAAB;
}

.bg-is-twelveth-xs {
  background-color: #F7CAAB;
}

@media (min-width: 576px) {
  .bg-is-twelveth-sm {
    background-color: #F7CAAB;
  }
}
@media (min-width: 768px) {
  .bg-is-twelveth-md {
    background-color: #F7CAAB;
  }
}
@media (min-width: 992px) {
  .bg-is-twelveth-lg {
    background-color: #F7CAAB;
  }
}
@media (min-width: 1200px) {
  .bg-is-twelveth-xl {
    background-color: #F7CAAB;
  }
}
@media (min-width: 1400px) {
  .bg-is-twelveth-xxl {
    background-color: #F7CAAB;
  }
}
@media (min-width: 1200px) {
  .bg-is-twelveth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #F7CAAB 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-twelveth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #F7CAAB 50%);
  }
  .bg-is-twelveth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #F7CAAB 50%);
  }
}
.bg-is-twelveth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F7CAAB;
  z-index: -1;
}

.border-is-twelveth {
  border-color: #F7CAAB !important;
}

.border-top-is-twelveth {
  border-top-color: #F7CAAB !important;
}

.border-right-is-twelveth {
  border-right-color: #F7CAAB !important;
}

.border-bottom-is-twelveth {
  border-bottom-color: #F7CAAB !important;
}

.border-left-is-twelveth {
  border-left-color: #F7CAAB !important;
}

.outline-is-twelveth {
  outline: 1px solid #F7CAAB;
}

.is-thirteenth {
  color: #3165A0;
}

.bg-is-thirteenth {
  background-color: #3165A0;
}

.bg-is-thirteenth-xs {
  background-color: #3165A0;
}

@media (min-width: 576px) {
  .bg-is-thirteenth-sm {
    background-color: #3165A0;
  }
}
@media (min-width: 768px) {
  .bg-is-thirteenth-md {
    background-color: #3165A0;
  }
}
@media (min-width: 992px) {
  .bg-is-thirteenth-lg {
    background-color: #3165A0;
  }
}
@media (min-width: 1200px) {
  .bg-is-thirteenth-xl {
    background-color: #3165A0;
  }
}
@media (min-width: 1400px) {
  .bg-is-thirteenth-xxl {
    background-color: #3165A0;
  }
}
@media (min-width: 1200px) {
  .bg-is-thirteenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #3165A0 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-thirteenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #3165A0 50%);
  }
  .bg-is-thirteenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #3165A0 50%);
  }
}
.bg-is-thirteenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3165A0;
  z-index: -1;
}

.border-is-thirteenth {
  border-color: #3165A0 !important;
}

.border-top-is-thirteenth {
  border-top-color: #3165A0 !important;
}

.border-right-is-thirteenth {
  border-right-color: #3165A0 !important;
}

.border-bottom-is-thirteenth {
  border-bottom-color: #3165A0 !important;
}

.border-left-is-thirteenth {
  border-left-color: #3165A0 !important;
}

.outline-is-thirteenth {
  outline: 1px solid #3165A0;
}

.is-fourteenth {
  color: #FFDAD8;
}

.bg-is-fourteenth {
  background-color: #FFDAD8;
}

.bg-is-fourteenth-xs {
  background-color: #FFDAD8;
}

@media (min-width: 576px) {
  .bg-is-fourteenth-sm {
    background-color: #FFDAD8;
  }
}
@media (min-width: 768px) {
  .bg-is-fourteenth-md {
    background-color: #FFDAD8;
  }
}
@media (min-width: 992px) {
  .bg-is-fourteenth-lg {
    background-color: #FFDAD8;
  }
}
@media (min-width: 1200px) {
  .bg-is-fourteenth-xl {
    background-color: #FFDAD8;
  }
}
@media (min-width: 1400px) {
  .bg-is-fourteenth-xxl {
    background-color: #FFDAD8;
  }
}
@media (min-width: 1200px) {
  .bg-is-fourteenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #FFDAD8 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-fourteenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #FFDAD8 50%);
  }
  .bg-is-fourteenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #FFDAD8 50%);
  }
}
.bg-is-fourteenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFDAD8;
  z-index: -1;
}

.border-is-fourteenth {
  border-color: #FFDAD8 !important;
}

.border-top-is-fourteenth {
  border-top-color: #FFDAD8 !important;
}

.border-right-is-fourteenth {
  border-right-color: #FFDAD8 !important;
}

.border-bottom-is-fourteenth {
  border-bottom-color: #FFDAD8 !important;
}

.border-left-is-fourteenth {
  border-left-color: #FFDAD8 !important;
}

.outline-is-fourteenth {
  outline: 1px solid #FFDAD8;
}

.is-fifteenth {
  color: #c1ebfb;
}

.bg-is-fifteenth {
  background-color: #c1ebfb;
}

.bg-is-fifteenth-xs {
  background-color: #c1ebfb;
}

@media (min-width: 576px) {
  .bg-is-fifteenth-sm {
    background-color: #c1ebfb;
  }
}
@media (min-width: 768px) {
  .bg-is-fifteenth-md {
    background-color: #c1ebfb;
  }
}
@media (min-width: 992px) {
  .bg-is-fifteenth-lg {
    background-color: #c1ebfb;
  }
}
@media (min-width: 1200px) {
  .bg-is-fifteenth-xl {
    background-color: #c1ebfb;
  }
}
@media (min-width: 1400px) {
  .bg-is-fifteenth-xxl {
    background-color: #c1ebfb;
  }
}
@media (min-width: 1200px) {
  .bg-is-fifteenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #c1ebfb 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-fifteenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #c1ebfb 50%);
  }
  .bg-is-fifteenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #c1ebfb 50%);
  }
}
.bg-is-fifteenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c1ebfb;
  z-index: -1;
}

.border-is-fifteenth {
  border-color: #c1ebfb !important;
}

.border-top-is-fifteenth {
  border-top-color: #c1ebfb !important;
}

.border-right-is-fifteenth {
  border-right-color: #c1ebfb !important;
}

.border-bottom-is-fifteenth {
  border-bottom-color: #c1ebfb !important;
}

.border-left-is-fifteenth {
  border-left-color: #c1ebfb !important;
}

.outline-is-fifteenth {
  outline: 1px solid #c1ebfb;
}

.is-sixteenth {
  color: #3486c1;
}

.bg-is-sixteenth {
  background-color: #3486c1;
}

.bg-is-sixteenth-xs {
  background-color: #3486c1;
}

@media (min-width: 576px) {
  .bg-is-sixteenth-sm {
    background-color: #3486c1;
  }
}
@media (min-width: 768px) {
  .bg-is-sixteenth-md {
    background-color: #3486c1;
  }
}
@media (min-width: 992px) {
  .bg-is-sixteenth-lg {
    background-color: #3486c1;
  }
}
@media (min-width: 1200px) {
  .bg-is-sixteenth-xl {
    background-color: #3486c1;
  }
}
@media (min-width: 1400px) {
  .bg-is-sixteenth-xxl {
    background-color: #3486c1;
  }
}
@media (min-width: 1200px) {
  .bg-is-sixteenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #3486c1 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-sixteenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #3486c1 50%);
  }
  .bg-is-sixteenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #3486c1 50%);
  }
}
.bg-is-sixteenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3486c1;
  z-index: -1;
}

.border-is-sixteenth {
  border-color: #3486c1 !important;
}

.border-top-is-sixteenth {
  border-top-color: #3486c1 !important;
}

.border-right-is-sixteenth {
  border-right-color: #3486c1 !important;
}

.border-bottom-is-sixteenth {
  border-bottom-color: #3486c1 !important;
}

.border-left-is-sixteenth {
  border-left-color: #3486c1 !important;
}

.outline-is-sixteenth {
  outline: 1px solid #3486c1;
}

.is-seventeenth {
  color: #a6b7c5;
}

.bg-is-seventeenth {
  background-color: #a6b7c5;
}

.bg-is-seventeenth-xs {
  background-color: #a6b7c5;
}

@media (min-width: 576px) {
  .bg-is-seventeenth-sm {
    background-color: #a6b7c5;
  }
}
@media (min-width: 768px) {
  .bg-is-seventeenth-md {
    background-color: #a6b7c5;
  }
}
@media (min-width: 992px) {
  .bg-is-seventeenth-lg {
    background-color: #a6b7c5;
  }
}
@media (min-width: 1200px) {
  .bg-is-seventeenth-xl {
    background-color: #a6b7c5;
  }
}
@media (min-width: 1400px) {
  .bg-is-seventeenth-xxl {
    background-color: #a6b7c5;
  }
}
@media (min-width: 1200px) {
  .bg-is-seventeenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #a6b7c5 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-seventeenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #a6b7c5 50%);
  }
  .bg-is-seventeenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #a6b7c5 50%);
  }
}
.bg-is-seventeenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a6b7c5;
  z-index: -1;
}

.border-is-seventeenth {
  border-color: #a6b7c5 !important;
}

.border-top-is-seventeenth {
  border-top-color: #a6b7c5 !important;
}

.border-right-is-seventeenth {
  border-right-color: #a6b7c5 !important;
}

.border-bottom-is-seventeenth {
  border-bottom-color: #a6b7c5 !important;
}

.border-left-is-seventeenth {
  border-left-color: #a6b7c5 !important;
}

.outline-is-seventeenth {
  outline: 1px solid #a6b7c5;
}

.is-eightenth {
  color: #ececec;
}

.bg-is-eightenth {
  background-color: #ececec;
}

.bg-is-eightenth-xs {
  background-color: #ececec;
}

@media (min-width: 576px) {
  .bg-is-eightenth-sm {
    background-color: #ececec;
  }
}
@media (min-width: 768px) {
  .bg-is-eightenth-md {
    background-color: #ececec;
  }
}
@media (min-width: 992px) {
  .bg-is-eightenth-lg {
    background-color: #ececec;
  }
}
@media (min-width: 1200px) {
  .bg-is-eightenth-xl {
    background-color: #ececec;
  }
}
@media (min-width: 1400px) {
  .bg-is-eightenth-xxl {
    background-color: #ececec;
  }
}
@media (min-width: 1200px) {
  .bg-is-eightenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #ececec 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-eightenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #ececec 50%);
  }
  .bg-is-eightenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #ececec 50%);
  }
}
.bg-is-eightenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ececec;
  z-index: -1;
}

.border-is-eightenth {
  border-color: #ececec !important;
}

.border-top-is-eightenth {
  border-top-color: #ececec !important;
}

.border-right-is-eightenth {
  border-right-color: #ececec !important;
}

.border-bottom-is-eightenth {
  border-bottom-color: #ececec !important;
}

.border-left-is-eightenth {
  border-left-color: #ececec !important;
}

.outline-is-eightenth {
  outline: 1px solid #ececec;
}

.is-nineteenth {
  color: #e6eef4;
}

.bg-is-nineteenth {
  background-color: #e6eef4;
}

.bg-is-nineteenth-xs {
  background-color: #e6eef4;
}

@media (min-width: 576px) {
  .bg-is-nineteenth-sm {
    background-color: #e6eef4;
  }
}
@media (min-width: 768px) {
  .bg-is-nineteenth-md {
    background-color: #e6eef4;
  }
}
@media (min-width: 992px) {
  .bg-is-nineteenth-lg {
    background-color: #e6eef4;
  }
}
@media (min-width: 1200px) {
  .bg-is-nineteenth-xl {
    background-color: #e6eef4;
  }
}
@media (min-width: 1400px) {
  .bg-is-nineteenth-xxl {
    background-color: #e6eef4;
  }
}
@media (min-width: 1200px) {
  .bg-is-nineteenth-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #e6eef4 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-nineteenth-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #e6eef4 50%);
  }
  .bg-is-nineteenth-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #e6eef4 50%);
  }
}
.bg-is-nineteenth-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6eef4;
  z-index: -1;
}

.border-is-nineteenth {
  border-color: #e6eef4 !important;
}

.border-top-is-nineteenth {
  border-top-color: #e6eef4 !important;
}

.border-right-is-nineteenth {
  border-right-color: #e6eef4 !important;
}

.border-bottom-is-nineteenth {
  border-bottom-color: #e6eef4 !important;
}

.border-left-is-nineteenth {
  border-left-color: #e6eef4 !important;
}

.outline-is-nineteenth {
  outline: 1px solid #e6eef4;
}

.is-text {
  color: #414141;
}

.bg-is-text {
  background-color: #414141;
}

.bg-is-text-xs {
  background-color: #414141;
}

@media (min-width: 576px) {
  .bg-is-text-sm {
    background-color: #414141;
  }
}
@media (min-width: 768px) {
  .bg-is-text-md {
    background-color: #414141;
  }
}
@media (min-width: 992px) {
  .bg-is-text-lg {
    background-color: #414141;
  }
}
@media (min-width: 1200px) {
  .bg-is-text-xl {
    background-color: #414141;
  }
}
@media (min-width: 1400px) {
  .bg-is-text-xxl {
    background-color: #414141;
  }
}
@media (min-width: 1200px) {
  .bg-is-text-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #414141 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-text-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #414141 50%);
  }
  .bg-is-text-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #414141 50%);
  }
}
.bg-is-text-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #414141;
  z-index: -1;
}

.border-is-text {
  border-color: #414141 !important;
}

.border-top-is-text {
  border-top-color: #414141 !important;
}

.border-right-is-text {
  border-right-color: #414141 !important;
}

.border-bottom-is-text {
  border-bottom-color: #414141 !important;
}

.border-left-is-text {
  border-left-color: #414141 !important;
}

.outline-is-text {
  outline: 1px solid #414141;
}

.is-textlight {
  color: #686868;
}

.bg-is-textlight {
  background-color: #686868;
}

.bg-is-textlight-xs {
  background-color: #686868;
}

@media (min-width: 576px) {
  .bg-is-textlight-sm {
    background-color: #686868;
  }
}
@media (min-width: 768px) {
  .bg-is-textlight-md {
    background-color: #686868;
  }
}
@media (min-width: 992px) {
  .bg-is-textlight-lg {
    background-color: #686868;
  }
}
@media (min-width: 1200px) {
  .bg-is-textlight-xl {
    background-color: #686868;
  }
}
@media (min-width: 1400px) {
  .bg-is-textlight-xxl {
    background-color: #686868;
  }
}
@media (min-width: 1200px) {
  .bg-is-textlight-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #686868 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-textlight-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #686868 50%);
  }
  .bg-is-textlight-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #686868 50%);
  }
}
.bg-is-textlight-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #686868;
  z-index: -1;
}

.border-is-textlight {
  border-color: #686868 !important;
}

.border-top-is-textlight {
  border-top-color: #686868 !important;
}

.border-right-is-textlight {
  border-right-color: #686868 !important;
}

.border-bottom-is-textlight {
  border-bottom-color: #686868 !important;
}

.border-left-is-textlight {
  border-left-color: #686868 !important;
}

.outline-is-textlight {
  outline: 1px solid #686868;
}

.is-amiable {
  color: #9cce7b;
}

.bg-is-amiable {
  background-color: #9cce7b;
}

.bg-is-amiable-xs {
  background-color: #9cce7b;
}

@media (min-width: 576px) {
  .bg-is-amiable-sm {
    background-color: #9cce7b;
  }
}
@media (min-width: 768px) {
  .bg-is-amiable-md {
    background-color: #9cce7b;
  }
}
@media (min-width: 992px) {
  .bg-is-amiable-lg {
    background-color: #9cce7b;
  }
}
@media (min-width: 1200px) {
  .bg-is-amiable-xl {
    background-color: #9cce7b;
  }
}
@media (min-width: 1400px) {
  .bg-is-amiable-xxl {
    background-color: #9cce7b;
  }
}
@media (min-width: 1200px) {
  .bg-is-amiable-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #9cce7b 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-amiable-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #9cce7b 50%);
  }
  .bg-is-amiable-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #9cce7b 50%);
  }
}
.bg-is-amiable-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9cce7b;
  z-index: -1;
}

.border-is-amiable {
  border-color: #9cce7b !important;
}

.border-top-is-amiable {
  border-top-color: #9cce7b !important;
}

.border-right-is-amiable {
  border-right-color: #9cce7b !important;
}

.border-bottom-is-amiable {
  border-bottom-color: #9cce7b !important;
}

.border-left-is-amiable {
  border-left-color: #9cce7b !important;
}

.outline-is-amiable {
  outline: 1px solid #9cce7b;
}

.is-clos {
  color: #f89c97;
}

.bg-is-clos {
  background-color: #f89c97;
}

.bg-is-clos-xs {
  background-color: #f89c97;
}

@media (min-width: 576px) {
  .bg-is-clos-sm {
    background-color: #f89c97;
  }
}
@media (min-width: 768px) {
  .bg-is-clos-md {
    background-color: #f89c97;
  }
}
@media (min-width: 992px) {
  .bg-is-clos-lg {
    background-color: #f89c97;
  }
}
@media (min-width: 1200px) {
  .bg-is-clos-xl {
    background-color: #f89c97;
  }
}
@media (min-width: 1400px) {
  .bg-is-clos-xxl {
    background-color: #f89c97;
  }
}
@media (min-width: 1200px) {
  .bg-is-clos-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #f89c97 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-clos-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #f89c97 50%);
  }
  .bg-is-clos-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #f89c97 50%);
  }
}
.bg-is-clos-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f89c97;
  z-index: -1;
}

.border-is-clos {
  border-color: #f89c97 !important;
}

.border-top-is-clos {
  border-top-color: #f89c97 !important;
}

.border-right-is-clos {
  border-right-color: #f89c97 !important;
}

.border-bottom-is-clos {
  border-bottom-color: #f89c97 !important;
}

.border-left-is-clos {
  border-left-color: #f89c97 !important;
}

.outline-is-clos {
  outline: 1px solid #f89c97;
}

.is-judiciaire {
  color: #ce987b;
}

.bg-is-judiciaire {
  background-color: #ce987b;
}

.bg-is-judiciaire-xs {
  background-color: #ce987b;
}

@media (min-width: 576px) {
  .bg-is-judiciaire-sm {
    background-color: #ce987b;
  }
}
@media (min-width: 768px) {
  .bg-is-judiciaire-md {
    background-color: #ce987b;
  }
}
@media (min-width: 992px) {
  .bg-is-judiciaire-lg {
    background-color: #ce987b;
  }
}
@media (min-width: 1200px) {
  .bg-is-judiciaire-xl {
    background-color: #ce987b;
  }
}
@media (min-width: 1400px) {
  .bg-is-judiciaire-xxl {
    background-color: #ce987b;
  }
}
@media (min-width: 1200px) {
  .bg-is-judiciaire-half-bottom-xl {
    background: linear-gradient(180deg, transparent 50%, #ce987b 50%);
  }
}
@media (min-width: 992px) {
  .bg-is-judiciaire-half-bottom {
    background: linear-gradient(180deg, transparent 50%, #ce987b 50%);
  }
  .bg-is-judiciaire-half-bottom-lg {
    background: linear-gradient(180deg, transparent 50%, #ce987b 50%);
  }
}
.bg-is-judiciaire-before::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ce987b;
  z-index: -1;
}

.border-is-judiciaire {
  border-color: #ce987b !important;
}

.border-top-is-judiciaire {
  border-top-color: #ce987b !important;
}

.border-right-is-judiciaire {
  border-right-color: #ce987b !important;
}

.border-bottom-is-judiciaire {
  border-bottom-color: #ce987b !important;
}

.border-left-is-judiciaire {
  border-left-color: #ce987b !important;
}

.outline-is-judiciaire {
  outline: 1px solid #ce987b;
}

@media (min-width: 992px) {
  .bg-is-third-fourteenth {
    background: linear-gradient(90deg, #F89c97 36%, #FFDAD8 36%);
  }
  .bg-is-fourteenth-fourth {
    background: linear-gradient(90deg, #FFDAD8 52%, #f9f2ef 52%);
  }
  .bg-is-eighth-nineth {
    background: linear-gradient(90deg, #E6F4F6 52%, #F5F5F5 52%);
  }
  .bg-is-partenaire {
    background: linear-gradient(90deg, #1C467E 36%, #3486c1 36%);
  }
  .bg-is-markwhite {
    background: linear-gradient(90deg, #003780 36%, #3165A0 36%);
  }
  .bg-transparent-lg {
    background-color: transparent;
  }
}
.filter-is-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(103%) contrast(102%);
}

@media (min-width: 992px) {
  .bg-lg-white {
    background-color: white !important;
  }
}
/* purgecss start ignore */
* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  position: relative;
  min-height: 100%;
}
html:focus {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "AllianzNeo", sans-serif;
}

body {
  background-color: #FFF;
  font-size: 0.938rem;
  --bs-primary: #075994;
  height: 100%;
  position: relative;
}
body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
body.offcanvas-menu::before {
  opacity: 1;
  z-index: 1002;
  visibility: visible;
}

body {
  /*&.kolibri {
         --bs-primary: #ff97ad;
  }
     //--unim
  &.unim {
         --bs-primary: #ff97ad!important;
  }*/
}
body.tego, body.agpm {
  --bs-primary: #122b63!important;
}

.objectfitcover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1;
}

.hrtext {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hrtext span {
  flex-grow: 0;
}

.headerhr {
  flex-grow: 1;
  height: 2px;
  margin: 0 0 0 2rem;
  background-color: #e6e6e6;
}

.flex-inherit {
  flex-direction: inherit;
}

.text-justify {
  text-align: justify;
}

i {
  font-size: 0.938rem;
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100%;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .d-lg-unset {
    display: unset;
  }
}
@media (min-width: 768px) {
  .h-md-100 {
    height: 100%;
  }
}
.tarteaucitronTitle,
.tarteaucitronDetails {
  background: #000 !important;
}

#tarteaucitronIcon {
  left: 0;
  right: unset;
}

.appear[data-src], .appear[data-bkg], .appear [data-src], .appear [data-bkg] {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.is-lazyload[data-src], .is-lazyload[data-bkg], .is-lazyload [data-src], .is-lazyload [data-bkg] {
  opacity: 0;
  transition: opacity 0.33s ease-in;
}

.appeared[data-src], .appeared[data-bkg], .appeared [data-src], .appeared [data-bkg],
.lazyloaded[data-src], .lazyloaded[data-bkg], .lazyloaded [data-src], .lazyloaded [data-bkg] {
  opacity: 1;
}

@media (max-width: 1199.98px) {
  .img-fluid-xs-xl {
    width: 100%;
    height: auto;
  }
}
.bg-position-bottom {
  background-position: bottom;
}

.bg-position-top {
  background-position: top;
}

@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }
}
/* purgecss end ignore */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }
  30% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }
  75% {
    transform: scale(1, 1.1) translate(0px, -25px);
  }
  100% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}
.animation1 {
  overflow-y: hidden;
}
.animation1 i {
  display: inline-block;
}
.animation1:hover i {
  animation: bounce 0.75s infinite;
}

.animation2 i {
  display: inline-block;
  position: relative;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out 0s;
}
.animation2:hover i.left {
  transform: translateX(-0.5rem);
}
.animation2:hover i.right {
  transform: translateX(0.5rem);
}

.animation3 {
  transition: all 0.25s ease-in;
  border-bottom: 5px solid transparent;
}
.animation3.border-transparent-primary {
  border-color: var(--bs-primary);
}
.animation3.border-transparent-secondary {
  border-color: #F7E6BF;
}
.animation3.border-transparent-third {
  border-color: #F89c97;
}
.animation3.border-transparent-fourth {
  border-color: #f9f2ef;
}
.animation3.border-transparent-fifth {
  border-color: #003780;
}
.animation3.border-transparent-sixth {
  border-color: #00aff7;
}
.animation3.border-transparent-seventh {
  border-color: #FBE4D5;
}
.animation3.border-transparent-eighth {
  border-color: #E6F4F6;
}
.animation3.border-transparent-nineth {
  border-color: #F5F5F5;
}
.animation3.border-transparent-tenth {
  border-color: #F83329;
}
.animation3.border-transparent-eleventh {
  border-color: #DDECD3;
}
.animation3.border-transparent-twelveth {
  border-color: #F7CAAB;
}
.animation3.border-transparent-thirteenth {
  border-color: #3165A0;
}
.animation3.border-transparent-fourteenth {
  border-color: #FFDAD8;
}
.animation3.border-transparent-fifteenth {
  border-color: #c1ebfb;
}
.animation3.border-transparent-sixteenth {
  border-color: #3486c1;
}
.animation3.border-transparent-seventeenth {
  border-color: #a6b7c5;
}
.animation3.border-transparent-eightenth {
  border-color: #ececec;
}
.animation3.border-transparent-nineteenth {
  border-color: #e6eef4;
}
.animation3.border-transparent-text {
  border-color: #414141;
}
.animation3.border-transparent-textlight {
  border-color: #686868;
}
.animation3.border-transparent-amiable {
  border-color: #9cce7b;
}
.animation3.border-transparent-clos {
  border-color: #f89c97;
}
.animation3.border-transparent-judiciaire {
  border-color: #ce987b;
}
.animation3:hover {
  transform: translateY(-5px);
  border: none;
  border-bottom: 5px solid var(--bs-primary);
}
.animation3:hover.bg-is-primary {
  border-color: var(--bs-primary);
}
.animation3:hover.border-hover-primary {
  border-color: var(--bs-primary) !important;
}
.animation3:hover.bg-is-secondary {
  border-color: #F7E6BF;
}
.animation3:hover.border-hover-secondary {
  border-color: #F7E6BF !important;
}
.animation3:hover.bg-is-third {
  border-color: #F89c97;
}
.animation3:hover.border-hover-third {
  border-color: #F89c97 !important;
}
.animation3:hover.bg-is-fourth {
  border-color: #f9f2ef;
}
.animation3:hover.border-hover-fourth {
  border-color: #f9f2ef !important;
}
.animation3:hover.bg-is-fifth {
  border-color: #003780;
}
.animation3:hover.border-hover-fifth {
  border-color: #003780 !important;
}
.animation3:hover.bg-is-sixth {
  border-color: #00aff7;
}
.animation3:hover.border-hover-sixth {
  border-color: #00aff7 !important;
}
.animation3:hover.bg-is-seventh {
  border-color: #FBE4D5;
}
.animation3:hover.border-hover-seventh {
  border-color: #FBE4D5 !important;
}
.animation3:hover.bg-is-eighth {
  border-color: #E6F4F6;
}
.animation3:hover.border-hover-eighth {
  border-color: #E6F4F6 !important;
}
.animation3:hover.bg-is-nineth {
  border-color: #F5F5F5;
}
.animation3:hover.border-hover-nineth {
  border-color: #F5F5F5 !important;
}
.animation3:hover.bg-is-tenth {
  border-color: #F83329;
}
.animation3:hover.border-hover-tenth {
  border-color: #F83329 !important;
}
.animation3:hover.bg-is-eleventh {
  border-color: #DDECD3;
}
.animation3:hover.border-hover-eleventh {
  border-color: #DDECD3 !important;
}
.animation3:hover.bg-is-twelveth {
  border-color: #F7CAAB;
}
.animation3:hover.border-hover-twelveth {
  border-color: #F7CAAB !important;
}
.animation3:hover.bg-is-thirteenth {
  border-color: #3165A0;
}
.animation3:hover.border-hover-thirteenth {
  border-color: #3165A0 !important;
}
.animation3:hover.bg-is-fourteenth {
  border-color: #FFDAD8;
}
.animation3:hover.border-hover-fourteenth {
  border-color: #FFDAD8 !important;
}
.animation3:hover.bg-is-fifteenth {
  border-color: #c1ebfb;
}
.animation3:hover.border-hover-fifteenth {
  border-color: #c1ebfb !important;
}
.animation3:hover.bg-is-sixteenth {
  border-color: #3486c1;
}
.animation3:hover.border-hover-sixteenth {
  border-color: #3486c1 !important;
}
.animation3:hover.bg-is-seventeenth {
  border-color: #a6b7c5;
}
.animation3:hover.border-hover-seventeenth {
  border-color: #a6b7c5 !important;
}
.animation3:hover.bg-is-eightenth {
  border-color: #ececec;
}
.animation3:hover.border-hover-eightenth {
  border-color: #ececec !important;
}
.animation3:hover.bg-is-nineteenth {
  border-color: #e6eef4;
}
.animation3:hover.border-hover-nineteenth {
  border-color: #e6eef4 !important;
}
.animation3:hover.bg-is-text {
  border-color: #414141;
}
.animation3:hover.border-hover-text {
  border-color: #414141 !important;
}
.animation3:hover.bg-is-textlight {
  border-color: #686868;
}
.animation3:hover.border-hover-textlight {
  border-color: #686868 !important;
}
.animation3:hover.bg-is-amiable {
  border-color: #9cce7b;
}
.animation3:hover.border-hover-amiable {
  border-color: #9cce7b !important;
}
.animation3:hover.bg-is-clos {
  border-color: #f89c97;
}
.animation3:hover.border-hover-clos {
  border-color: #f89c97 !important;
}
.animation3:hover.bg-is-judiciaire {
  border-color: #ce987b;
}
.animation3:hover.border-hover-judiciaire {
  border-color: #ce987b !important;
}
.animation3.bg-is-seventh:hover {
  border-color: #F89c97;
}
.animation3.bg-is-eighth:hover {
  border-color: #F89c97;
}
.animation3.bg-white:hover {
  border-color: #F89c97;
}

.animation4 .animate4 {
  border: 2px solid transparent;
  border-left: none;
  transition: all ease-in-out 0.2s;
}
.animation4:hover .animate4 {
  border-color: #F89c97;
}
.animation4:hover .animate4 a {
  color: #414141;
}
@media (max-width: 991.98px) {
  .animation4 .animate4 {
    border-left: 2px solid transparent;
  }
  .animation4 .animate4:hover {
    border-left: 2px solid #F89c97;
  }
}

a {
  color: var(--bs-primary);
}

/* purgecss start ignore */
.btn {
  transition: all 0.33s !important;
}

.btn-is-primary {
  color: #FFF;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary) !important;
}
.btn-is-primary.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-primary:hover {
  color: var(--bs-primary) !important;
}

.btn-is-secondary {
  color: #FFF;
  background-color: #F7E6BF;
  border-color: #F7E6BF !important;
}
.btn-is-secondary.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-secondary:hover {
  color: #F7E6BF !important;
}

.btn-is-third {
  color: #FFF;
  background-color: #F89c97;
  border-color: #F89c97 !important;
}
.btn-is-third.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-third:hover {
  color: #F89c97 !important;
}

.btn-is-fourth {
  color: #FFF;
  background-color: #f9f2ef;
  border-color: #f9f2ef !important;
}
.btn-is-fourth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-fourth:hover {
  color: #f9f2ef !important;
}

.btn-is-fifth {
  color: #FFF;
  background-color: #003780;
  border-color: #003780 !important;
}
.btn-is-fifth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-fifth:hover {
  color: #003780 !important;
}

.btn-is-sixth {
  color: #FFF;
  background-color: #00aff7;
  border-color: #00aff7 !important;
}
.btn-is-sixth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-sixth:hover {
  color: #00aff7 !important;
}

.btn-is-seventh {
  color: #FFF;
  background-color: #FBE4D5;
  border-color: #FBE4D5 !important;
}
.btn-is-seventh.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-seventh:hover {
  color: #FBE4D5 !important;
}

.btn-is-eighth {
  color: #FFF;
  background-color: #E6F4F6;
  border-color: #E6F4F6 !important;
}
.btn-is-eighth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-eighth:hover {
  color: #E6F4F6 !important;
}

.btn-is-nineth {
  color: #FFF;
  background-color: #F5F5F5;
  border-color: #F5F5F5 !important;
}
.btn-is-nineth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-nineth:hover {
  color: #F5F5F5 !important;
}

.btn-is-tenth {
  color: #FFF;
  background-color: #F83329;
  border-color: #F83329 !important;
}
.btn-is-tenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-tenth:hover {
  color: #F83329 !important;
}

.btn-is-eleventh {
  color: #FFF;
  background-color: #DDECD3;
  border-color: #DDECD3 !important;
}
.btn-is-eleventh.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-eleventh:hover {
  color: #DDECD3 !important;
}

.btn-is-twelveth {
  color: #FFF;
  background-color: #F7CAAB;
  border-color: #F7CAAB !important;
}
.btn-is-twelveth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-twelveth:hover {
  color: #F7CAAB !important;
}

.btn-is-thirteenth {
  color: #FFF;
  background-color: #3165A0;
  border-color: #3165A0 !important;
}
.btn-is-thirteenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-thirteenth:hover {
  color: #3165A0 !important;
}

.btn-is-fourteenth {
  color: #FFF;
  background-color: #FFDAD8;
  border-color: #FFDAD8 !important;
}
.btn-is-fourteenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-fourteenth:hover {
  color: #FFDAD8 !important;
}

.btn-is-fifteenth {
  color: #FFF;
  background-color: #c1ebfb;
  border-color: #c1ebfb !important;
}
.btn-is-fifteenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-fifteenth:hover {
  color: #c1ebfb !important;
}

.btn-is-sixteenth {
  color: #FFF;
  background-color: #3486c1;
  border-color: #3486c1 !important;
}
.btn-is-sixteenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-sixteenth:hover {
  color: #3486c1 !important;
}

.btn-is-seventeenth {
  color: #FFF;
  background-color: #a6b7c5;
  border-color: #a6b7c5 !important;
}
.btn-is-seventeenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-seventeenth:hover {
  color: #a6b7c5 !important;
}

.btn-is-eightenth {
  color: #FFF;
  background-color: #ececec;
  border-color: #ececec !important;
}
.btn-is-eightenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-eightenth:hover {
  color: #ececec !important;
}

.btn-is-nineteenth {
  color: #FFF;
  background-color: #e6eef4;
  border-color: #e6eef4 !important;
}
.btn-is-nineteenth.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-nineteenth:hover {
  color: #e6eef4 !important;
}

.btn-is-text {
  color: #FFF;
  background-color: #414141;
  border-color: #414141 !important;
}
.btn-is-text.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-text:hover {
  color: #414141 !important;
}

.btn-is-textlight {
  color: #FFF;
  background-color: #686868;
  border-color: #686868 !important;
}
.btn-is-textlight.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-textlight:hover {
  color: #686868 !important;
}

.btn-is-amiable {
  color: #FFF;
  background-color: #9cce7b;
  border-color: #9cce7b !important;
}
.btn-is-amiable.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-amiable:hover {
  color: #9cce7b !important;
}

.btn-is-clos {
  color: #FFF;
  background-color: #f89c97;
  border-color: #f89c97 !important;
}
.btn-is-clos.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-clos:hover {
  color: #f89c97 !important;
}

.btn-is-judiciaire {
  color: #FFF;
  background-color: #ce987b;
  border-color: #ce987b !important;
}
.btn-is-judiciaire.is-primary {
  color: var(--bs-primary);
}

.is-hover-color-judiciaire:hover {
  color: #ce987b !important;
}

button:focus-visible {
  outline: none;
}
button.nextBtn, button.prevBtn, button #buttonAvisModal, button #clickmodalAvisGen {
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  cursor: pointer;
  --bs-border-width: 2px;
  margin: 3rem 0;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
}
button.nextBtn.float-right, button.prevBtn.float-right, button #buttonAvisModal.float-right, button #clickmodalAvisGen.float-right {
  float: right;
}
button.nextBtn.float-left, button.prevBtn.float-left, button #buttonAvisModal.float-left, button #clickmodalAvisGen.float-left {
  float: left;
}
button.nextBtn:hover, button.prevBtn:hover, button #buttonAvisModal:hover, button #clickmodalAvisGen:hover {
  background-color: var(--bs-primary);
  color: #fff;
}
button.nextBtn:hover i, button.prevBtn:hover i, button #buttonAvisModal:hover i, button #clickmodalAvisGen:hover i {
  color: #fff;
}
button.filter-button {
  background-color: #FFF;
  color: #414141;
  border: 1px solid #DADADA;
  transition: all 0.33s;
}
button.filter-button:not(.database) span.administratives {
  background-image: url("/images/icones/administratif.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.immobilier {
  background-image: url("/images/icones/immobilier.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.travail {
  background-image: url("/images/icones/travail.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.automobilistes {
  background-image: url("/images/icones/automobiliste.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.consommateurs {
  background-image: url("/images/icones/consommateur.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.entreprises {
  background-image: url("/images/icones/entreprise.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.familles {
  background-image: url("/images/icones/famille.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button:not(.database) span.etrangers {
  background-image: url("/images/icones/etranger.svg");
  width: 15px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
button.filter-button.database span.administration::before {
  content: "\f62e";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.consommation::before {
  content: "\f634";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.droit-penal::before {
  content: url("/images/icones/scale-balanced.svg");
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.famille::before {
  content: "\f4d0";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.fiscalite-impots::before {
  content: "\f4e9";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.immobilier-construction::before {
  content: "\f423";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.professionnels-et-entreprises::before {
  content: "\f1dd";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button.database span.travail::before {
  content: "\f1cc";
  font-size: 12px;
  font-family: "bootstrap-icons";
  width: 15px;
}
button.filter-button:hover, button.filter-button.active {
  background-color: var(--bs-primary);
  color: #FFF;
  font-weight: bold;
}
button.filter-button:hover span, button.filter-button.active span {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(103%) contrast(102%);
}

.no-check-paiement:hover {
  color: #fff !important;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.bloc-promo-input button {
  background-color: #fff;
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  cursor: pointer;
  border-radius: 3px;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  text-transform: uppercase;
}
.bloc-promo-input button i {
  color: var(--bs-primary);
}
.bloc-promo-input button:hover {
  background-color: var(--bs-primary);
  color: #fff;
}
.bloc-promo-input button:hover i {
  color: #fff;
}

#previewDoc {
  background-color: #c1ebfb;
  border: 1px solid #c1ebfb;
  color: var(--bs-primary);
  cursor: pointer;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  text-transform: uppercase;
}
#previewDoc i {
  color: var(--bs-primary);
}
#previewDoc:hover {
  background-color: var(--bs-primary);
  color: #c1ebfb;
}
#previewDoc:hover i {
  color: #c1ebfb;
}

#procedPaiement {
  background-color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
  color: #fff;
  cursor: pointer;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  text-transform: uppercase;
}
#procedPaiement i {
  color: #fff;
}
#procedPaiement:hover {
  background-color: #fff;
  color: var(--bs-primary);
}
#procedPaiement:hover i {
  color: var(--bs-primary);
}

.lettreRecommande a, #buttonAvisModal, #clickmodalAvisGen {
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  cursor: pointer;
  --bs-border-width: 2px;
  margin: 3rem 0;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
}
.lettreRecommande a:hover, #buttonAvisModal:hover, #clickmodalAvisGen:hover {
  background-color: var(--bs-primary);
  color: #fff;
}
.lettreRecommande a:hover i, #buttonAvisModal:hover i, #clickmodalAvisGen:hover i {
  color: #fff;
}

.btn:focus {
  box-shadow: none;
}
.btn.effect1.btn-is-primary:hover {
  color: var(--bs-primary);
  background-color: #FFF;
}
.btn.effect1.is-hover-border-primary {
  border-color: var(--bs-primary);
}
.btn.effect1.btn-is-secondary:hover {
  color: #F7E6BF;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-secondary {
  border-color: #F7E6BF;
}
.btn.effect1.btn-is-third:hover {
  color: #F89c97;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-third {
  border-color: #F89c97;
}
.btn.effect1.btn-is-fourth:hover {
  color: #f9f2ef;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-fourth {
  border-color: #f9f2ef;
}
.btn.effect1.btn-is-fifth:hover {
  color: #003780;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-fifth {
  border-color: #003780;
}
.btn.effect1.btn-is-sixth:hover {
  color: #00aff7;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-sixth {
  border-color: #00aff7;
}
.btn.effect1.btn-is-seventh:hover {
  color: #FBE4D5;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-seventh {
  border-color: #FBE4D5;
}
.btn.effect1.btn-is-eighth:hover {
  color: #E6F4F6;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-eighth {
  border-color: #E6F4F6;
}
.btn.effect1.btn-is-nineth:hover {
  color: #F5F5F5;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-nineth {
  border-color: #F5F5F5;
}
.btn.effect1.btn-is-tenth:hover {
  color: #F83329;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-tenth {
  border-color: #F83329;
}
.btn.effect1.btn-is-eleventh:hover {
  color: #DDECD3;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-eleventh {
  border-color: #DDECD3;
}
.btn.effect1.btn-is-twelveth:hover {
  color: #F7CAAB;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-twelveth {
  border-color: #F7CAAB;
}
.btn.effect1.btn-is-thirteenth:hover {
  color: #3165A0;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-thirteenth {
  border-color: #3165A0;
}
.btn.effect1.btn-is-fourteenth:hover {
  color: #FFDAD8;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-fourteenth {
  border-color: #FFDAD8;
}
.btn.effect1.btn-is-fifteenth:hover {
  color: #c1ebfb;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-fifteenth {
  border-color: #c1ebfb;
}
.btn.effect1.btn-is-sixteenth:hover {
  color: #3486c1;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-sixteenth {
  border-color: #3486c1;
}
.btn.effect1.btn-is-seventeenth:hover {
  color: #a6b7c5;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-seventeenth {
  border-color: #a6b7c5;
}
.btn.effect1.btn-is-eightenth:hover {
  color: #ececec;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-eightenth {
  border-color: #ececec;
}
.btn.effect1.btn-is-nineteenth:hover {
  color: #e6eef4;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-nineteenth {
  border-color: #e6eef4;
}
.btn.effect1.btn-is-text:hover {
  color: #414141;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-text {
  border-color: #414141;
}
.btn.effect1.btn-is-textlight:hover {
  color: #686868;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-textlight {
  border-color: #686868;
}
.btn.effect1.btn-is-amiable:hover {
  color: #9cce7b;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-amiable {
  border-color: #9cce7b;
}
.btn.effect1.btn-is-clos:hover {
  color: #f89c97;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-clos {
  border-color: #f89c97;
}
.btn.effect1.btn-is-judiciaire:hover {
  color: #ce987b;
  background-color: #FFF;
}
.btn.effect1.is-hover-border-judiciaire {
  border-color: #ce987b;
}
.btn.btn-is-border-primary {
  border: 1px solid var(--bs-primary);
}
.btn.btn-is-border-primary:hover {
  background-color: var(--bs-primary);
  color: #FFF;
}
.btn.btn-is-border-secondary {
  border: 1px solid #F7E6BF;
}
.btn.btn-is-border-secondary:hover {
  background-color: #F7E6BF;
  color: #FFF;
}
.btn.btn-is-border-third {
  border: 1px solid #F89c97;
}
.btn.btn-is-border-third:hover {
  background-color: #F89c97;
  color: #FFF;
}
.btn.btn-is-border-fourth {
  border: 1px solid #f9f2ef;
}
.btn.btn-is-border-fourth:hover {
  background-color: #f9f2ef;
  color: #FFF;
}
.btn.btn-is-border-fifth {
  border: 1px solid #003780;
}
.btn.btn-is-border-fifth:hover {
  background-color: #003780;
  color: #FFF;
}
.btn.btn-is-border-sixth {
  border: 1px solid #00aff7;
}
.btn.btn-is-border-sixth:hover {
  background-color: #00aff7;
  color: #FFF;
}
.btn.btn-is-border-seventh {
  border: 1px solid #FBE4D5;
}
.btn.btn-is-border-seventh:hover {
  background-color: #FBE4D5;
  color: #FFF;
}
.btn.btn-is-border-eighth {
  border: 1px solid #E6F4F6;
}
.btn.btn-is-border-eighth:hover {
  background-color: #E6F4F6;
  color: #FFF;
}
.btn.btn-is-border-nineth {
  border: 1px solid #F5F5F5;
}
.btn.btn-is-border-nineth:hover {
  background-color: #F5F5F5;
  color: #FFF;
}
.btn.btn-is-border-tenth {
  border: 1px solid #F83329;
}
.btn.btn-is-border-tenth:hover {
  background-color: #F83329;
  color: #FFF;
}
.btn.btn-is-border-eleventh {
  border: 1px solid #DDECD3;
}
.btn.btn-is-border-eleventh:hover {
  background-color: #DDECD3;
  color: #FFF;
}
.btn.btn-is-border-twelveth {
  border: 1px solid #F7CAAB;
}
.btn.btn-is-border-twelveth:hover {
  background-color: #F7CAAB;
  color: #FFF;
}
.btn.btn-is-border-thirteenth {
  border: 1px solid #3165A0;
}
.btn.btn-is-border-thirteenth:hover {
  background-color: #3165A0;
  color: #FFF;
}
.btn.btn-is-border-fourteenth {
  border: 1px solid #FFDAD8;
}
.btn.btn-is-border-fourteenth:hover {
  background-color: #FFDAD8;
  color: #FFF;
}
.btn.btn-is-border-fifteenth {
  border: 1px solid #c1ebfb;
}
.btn.btn-is-border-fifteenth:hover {
  background-color: #c1ebfb;
  color: #FFF;
}
.btn.btn-is-border-sixteenth {
  border: 1px solid #3486c1;
}
.btn.btn-is-border-sixteenth:hover {
  background-color: #3486c1;
  color: #FFF;
}
.btn.btn-is-border-seventeenth {
  border: 1px solid #a6b7c5;
}
.btn.btn-is-border-seventeenth:hover {
  background-color: #a6b7c5;
  color: #FFF;
}
.btn.btn-is-border-eightenth {
  border: 1px solid #ececec;
}
.btn.btn-is-border-eightenth:hover {
  background-color: #ececec;
  color: #FFF;
}
.btn.btn-is-border-nineteenth {
  border: 1px solid #e6eef4;
}
.btn.btn-is-border-nineteenth:hover {
  background-color: #e6eef4;
  color: #FFF;
}
.btn.btn-is-border-text {
  border: 1px solid #414141;
}
.btn.btn-is-border-text:hover {
  background-color: #414141;
  color: #FFF;
}
.btn.btn-is-border-textlight {
  border: 1px solid #686868;
}
.btn.btn-is-border-textlight:hover {
  background-color: #686868;
  color: #FFF;
}
.btn.btn-is-border-amiable {
  border: 1px solid #9cce7b;
}
.btn.btn-is-border-amiable:hover {
  background-color: #9cce7b;
  color: #FFF;
}
.btn.btn-is-border-clos {
  border: 1px solid #f89c97;
}
.btn.btn-is-border-clos:hover {
  background-color: #f89c97;
  color: #FFF;
}
.btn.btn-is-border-judiciaire {
  border: 1px solid #ce987b;
}
.btn.btn-is-border-judiciaire:hover {
  background-color: #ce987b;
  color: #FFF;
}

/* purgecss end ignore */
.pt-6 {
  padding-top: 3.5rem;
}

.pe-6 {
  padding-right: 3.5rem;
}

.pb-6 {
  padding-bottom: 3.5rem;
}

.ps-6 {
  padding-left: 3.5rem;
}

.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (min-width: 992px) {
  .pb-lg-6 {
    padding-bottom: 3.5rem !important;
  }
  .py-lg-6px {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}
.pt-7 {
  padding-top: 4rem;
}

.pe-7 {
  padding-right: 4rem;
}

.pb-7 {
  padding-bottom: 4rem;
}

.ps-7 {
  padding-left: 4rem;
}

.py-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

@media (min-width: 992px) {
  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }
  .py-lg-7px {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}
.pt-8 {
  padding-top: 4.5rem;
}

.pe-8 {
  padding-right: 4.5rem;
}

.pb-8 {
  padding-bottom: 4.5rem;
}

.ps-8 {
  padding-left: 4.5rem;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 992px) {
  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }
  .py-lg-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
.pt-9 {
  padding-top: 5rem;
}

.pe-9 {
  padding-right: 5rem;
}

.pb-9 {
  padding-bottom: 5rem;
}

.ps-9 {
  padding-left: 5rem;
}

.py-9px {
  padding-top: 9px;
  padding-bottom: 9px;
}

@media (min-width: 992px) {
  .pb-lg-9 {
    padding-bottom: 5rem !important;
  }
  .py-lg-9px {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
}
.pt-10 {
  padding-top: 5.5rem;
}

.pe-10 {
  padding-right: 5.5rem;
}

.pb-10 {
  padding-bottom: 5.5rem;
}

.ps-10 {
  padding-left: 5.5rem;
}

.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .pb-lg-10 {
    padding-bottom: 5.5rem !important;
  }
  .py-lg-10px {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
.pt-11 {
  padding-top: 6rem;
}

.pe-11 {
  padding-right: 6rem;
}

.pb-11 {
  padding-bottom: 6rem;
}

.ps-11 {
  padding-left: 6rem;
}

.py-11px {
  padding-top: 11px;
  padding-bottom: 11px;
}

@media (min-width: 992px) {
  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }
  .py-lg-11px {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}
.pt-12 {
  padding-top: 6.5rem;
}

.pe-12 {
  padding-right: 6.5rem;
}

.pb-12 {
  padding-bottom: 6.5rem;
}

.ps-12 {
  padding-left: 6.5rem;
}

.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (min-width: 992px) {
  .pb-lg-12 {
    padding-bottom: 6.5rem !important;
  }
  .py-lg-12px {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}
.pt-13 {
  padding-top: 7rem;
}

.pe-13 {
  padding-right: 7rem;
}

.pb-13 {
  padding-bottom: 7rem;
}

.ps-13 {
  padding-left: 7rem;
}

.py-13px {
  padding-top: 13px;
  padding-bottom: 13px;
}

@media (min-width: 992px) {
  .pb-lg-13 {
    padding-bottom: 7rem !important;
  }
  .py-lg-13px {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
}
.pt-14 {
  padding-top: 7.5rem;
}

.pe-14 {
  padding-right: 7.5rem;
}

.pb-14 {
  padding-bottom: 7.5rem;
}

.ps-14 {
  padding-left: 7.5rem;
}

.py-14px {
  padding-top: 14px;
  padding-bottom: 14px;
}

@media (min-width: 992px) {
  .pb-lg-14 {
    padding-bottom: 7.5rem !important;
  }
  .py-lg-14px {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
}
.pt-15 {
  padding-top: 8rem;
}

.pe-15 {
  padding-right: 8rem;
}

.pb-15 {
  padding-bottom: 8rem;
}

.ps-15 {
  padding-left: 8rem;
}

.py-15px {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 992px) {
  .pb-lg-15 {
    padding-bottom: 8rem !important;
  }
  .py-lg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
.pt-16 {
  padding-top: 8.5rem;
}

.pe-16 {
  padding-right: 8.5rem;
}

.pb-16 {
  padding-bottom: 8.5rem;
}

.ps-16 {
  padding-left: 8.5rem;
}

.py-16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width: 992px) {
  .pb-lg-16 {
    padding-bottom: 8.5rem !important;
  }
  .py-lg-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}
.pt-17 {
  padding-top: 9rem;
}

.pe-17 {
  padding-right: 9rem;
}

.pb-17 {
  padding-bottom: 9rem;
}

.ps-17 {
  padding-left: 9rem;
}

.py-17px {
  padding-top: 17px;
  padding-bottom: 17px;
}

@media (min-width: 992px) {
  .pb-lg-17 {
    padding-bottom: 9rem !important;
  }
  .py-lg-17px {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
}
.pt-18 {
  padding-top: 9.5rem;
}

.pe-18 {
  padding-right: 9.5rem;
}

.pb-18 {
  padding-bottom: 9.5rem;
}

.ps-18 {
  padding-left: 9.5rem;
}

.py-18px {
  padding-top: 18px;
  padding-bottom: 18px;
}

@media (min-width: 992px) {
  .pb-lg-18 {
    padding-bottom: 9.5rem !important;
  }
  .py-lg-18px {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
}
.pt-19 {
  padding-top: 10rem;
}

.pe-19 {
  padding-right: 10rem;
}

.pb-19 {
  padding-bottom: 10rem;
}

.ps-19 {
  padding-left: 10rem;
}

.py-19px {
  padding-top: 19px;
  padding-bottom: 19px;
}

@media (min-width: 992px) {
  .pb-lg-19 {
    padding-bottom: 10rem !important;
  }
  .py-lg-19px {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }
}
.pt-20 {
  padding-top: 10.5rem;
}

.pe-20 {
  padding-right: 10.5rem;
}

.pb-20 {
  padding-bottom: 10.5rem;
}

.ps-20 {
  padding-left: 10.5rem;
}

.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .pb-lg-20 {
    padding-bottom: 10.5rem !important;
  }
  .py-lg-20px {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
.pt-21 {
  padding-top: 11rem;
}

.pe-21 {
  padding-right: 11rem;
}

.pb-21 {
  padding-bottom: 11rem;
}

.ps-21 {
  padding-left: 11rem;
}

.py-21px {
  padding-top: 21px;
  padding-bottom: 21px;
}

@media (min-width: 992px) {
  .pb-lg-21 {
    padding-bottom: 11rem !important;
  }
  .py-lg-21px {
    padding-top: 21px !important;
    padding-bottom: 21px !important;
  }
}
.pt-22 {
  padding-top: 11.5rem;
}

.pe-22 {
  padding-right: 11.5rem;
}

.pb-22 {
  padding-bottom: 11.5rem;
}

.ps-22 {
  padding-left: 11.5rem;
}

.py-22px {
  padding-top: 22px;
  padding-bottom: 22px;
}

@media (min-width: 992px) {
  .pb-lg-22 {
    padding-bottom: 11.5rem !important;
  }
  .py-lg-22px {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
}
.pt-23 {
  padding-top: 12rem;
}

.pe-23 {
  padding-right: 12rem;
}

.pb-23 {
  padding-bottom: 12rem;
}

.ps-23 {
  padding-left: 12rem;
}

.py-23px {
  padding-top: 23px;
  padding-bottom: 23px;
}

@media (min-width: 992px) {
  .pb-lg-23 {
    padding-bottom: 12rem !important;
  }
  .py-lg-23px {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }
}
.pt-24 {
  padding-top: 12.5rem;
}

.pe-24 {
  padding-right: 12.5rem;
}

.pb-24 {
  padding-bottom: 12.5rem;
}

.ps-24 {
  padding-left: 12.5rem;
}

.py-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}

@media (min-width: 992px) {
  .pb-lg-24 {
    padding-bottom: 12.5rem !important;
  }
  .py-lg-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}
.pt-25 {
  padding-top: 13rem;
}

.pe-25 {
  padding-right: 13rem;
}

.pb-25 {
  padding-bottom: 13rem;
}

.ps-25 {
  padding-left: 13rem;
}

.py-25px {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 992px) {
  .pb-lg-25 {
    padding-bottom: 13rem !important;
  }
  .py-lg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}
.pt-26 {
  padding-top: 13.5rem;
}

.pe-26 {
  padding-right: 13.5rem;
}

.pb-26 {
  padding-bottom: 13.5rem;
}

.ps-26 {
  padding-left: 13.5rem;
}

.py-26px {
  padding-top: 26px;
  padding-bottom: 26px;
}

@media (min-width: 992px) {
  .pb-lg-26 {
    padding-bottom: 13.5rem !important;
  }
  .py-lg-26px {
    padding-top: 26px !important;
    padding-bottom: 26px !important;
  }
}
.pt-27 {
  padding-top: 14rem;
}

.pe-27 {
  padding-right: 14rem;
}

.pb-27 {
  padding-bottom: 14rem;
}

.ps-27 {
  padding-left: 14rem;
}

.py-27px {
  padding-top: 27px;
  padding-bottom: 27px;
}

@media (min-width: 992px) {
  .pb-lg-27 {
    padding-bottom: 14rem !important;
  }
  .py-lg-27px {
    padding-top: 27px !important;
    padding-bottom: 27px !important;
  }
}
.pt-28 {
  padding-top: 14.5rem;
}

.pe-28 {
  padding-right: 14.5rem;
}

.pb-28 {
  padding-bottom: 14.5rem;
}

.ps-28 {
  padding-left: 14.5rem;
}

.py-28px {
  padding-top: 28px;
  padding-bottom: 28px;
}

@media (min-width: 992px) {
  .pb-lg-28 {
    padding-bottom: 14.5rem !important;
  }
  .py-lg-28px {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
}
.pt-29 {
  padding-top: 15rem;
}

.pe-29 {
  padding-right: 15rem;
}

.pb-29 {
  padding-bottom: 15rem;
}

.ps-29 {
  padding-left: 15rem;
}

.py-29px {
  padding-top: 29px;
  padding-bottom: 29px;
}

@media (min-width: 992px) {
  .pb-lg-29 {
    padding-bottom: 15rem !important;
  }
  .py-lg-29px {
    padding-top: 29px !important;
    padding-bottom: 29px !important;
  }
}
.pt-30 {
  padding-top: 15.5rem;
}

.pe-30 {
  padding-right: 15.5rem;
}

.pb-30 {
  padding-bottom: 15.5rem;
}

.ps-30 {
  padding-left: 15.5rem;
}

.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .pb-lg-30 {
    padding-bottom: 15.5rem !important;
  }
  .py-lg-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}
.pt-31 {
  padding-top: 16rem;
}

.pe-31 {
  padding-right: 16rem;
}

.pb-31 {
  padding-bottom: 16rem;
}

.ps-31 {
  padding-left: 16rem;
}

.py-31px {
  padding-top: 31px;
  padding-bottom: 31px;
}

@media (min-width: 992px) {
  .pb-lg-31 {
    padding-bottom: 16rem !important;
  }
  .py-lg-31px {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
  }
}
.pt-32 {
  padding-top: 16.5rem;
}

.pe-32 {
  padding-right: 16.5rem;
}

.pb-32 {
  padding-bottom: 16.5rem;
}

.ps-32 {
  padding-left: 16.5rem;
}

.py-32px {
  padding-top: 32px;
  padding-bottom: 32px;
}

@media (min-width: 992px) {
  .pb-lg-32 {
    padding-bottom: 16.5rem !important;
  }
  .py-lg-32px {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}
.pt-33 {
  padding-top: 17rem;
}

.pe-33 {
  padding-right: 17rem;
}

.pb-33 {
  padding-bottom: 17rem;
}

.ps-33 {
  padding-left: 17rem;
}

.py-33px {
  padding-top: 33px;
  padding-bottom: 33px;
}

@media (min-width: 992px) {
  .pb-lg-33 {
    padding-bottom: 17rem !important;
  }
  .py-lg-33px {
    padding-top: 33px !important;
    padding-bottom: 33px !important;
  }
}
.pt-34 {
  padding-top: 17.5rem;
}

.pe-34 {
  padding-right: 17.5rem;
}

.pb-34 {
  padding-bottom: 17.5rem;
}

.ps-34 {
  padding-left: 17.5rem;
}

.py-34px {
  padding-top: 34px;
  padding-bottom: 34px;
}

@media (min-width: 992px) {
  .pb-lg-34 {
    padding-bottom: 17.5rem !important;
  }
  .py-lg-34px {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }
}
.pt-35 {
  padding-top: 18rem;
}

.pe-35 {
  padding-right: 18rem;
}

.pb-35 {
  padding-bottom: 18rem;
}

.ps-35 {
  padding-left: 18rem;
}

.py-35px {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 992px) {
  .pb-lg-35 {
    padding-bottom: 18rem !important;
  }
  .py-lg-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
}
.pt-36 {
  padding-top: 18.5rem;
}

.pe-36 {
  padding-right: 18.5rem;
}

.pb-36 {
  padding-bottom: 18.5rem;
}

.ps-36 {
  padding-left: 18.5rem;
}

.py-36px {
  padding-top: 36px;
  padding-bottom: 36px;
}

@media (min-width: 992px) {
  .pb-lg-36 {
    padding-bottom: 18.5rem !important;
  }
  .py-lg-36px {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
}
.pt-37 {
  padding-top: 19rem;
}

.pe-37 {
  padding-right: 19rem;
}

.pb-37 {
  padding-bottom: 19rem;
}

.ps-37 {
  padding-left: 19rem;
}

.py-37px {
  padding-top: 37px;
  padding-bottom: 37px;
}

@media (min-width: 992px) {
  .pb-lg-37 {
    padding-bottom: 19rem !important;
  }
  .py-lg-37px {
    padding-top: 37px !important;
    padding-bottom: 37px !important;
  }
}
.pt-38 {
  padding-top: 19.5rem;
}

.pe-38 {
  padding-right: 19.5rem;
}

.pb-38 {
  padding-bottom: 19.5rem;
}

.ps-38 {
  padding-left: 19.5rem;
}

.py-38px {
  padding-top: 38px;
  padding-bottom: 38px;
}

@media (min-width: 992px) {
  .pb-lg-38 {
    padding-bottom: 19.5rem !important;
  }
  .py-lg-38px {
    padding-top: 38px !important;
    padding-bottom: 38px !important;
  }
}
.pt-39 {
  padding-top: 20rem;
}

.pe-39 {
  padding-right: 20rem;
}

.pb-39 {
  padding-bottom: 20rem;
}

.ps-39 {
  padding-left: 20rem;
}

.py-39px {
  padding-top: 39px;
  padding-bottom: 39px;
}

@media (min-width: 992px) {
  .pb-lg-39 {
    padding-bottom: 20rem !important;
  }
  .py-lg-39px {
    padding-top: 39px !important;
    padding-bottom: 39px !important;
  }
}
.pt-40 {
  padding-top: 20.5rem;
}

.pe-40 {
  padding-right: 20.5rem;
}

.pb-40 {
  padding-bottom: 20.5rem;
}

.ps-40 {
  padding-left: 20.5rem;
}

.py-40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .pb-lg-40 {
    padding-bottom: 20.5rem !important;
  }
  .py-lg-40px {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}
.pt-41 {
  padding-top: 21rem;
}

.pe-41 {
  padding-right: 21rem;
}

.pb-41 {
  padding-bottom: 21rem;
}

.ps-41 {
  padding-left: 21rem;
}

.py-41px {
  padding-top: 41px;
  padding-bottom: 41px;
}

@media (min-width: 992px) {
  .pb-lg-41 {
    padding-bottom: 21rem !important;
  }
  .py-lg-41px {
    padding-top: 41px !important;
    padding-bottom: 41px !important;
  }
}
.pt-42 {
  padding-top: 21.5rem;
}

.pe-42 {
  padding-right: 21.5rem;
}

.pb-42 {
  padding-bottom: 21.5rem;
}

.ps-42 {
  padding-left: 21.5rem;
}

.py-42px {
  padding-top: 42px;
  padding-bottom: 42px;
}

@media (min-width: 992px) {
  .pb-lg-42 {
    padding-bottom: 21.5rem !important;
  }
  .py-lg-42px {
    padding-top: 42px !important;
    padding-bottom: 42px !important;
  }
}
.pt-43 {
  padding-top: 22rem;
}

.pe-43 {
  padding-right: 22rem;
}

.pb-43 {
  padding-bottom: 22rem;
}

.ps-43 {
  padding-left: 22rem;
}

.py-43px {
  padding-top: 43px;
  padding-bottom: 43px;
}

@media (min-width: 992px) {
  .pb-lg-43 {
    padding-bottom: 22rem !important;
  }
  .py-lg-43px {
    padding-top: 43px !important;
    padding-bottom: 43px !important;
  }
}
.pt-44 {
  padding-top: 22.5rem;
}

.pe-44 {
  padding-right: 22.5rem;
}

.pb-44 {
  padding-bottom: 22.5rem;
}

.ps-44 {
  padding-left: 22.5rem;
}

.py-44px {
  padding-top: 44px;
  padding-bottom: 44px;
}

@media (min-width: 992px) {
  .pb-lg-44 {
    padding-bottom: 22.5rem !important;
  }
  .py-lg-44px {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
}
.pt-45 {
  padding-top: 23rem;
}

.pe-45 {
  padding-right: 23rem;
}

.pb-45 {
  padding-bottom: 23rem;
}

.ps-45 {
  padding-left: 23rem;
}

.py-45px {
  padding-top: 45px;
  padding-bottom: 45px;
}

@media (min-width: 992px) {
  .pb-lg-45 {
    padding-bottom: 23rem !important;
  }
  .py-lg-45px {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
.pt-46 {
  padding-top: 23.5rem;
}

.pe-46 {
  padding-right: 23.5rem;
}

.pb-46 {
  padding-bottom: 23.5rem;
}

.ps-46 {
  padding-left: 23.5rem;
}

.py-46px {
  padding-top: 46px;
  padding-bottom: 46px;
}

@media (min-width: 992px) {
  .pb-lg-46 {
    padding-bottom: 23.5rem !important;
  }
  .py-lg-46px {
    padding-top: 46px !important;
    padding-bottom: 46px !important;
  }
}
.pt-47 {
  padding-top: 24rem;
}

.pe-47 {
  padding-right: 24rem;
}

.pb-47 {
  padding-bottom: 24rem;
}

.ps-47 {
  padding-left: 24rem;
}

.py-47px {
  padding-top: 47px;
  padding-bottom: 47px;
}

@media (min-width: 992px) {
  .pb-lg-47 {
    padding-bottom: 24rem !important;
  }
  .py-lg-47px {
    padding-top: 47px !important;
    padding-bottom: 47px !important;
  }
}
.pt-48 {
  padding-top: 24.5rem;
}

.pe-48 {
  padding-right: 24.5rem;
}

.pb-48 {
  padding-bottom: 24.5rem;
}

.ps-48 {
  padding-left: 24.5rem;
}

.py-48px {
  padding-top: 48px;
  padding-bottom: 48px;
}

@media (min-width: 992px) {
  .pb-lg-48 {
    padding-bottom: 24.5rem !important;
  }
  .py-lg-48px {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
}
.pt-49 {
  padding-top: 25rem;
}

.pe-49 {
  padding-right: 25rem;
}

.pb-49 {
  padding-bottom: 25rem;
}

.ps-49 {
  padding-left: 25rem;
}

.py-49px {
  padding-top: 49px;
  padding-bottom: 49px;
}

@media (min-width: 992px) {
  .pb-lg-49 {
    padding-bottom: 25rem !important;
  }
  .py-lg-49px {
    padding-top: 49px !important;
    padding-bottom: 49px !important;
  }
}
.pt-50 {
  padding-top: 25.5rem;
}

.pe-50 {
  padding-right: 25.5rem;
}

.pb-50 {
  padding-bottom: 25.5rem;
}

.ps-50 {
  padding-left: 25.5rem;
}

.py-50px {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  .pb-lg-50 {
    padding-bottom: 25.5rem !important;
  }
  .py-lg-50px {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
}
.mt-6 {
  margin-top: 3.5rem;
}

.mb-6 {
  margin-bottom: 3.5rem;
}

.gx-6 {
  --bs-gutter-x: 3.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-6 {
    --bs-gutter-x: 3.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-6 {
    --bs-gutter-x: 3.5rem;
  }
}
.mt-7 {
  margin-top: 4rem;
}

.mb-7 {
  margin-bottom: 4rem;
}

.gx-7 {
  --bs-gutter-x: 4rem;
}

@media (min-width: 1200px) {
  .gx-xl-7 {
    --bs-gutter-x: 4rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-7 {
    --bs-gutter-x: 4rem;
  }
}
.mt-8 {
  margin-top: 4.5rem;
}

.mb-8 {
  margin-bottom: 4.5rem;
}

.gx-8 {
  --bs-gutter-x: 4.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-8 {
    --bs-gutter-x: 4.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-8 {
    --bs-gutter-x: 4.5rem;
  }
}
.mt-9 {
  margin-top: 5rem;
}

.mb-9 {
  margin-bottom: 5rem;
}

.gx-9 {
  --bs-gutter-x: 5rem;
}

@media (min-width: 1200px) {
  .gx-xl-9 {
    --bs-gutter-x: 5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-9 {
    --bs-gutter-x: 5rem;
  }
}
.mt-10 {
  margin-top: 5.5rem;
}

.mb-10 {
  margin-bottom: 5.5rem;
}

.gx-10 {
  --bs-gutter-x: 5.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-10 {
    --bs-gutter-x: 5.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-10 {
    --bs-gutter-x: 5.5rem;
  }
}
.mt-11 {
  margin-top: 6rem;
}

.mb-11 {
  margin-bottom: 6rem;
}

.gx-11 {
  --bs-gutter-x: 6rem;
}

@media (min-width: 1200px) {
  .gx-xl-11 {
    --bs-gutter-x: 6rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-11 {
    --bs-gutter-x: 6rem;
  }
}
.mt-12 {
  margin-top: 6.5rem;
}

.mb-12 {
  margin-bottom: 6.5rem;
}

.gx-12 {
  --bs-gutter-x: 6.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-12 {
    --bs-gutter-x: 6.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-12 {
    --bs-gutter-x: 6.5rem;
  }
}
.mt-13 {
  margin-top: 7rem;
}

.mb-13 {
  margin-bottom: 7rem;
}

.gx-13 {
  --bs-gutter-x: 7rem;
}

@media (min-width: 1200px) {
  .gx-xl-13 {
    --bs-gutter-x: 7rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-13 {
    --bs-gutter-x: 7rem;
  }
}
.mt-14 {
  margin-top: 7.5rem;
}

.mb-14 {
  margin-bottom: 7.5rem;
}

.gx-14 {
  --bs-gutter-x: 7.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-14 {
    --bs-gutter-x: 7.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-14 {
    --bs-gutter-x: 7.5rem;
  }
}
.mt-15 {
  margin-top: 8rem;
}

.mb-15 {
  margin-bottom: 8rem;
}

.gx-15 {
  --bs-gutter-x: 8rem;
}

@media (min-width: 1200px) {
  .gx-xl-15 {
    --bs-gutter-x: 8rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-15 {
    --bs-gutter-x: 8rem;
  }
}
.mt-16 {
  margin-top: 8.5rem;
}

.mb-16 {
  margin-bottom: 8.5rem;
}

.gx-16 {
  --bs-gutter-x: 8.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-16 {
    --bs-gutter-x: 8.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-16 {
    --bs-gutter-x: 8.5rem;
  }
}
.mt-17 {
  margin-top: 9rem;
}

.mb-17 {
  margin-bottom: 9rem;
}

.gx-17 {
  --bs-gutter-x: 9rem;
}

@media (min-width: 1200px) {
  .gx-xl-17 {
    --bs-gutter-x: 9rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-17 {
    --bs-gutter-x: 9rem;
  }
}
.mt-18 {
  margin-top: 9.5rem;
}

.mb-18 {
  margin-bottom: 9.5rem;
}

.gx-18 {
  --bs-gutter-x: 9.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-18 {
    --bs-gutter-x: 9.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-18 {
    --bs-gutter-x: 9.5rem;
  }
}
.mt-19 {
  margin-top: 10rem;
}

.mb-19 {
  margin-bottom: 10rem;
}

.gx-19 {
  --bs-gutter-x: 10rem;
}

@media (min-width: 1200px) {
  .gx-xl-19 {
    --bs-gutter-x: 10rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-19 {
    --bs-gutter-x: 10rem;
  }
}
.mt-20 {
  margin-top: 10.5rem;
}

.mb-20 {
  margin-bottom: 10.5rem;
}

.gx-20 {
  --bs-gutter-x: 10.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-20 {
    --bs-gutter-x: 10.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-20 {
    --bs-gutter-x: 10.5rem;
  }
}
.mt-21 {
  margin-top: 11rem;
}

.mb-21 {
  margin-bottom: 11rem;
}

.gx-21 {
  --bs-gutter-x: 11rem;
}

@media (min-width: 1200px) {
  .gx-xl-21 {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-21 {
    --bs-gutter-x: 11rem;
  }
}
.mt-22 {
  margin-top: 11.5rem;
}

.mb-22 {
  margin-bottom: 11.5rem;
}

.gx-22 {
  --bs-gutter-x: 11.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-22 {
    --bs-gutter-x: 11.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-22 {
    --bs-gutter-x: 11.5rem;
  }
}
.mt-23 {
  margin-top: 12rem;
}

.mb-23 {
  margin-bottom: 12rem;
}

.gx-23 {
  --bs-gutter-x: 12rem;
}

@media (min-width: 1200px) {
  .gx-xl-23 {
    --bs-gutter-x: 12rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-23 {
    --bs-gutter-x: 12rem;
  }
}
.mt-24 {
  margin-top: 12.5rem;
}

.mb-24 {
  margin-bottom: 12.5rem;
}

.gx-24 {
  --bs-gutter-x: 12.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-24 {
    --bs-gutter-x: 12.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-24 {
    --bs-gutter-x: 12.5rem;
  }
}
.mt-25 {
  margin-top: 13rem;
}

.mb-25 {
  margin-bottom: 13rem;
}

.gx-25 {
  --bs-gutter-x: 13rem;
}

@media (min-width: 1200px) {
  .gx-xl-25 {
    --bs-gutter-x: 13rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-25 {
    --bs-gutter-x: 13rem;
  }
}
.mt-26 {
  margin-top: 13.5rem;
}

.mb-26 {
  margin-bottom: 13.5rem;
}

.gx-26 {
  --bs-gutter-x: 13.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-26 {
    --bs-gutter-x: 13.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-26 {
    --bs-gutter-x: 13.5rem;
  }
}
.mt-27 {
  margin-top: 14rem;
}

.mb-27 {
  margin-bottom: 14rem;
}

.gx-27 {
  --bs-gutter-x: 14rem;
}

@media (min-width: 1200px) {
  .gx-xl-27 {
    --bs-gutter-x: 14rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-27 {
    --bs-gutter-x: 14rem;
  }
}
.mt-28 {
  margin-top: 14.5rem;
}

.mb-28 {
  margin-bottom: 14.5rem;
}

.gx-28 {
  --bs-gutter-x: 14.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-28 {
    --bs-gutter-x: 14.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-28 {
    --bs-gutter-x: 14.5rem;
  }
}
.mt-29 {
  margin-top: 15rem;
}

.mb-29 {
  margin-bottom: 15rem;
}

.gx-29 {
  --bs-gutter-x: 15rem;
}

@media (min-width: 1200px) {
  .gx-xl-29 {
    --bs-gutter-x: 15rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-29 {
    --bs-gutter-x: 15rem;
  }
}
.mt-30 {
  margin-top: 15.5rem;
}

.mb-30 {
  margin-bottom: 15.5rem;
}

.gx-30 {
  --bs-gutter-x: 15.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-30 {
    --bs-gutter-x: 15.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-30 {
    --bs-gutter-x: 15.5rem;
  }
}
.mt-31 {
  margin-top: 16rem;
}

.mb-31 {
  margin-bottom: 16rem;
}

.gx-31 {
  --bs-gutter-x: 16rem;
}

@media (min-width: 1200px) {
  .gx-xl-31 {
    --bs-gutter-x: 16rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-31 {
    --bs-gutter-x: 16rem;
  }
}
.mt-32 {
  margin-top: 16.5rem;
}

.mb-32 {
  margin-bottom: 16.5rem;
}

.gx-32 {
  --bs-gutter-x: 16.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-32 {
    --bs-gutter-x: 16.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-32 {
    --bs-gutter-x: 16.5rem;
  }
}
.mt-33 {
  margin-top: 17rem;
}

.mb-33 {
  margin-bottom: 17rem;
}

.gx-33 {
  --bs-gutter-x: 17rem;
}

@media (min-width: 1200px) {
  .gx-xl-33 {
    --bs-gutter-x: 17rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-33 {
    --bs-gutter-x: 17rem;
  }
}
.mt-34 {
  margin-top: 17.5rem;
}

.mb-34 {
  margin-bottom: 17.5rem;
}

.gx-34 {
  --bs-gutter-x: 17.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-34 {
    --bs-gutter-x: 17.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-34 {
    --bs-gutter-x: 17.5rem;
  }
}
.mt-35 {
  margin-top: 18rem;
}

.mb-35 {
  margin-bottom: 18rem;
}

.gx-35 {
  --bs-gutter-x: 18rem;
}

@media (min-width: 1200px) {
  .gx-xl-35 {
    --bs-gutter-x: 18rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-35 {
    --bs-gutter-x: 18rem;
  }
}
.mt-36 {
  margin-top: 18.5rem;
}

.mb-36 {
  margin-bottom: 18.5rem;
}

.gx-36 {
  --bs-gutter-x: 18.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-36 {
    --bs-gutter-x: 18.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-36 {
    --bs-gutter-x: 18.5rem;
  }
}
.mt-37 {
  margin-top: 19rem;
}

.mb-37 {
  margin-bottom: 19rem;
}

.gx-37 {
  --bs-gutter-x: 19rem;
}

@media (min-width: 1200px) {
  .gx-xl-37 {
    --bs-gutter-x: 19rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-37 {
    --bs-gutter-x: 19rem;
  }
}
.mt-38 {
  margin-top: 19.5rem;
}

.mb-38 {
  margin-bottom: 19.5rem;
}

.gx-38 {
  --bs-gutter-x: 19.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-38 {
    --bs-gutter-x: 19.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-38 {
    --bs-gutter-x: 19.5rem;
  }
}
.mt-39 {
  margin-top: 20rem;
}

.mb-39 {
  margin-bottom: 20rem;
}

.gx-39 {
  --bs-gutter-x: 20rem;
}

@media (min-width: 1200px) {
  .gx-xl-39 {
    --bs-gutter-x: 20rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-39 {
    --bs-gutter-x: 20rem;
  }
}
.mt-40 {
  margin-top: 20.5rem;
}

.mb-40 {
  margin-bottom: 20.5rem;
}

.gx-40 {
  --bs-gutter-x: 20.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-40 {
    --bs-gutter-x: 20.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-40 {
    --bs-gutter-x: 20.5rem;
  }
}
.mt-41 {
  margin-top: 21rem;
}

.mb-41 {
  margin-bottom: 21rem;
}

.gx-41 {
  --bs-gutter-x: 21rem;
}

@media (min-width: 1200px) {
  .gx-xl-41 {
    --bs-gutter-x: 21rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-41 {
    --bs-gutter-x: 21rem;
  }
}
.mt-42 {
  margin-top: 21.5rem;
}

.mb-42 {
  margin-bottom: 21.5rem;
}

.gx-42 {
  --bs-gutter-x: 21.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-42 {
    --bs-gutter-x: 21.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-42 {
    --bs-gutter-x: 21.5rem;
  }
}
.mt-43 {
  margin-top: 22rem;
}

.mb-43 {
  margin-bottom: 22rem;
}

.gx-43 {
  --bs-gutter-x: 22rem;
}

@media (min-width: 1200px) {
  .gx-xl-43 {
    --bs-gutter-x: 22rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-43 {
    --bs-gutter-x: 22rem;
  }
}
.mt-44 {
  margin-top: 22.5rem;
}

.mb-44 {
  margin-bottom: 22.5rem;
}

.gx-44 {
  --bs-gutter-x: 22.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-44 {
    --bs-gutter-x: 22.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-44 {
    --bs-gutter-x: 22.5rem;
  }
}
.mt-45 {
  margin-top: 23rem;
}

.mb-45 {
  margin-bottom: 23rem;
}

.gx-45 {
  --bs-gutter-x: 23rem;
}

@media (min-width: 1200px) {
  .gx-xl-45 {
    --bs-gutter-x: 23rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-45 {
    --bs-gutter-x: 23rem;
  }
}
.mt-46 {
  margin-top: 23.5rem;
}

.mb-46 {
  margin-bottom: 23.5rem;
}

.gx-46 {
  --bs-gutter-x: 23.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-46 {
    --bs-gutter-x: 23.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-46 {
    --bs-gutter-x: 23.5rem;
  }
}
.mt-47 {
  margin-top: 24rem;
}

.mb-47 {
  margin-bottom: 24rem;
}

.gx-47 {
  --bs-gutter-x: 24rem;
}

@media (min-width: 1200px) {
  .gx-xl-47 {
    --bs-gutter-x: 24rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-47 {
    --bs-gutter-x: 24rem;
  }
}
.mt-48 {
  margin-top: 24.5rem;
}

.mb-48 {
  margin-bottom: 24.5rem;
}

.gx-48 {
  --bs-gutter-x: 24.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-48 {
    --bs-gutter-x: 24.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-48 {
    --bs-gutter-x: 24.5rem;
  }
}
.mt-49 {
  margin-top: 25rem;
}

.mb-49 {
  margin-bottom: 25rem;
}

.gx-49 {
  --bs-gutter-x: 25rem;
}

@media (min-width: 1200px) {
  .gx-xl-49 {
    --bs-gutter-x: 25rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-49 {
    --bs-gutter-x: 25rem;
  }
}
.mt-50 {
  margin-top: 25.5rem;
}

.mb-50 {
  margin-bottom: 25.5rem;
}

.gx-50 {
  --bs-gutter-x: 25.5rem;
}

@media (min-width: 1200px) {
  .gx-xl-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 992px) {
  .gx-lg-50 {
    --bs-gutter-x: 25.5rem;
  }
}
.mt-n6 {
  margin-top: -3.5rem;
}

.mt-n6px {
  margin-top: -6px;
}

@media (min-width: 1200px) {
  .mt-xl-n6 {
    margin-top: -3.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n6 {
    margin-top: -3.5rem;
  }
  .mt-lg-n6px {
    margin-top: -6px;
  }
}
.mt-n7 {
  margin-top: -4rem;
}

.mt-n7px {
  margin-top: -7px;
}

@media (min-width: 1200px) {
  .mt-xl-n7 {
    margin-top: -4rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n7 {
    margin-top: -4rem;
  }
  .mt-lg-n7px {
    margin-top: -7px;
  }
}
.mt-n8 {
  margin-top: -4.5rem;
}

.mt-n8px {
  margin-top: -8px;
}

@media (min-width: 1200px) {
  .mt-xl-n8 {
    margin-top: -4.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n8 {
    margin-top: -4.5rem;
  }
  .mt-lg-n8px {
    margin-top: -8px;
  }
}
.mt-n9 {
  margin-top: -5rem;
}

.mt-n9px {
  margin-top: -9px;
}

@media (min-width: 1200px) {
  .mt-xl-n9 {
    margin-top: -5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n9 {
    margin-top: -5rem;
  }
  .mt-lg-n9px {
    margin-top: -9px;
  }
}
.mt-n10 {
  margin-top: -5.5rem;
}

.mt-n10px {
  margin-top: -10px;
}

@media (min-width: 1200px) {
  .mt-xl-n10 {
    margin-top: -5.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n10 {
    margin-top: -5.5rem;
  }
  .mt-lg-n10px {
    margin-top: -10px;
  }
}
.mt-n11 {
  margin-top: -6rem;
}

.mt-n11px {
  margin-top: -11px;
}

@media (min-width: 1200px) {
  .mt-xl-n11 {
    margin-top: -6rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n11 {
    margin-top: -6rem;
  }
  .mt-lg-n11px {
    margin-top: -11px;
  }
}
.mt-n12 {
  margin-top: -6.5rem;
}

.mt-n12px {
  margin-top: -12px;
}

@media (min-width: 1200px) {
  .mt-xl-n12 {
    margin-top: -6.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n12 {
    margin-top: -6.5rem;
  }
  .mt-lg-n12px {
    margin-top: -12px;
  }
}
.mt-n13 {
  margin-top: -7rem;
}

.mt-n13px {
  margin-top: -13px;
}

@media (min-width: 1200px) {
  .mt-xl-n13 {
    margin-top: -7rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n13 {
    margin-top: -7rem;
  }
  .mt-lg-n13px {
    margin-top: -13px;
  }
}
.mt-n14 {
  margin-top: -7.5rem;
}

.mt-n14px {
  margin-top: -14px;
}

@media (min-width: 1200px) {
  .mt-xl-n14 {
    margin-top: -7.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n14 {
    margin-top: -7.5rem;
  }
  .mt-lg-n14px {
    margin-top: -14px;
  }
}
.mt-n15 {
  margin-top: -8rem;
}

.mt-n15px {
  margin-top: -15px;
}

@media (min-width: 1200px) {
  .mt-xl-n15 {
    margin-top: -8rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n15 {
    margin-top: -8rem;
  }
  .mt-lg-n15px {
    margin-top: -15px;
  }
}
.mt-n16 {
  margin-top: -8.5rem;
}

.mt-n16px {
  margin-top: -16px;
}

@media (min-width: 1200px) {
  .mt-xl-n16 {
    margin-top: -8.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n16 {
    margin-top: -8.5rem;
  }
  .mt-lg-n16px {
    margin-top: -16px;
  }
}
.mt-n17 {
  margin-top: -9rem;
}

.mt-n17px {
  margin-top: -17px;
}

@media (min-width: 1200px) {
  .mt-xl-n17 {
    margin-top: -9rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n17 {
    margin-top: -9rem;
  }
  .mt-lg-n17px {
    margin-top: -17px;
  }
}
.mt-n18 {
  margin-top: -9.5rem;
}

.mt-n18px {
  margin-top: -18px;
}

@media (min-width: 1200px) {
  .mt-xl-n18 {
    margin-top: -9.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n18 {
    margin-top: -9.5rem;
  }
  .mt-lg-n18px {
    margin-top: -18px;
  }
}
.mt-n19 {
  margin-top: -10rem;
}

.mt-n19px {
  margin-top: -19px;
}

@media (min-width: 1200px) {
  .mt-xl-n19 {
    margin-top: -10rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n19 {
    margin-top: -10rem;
  }
  .mt-lg-n19px {
    margin-top: -19px;
  }
}
.mt-n20 {
  margin-top: -10.5rem;
}

.mt-n20px {
  margin-top: -20px;
}

@media (min-width: 1200px) {
  .mt-xl-n20 {
    margin-top: -10.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n20 {
    margin-top: -10.5rem;
  }
  .mt-lg-n20px {
    margin-top: -20px;
  }
}
.mt-n21 {
  margin-top: -11rem;
}

.mt-n21px {
  margin-top: -21px;
}

@media (min-width: 1200px) {
  .mt-xl-n21 {
    margin-top: -11rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n21 {
    margin-top: -11rem;
  }
  .mt-lg-n21px {
    margin-top: -21px;
  }
}
.mt-n22 {
  margin-top: -11.5rem;
}

.mt-n22px {
  margin-top: -22px;
}

@media (min-width: 1200px) {
  .mt-xl-n22 {
    margin-top: -11.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n22 {
    margin-top: -11.5rem;
  }
  .mt-lg-n22px {
    margin-top: -22px;
  }
}
.mt-n23 {
  margin-top: -12rem;
}

.mt-n23px {
  margin-top: -23px;
}

@media (min-width: 1200px) {
  .mt-xl-n23 {
    margin-top: -12rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n23 {
    margin-top: -12rem;
  }
  .mt-lg-n23px {
    margin-top: -23px;
  }
}
.mt-n24 {
  margin-top: -12.5rem;
}

.mt-n24px {
  margin-top: -24px;
}

@media (min-width: 1200px) {
  .mt-xl-n24 {
    margin-top: -12.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n24 {
    margin-top: -12.5rem;
  }
  .mt-lg-n24px {
    margin-top: -24px;
  }
}
.mt-n25 {
  margin-top: -13rem;
}

.mt-n25px {
  margin-top: -25px;
}

@media (min-width: 1200px) {
  .mt-xl-n25 {
    margin-top: -13rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n25 {
    margin-top: -13rem;
  }
  .mt-lg-n25px {
    margin-top: -25px;
  }
}
.mt-n26 {
  margin-top: -13.5rem;
}

.mt-n26px {
  margin-top: -26px;
}

@media (min-width: 1200px) {
  .mt-xl-n26 {
    margin-top: -13.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n26 {
    margin-top: -13.5rem;
  }
  .mt-lg-n26px {
    margin-top: -26px;
  }
}
.mt-n27 {
  margin-top: -14rem;
}

.mt-n27px {
  margin-top: -27px;
}

@media (min-width: 1200px) {
  .mt-xl-n27 {
    margin-top: -14rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n27 {
    margin-top: -14rem;
  }
  .mt-lg-n27px {
    margin-top: -27px;
  }
}
.mt-n28 {
  margin-top: -14.5rem;
}

.mt-n28px {
  margin-top: -28px;
}

@media (min-width: 1200px) {
  .mt-xl-n28 {
    margin-top: -14.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n28 {
    margin-top: -14.5rem;
  }
  .mt-lg-n28px {
    margin-top: -28px;
  }
}
.mt-n29 {
  margin-top: -15rem;
}

.mt-n29px {
  margin-top: -29px;
}

@media (min-width: 1200px) {
  .mt-xl-n29 {
    margin-top: -15rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n29 {
    margin-top: -15rem;
  }
  .mt-lg-n29px {
    margin-top: -29px;
  }
}
.mt-n30 {
  margin-top: -15.5rem;
}

.mt-n30px {
  margin-top: -30px;
}

@media (min-width: 1200px) {
  .mt-xl-n30 {
    margin-top: -15.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n30 {
    margin-top: -15.5rem;
  }
  .mt-lg-n30px {
    margin-top: -30px;
  }
}
.mt-n31 {
  margin-top: -16rem;
}

.mt-n31px {
  margin-top: -31px;
}

@media (min-width: 1200px) {
  .mt-xl-n31 {
    margin-top: -16rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n31 {
    margin-top: -16rem;
  }
  .mt-lg-n31px {
    margin-top: -31px;
  }
}
.mt-n32 {
  margin-top: -16.5rem;
}

.mt-n32px {
  margin-top: -32px;
}

@media (min-width: 1200px) {
  .mt-xl-n32 {
    margin-top: -16.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n32 {
    margin-top: -16.5rem;
  }
  .mt-lg-n32px {
    margin-top: -32px;
  }
}
.mt-n33 {
  margin-top: -17rem;
}

.mt-n33px {
  margin-top: -33px;
}

@media (min-width: 1200px) {
  .mt-xl-n33 {
    margin-top: -17rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n33 {
    margin-top: -17rem;
  }
  .mt-lg-n33px {
    margin-top: -33px;
  }
}
.mt-n34 {
  margin-top: -17.5rem;
}

.mt-n34px {
  margin-top: -34px;
}

@media (min-width: 1200px) {
  .mt-xl-n34 {
    margin-top: -17.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n34 {
    margin-top: -17.5rem;
  }
  .mt-lg-n34px {
    margin-top: -34px;
  }
}
.mt-n35 {
  margin-top: -18rem;
}

.mt-n35px {
  margin-top: -35px;
}

@media (min-width: 1200px) {
  .mt-xl-n35 {
    margin-top: -18rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n35 {
    margin-top: -18rem;
  }
  .mt-lg-n35px {
    margin-top: -35px;
  }
}
.mt-n36 {
  margin-top: -18.5rem;
}

.mt-n36px {
  margin-top: -36px;
}

@media (min-width: 1200px) {
  .mt-xl-n36 {
    margin-top: -18.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n36 {
    margin-top: -18.5rem;
  }
  .mt-lg-n36px {
    margin-top: -36px;
  }
}
.mt-n37 {
  margin-top: -19rem;
}

.mt-n37px {
  margin-top: -37px;
}

@media (min-width: 1200px) {
  .mt-xl-n37 {
    margin-top: -19rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n37 {
    margin-top: -19rem;
  }
  .mt-lg-n37px {
    margin-top: -37px;
  }
}
.mt-n38 {
  margin-top: -19.5rem;
}

.mt-n38px {
  margin-top: -38px;
}

@media (min-width: 1200px) {
  .mt-xl-n38 {
    margin-top: -19.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n38 {
    margin-top: -19.5rem;
  }
  .mt-lg-n38px {
    margin-top: -38px;
  }
}
.mt-n39 {
  margin-top: -20rem;
}

.mt-n39px {
  margin-top: -39px;
}

@media (min-width: 1200px) {
  .mt-xl-n39 {
    margin-top: -20rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n39 {
    margin-top: -20rem;
  }
  .mt-lg-n39px {
    margin-top: -39px;
  }
}
.mt-n40 {
  margin-top: -20.5rem;
}

.mt-n40px {
  margin-top: -40px;
}

@media (min-width: 1200px) {
  .mt-xl-n40 {
    margin-top: -20.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n40 {
    margin-top: -20.5rem;
  }
  .mt-lg-n40px {
    margin-top: -40px;
  }
}
.mt-n41 {
  margin-top: -21rem;
}

.mt-n41px {
  margin-top: -41px;
}

@media (min-width: 1200px) {
  .mt-xl-n41 {
    margin-top: -21rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n41 {
    margin-top: -21rem;
  }
  .mt-lg-n41px {
    margin-top: -41px;
  }
}
.mt-n42 {
  margin-top: -21.5rem;
}

.mt-n42px {
  margin-top: -42px;
}

@media (min-width: 1200px) {
  .mt-xl-n42 {
    margin-top: -21.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n42 {
    margin-top: -21.5rem;
  }
  .mt-lg-n42px {
    margin-top: -42px;
  }
}
.mt-n43 {
  margin-top: -22rem;
}

.mt-n43px {
  margin-top: -43px;
}

@media (min-width: 1200px) {
  .mt-xl-n43 {
    margin-top: -22rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n43 {
    margin-top: -22rem;
  }
  .mt-lg-n43px {
    margin-top: -43px;
  }
}
.mt-n44 {
  margin-top: -22.5rem;
}

.mt-n44px {
  margin-top: -44px;
}

@media (min-width: 1200px) {
  .mt-xl-n44 {
    margin-top: -22.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n44 {
    margin-top: -22.5rem;
  }
  .mt-lg-n44px {
    margin-top: -44px;
  }
}
.mt-n45 {
  margin-top: -23rem;
}

.mt-n45px {
  margin-top: -45px;
}

@media (min-width: 1200px) {
  .mt-xl-n45 {
    margin-top: -23rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n45 {
    margin-top: -23rem;
  }
  .mt-lg-n45px {
    margin-top: -45px;
  }
}
.mt-n46 {
  margin-top: -23.5rem;
}

.mt-n46px {
  margin-top: -46px;
}

@media (min-width: 1200px) {
  .mt-xl-n46 {
    margin-top: -23.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n46 {
    margin-top: -23.5rem;
  }
  .mt-lg-n46px {
    margin-top: -46px;
  }
}
.mt-n47 {
  margin-top: -24rem;
}

.mt-n47px {
  margin-top: -47px;
}

@media (min-width: 1200px) {
  .mt-xl-n47 {
    margin-top: -24rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n47 {
    margin-top: -24rem;
  }
  .mt-lg-n47px {
    margin-top: -47px;
  }
}
.mt-n48 {
  margin-top: -24.5rem;
}

.mt-n48px {
  margin-top: -48px;
}

@media (min-width: 1200px) {
  .mt-xl-n48 {
    margin-top: -24.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n48 {
    margin-top: -24.5rem;
  }
  .mt-lg-n48px {
    margin-top: -48px;
  }
}
.mt-n49 {
  margin-top: -25rem;
}

.mt-n49px {
  margin-top: -49px;
}

@media (min-width: 1200px) {
  .mt-xl-n49 {
    margin-top: -25rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n49 {
    margin-top: -25rem;
  }
  .mt-lg-n49px {
    margin-top: -49px;
  }
}
.mt-n50 {
  margin-top: -25.5rem;
}

.mt-n50px {
  margin-top: -50px;
}

@media (min-width: 1200px) {
  .mt-xl-n50 {
    margin-top: -25.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n50 {
    margin-top: -25.5rem;
  }
  .mt-lg-n50px {
    margin-top: -50px;
  }
}
.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-27 {
  font-size: 27px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-29 {
  font-size: 29px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-31 {
  font-size: 31px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-35 {
  font-size: 35px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-37 {
  font-size: 37px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-39 {
  font-size: 39px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-41 {
  font-size: 41px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-43 {
  font-size: 43px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-47 {
  font-size: 47px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-49 {
  font-size: 49px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-51 {
  font-size: 51px;
}

.font-size-52 {
  font-size: 52px;
}

.font-size-53 {
  font-size: 53px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-55 {
  font-size: 55px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-57 {
  font-size: 57px;
}

.font-size-58 {
  font-size: 58px;
}

.font-size-59 {
  font-size: 59px;
}

.font-size-60 {
  font-size: 60px;
}

.font-size-61 {
  font-size: 61px;
}

.font-size-62 {
  font-size: 62px;
}

.font-size-63 {
  font-size: 63px;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-65 {
  font-size: 65px;
}

.font-size-66 {
  font-size: 66px;
}

.font-size-67 {
  font-size: 67px;
}

.font-size-68 {
  font-size: 68px;
}

.font-size-69 {
  font-size: 69px;
}

.font-size-70 {
  font-size: 70px;
}

.font-size-71 {
  font-size: 71px;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-73 {
  font-size: 73px;
}

.font-size-74 {
  font-size: 74px;
}

.font-size-75 {
  font-size: 75px;
}

.font-size-76 {
  font-size: 76px;
}

.font-size-77 {
  font-size: 77px;
}

.font-size-78 {
  font-size: 78px;
}

.font-size-79 {
  font-size: 79px;
}

.font-size-80 {
  font-size: 80px;
}

.font-size-81 {
  font-size: 81px;
}

.font-size-82 {
  font-size: 82px;
}

.font-size-83 {
  font-size: 83px;
}

.font-size-84 {
  font-size: 84px;
}

.font-size-85 {
  font-size: 85px;
}

.font-size-86 {
  font-size: 86px;
}

.font-size-87 {
  font-size: 87px;
}

.font-size-88 {
  font-size: 88px;
}

.font-size-89 {
  font-size: 89px;
}

.font-size-90 {
  font-size: 90px;
}

.font-size-91 {
  font-size: 91px;
}

.font-size-92 {
  font-size: 92px;
}

.font-size-93 {
  font-size: 93px;
}

.font-size-94 {
  font-size: 94px;
}

.font-size-95 {
  font-size: 95px;
}

.font-size-96 {
  font-size: 96px;
}

.font-size-97 {
  font-size: 97px;
}

.font-size-98 {
  font-size: 98px;
}

.font-size-99 {
  font-size: 99px;
}

.font-size-100 {
  font-size: 100px;
}

.font-size-xs-10 {
  font-size: 10px;
}

.font-size-xs-11 {
  font-size: 11px;
}

.font-size-xs-12 {
  font-size: 12px;
}

.font-size-xs-13 {
  font-size: 13px;
}

.font-size-xs-14 {
  font-size: 14px;
}

.font-size-xs-15 {
  font-size: 15px;
}

.font-size-xs-16 {
  font-size: 16px;
}

.font-size-xs-17 {
  font-size: 17px;
}

.font-size-xs-18 {
  font-size: 18px;
}

.font-size-xs-19 {
  font-size: 19px;
}

.font-size-xs-20 {
  font-size: 20px;
}

.font-size-xs-21 {
  font-size: 21px;
}

.font-size-xs-22 {
  font-size: 22px;
}

.font-size-xs-23 {
  font-size: 23px;
}

.font-size-xs-24 {
  font-size: 24px;
}

.font-size-xs-25 {
  font-size: 25px;
}

.font-size-xs-26 {
  font-size: 26px;
}

.font-size-xs-27 {
  font-size: 27px;
}

.font-size-xs-28 {
  font-size: 28px;
}

.font-size-xs-29 {
  font-size: 29px;
}

.font-size-xs-30 {
  font-size: 30px;
}

.font-size-xs-31 {
  font-size: 31px;
}

.font-size-xs-32 {
  font-size: 32px;
}

.font-size-xs-33 {
  font-size: 33px;
}

.font-size-xs-34 {
  font-size: 34px;
}

.font-size-xs-35 {
  font-size: 35px;
}

.font-size-xs-36 {
  font-size: 36px;
}

.font-size-xs-37 {
  font-size: 37px;
}

.font-size-xs-38 {
  font-size: 38px;
}

.font-size-xs-39 {
  font-size: 39px;
}

.font-size-xs-40 {
  font-size: 40px;
}

.font-size-xs-41 {
  font-size: 41px;
}

.font-size-xs-42 {
  font-size: 42px;
}

.font-size-xs-43 {
  font-size: 43px;
}

.font-size-xs-44 {
  font-size: 44px;
}

.font-size-xs-45 {
  font-size: 45px;
}

.font-size-xs-46 {
  font-size: 46px;
}

.font-size-xs-47 {
  font-size: 47px;
}

.font-size-xs-48 {
  font-size: 48px;
}

.font-size-xs-49 {
  font-size: 49px;
}

.font-size-xs-50 {
  font-size: 50px;
}

.font-size-xs-51 {
  font-size: 51px;
}

.font-size-xs-52 {
  font-size: 52px;
}

.font-size-xs-53 {
  font-size: 53px;
}

.font-size-xs-54 {
  font-size: 54px;
}

.font-size-xs-55 {
  font-size: 55px;
}

.font-size-xs-56 {
  font-size: 56px;
}

.font-size-xs-57 {
  font-size: 57px;
}

.font-size-xs-58 {
  font-size: 58px;
}

.font-size-xs-59 {
  font-size: 59px;
}

.font-size-xs-60 {
  font-size: 60px;
}

.font-size-xs-61 {
  font-size: 61px;
}

.font-size-xs-62 {
  font-size: 62px;
}

.font-size-xs-63 {
  font-size: 63px;
}

.font-size-xs-64 {
  font-size: 64px;
}

.font-size-xs-65 {
  font-size: 65px;
}

.font-size-xs-66 {
  font-size: 66px;
}

.font-size-xs-67 {
  font-size: 67px;
}

.font-size-xs-68 {
  font-size: 68px;
}

.font-size-xs-69 {
  font-size: 69px;
}

.font-size-xs-70 {
  font-size: 70px;
}

.font-size-xs-71 {
  font-size: 71px;
}

.font-size-xs-72 {
  font-size: 72px;
}

.font-size-xs-73 {
  font-size: 73px;
}

.font-size-xs-74 {
  font-size: 74px;
}

.font-size-xs-75 {
  font-size: 75px;
}

.font-size-xs-76 {
  font-size: 76px;
}

.font-size-xs-77 {
  font-size: 77px;
}

.font-size-xs-78 {
  font-size: 78px;
}

.font-size-xs-79 {
  font-size: 79px;
}

.font-size-xs-80 {
  font-size: 80px;
}

.font-size-xs-81 {
  font-size: 81px;
}

.font-size-xs-82 {
  font-size: 82px;
}

.font-size-xs-83 {
  font-size: 83px;
}

.font-size-xs-84 {
  font-size: 84px;
}

.font-size-xs-85 {
  font-size: 85px;
}

.font-size-xs-86 {
  font-size: 86px;
}

.font-size-xs-87 {
  font-size: 87px;
}

.font-size-xs-88 {
  font-size: 88px;
}

.font-size-xs-89 {
  font-size: 89px;
}

.font-size-xs-90 {
  font-size: 90px;
}

.font-size-xs-91 {
  font-size: 91px;
}

.font-size-xs-92 {
  font-size: 92px;
}

.font-size-xs-93 {
  font-size: 93px;
}

.font-size-xs-94 {
  font-size: 94px;
}

.font-size-xs-95 {
  font-size: 95px;
}

.font-size-xs-96 {
  font-size: 96px;
}

.font-size-xs-97 {
  font-size: 97px;
}

.font-size-xs-98 {
  font-size: 98px;
}

.font-size-xs-99 {
  font-size: 99px;
}

.font-size-xs-100 {
  font-size: 100px;
}

@media (min-width: 576px) {
  .font-size-sm-10 {
    font-size: 10px;
  }
  .font-size-sm-11 {
    font-size: 11px;
  }
  .font-size-sm-12 {
    font-size: 12px;
  }
  .font-size-sm-13 {
    font-size: 13px;
  }
  .font-size-sm-14 {
    font-size: 14px;
  }
  .font-size-sm-15 {
    font-size: 15px;
  }
  .font-size-sm-16 {
    font-size: 16px;
  }
  .font-size-sm-17 {
    font-size: 17px;
  }
  .font-size-sm-18 {
    font-size: 18px;
  }
  .font-size-sm-19 {
    font-size: 19px;
  }
  .font-size-sm-20 {
    font-size: 20px;
  }
  .font-size-sm-21 {
    font-size: 21px;
  }
  .font-size-sm-22 {
    font-size: 22px;
  }
  .font-size-sm-23 {
    font-size: 23px;
  }
  .font-size-sm-24 {
    font-size: 24px;
  }
  .font-size-sm-25 {
    font-size: 25px;
  }
  .font-size-sm-26 {
    font-size: 26px;
  }
  .font-size-sm-27 {
    font-size: 27px;
  }
  .font-size-sm-28 {
    font-size: 28px;
  }
  .font-size-sm-29 {
    font-size: 29px;
  }
  .font-size-sm-30 {
    font-size: 30px;
  }
  .font-size-sm-31 {
    font-size: 31px;
  }
  .font-size-sm-32 {
    font-size: 32px;
  }
  .font-size-sm-33 {
    font-size: 33px;
  }
  .font-size-sm-34 {
    font-size: 34px;
  }
  .font-size-sm-35 {
    font-size: 35px;
  }
  .font-size-sm-36 {
    font-size: 36px;
  }
  .font-size-sm-37 {
    font-size: 37px;
  }
  .font-size-sm-38 {
    font-size: 38px;
  }
  .font-size-sm-39 {
    font-size: 39px;
  }
  .font-size-sm-40 {
    font-size: 40px;
  }
  .font-size-sm-41 {
    font-size: 41px;
  }
  .font-size-sm-42 {
    font-size: 42px;
  }
  .font-size-sm-43 {
    font-size: 43px;
  }
  .font-size-sm-44 {
    font-size: 44px;
  }
  .font-size-sm-45 {
    font-size: 45px;
  }
  .font-size-sm-46 {
    font-size: 46px;
  }
  .font-size-sm-47 {
    font-size: 47px;
  }
  .font-size-sm-48 {
    font-size: 48px;
  }
  .font-size-sm-49 {
    font-size: 49px;
  }
  .font-size-sm-50 {
    font-size: 50px;
  }
  .font-size-sm-51 {
    font-size: 51px;
  }
  .font-size-sm-52 {
    font-size: 52px;
  }
  .font-size-sm-53 {
    font-size: 53px;
  }
  .font-size-sm-54 {
    font-size: 54px;
  }
  .font-size-sm-55 {
    font-size: 55px;
  }
  .font-size-sm-56 {
    font-size: 56px;
  }
  .font-size-sm-57 {
    font-size: 57px;
  }
  .font-size-sm-58 {
    font-size: 58px;
  }
  .font-size-sm-59 {
    font-size: 59px;
  }
  .font-size-sm-60 {
    font-size: 60px;
  }
  .font-size-sm-61 {
    font-size: 61px;
  }
  .font-size-sm-62 {
    font-size: 62px;
  }
  .font-size-sm-63 {
    font-size: 63px;
  }
  .font-size-sm-64 {
    font-size: 64px;
  }
  .font-size-sm-65 {
    font-size: 65px;
  }
  .font-size-sm-66 {
    font-size: 66px;
  }
  .font-size-sm-67 {
    font-size: 67px;
  }
  .font-size-sm-68 {
    font-size: 68px;
  }
  .font-size-sm-69 {
    font-size: 69px;
  }
  .font-size-sm-70 {
    font-size: 70px;
  }
  .font-size-sm-71 {
    font-size: 71px;
  }
  .font-size-sm-72 {
    font-size: 72px;
  }
  .font-size-sm-73 {
    font-size: 73px;
  }
  .font-size-sm-74 {
    font-size: 74px;
  }
  .font-size-sm-75 {
    font-size: 75px;
  }
  .font-size-sm-76 {
    font-size: 76px;
  }
  .font-size-sm-77 {
    font-size: 77px;
  }
  .font-size-sm-78 {
    font-size: 78px;
  }
  .font-size-sm-79 {
    font-size: 79px;
  }
  .font-size-sm-80 {
    font-size: 80px;
  }
  .font-size-sm-81 {
    font-size: 81px;
  }
  .font-size-sm-82 {
    font-size: 82px;
  }
  .font-size-sm-83 {
    font-size: 83px;
  }
  .font-size-sm-84 {
    font-size: 84px;
  }
  .font-size-sm-85 {
    font-size: 85px;
  }
  .font-size-sm-86 {
    font-size: 86px;
  }
  .font-size-sm-87 {
    font-size: 87px;
  }
  .font-size-sm-88 {
    font-size: 88px;
  }
  .font-size-sm-89 {
    font-size: 89px;
  }
  .font-size-sm-90 {
    font-size: 90px;
  }
  .font-size-sm-91 {
    font-size: 91px;
  }
  .font-size-sm-92 {
    font-size: 92px;
  }
  .font-size-sm-93 {
    font-size: 93px;
  }
  .font-size-sm-94 {
    font-size: 94px;
  }
  .font-size-sm-95 {
    font-size: 95px;
  }
  .font-size-sm-96 {
    font-size: 96px;
  }
  .font-size-sm-97 {
    font-size: 97px;
  }
  .font-size-sm-98 {
    font-size: 98px;
  }
  .font-size-sm-99 {
    font-size: 99px;
  }
  .font-size-sm-100 {
    font-size: 100px;
  }
}
@media (min-width: 768px) {
  .font-size-md-10 {
    font-size: 10px;
  }
  .font-size-md-11 {
    font-size: 11px;
  }
  .font-size-md-12 {
    font-size: 12px;
  }
  .font-size-md-13 {
    font-size: 13px;
  }
  .font-size-md-14 {
    font-size: 14px;
  }
  .font-size-md-15 {
    font-size: 15px;
  }
  .font-size-md-16 {
    font-size: 16px;
  }
  .font-size-md-17 {
    font-size: 17px;
  }
  .font-size-md-18 {
    font-size: 18px;
  }
  .font-size-md-19 {
    font-size: 19px;
  }
  .font-size-md-20 {
    font-size: 20px;
  }
  .font-size-md-21 {
    font-size: 21px;
  }
  .font-size-md-22 {
    font-size: 22px;
  }
  .font-size-md-23 {
    font-size: 23px;
  }
  .font-size-md-24 {
    font-size: 24px;
  }
  .font-size-md-25 {
    font-size: 25px;
  }
  .font-size-md-26 {
    font-size: 26px;
  }
  .font-size-md-27 {
    font-size: 27px;
  }
  .font-size-md-28 {
    font-size: 28px;
  }
  .font-size-md-29 {
    font-size: 29px;
  }
  .font-size-md-30 {
    font-size: 30px;
  }
  .font-size-md-31 {
    font-size: 31px;
  }
  .font-size-md-32 {
    font-size: 32px;
  }
  .font-size-md-33 {
    font-size: 33px;
  }
  .font-size-md-34 {
    font-size: 34px;
  }
  .font-size-md-35 {
    font-size: 35px;
  }
  .font-size-md-36 {
    font-size: 36px;
  }
  .font-size-md-37 {
    font-size: 37px;
  }
  .font-size-md-38 {
    font-size: 38px;
  }
  .font-size-md-39 {
    font-size: 39px;
  }
  .font-size-md-40 {
    font-size: 40px;
  }
  .font-size-md-41 {
    font-size: 41px;
  }
  .font-size-md-42 {
    font-size: 42px;
  }
  .font-size-md-43 {
    font-size: 43px;
  }
  .font-size-md-44 {
    font-size: 44px;
  }
  .font-size-md-45 {
    font-size: 45px;
  }
  .font-size-md-46 {
    font-size: 46px;
  }
  .font-size-md-47 {
    font-size: 47px;
  }
  .font-size-md-48 {
    font-size: 48px;
  }
  .font-size-md-49 {
    font-size: 49px;
  }
  .font-size-md-50 {
    font-size: 50px;
  }
  .font-size-md-51 {
    font-size: 51px;
  }
  .font-size-md-52 {
    font-size: 52px;
  }
  .font-size-md-53 {
    font-size: 53px;
  }
  .font-size-md-54 {
    font-size: 54px;
  }
  .font-size-md-55 {
    font-size: 55px;
  }
  .font-size-md-56 {
    font-size: 56px;
  }
  .font-size-md-57 {
    font-size: 57px;
  }
  .font-size-md-58 {
    font-size: 58px;
  }
  .font-size-md-59 {
    font-size: 59px;
  }
  .font-size-md-60 {
    font-size: 60px;
  }
  .font-size-md-61 {
    font-size: 61px;
  }
  .font-size-md-62 {
    font-size: 62px;
  }
  .font-size-md-63 {
    font-size: 63px;
  }
  .font-size-md-64 {
    font-size: 64px;
  }
  .font-size-md-65 {
    font-size: 65px;
  }
  .font-size-md-66 {
    font-size: 66px;
  }
  .font-size-md-67 {
    font-size: 67px;
  }
  .font-size-md-68 {
    font-size: 68px;
  }
  .font-size-md-69 {
    font-size: 69px;
  }
  .font-size-md-70 {
    font-size: 70px;
  }
  .font-size-md-71 {
    font-size: 71px;
  }
  .font-size-md-72 {
    font-size: 72px;
  }
  .font-size-md-73 {
    font-size: 73px;
  }
  .font-size-md-74 {
    font-size: 74px;
  }
  .font-size-md-75 {
    font-size: 75px;
  }
  .font-size-md-76 {
    font-size: 76px;
  }
  .font-size-md-77 {
    font-size: 77px;
  }
  .font-size-md-78 {
    font-size: 78px;
  }
  .font-size-md-79 {
    font-size: 79px;
  }
  .font-size-md-80 {
    font-size: 80px;
  }
  .font-size-md-81 {
    font-size: 81px;
  }
  .font-size-md-82 {
    font-size: 82px;
  }
  .font-size-md-83 {
    font-size: 83px;
  }
  .font-size-md-84 {
    font-size: 84px;
  }
  .font-size-md-85 {
    font-size: 85px;
  }
  .font-size-md-86 {
    font-size: 86px;
  }
  .font-size-md-87 {
    font-size: 87px;
  }
  .font-size-md-88 {
    font-size: 88px;
  }
  .font-size-md-89 {
    font-size: 89px;
  }
  .font-size-md-90 {
    font-size: 90px;
  }
  .font-size-md-91 {
    font-size: 91px;
  }
  .font-size-md-92 {
    font-size: 92px;
  }
  .font-size-md-93 {
    font-size: 93px;
  }
  .font-size-md-94 {
    font-size: 94px;
  }
  .font-size-md-95 {
    font-size: 95px;
  }
  .font-size-md-96 {
    font-size: 96px;
  }
  .font-size-md-97 {
    font-size: 97px;
  }
  .font-size-md-98 {
    font-size: 98px;
  }
  .font-size-md-99 {
    font-size: 99px;
  }
  .font-size-md-100 {
    font-size: 100px;
  }
}
@media (min-width: 992px) {
  .font-size-lg-10 {
    font-size: 10px;
  }
  .font-size-lg-11 {
    font-size: 11px;
  }
  .font-size-lg-12 {
    font-size: 12px;
  }
  .font-size-lg-13 {
    font-size: 13px;
  }
  .font-size-lg-14 {
    font-size: 14px;
  }
  .font-size-lg-15 {
    font-size: 15px;
  }
  .font-size-lg-16 {
    font-size: 16px;
  }
  .font-size-lg-17 {
    font-size: 17px;
  }
  .font-size-lg-18 {
    font-size: 18px;
  }
  .font-size-lg-19 {
    font-size: 19px;
  }
  .font-size-lg-20 {
    font-size: 20px;
  }
  .font-size-lg-21 {
    font-size: 21px;
  }
  .font-size-lg-22 {
    font-size: 22px;
  }
  .font-size-lg-23 {
    font-size: 23px;
  }
  .font-size-lg-24 {
    font-size: 24px;
  }
  .font-size-lg-25 {
    font-size: 25px;
  }
  .font-size-lg-26 {
    font-size: 26px;
  }
  .font-size-lg-27 {
    font-size: 27px;
  }
  .font-size-lg-28 {
    font-size: 28px;
  }
  .font-size-lg-29 {
    font-size: 29px;
  }
  .font-size-lg-30 {
    font-size: 30px;
  }
  .font-size-lg-31 {
    font-size: 31px;
  }
  .font-size-lg-32 {
    font-size: 32px;
  }
  .font-size-lg-33 {
    font-size: 33px;
  }
  .font-size-lg-34 {
    font-size: 34px;
  }
  .font-size-lg-35 {
    font-size: 35px;
  }
  .font-size-lg-36 {
    font-size: 36px;
  }
  .font-size-lg-37 {
    font-size: 37px;
  }
  .font-size-lg-38 {
    font-size: 38px;
  }
  .font-size-lg-39 {
    font-size: 39px;
  }
  .font-size-lg-40 {
    font-size: 40px;
  }
  .font-size-lg-41 {
    font-size: 41px;
  }
  .font-size-lg-42 {
    font-size: 42px;
  }
  .font-size-lg-43 {
    font-size: 43px;
  }
  .font-size-lg-44 {
    font-size: 44px;
  }
  .font-size-lg-45 {
    font-size: 45px;
  }
  .font-size-lg-46 {
    font-size: 46px;
  }
  .font-size-lg-47 {
    font-size: 47px;
  }
  .font-size-lg-48 {
    font-size: 48px;
  }
  .font-size-lg-49 {
    font-size: 49px;
  }
  .font-size-lg-50 {
    font-size: 50px;
  }
  .font-size-lg-51 {
    font-size: 51px;
  }
  .font-size-lg-52 {
    font-size: 52px;
  }
  .font-size-lg-53 {
    font-size: 53px;
  }
  .font-size-lg-54 {
    font-size: 54px;
  }
  .font-size-lg-55 {
    font-size: 55px;
  }
  .font-size-lg-56 {
    font-size: 56px;
  }
  .font-size-lg-57 {
    font-size: 57px;
  }
  .font-size-lg-58 {
    font-size: 58px;
  }
  .font-size-lg-59 {
    font-size: 59px;
  }
  .font-size-lg-60 {
    font-size: 60px;
  }
  .font-size-lg-61 {
    font-size: 61px;
  }
  .font-size-lg-62 {
    font-size: 62px;
  }
  .font-size-lg-63 {
    font-size: 63px;
  }
  .font-size-lg-64 {
    font-size: 64px;
  }
  .font-size-lg-65 {
    font-size: 65px;
  }
  .font-size-lg-66 {
    font-size: 66px;
  }
  .font-size-lg-67 {
    font-size: 67px;
  }
  .font-size-lg-68 {
    font-size: 68px;
  }
  .font-size-lg-69 {
    font-size: 69px;
  }
  .font-size-lg-70 {
    font-size: 70px;
  }
  .font-size-lg-71 {
    font-size: 71px;
  }
  .font-size-lg-72 {
    font-size: 72px;
  }
  .font-size-lg-73 {
    font-size: 73px;
  }
  .font-size-lg-74 {
    font-size: 74px;
  }
  .font-size-lg-75 {
    font-size: 75px;
  }
  .font-size-lg-76 {
    font-size: 76px;
  }
  .font-size-lg-77 {
    font-size: 77px;
  }
  .font-size-lg-78 {
    font-size: 78px;
  }
  .font-size-lg-79 {
    font-size: 79px;
  }
  .font-size-lg-80 {
    font-size: 80px;
  }
  .font-size-lg-81 {
    font-size: 81px;
  }
  .font-size-lg-82 {
    font-size: 82px;
  }
  .font-size-lg-83 {
    font-size: 83px;
  }
  .font-size-lg-84 {
    font-size: 84px;
  }
  .font-size-lg-85 {
    font-size: 85px;
  }
  .font-size-lg-86 {
    font-size: 86px;
  }
  .font-size-lg-87 {
    font-size: 87px;
  }
  .font-size-lg-88 {
    font-size: 88px;
  }
  .font-size-lg-89 {
    font-size: 89px;
  }
  .font-size-lg-90 {
    font-size: 90px;
  }
  .font-size-lg-91 {
    font-size: 91px;
  }
  .font-size-lg-92 {
    font-size: 92px;
  }
  .font-size-lg-93 {
    font-size: 93px;
  }
  .font-size-lg-94 {
    font-size: 94px;
  }
  .font-size-lg-95 {
    font-size: 95px;
  }
  .font-size-lg-96 {
    font-size: 96px;
  }
  .font-size-lg-97 {
    font-size: 97px;
  }
  .font-size-lg-98 {
    font-size: 98px;
  }
  .font-size-lg-99 {
    font-size: 99px;
  }
  .font-size-lg-100 {
    font-size: 100px;
  }
}
@media (min-width: 1200px) {
  .font-size-xl-10 {
    font-size: 10px;
  }
  .font-size-xl-11 {
    font-size: 11px;
  }
  .font-size-xl-12 {
    font-size: 12px;
  }
  .font-size-xl-13 {
    font-size: 13px;
  }
  .font-size-xl-14 {
    font-size: 14px;
  }
  .font-size-xl-15 {
    font-size: 15px;
  }
  .font-size-xl-16 {
    font-size: 16px;
  }
  .font-size-xl-17 {
    font-size: 17px;
  }
  .font-size-xl-18 {
    font-size: 18px;
  }
  .font-size-xl-19 {
    font-size: 19px;
  }
  .font-size-xl-20 {
    font-size: 20px;
  }
  .font-size-xl-21 {
    font-size: 21px;
  }
  .font-size-xl-22 {
    font-size: 22px;
  }
  .font-size-xl-23 {
    font-size: 23px;
  }
  .font-size-xl-24 {
    font-size: 24px;
  }
  .font-size-xl-25 {
    font-size: 25px;
  }
  .font-size-xl-26 {
    font-size: 26px;
  }
  .font-size-xl-27 {
    font-size: 27px;
  }
  .font-size-xl-28 {
    font-size: 28px;
  }
  .font-size-xl-29 {
    font-size: 29px;
  }
  .font-size-xl-30 {
    font-size: 30px;
  }
  .font-size-xl-31 {
    font-size: 31px;
  }
  .font-size-xl-32 {
    font-size: 32px;
  }
  .font-size-xl-33 {
    font-size: 33px;
  }
  .font-size-xl-34 {
    font-size: 34px;
  }
  .font-size-xl-35 {
    font-size: 35px;
  }
  .font-size-xl-36 {
    font-size: 36px;
  }
  .font-size-xl-37 {
    font-size: 37px;
  }
  .font-size-xl-38 {
    font-size: 38px;
  }
  .font-size-xl-39 {
    font-size: 39px;
  }
  .font-size-xl-40 {
    font-size: 40px;
  }
  .font-size-xl-41 {
    font-size: 41px;
  }
  .font-size-xl-42 {
    font-size: 42px;
  }
  .font-size-xl-43 {
    font-size: 43px;
  }
  .font-size-xl-44 {
    font-size: 44px;
  }
  .font-size-xl-45 {
    font-size: 45px;
  }
  .font-size-xl-46 {
    font-size: 46px;
  }
  .font-size-xl-47 {
    font-size: 47px;
  }
  .font-size-xl-48 {
    font-size: 48px;
  }
  .font-size-xl-49 {
    font-size: 49px;
  }
  .font-size-xl-50 {
    font-size: 50px;
  }
  .font-size-xl-51 {
    font-size: 51px;
  }
  .font-size-xl-52 {
    font-size: 52px;
  }
  .font-size-xl-53 {
    font-size: 53px;
  }
  .font-size-xl-54 {
    font-size: 54px;
  }
  .font-size-xl-55 {
    font-size: 55px;
  }
  .font-size-xl-56 {
    font-size: 56px;
  }
  .font-size-xl-57 {
    font-size: 57px;
  }
  .font-size-xl-58 {
    font-size: 58px;
  }
  .font-size-xl-59 {
    font-size: 59px;
  }
  .font-size-xl-60 {
    font-size: 60px;
  }
  .font-size-xl-61 {
    font-size: 61px;
  }
  .font-size-xl-62 {
    font-size: 62px;
  }
  .font-size-xl-63 {
    font-size: 63px;
  }
  .font-size-xl-64 {
    font-size: 64px;
  }
  .font-size-xl-65 {
    font-size: 65px;
  }
  .font-size-xl-66 {
    font-size: 66px;
  }
  .font-size-xl-67 {
    font-size: 67px;
  }
  .font-size-xl-68 {
    font-size: 68px;
  }
  .font-size-xl-69 {
    font-size: 69px;
  }
  .font-size-xl-70 {
    font-size: 70px;
  }
  .font-size-xl-71 {
    font-size: 71px;
  }
  .font-size-xl-72 {
    font-size: 72px;
  }
  .font-size-xl-73 {
    font-size: 73px;
  }
  .font-size-xl-74 {
    font-size: 74px;
  }
  .font-size-xl-75 {
    font-size: 75px;
  }
  .font-size-xl-76 {
    font-size: 76px;
  }
  .font-size-xl-77 {
    font-size: 77px;
  }
  .font-size-xl-78 {
    font-size: 78px;
  }
  .font-size-xl-79 {
    font-size: 79px;
  }
  .font-size-xl-80 {
    font-size: 80px;
  }
  .font-size-xl-81 {
    font-size: 81px;
  }
  .font-size-xl-82 {
    font-size: 82px;
  }
  .font-size-xl-83 {
    font-size: 83px;
  }
  .font-size-xl-84 {
    font-size: 84px;
  }
  .font-size-xl-85 {
    font-size: 85px;
  }
  .font-size-xl-86 {
    font-size: 86px;
  }
  .font-size-xl-87 {
    font-size: 87px;
  }
  .font-size-xl-88 {
    font-size: 88px;
  }
  .font-size-xl-89 {
    font-size: 89px;
  }
  .font-size-xl-90 {
    font-size: 90px;
  }
  .font-size-xl-91 {
    font-size: 91px;
  }
  .font-size-xl-92 {
    font-size: 92px;
  }
  .font-size-xl-93 {
    font-size: 93px;
  }
  .font-size-xl-94 {
    font-size: 94px;
  }
  .font-size-xl-95 {
    font-size: 95px;
  }
  .font-size-xl-96 {
    font-size: 96px;
  }
  .font-size-xl-97 {
    font-size: 97px;
  }
  .font-size-xl-98 {
    font-size: 98px;
  }
  .font-size-xl-99 {
    font-size: 99px;
  }
  .font-size-xl-100 {
    font-size: 100px;
  }
}
@media (min-width: 1400px) {
  .font-size-xxl-10 {
    font-size: 10px;
  }
  .font-size-xxl-11 {
    font-size: 11px;
  }
  .font-size-xxl-12 {
    font-size: 12px;
  }
  .font-size-xxl-13 {
    font-size: 13px;
  }
  .font-size-xxl-14 {
    font-size: 14px;
  }
  .font-size-xxl-15 {
    font-size: 15px;
  }
  .font-size-xxl-16 {
    font-size: 16px;
  }
  .font-size-xxl-17 {
    font-size: 17px;
  }
  .font-size-xxl-18 {
    font-size: 18px;
  }
  .font-size-xxl-19 {
    font-size: 19px;
  }
  .font-size-xxl-20 {
    font-size: 20px;
  }
  .font-size-xxl-21 {
    font-size: 21px;
  }
  .font-size-xxl-22 {
    font-size: 22px;
  }
  .font-size-xxl-23 {
    font-size: 23px;
  }
  .font-size-xxl-24 {
    font-size: 24px;
  }
  .font-size-xxl-25 {
    font-size: 25px;
  }
  .font-size-xxl-26 {
    font-size: 26px;
  }
  .font-size-xxl-27 {
    font-size: 27px;
  }
  .font-size-xxl-28 {
    font-size: 28px;
  }
  .font-size-xxl-29 {
    font-size: 29px;
  }
  .font-size-xxl-30 {
    font-size: 30px;
  }
  .font-size-xxl-31 {
    font-size: 31px;
  }
  .font-size-xxl-32 {
    font-size: 32px;
  }
  .font-size-xxl-33 {
    font-size: 33px;
  }
  .font-size-xxl-34 {
    font-size: 34px;
  }
  .font-size-xxl-35 {
    font-size: 35px;
  }
  .font-size-xxl-36 {
    font-size: 36px;
  }
  .font-size-xxl-37 {
    font-size: 37px;
  }
  .font-size-xxl-38 {
    font-size: 38px;
  }
  .font-size-xxl-39 {
    font-size: 39px;
  }
  .font-size-xxl-40 {
    font-size: 40px;
  }
  .font-size-xxl-41 {
    font-size: 41px;
  }
  .font-size-xxl-42 {
    font-size: 42px;
  }
  .font-size-xxl-43 {
    font-size: 43px;
  }
  .font-size-xxl-44 {
    font-size: 44px;
  }
  .font-size-xxl-45 {
    font-size: 45px;
  }
  .font-size-xxl-46 {
    font-size: 46px;
  }
  .font-size-xxl-47 {
    font-size: 47px;
  }
  .font-size-xxl-48 {
    font-size: 48px;
  }
  .font-size-xxl-49 {
    font-size: 49px;
  }
  .font-size-xxl-50 {
    font-size: 50px;
  }
  .font-size-xxl-51 {
    font-size: 51px;
  }
  .font-size-xxl-52 {
    font-size: 52px;
  }
  .font-size-xxl-53 {
    font-size: 53px;
  }
  .font-size-xxl-54 {
    font-size: 54px;
  }
  .font-size-xxl-55 {
    font-size: 55px;
  }
  .font-size-xxl-56 {
    font-size: 56px;
  }
  .font-size-xxl-57 {
    font-size: 57px;
  }
  .font-size-xxl-58 {
    font-size: 58px;
  }
  .font-size-xxl-59 {
    font-size: 59px;
  }
  .font-size-xxl-60 {
    font-size: 60px;
  }
  .font-size-xxl-61 {
    font-size: 61px;
  }
  .font-size-xxl-62 {
    font-size: 62px;
  }
  .font-size-xxl-63 {
    font-size: 63px;
  }
  .font-size-xxl-64 {
    font-size: 64px;
  }
  .font-size-xxl-65 {
    font-size: 65px;
  }
  .font-size-xxl-66 {
    font-size: 66px;
  }
  .font-size-xxl-67 {
    font-size: 67px;
  }
  .font-size-xxl-68 {
    font-size: 68px;
  }
  .font-size-xxl-69 {
    font-size: 69px;
  }
  .font-size-xxl-70 {
    font-size: 70px;
  }
  .font-size-xxl-71 {
    font-size: 71px;
  }
  .font-size-xxl-72 {
    font-size: 72px;
  }
  .font-size-xxl-73 {
    font-size: 73px;
  }
  .font-size-xxl-74 {
    font-size: 74px;
  }
  .font-size-xxl-75 {
    font-size: 75px;
  }
  .font-size-xxl-76 {
    font-size: 76px;
  }
  .font-size-xxl-77 {
    font-size: 77px;
  }
  .font-size-xxl-78 {
    font-size: 78px;
  }
  .font-size-xxl-79 {
    font-size: 79px;
  }
  .font-size-xxl-80 {
    font-size: 80px;
  }
  .font-size-xxl-81 {
    font-size: 81px;
  }
  .font-size-xxl-82 {
    font-size: 82px;
  }
  .font-size-xxl-83 {
    font-size: 83px;
  }
  .font-size-xxl-84 {
    font-size: 84px;
  }
  .font-size-xxl-85 {
    font-size: 85px;
  }
  .font-size-xxl-86 {
    font-size: 86px;
  }
  .font-size-xxl-87 {
    font-size: 87px;
  }
  .font-size-xxl-88 {
    font-size: 88px;
  }
  .font-size-xxl-89 {
    font-size: 89px;
  }
  .font-size-xxl-90 {
    font-size: 90px;
  }
  .font-size-xxl-91 {
    font-size: 91px;
  }
  .font-size-xxl-92 {
    font-size: 92px;
  }
  .font-size-xxl-93 {
    font-size: 93px;
  }
  .font-size-xxl-94 {
    font-size: 94px;
  }
  .font-size-xxl-95 {
    font-size: 95px;
  }
  .font-size-xxl-96 {
    font-size: 96px;
  }
  .font-size-xxl-97 {
    font-size: 97px;
  }
  .font-size-xxl-98 {
    font-size: 98px;
  }
  .font-size-xxl-99 {
    font-size: 99px;
  }
  .font-size-xxl-100 {
    font-size: 100px;
  }
}
@media (max-width: 991.98px) {
  .font-size-50 {
    font-size: 30px;
  }
  .font-size-51 {
    font-size: 31px;
  }
  .font-size-52 {
    font-size: 32px;
  }
  .font-size-53 {
    font-size: 33px;
  }
  .font-size-54 {
    font-size: 34px;
  }
  .font-size-55 {
    font-size: 35px;
  }
  .font-size-56 {
    font-size: 36px;
  }
  .font-size-40 {
    font-size: 28px;
  }
  .font-size-24, .font-size-23 {
    font-size: 18px;
  }
  .font-size-17 {
    font-size: 14px;
  }
}
.is-badge::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px var(--bs-primary);
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}
.is-badge.shadow-is-primary {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(7, 89, 148, 0.9) !important;
}
.is-badge.btn-is-light {
  background-color: #F9F2EF;
  color: var(--bs-primary);
}
.is-badge.shadow-is-light {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(249, 242, 239, 0.9) !important;
}
.is-badge.shadow-is-light::after {
  border-top-color: #F9F2EF;
}

@media (min-width: 992px) {
  .imageSplited .diagonal.diagonal-image {
    position: relative;
    overflow: hidden;
    transition: 0.5s all;
  }
  .imageSplited .diagonal.diagonal-image::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background-origin: content-box;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    top: 0;
    left: 0;
    border-right: 300px solid #F7E6BF;
    border-top: 80vh solid rgba(0, 0, 0, 0);
    transition: 0.1s all;
  }
}
.imageSplited .diagonal.right {
  background-color: #F7E6BF;
}

.testimonial-right-bloc {
  padding-top: 0 !important;
}

/*@media screen and (max-width: 600px) {
    .imageSplited {
        .diagonal {
            min-height: auto;
            padding-top: 45px !important;
        }
        .diagonal-image {
            position: relative;
            overflow: hidden;
            transition: 0.5s all;
            background-color: #94bbc3;
            background-size: 100% 100%;
            min-height: 50vh;
        }
    }
}*/
.bg-is-lightblue,
.highlight_search {
  white-space: nowrap;
  background-color: #fff686;
}

.card-discover .discover-link {
  color: black;
  text-decoration: none;
}
.card-discover .discover-link:hover {
  text-decoration: none;
  color: black;
}

.cardFiche img {
  width: 100%;
  height: 240px;
  position: relative;
  display: flex;
  overflow: hidden;
}
.cardFiche img::before {
  content: "";
  background-image: url("/images/05.webp");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .testimonial-right-bloc {
    padding-top: 1.5rem !important;
  }
}
ul.list-style-inside {
  list-style: inside;
}
ul.list-style-none {
  list-style: none;
}
ul li::marker {
  font-size: 23px;
}
ul li a {
  text-decoration: none;
}
ul.list-dotsbetween {
  display: inline-block;
  padding: 0;
  text-align: center;
}
ul.list-dotsbetween li {
  display: inline;
}
ul.list-dotsbetween li::after {
  content: " ";
  letter-spacing: 1em;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwAAADsABataJCQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xMkMEa+wAAAAnSURBVBhXY/Dz89MA4sNA/B9Ka4AEYQIwfBgkiCwAxjhVopnppwEApxQqhnyQ+VkAAAAASUVORK5CYII=) center center no-repeat;
}
ul.list-dotsbetween li a {
  white-space: nowrap;
}
ul.list-linebetween {
  display: inline-block;
  padding: 0;
  text-align: center;
}
ul.list-linebetween > li {
  display: inline;
}
ul.list-linebetween > li:not(:last-child)::after {
  content: " ";
  border-left: 2px solid #ececec;
}
ul.list-linebetween > li a {
  white-space: nowrap;
}
ul.list-style-is-check li::before {
  content: "\f633";
  font-family: "bootstrap-icons";
  margin-right: 1rem;
}
ul.list-style-circle-is-primary li::marker {
  color: var(--bs-primary);
}
ul.list-style-square-is-primary li::marker {
  color: var(--bs-primary);
}
ul.list-style-check-is-primary li::marker {
  color: var(--bs-primary);
}
ul.list-style-circle-is-secondary li::marker {
  color: #F7E6BF;
}
ul.list-style-square-is-secondary li::marker {
  color: #F7E6BF;
}
ul.list-style-check-is-secondary li::marker {
  color: #F7E6BF;
}
ul.list-style-circle-is-third li::marker {
  color: #F89c97;
}
ul.list-style-square-is-third li::marker {
  color: #F89c97;
}
ul.list-style-check-is-third li::marker {
  color: #F89c97;
}
ul.list-style-circle-is-fourth li::marker {
  color: #f9f2ef;
}
ul.list-style-square-is-fourth li::marker {
  color: #f9f2ef;
}
ul.list-style-check-is-fourth li::marker {
  color: #f9f2ef;
}
ul.list-style-circle-is-fifth li::marker {
  color: #003780;
}
ul.list-style-square-is-fifth li::marker {
  color: #003780;
}
ul.list-style-check-is-fifth li::marker {
  color: #003780;
}
ul.list-style-circle-is-sixth li::marker {
  color: #00aff7;
}
ul.list-style-square-is-sixth li::marker {
  color: #00aff7;
}
ul.list-style-check-is-sixth li::marker {
  color: #00aff7;
}
ul.list-style-circle-is-seventh li::marker {
  color: #FBE4D5;
}
ul.list-style-square-is-seventh li::marker {
  color: #FBE4D5;
}
ul.list-style-check-is-seventh li::marker {
  color: #FBE4D5;
}
ul.list-style-circle-is-eighth li::marker {
  color: #E6F4F6;
}
ul.list-style-square-is-eighth li::marker {
  color: #E6F4F6;
}
ul.list-style-check-is-eighth li::marker {
  color: #E6F4F6;
}
ul.list-style-circle-is-nineth li::marker {
  color: #F5F5F5;
}
ul.list-style-square-is-nineth li::marker {
  color: #F5F5F5;
}
ul.list-style-check-is-nineth li::marker {
  color: #F5F5F5;
}
ul.list-style-circle-is-tenth li::marker {
  color: #F83329;
}
ul.list-style-square-is-tenth li::marker {
  color: #F83329;
}
ul.list-style-check-is-tenth li::marker {
  color: #F83329;
}
ul.list-style-circle-is-eleventh li::marker {
  color: #DDECD3;
}
ul.list-style-square-is-eleventh li::marker {
  color: #DDECD3;
}
ul.list-style-check-is-eleventh li::marker {
  color: #DDECD3;
}
ul.list-style-circle-is-twelveth li::marker {
  color: #F7CAAB;
}
ul.list-style-square-is-twelveth li::marker {
  color: #F7CAAB;
}
ul.list-style-check-is-twelveth li::marker {
  color: #F7CAAB;
}
ul.list-style-circle-is-thirteenth li::marker {
  color: #3165A0;
}
ul.list-style-square-is-thirteenth li::marker {
  color: #3165A0;
}
ul.list-style-check-is-thirteenth li::marker {
  color: #3165A0;
}
ul.list-style-circle-is-fourteenth li::marker {
  color: #FFDAD8;
}
ul.list-style-square-is-fourteenth li::marker {
  color: #FFDAD8;
}
ul.list-style-check-is-fourteenth li::marker {
  color: #FFDAD8;
}
ul.list-style-circle-is-fifteenth li::marker {
  color: #c1ebfb;
}
ul.list-style-square-is-fifteenth li::marker {
  color: #c1ebfb;
}
ul.list-style-check-is-fifteenth li::marker {
  color: #c1ebfb;
}
ul.list-style-circle-is-sixteenth li::marker {
  color: #3486c1;
}
ul.list-style-square-is-sixteenth li::marker {
  color: #3486c1;
}
ul.list-style-check-is-sixteenth li::marker {
  color: #3486c1;
}
ul.list-style-circle-is-seventeenth li::marker {
  color: #a6b7c5;
}
ul.list-style-square-is-seventeenth li::marker {
  color: #a6b7c5;
}
ul.list-style-check-is-seventeenth li::marker {
  color: #a6b7c5;
}
ul.list-style-circle-is-eightenth li::marker {
  color: #ececec;
}
ul.list-style-square-is-eightenth li::marker {
  color: #ececec;
}
ul.list-style-check-is-eightenth li::marker {
  color: #ececec;
}
ul.list-style-circle-is-nineteenth li::marker {
  color: #e6eef4;
}
ul.list-style-square-is-nineteenth li::marker {
  color: #e6eef4;
}
ul.list-style-check-is-nineteenth li::marker {
  color: #e6eef4;
}
ul.list-style-circle-is-text li::marker {
  color: #414141;
}
ul.list-style-square-is-text li::marker {
  color: #414141;
}
ul.list-style-check-is-text li::marker {
  color: #414141;
}
ul.list-style-circle-is-textlight li::marker {
  color: #686868;
}
ul.list-style-square-is-textlight li::marker {
  color: #686868;
}
ul.list-style-check-is-textlight li::marker {
  color: #686868;
}
ul.list-style-circle-is-amiable li::marker {
  color: #9cce7b;
}
ul.list-style-square-is-amiable li::marker {
  color: #9cce7b;
}
ul.list-style-check-is-amiable li::marker {
  color: #9cce7b;
}
ul.list-style-circle-is-clos li::marker {
  color: #f89c97;
}
ul.list-style-square-is-clos li::marker {
  color: #f89c97;
}
ul.list-style-check-is-clos li::marker {
  color: #f89c97;
}
ul.list-style-circle-is-judiciaire li::marker {
  color: #ce987b;
}
ul.list-style-square-is-judiciaire li::marker {
  color: #ce987b;
}
ul.list-style-check-is-judiciaire li::marker {
  color: #ce987b;
}
ul.list-inline {
  display: inline-block;
}
ul.list-inline.with-dotsbetween li {
  display: inline-flex;
  align-items: center;
}
@media (min-width: 768px) {
  ul.list-inline.with-dotsbetween li:not(:last-child)::after {
    content: "\f309";
    font-family: "bootstrap-icons";
    margin: 0 10px;
    font-size: 30px;
  }
}
@media (max-width: 767.98px) {
  ul.list-inline.with-dotsbetween li {
    display: flex;
    justify-content: center;
  }
}

.rotated-0 {
  transform: rotate(0deg);
}

.rotated-n0 {
  transform: rotate(-0deg);
}

.rotated-1 {
  transform: rotate(1deg);
}

.rotated-n1 {
  transform: rotate(-1deg);
}

.rotated-2 {
  transform: rotate(2deg);
}

.rotated-n2 {
  transform: rotate(-2deg);
}

.rotated-3 {
  transform: rotate(3deg);
}

.rotated-n3 {
  transform: rotate(-3deg);
}

.rotated-4 {
  transform: rotate(4deg);
}

.rotated-n4 {
  transform: rotate(-4deg);
}

.rotated-5 {
  transform: rotate(5deg);
}

.rotated-n5 {
  transform: rotate(-5deg);
}

.rotated-6 {
  transform: rotate(6deg);
}

.rotated-n6 {
  transform: rotate(-6deg);
}

.rotated-7 {
  transform: rotate(7deg);
}

.rotated-n7 {
  transform: rotate(-7deg);
}

.rotated-8 {
  transform: rotate(8deg);
}

.rotated-n8 {
  transform: rotate(-8deg);
}

.rotated-9 {
  transform: rotate(9deg);
}

.rotated-n9 {
  transform: rotate(-9deg);
}

.rotated-10 {
  transform: rotate(10deg);
}

.rotated-n10 {
  transform: rotate(-10deg);
}

.rotated-11 {
  transform: rotate(11deg);
}

.rotated-n11 {
  transform: rotate(-11deg);
}

.rotated-12 {
  transform: rotate(12deg);
}

.rotated-n12 {
  transform: rotate(-12deg);
}

.rotated-13 {
  transform: rotate(13deg);
}

.rotated-n13 {
  transform: rotate(-13deg);
}

.rotated-14 {
  transform: rotate(14deg);
}

.rotated-n14 {
  transform: rotate(-14deg);
}

.rotated-15 {
  transform: rotate(15deg);
}

.rotated-n15 {
  transform: rotate(-15deg);
}

.rotated-16 {
  transform: rotate(16deg);
}

.rotated-n16 {
  transform: rotate(-16deg);
}

.rotated-17 {
  transform: rotate(17deg);
}

.rotated-n17 {
  transform: rotate(-17deg);
}

.rotated-18 {
  transform: rotate(18deg);
}

.rotated-n18 {
  transform: rotate(-18deg);
}

.rotated-19 {
  transform: rotate(19deg);
}

.rotated-n19 {
  transform: rotate(-19deg);
}

.rotated-20 {
  transform: rotate(20deg);
}

.rotated-n20 {
  transform: rotate(-20deg);
}

.rotated-21 {
  transform: rotate(21deg);
}

.rotated-n21 {
  transform: rotate(-21deg);
}

.rotated-22 {
  transform: rotate(22deg);
}

.rotated-n22 {
  transform: rotate(-22deg);
}

.rotated-23 {
  transform: rotate(23deg);
}

.rotated-n23 {
  transform: rotate(-23deg);
}

.rotated-24 {
  transform: rotate(24deg);
}

.rotated-n24 {
  transform: rotate(-24deg);
}

.rotated-25 {
  transform: rotate(25deg);
}

.rotated-n25 {
  transform: rotate(-25deg);
}

.rotated-26 {
  transform: rotate(26deg);
}

.rotated-n26 {
  transform: rotate(-26deg);
}

.rotated-27 {
  transform: rotate(27deg);
}

.rotated-n27 {
  transform: rotate(-27deg);
}

.rotated-28 {
  transform: rotate(28deg);
}

.rotated-n28 {
  transform: rotate(-28deg);
}

.rotated-29 {
  transform: rotate(29deg);
}

.rotated-n29 {
  transform: rotate(-29deg);
}

.rotated-30 {
  transform: rotate(30deg);
}

.rotated-n30 {
  transform: rotate(-30deg);
}

.rotated-31 {
  transform: rotate(31deg);
}

.rotated-n31 {
  transform: rotate(-31deg);
}

.rotated-32 {
  transform: rotate(32deg);
}

.rotated-n32 {
  transform: rotate(-32deg);
}

.rotated-33 {
  transform: rotate(33deg);
}

.rotated-n33 {
  transform: rotate(-33deg);
}

.rotated-34 {
  transform: rotate(34deg);
}

.rotated-n34 {
  transform: rotate(-34deg);
}

.rotated-35 {
  transform: rotate(35deg);
}

.rotated-n35 {
  transform: rotate(-35deg);
}

.rotated-36 {
  transform: rotate(36deg);
}

.rotated-n36 {
  transform: rotate(-36deg);
}

.rotated-37 {
  transform: rotate(37deg);
}

.rotated-n37 {
  transform: rotate(-37deg);
}

.rotated-38 {
  transform: rotate(38deg);
}

.rotated-n38 {
  transform: rotate(-38deg);
}

.rotated-39 {
  transform: rotate(39deg);
}

.rotated-n39 {
  transform: rotate(-39deg);
}

.rotated-40 {
  transform: rotate(40deg);
}

.rotated-n40 {
  transform: rotate(-40deg);
}

.rotated-41 {
  transform: rotate(41deg);
}

.rotated-n41 {
  transform: rotate(-41deg);
}

.rotated-42 {
  transform: rotate(42deg);
}

.rotated-n42 {
  transform: rotate(-42deg);
}

.rotated-43 {
  transform: rotate(43deg);
}

.rotated-n43 {
  transform: rotate(-43deg);
}

.rotated-44 {
  transform: rotate(44deg);
}

.rotated-n44 {
  transform: rotate(-44deg);
}

.rotated-45 {
  transform: rotate(45deg);
}

.rotated-n45 {
  transform: rotate(-45deg);
}

.rotated-46 {
  transform: rotate(46deg);
}

.rotated-n46 {
  transform: rotate(-46deg);
}

.rotated-47 {
  transform: rotate(47deg);
}

.rotated-n47 {
  transform: rotate(-47deg);
}

.rotated-48 {
  transform: rotate(48deg);
}

.rotated-n48 {
  transform: rotate(-48deg);
}

.rotated-49 {
  transform: rotate(49deg);
}

.rotated-n49 {
  transform: rotate(-49deg);
}

.rotated-50 {
  transform: rotate(50deg);
}

.rotated-n50 {
  transform: rotate(-50deg);
}

.rotated-51 {
  transform: rotate(51deg);
}

.rotated-n51 {
  transform: rotate(-51deg);
}

.rotated-52 {
  transform: rotate(52deg);
}

.rotated-n52 {
  transform: rotate(-52deg);
}

.rotated-53 {
  transform: rotate(53deg);
}

.rotated-n53 {
  transform: rotate(-53deg);
}

.rotated-54 {
  transform: rotate(54deg);
}

.rotated-n54 {
  transform: rotate(-54deg);
}

.rotated-55 {
  transform: rotate(55deg);
}

.rotated-n55 {
  transform: rotate(-55deg);
}

.rotated-56 {
  transform: rotate(56deg);
}

.rotated-n56 {
  transform: rotate(-56deg);
}

.rotated-57 {
  transform: rotate(57deg);
}

.rotated-n57 {
  transform: rotate(-57deg);
}

.rotated-58 {
  transform: rotate(58deg);
}

.rotated-n58 {
  transform: rotate(-58deg);
}

.rotated-59 {
  transform: rotate(59deg);
}

.rotated-n59 {
  transform: rotate(-59deg);
}

.rotated-60 {
  transform: rotate(60deg);
}

.rotated-n60 {
  transform: rotate(-60deg);
}

.rotated-61 {
  transform: rotate(61deg);
}

.rotated-n61 {
  transform: rotate(-61deg);
}

.rotated-62 {
  transform: rotate(62deg);
}

.rotated-n62 {
  transform: rotate(-62deg);
}

.rotated-63 {
  transform: rotate(63deg);
}

.rotated-n63 {
  transform: rotate(-63deg);
}

.rotated-64 {
  transform: rotate(64deg);
}

.rotated-n64 {
  transform: rotate(-64deg);
}

.rotated-65 {
  transform: rotate(65deg);
}

.rotated-n65 {
  transform: rotate(-65deg);
}

.rotated-66 {
  transform: rotate(66deg);
}

.rotated-n66 {
  transform: rotate(-66deg);
}

.rotated-67 {
  transform: rotate(67deg);
}

.rotated-n67 {
  transform: rotate(-67deg);
}

.rotated-68 {
  transform: rotate(68deg);
}

.rotated-n68 {
  transform: rotate(-68deg);
}

.rotated-69 {
  transform: rotate(69deg);
}

.rotated-n69 {
  transform: rotate(-69deg);
}

.rotated-70 {
  transform: rotate(70deg);
}

.rotated-n70 {
  transform: rotate(-70deg);
}

.rotated-71 {
  transform: rotate(71deg);
}

.rotated-n71 {
  transform: rotate(-71deg);
}

.rotated-72 {
  transform: rotate(72deg);
}

.rotated-n72 {
  transform: rotate(-72deg);
}

.rotated-73 {
  transform: rotate(73deg);
}

.rotated-n73 {
  transform: rotate(-73deg);
}

.rotated-74 {
  transform: rotate(74deg);
}

.rotated-n74 {
  transform: rotate(-74deg);
}

.rotated-75 {
  transform: rotate(75deg);
}

.rotated-n75 {
  transform: rotate(-75deg);
}

.rotated-76 {
  transform: rotate(76deg);
}

.rotated-n76 {
  transform: rotate(-76deg);
}

.rotated-77 {
  transform: rotate(77deg);
}

.rotated-n77 {
  transform: rotate(-77deg);
}

.rotated-78 {
  transform: rotate(78deg);
}

.rotated-n78 {
  transform: rotate(-78deg);
}

.rotated-79 {
  transform: rotate(79deg);
}

.rotated-n79 {
  transform: rotate(-79deg);
}

.rotated-80 {
  transform: rotate(80deg);
}

.rotated-n80 {
  transform: rotate(-80deg);
}

.rotated-81 {
  transform: rotate(81deg);
}

.rotated-n81 {
  transform: rotate(-81deg);
}

.rotated-82 {
  transform: rotate(82deg);
}

.rotated-n82 {
  transform: rotate(-82deg);
}

.rotated-83 {
  transform: rotate(83deg);
}

.rotated-n83 {
  transform: rotate(-83deg);
}

.rotated-84 {
  transform: rotate(84deg);
}

.rotated-n84 {
  transform: rotate(-84deg);
}

.rotated-85 {
  transform: rotate(85deg);
}

.rotated-n85 {
  transform: rotate(-85deg);
}

.rotated-86 {
  transform: rotate(86deg);
}

.rotated-n86 {
  transform: rotate(-86deg);
}

.rotated-87 {
  transform: rotate(87deg);
}

.rotated-n87 {
  transform: rotate(-87deg);
}

.rotated-88 {
  transform: rotate(88deg);
}

.rotated-n88 {
  transform: rotate(-88deg);
}

.rotated-89 {
  transform: rotate(89deg);
}

.rotated-n89 {
  transform: rotate(-89deg);
}

.rotated-90 {
  transform: rotate(90deg);
}

.rotated-n90 {
  transform: rotate(-90deg);
}

.rotated-91 {
  transform: rotate(91deg);
}

.rotated-n91 {
  transform: rotate(-91deg);
}

.rotated-92 {
  transform: rotate(92deg);
}

.rotated-n92 {
  transform: rotate(-92deg);
}

.rotated-93 {
  transform: rotate(93deg);
}

.rotated-n93 {
  transform: rotate(-93deg);
}

.rotated-94 {
  transform: rotate(94deg);
}

.rotated-n94 {
  transform: rotate(-94deg);
}

.rotated-95 {
  transform: rotate(95deg);
}

.rotated-n95 {
  transform: rotate(-95deg);
}

.rotated-96 {
  transform: rotate(96deg);
}

.rotated-n96 {
  transform: rotate(-96deg);
}

.rotated-97 {
  transform: rotate(97deg);
}

.rotated-n97 {
  transform: rotate(-97deg);
}

.rotated-98 {
  transform: rotate(98deg);
}

.rotated-n98 {
  transform: rotate(-98deg);
}

.rotated-99 {
  transform: rotate(99deg);
}

.rotated-n99 {
  transform: rotate(-99deg);
}

.rotated-100 {
  transform: rotate(100deg);
}

.rotated-n100 {
  transform: rotate(-100deg);
}

.rotated-101 {
  transform: rotate(101deg);
}

.rotated-n101 {
  transform: rotate(-101deg);
}

.rotated-102 {
  transform: rotate(102deg);
}

.rotated-n102 {
  transform: rotate(-102deg);
}

.rotated-103 {
  transform: rotate(103deg);
}

.rotated-n103 {
  transform: rotate(-103deg);
}

.rotated-104 {
  transform: rotate(104deg);
}

.rotated-n104 {
  transform: rotate(-104deg);
}

.rotated-105 {
  transform: rotate(105deg);
}

.rotated-n105 {
  transform: rotate(-105deg);
}

.rotated-106 {
  transform: rotate(106deg);
}

.rotated-n106 {
  transform: rotate(-106deg);
}

.rotated-107 {
  transform: rotate(107deg);
}

.rotated-n107 {
  transform: rotate(-107deg);
}

.rotated-108 {
  transform: rotate(108deg);
}

.rotated-n108 {
  transform: rotate(-108deg);
}

.rotated-109 {
  transform: rotate(109deg);
}

.rotated-n109 {
  transform: rotate(-109deg);
}

.rotated-110 {
  transform: rotate(110deg);
}

.rotated-n110 {
  transform: rotate(-110deg);
}

.rotated-111 {
  transform: rotate(111deg);
}

.rotated-n111 {
  transform: rotate(-111deg);
}

.rotated-112 {
  transform: rotate(112deg);
}

.rotated-n112 {
  transform: rotate(-112deg);
}

.rotated-113 {
  transform: rotate(113deg);
}

.rotated-n113 {
  transform: rotate(-113deg);
}

.rotated-114 {
  transform: rotate(114deg);
}

.rotated-n114 {
  transform: rotate(-114deg);
}

.rotated-115 {
  transform: rotate(115deg);
}

.rotated-n115 {
  transform: rotate(-115deg);
}

.rotated-116 {
  transform: rotate(116deg);
}

.rotated-n116 {
  transform: rotate(-116deg);
}

.rotated-117 {
  transform: rotate(117deg);
}

.rotated-n117 {
  transform: rotate(-117deg);
}

.rotated-118 {
  transform: rotate(118deg);
}

.rotated-n118 {
  transform: rotate(-118deg);
}

.rotated-119 {
  transform: rotate(119deg);
}

.rotated-n119 {
  transform: rotate(-119deg);
}

.rotated-120 {
  transform: rotate(120deg);
}

.rotated-n120 {
  transform: rotate(-120deg);
}

.rotated-121 {
  transform: rotate(121deg);
}

.rotated-n121 {
  transform: rotate(-121deg);
}

.rotated-122 {
  transform: rotate(122deg);
}

.rotated-n122 {
  transform: rotate(-122deg);
}

.rotated-123 {
  transform: rotate(123deg);
}

.rotated-n123 {
  transform: rotate(-123deg);
}

.rotated-124 {
  transform: rotate(124deg);
}

.rotated-n124 {
  transform: rotate(-124deg);
}

.rotated-125 {
  transform: rotate(125deg);
}

.rotated-n125 {
  transform: rotate(-125deg);
}

.rotated-126 {
  transform: rotate(126deg);
}

.rotated-n126 {
  transform: rotate(-126deg);
}

.rotated-127 {
  transform: rotate(127deg);
}

.rotated-n127 {
  transform: rotate(-127deg);
}

.rotated-128 {
  transform: rotate(128deg);
}

.rotated-n128 {
  transform: rotate(-128deg);
}

.rotated-129 {
  transform: rotate(129deg);
}

.rotated-n129 {
  transform: rotate(-129deg);
}

.rotated-130 {
  transform: rotate(130deg);
}

.rotated-n130 {
  transform: rotate(-130deg);
}

.rotated-131 {
  transform: rotate(131deg);
}

.rotated-n131 {
  transform: rotate(-131deg);
}

.rotated-132 {
  transform: rotate(132deg);
}

.rotated-n132 {
  transform: rotate(-132deg);
}

.rotated-133 {
  transform: rotate(133deg);
}

.rotated-n133 {
  transform: rotate(-133deg);
}

.rotated-134 {
  transform: rotate(134deg);
}

.rotated-n134 {
  transform: rotate(-134deg);
}

.rotated-135 {
  transform: rotate(135deg);
}

.rotated-n135 {
  transform: rotate(-135deg);
}

.rotated-136 {
  transform: rotate(136deg);
}

.rotated-n136 {
  transform: rotate(-136deg);
}

.rotated-137 {
  transform: rotate(137deg);
}

.rotated-n137 {
  transform: rotate(-137deg);
}

.rotated-138 {
  transform: rotate(138deg);
}

.rotated-n138 {
  transform: rotate(-138deg);
}

.rotated-139 {
  transform: rotate(139deg);
}

.rotated-n139 {
  transform: rotate(-139deg);
}

.rotated-140 {
  transform: rotate(140deg);
}

.rotated-n140 {
  transform: rotate(-140deg);
}

.rotated-141 {
  transform: rotate(141deg);
}

.rotated-n141 {
  transform: rotate(-141deg);
}

.rotated-142 {
  transform: rotate(142deg);
}

.rotated-n142 {
  transform: rotate(-142deg);
}

.rotated-143 {
  transform: rotate(143deg);
}

.rotated-n143 {
  transform: rotate(-143deg);
}

.rotated-144 {
  transform: rotate(144deg);
}

.rotated-n144 {
  transform: rotate(-144deg);
}

.rotated-145 {
  transform: rotate(145deg);
}

.rotated-n145 {
  transform: rotate(-145deg);
}

.rotated-146 {
  transform: rotate(146deg);
}

.rotated-n146 {
  transform: rotate(-146deg);
}

.rotated-147 {
  transform: rotate(147deg);
}

.rotated-n147 {
  transform: rotate(-147deg);
}

.rotated-148 {
  transform: rotate(148deg);
}

.rotated-n148 {
  transform: rotate(-148deg);
}

.rotated-149 {
  transform: rotate(149deg);
}

.rotated-n149 {
  transform: rotate(-149deg);
}

.rotated-150 {
  transform: rotate(150deg);
}

.rotated-n150 {
  transform: rotate(-150deg);
}

.rotated-151 {
  transform: rotate(151deg);
}

.rotated-n151 {
  transform: rotate(-151deg);
}

.rotated-152 {
  transform: rotate(152deg);
}

.rotated-n152 {
  transform: rotate(-152deg);
}

.rotated-153 {
  transform: rotate(153deg);
}

.rotated-n153 {
  transform: rotate(-153deg);
}

.rotated-154 {
  transform: rotate(154deg);
}

.rotated-n154 {
  transform: rotate(-154deg);
}

.rotated-155 {
  transform: rotate(155deg);
}

.rotated-n155 {
  transform: rotate(-155deg);
}

.rotated-156 {
  transform: rotate(156deg);
}

.rotated-n156 {
  transform: rotate(-156deg);
}

.rotated-157 {
  transform: rotate(157deg);
}

.rotated-n157 {
  transform: rotate(-157deg);
}

.rotated-158 {
  transform: rotate(158deg);
}

.rotated-n158 {
  transform: rotate(-158deg);
}

.rotated-159 {
  transform: rotate(159deg);
}

.rotated-n159 {
  transform: rotate(-159deg);
}

.rotated-160 {
  transform: rotate(160deg);
}

.rotated-n160 {
  transform: rotate(-160deg);
}

.rotated-161 {
  transform: rotate(161deg);
}

.rotated-n161 {
  transform: rotate(-161deg);
}

.rotated-162 {
  transform: rotate(162deg);
}

.rotated-n162 {
  transform: rotate(-162deg);
}

.rotated-163 {
  transform: rotate(163deg);
}

.rotated-n163 {
  transform: rotate(-163deg);
}

.rotated-164 {
  transform: rotate(164deg);
}

.rotated-n164 {
  transform: rotate(-164deg);
}

.rotated-165 {
  transform: rotate(165deg);
}

.rotated-n165 {
  transform: rotate(-165deg);
}

.rotated-166 {
  transform: rotate(166deg);
}

.rotated-n166 {
  transform: rotate(-166deg);
}

.rotated-167 {
  transform: rotate(167deg);
}

.rotated-n167 {
  transform: rotate(-167deg);
}

.rotated-168 {
  transform: rotate(168deg);
}

.rotated-n168 {
  transform: rotate(-168deg);
}

.rotated-169 {
  transform: rotate(169deg);
}

.rotated-n169 {
  transform: rotate(-169deg);
}

.rotated-170 {
  transform: rotate(170deg);
}

.rotated-n170 {
  transform: rotate(-170deg);
}

.rotated-171 {
  transform: rotate(171deg);
}

.rotated-n171 {
  transform: rotate(-171deg);
}

.rotated-172 {
  transform: rotate(172deg);
}

.rotated-n172 {
  transform: rotate(-172deg);
}

.rotated-173 {
  transform: rotate(173deg);
}

.rotated-n173 {
  transform: rotate(-173deg);
}

.rotated-174 {
  transform: rotate(174deg);
}

.rotated-n174 {
  transform: rotate(-174deg);
}

.rotated-175 {
  transform: rotate(175deg);
}

.rotated-n175 {
  transform: rotate(-175deg);
}

.rotated-176 {
  transform: rotate(176deg);
}

.rotated-n176 {
  transform: rotate(-176deg);
}

.rotated-177 {
  transform: rotate(177deg);
}

.rotated-n177 {
  transform: rotate(-177deg);
}

.rotated-178 {
  transform: rotate(178deg);
}

.rotated-n178 {
  transform: rotate(-178deg);
}

.rotated-179 {
  transform: rotate(179deg);
}

.rotated-n179 {
  transform: rotate(-179deg);
}

.rotated-180 {
  transform: rotate(180deg);
}

.rotated-n180 {
  transform: rotate(-180deg);
}

.rotated-181 {
  transform: rotate(181deg);
}

.rotated-n181 {
  transform: rotate(-181deg);
}

.rotated-182 {
  transform: rotate(182deg);
}

.rotated-n182 {
  transform: rotate(-182deg);
}

.rotated-183 {
  transform: rotate(183deg);
}

.rotated-n183 {
  transform: rotate(-183deg);
}

.rotated-184 {
  transform: rotate(184deg);
}

.rotated-n184 {
  transform: rotate(-184deg);
}

.rotated-185 {
  transform: rotate(185deg);
}

.rotated-n185 {
  transform: rotate(-185deg);
}

.rotated-186 {
  transform: rotate(186deg);
}

.rotated-n186 {
  transform: rotate(-186deg);
}

.rotated-187 {
  transform: rotate(187deg);
}

.rotated-n187 {
  transform: rotate(-187deg);
}

.rotated-188 {
  transform: rotate(188deg);
}

.rotated-n188 {
  transform: rotate(-188deg);
}

.rotated-189 {
  transform: rotate(189deg);
}

.rotated-n189 {
  transform: rotate(-189deg);
}

.rotated-190 {
  transform: rotate(190deg);
}

.rotated-n190 {
  transform: rotate(-190deg);
}

.rotated-191 {
  transform: rotate(191deg);
}

.rotated-n191 {
  transform: rotate(-191deg);
}

.rotated-192 {
  transform: rotate(192deg);
}

.rotated-n192 {
  transform: rotate(-192deg);
}

.rotated-193 {
  transform: rotate(193deg);
}

.rotated-n193 {
  transform: rotate(-193deg);
}

.rotated-194 {
  transform: rotate(194deg);
}

.rotated-n194 {
  transform: rotate(-194deg);
}

.rotated-195 {
  transform: rotate(195deg);
}

.rotated-n195 {
  transform: rotate(-195deg);
}

.rotated-196 {
  transform: rotate(196deg);
}

.rotated-n196 {
  transform: rotate(-196deg);
}

.rotated-197 {
  transform: rotate(197deg);
}

.rotated-n197 {
  transform: rotate(-197deg);
}

.rotated-198 {
  transform: rotate(198deg);
}

.rotated-n198 {
  transform: rotate(-198deg);
}

.rotated-199 {
  transform: rotate(199deg);
}

.rotated-n199 {
  transform: rotate(-199deg);
}

.rotated-200 {
  transform: rotate(200deg);
}

.rotated-n200 {
  transform: rotate(-200deg);
}

.rotated-201 {
  transform: rotate(201deg);
}

.rotated-n201 {
  transform: rotate(-201deg);
}

.rotated-202 {
  transform: rotate(202deg);
}

.rotated-n202 {
  transform: rotate(-202deg);
}

.rotated-203 {
  transform: rotate(203deg);
}

.rotated-n203 {
  transform: rotate(-203deg);
}

.rotated-204 {
  transform: rotate(204deg);
}

.rotated-n204 {
  transform: rotate(-204deg);
}

.rotated-205 {
  transform: rotate(205deg);
}

.rotated-n205 {
  transform: rotate(-205deg);
}

.rotated-206 {
  transform: rotate(206deg);
}

.rotated-n206 {
  transform: rotate(-206deg);
}

.rotated-207 {
  transform: rotate(207deg);
}

.rotated-n207 {
  transform: rotate(-207deg);
}

.rotated-208 {
  transform: rotate(208deg);
}

.rotated-n208 {
  transform: rotate(-208deg);
}

.rotated-209 {
  transform: rotate(209deg);
}

.rotated-n209 {
  transform: rotate(-209deg);
}

.rotated-210 {
  transform: rotate(210deg);
}

.rotated-n210 {
  transform: rotate(-210deg);
}

.rotated-211 {
  transform: rotate(211deg);
}

.rotated-n211 {
  transform: rotate(-211deg);
}

.rotated-212 {
  transform: rotate(212deg);
}

.rotated-n212 {
  transform: rotate(-212deg);
}

.rotated-213 {
  transform: rotate(213deg);
}

.rotated-n213 {
  transform: rotate(-213deg);
}

.rotated-214 {
  transform: rotate(214deg);
}

.rotated-n214 {
  transform: rotate(-214deg);
}

.rotated-215 {
  transform: rotate(215deg);
}

.rotated-n215 {
  transform: rotate(-215deg);
}

.rotated-216 {
  transform: rotate(216deg);
}

.rotated-n216 {
  transform: rotate(-216deg);
}

.rotated-217 {
  transform: rotate(217deg);
}

.rotated-n217 {
  transform: rotate(-217deg);
}

.rotated-218 {
  transform: rotate(218deg);
}

.rotated-n218 {
  transform: rotate(-218deg);
}

.rotated-219 {
  transform: rotate(219deg);
}

.rotated-n219 {
  transform: rotate(-219deg);
}

.rotated-220 {
  transform: rotate(220deg);
}

.rotated-n220 {
  transform: rotate(-220deg);
}

.rotated-221 {
  transform: rotate(221deg);
}

.rotated-n221 {
  transform: rotate(-221deg);
}

.rotated-222 {
  transform: rotate(222deg);
}

.rotated-n222 {
  transform: rotate(-222deg);
}

.rotated-223 {
  transform: rotate(223deg);
}

.rotated-n223 {
  transform: rotate(-223deg);
}

.rotated-224 {
  transform: rotate(224deg);
}

.rotated-n224 {
  transform: rotate(-224deg);
}

.rotated-225 {
  transform: rotate(225deg);
}

.rotated-n225 {
  transform: rotate(-225deg);
}

.rotated-226 {
  transform: rotate(226deg);
}

.rotated-n226 {
  transform: rotate(-226deg);
}

.rotated-227 {
  transform: rotate(227deg);
}

.rotated-n227 {
  transform: rotate(-227deg);
}

.rotated-228 {
  transform: rotate(228deg);
}

.rotated-n228 {
  transform: rotate(-228deg);
}

.rotated-229 {
  transform: rotate(229deg);
}

.rotated-n229 {
  transform: rotate(-229deg);
}

.rotated-230 {
  transform: rotate(230deg);
}

.rotated-n230 {
  transform: rotate(-230deg);
}

.rotated-231 {
  transform: rotate(231deg);
}

.rotated-n231 {
  transform: rotate(-231deg);
}

.rotated-232 {
  transform: rotate(232deg);
}

.rotated-n232 {
  transform: rotate(-232deg);
}

.rotated-233 {
  transform: rotate(233deg);
}

.rotated-n233 {
  transform: rotate(-233deg);
}

.rotated-234 {
  transform: rotate(234deg);
}

.rotated-n234 {
  transform: rotate(-234deg);
}

.rotated-235 {
  transform: rotate(235deg);
}

.rotated-n235 {
  transform: rotate(-235deg);
}

.rotated-236 {
  transform: rotate(236deg);
}

.rotated-n236 {
  transform: rotate(-236deg);
}

.rotated-237 {
  transform: rotate(237deg);
}

.rotated-n237 {
  transform: rotate(-237deg);
}

.rotated-238 {
  transform: rotate(238deg);
}

.rotated-n238 {
  transform: rotate(-238deg);
}

.rotated-239 {
  transform: rotate(239deg);
}

.rotated-n239 {
  transform: rotate(-239deg);
}

.rotated-240 {
  transform: rotate(240deg);
}

.rotated-n240 {
  transform: rotate(-240deg);
}

.rotated-241 {
  transform: rotate(241deg);
}

.rotated-n241 {
  transform: rotate(-241deg);
}

.rotated-242 {
  transform: rotate(242deg);
}

.rotated-n242 {
  transform: rotate(-242deg);
}

.rotated-243 {
  transform: rotate(243deg);
}

.rotated-n243 {
  transform: rotate(-243deg);
}

.rotated-244 {
  transform: rotate(244deg);
}

.rotated-n244 {
  transform: rotate(-244deg);
}

.rotated-245 {
  transform: rotate(245deg);
}

.rotated-n245 {
  transform: rotate(-245deg);
}

.rotated-246 {
  transform: rotate(246deg);
}

.rotated-n246 {
  transform: rotate(-246deg);
}

.rotated-247 {
  transform: rotate(247deg);
}

.rotated-n247 {
  transform: rotate(-247deg);
}

.rotated-248 {
  transform: rotate(248deg);
}

.rotated-n248 {
  transform: rotate(-248deg);
}

.rotated-249 {
  transform: rotate(249deg);
}

.rotated-n249 {
  transform: rotate(-249deg);
}

.rotated-250 {
  transform: rotate(250deg);
}

.rotated-n250 {
  transform: rotate(-250deg);
}

.rotated-251 {
  transform: rotate(251deg);
}

.rotated-n251 {
  transform: rotate(-251deg);
}

.rotated-252 {
  transform: rotate(252deg);
}

.rotated-n252 {
  transform: rotate(-252deg);
}

.rotated-253 {
  transform: rotate(253deg);
}

.rotated-n253 {
  transform: rotate(-253deg);
}

.rotated-254 {
  transform: rotate(254deg);
}

.rotated-n254 {
  transform: rotate(-254deg);
}

.rotated-255 {
  transform: rotate(255deg);
}

.rotated-n255 {
  transform: rotate(-255deg);
}

.rotated-256 {
  transform: rotate(256deg);
}

.rotated-n256 {
  transform: rotate(-256deg);
}

.rotated-257 {
  transform: rotate(257deg);
}

.rotated-n257 {
  transform: rotate(-257deg);
}

.rotated-258 {
  transform: rotate(258deg);
}

.rotated-n258 {
  transform: rotate(-258deg);
}

.rotated-259 {
  transform: rotate(259deg);
}

.rotated-n259 {
  transform: rotate(-259deg);
}

.rotated-260 {
  transform: rotate(260deg);
}

.rotated-n260 {
  transform: rotate(-260deg);
}

.rotated-261 {
  transform: rotate(261deg);
}

.rotated-n261 {
  transform: rotate(-261deg);
}

.rotated-262 {
  transform: rotate(262deg);
}

.rotated-n262 {
  transform: rotate(-262deg);
}

.rotated-263 {
  transform: rotate(263deg);
}

.rotated-n263 {
  transform: rotate(-263deg);
}

.rotated-264 {
  transform: rotate(264deg);
}

.rotated-n264 {
  transform: rotate(-264deg);
}

.rotated-265 {
  transform: rotate(265deg);
}

.rotated-n265 {
  transform: rotate(-265deg);
}

.rotated-266 {
  transform: rotate(266deg);
}

.rotated-n266 {
  transform: rotate(-266deg);
}

.rotated-267 {
  transform: rotate(267deg);
}

.rotated-n267 {
  transform: rotate(-267deg);
}

.rotated-268 {
  transform: rotate(268deg);
}

.rotated-n268 {
  transform: rotate(-268deg);
}

.rotated-269 {
  transform: rotate(269deg);
}

.rotated-n269 {
  transform: rotate(-269deg);
}

.rotated-270 {
  transform: rotate(270deg);
}

.rotated-n270 {
  transform: rotate(-270deg);
}

.rotated-271 {
  transform: rotate(271deg);
}

.rotated-n271 {
  transform: rotate(-271deg);
}

.rotated-272 {
  transform: rotate(272deg);
}

.rotated-n272 {
  transform: rotate(-272deg);
}

.rotated-273 {
  transform: rotate(273deg);
}

.rotated-n273 {
  transform: rotate(-273deg);
}

.rotated-274 {
  transform: rotate(274deg);
}

.rotated-n274 {
  transform: rotate(-274deg);
}

.rotated-275 {
  transform: rotate(275deg);
}

.rotated-n275 {
  transform: rotate(-275deg);
}

.rotated-276 {
  transform: rotate(276deg);
}

.rotated-n276 {
  transform: rotate(-276deg);
}

.rotated-277 {
  transform: rotate(277deg);
}

.rotated-n277 {
  transform: rotate(-277deg);
}

.rotated-278 {
  transform: rotate(278deg);
}

.rotated-n278 {
  transform: rotate(-278deg);
}

.rotated-279 {
  transform: rotate(279deg);
}

.rotated-n279 {
  transform: rotate(-279deg);
}

.rotated-280 {
  transform: rotate(280deg);
}

.rotated-n280 {
  transform: rotate(-280deg);
}

.rotated-281 {
  transform: rotate(281deg);
}

.rotated-n281 {
  transform: rotate(-281deg);
}

.rotated-282 {
  transform: rotate(282deg);
}

.rotated-n282 {
  transform: rotate(-282deg);
}

.rotated-283 {
  transform: rotate(283deg);
}

.rotated-n283 {
  transform: rotate(-283deg);
}

.rotated-284 {
  transform: rotate(284deg);
}

.rotated-n284 {
  transform: rotate(-284deg);
}

.rotated-285 {
  transform: rotate(285deg);
}

.rotated-n285 {
  transform: rotate(-285deg);
}

.rotated-286 {
  transform: rotate(286deg);
}

.rotated-n286 {
  transform: rotate(-286deg);
}

.rotated-287 {
  transform: rotate(287deg);
}

.rotated-n287 {
  transform: rotate(-287deg);
}

.rotated-288 {
  transform: rotate(288deg);
}

.rotated-n288 {
  transform: rotate(-288deg);
}

.rotated-289 {
  transform: rotate(289deg);
}

.rotated-n289 {
  transform: rotate(-289deg);
}

.rotated-290 {
  transform: rotate(290deg);
}

.rotated-n290 {
  transform: rotate(-290deg);
}

.rotated-291 {
  transform: rotate(291deg);
}

.rotated-n291 {
  transform: rotate(-291deg);
}

.rotated-292 {
  transform: rotate(292deg);
}

.rotated-n292 {
  transform: rotate(-292deg);
}

.rotated-293 {
  transform: rotate(293deg);
}

.rotated-n293 {
  transform: rotate(-293deg);
}

.rotated-294 {
  transform: rotate(294deg);
}

.rotated-n294 {
  transform: rotate(-294deg);
}

.rotated-295 {
  transform: rotate(295deg);
}

.rotated-n295 {
  transform: rotate(-295deg);
}

.rotated-296 {
  transform: rotate(296deg);
}

.rotated-n296 {
  transform: rotate(-296deg);
}

.rotated-297 {
  transform: rotate(297deg);
}

.rotated-n297 {
  transform: rotate(-297deg);
}

.rotated-298 {
  transform: rotate(298deg);
}

.rotated-n298 {
  transform: rotate(-298deg);
}

.rotated-299 {
  transform: rotate(299deg);
}

.rotated-n299 {
  transform: rotate(-299deg);
}

.rotated-300 {
  transform: rotate(300deg);
}

.rotated-n300 {
  transform: rotate(-300deg);
}

.rotated-301 {
  transform: rotate(301deg);
}

.rotated-n301 {
  transform: rotate(-301deg);
}

.rotated-302 {
  transform: rotate(302deg);
}

.rotated-n302 {
  transform: rotate(-302deg);
}

.rotated-303 {
  transform: rotate(303deg);
}

.rotated-n303 {
  transform: rotate(-303deg);
}

.rotated-304 {
  transform: rotate(304deg);
}

.rotated-n304 {
  transform: rotate(-304deg);
}

.rotated-305 {
  transform: rotate(305deg);
}

.rotated-n305 {
  transform: rotate(-305deg);
}

.rotated-306 {
  transform: rotate(306deg);
}

.rotated-n306 {
  transform: rotate(-306deg);
}

.rotated-307 {
  transform: rotate(307deg);
}

.rotated-n307 {
  transform: rotate(-307deg);
}

.rotated-308 {
  transform: rotate(308deg);
}

.rotated-n308 {
  transform: rotate(-308deg);
}

.rotated-309 {
  transform: rotate(309deg);
}

.rotated-n309 {
  transform: rotate(-309deg);
}

.rotated-310 {
  transform: rotate(310deg);
}

.rotated-n310 {
  transform: rotate(-310deg);
}

.rotated-311 {
  transform: rotate(311deg);
}

.rotated-n311 {
  transform: rotate(-311deg);
}

.rotated-312 {
  transform: rotate(312deg);
}

.rotated-n312 {
  transform: rotate(-312deg);
}

.rotated-313 {
  transform: rotate(313deg);
}

.rotated-n313 {
  transform: rotate(-313deg);
}

.rotated-314 {
  transform: rotate(314deg);
}

.rotated-n314 {
  transform: rotate(-314deg);
}

.rotated-315 {
  transform: rotate(315deg);
}

.rotated-n315 {
  transform: rotate(-315deg);
}

.rotated-316 {
  transform: rotate(316deg);
}

.rotated-n316 {
  transform: rotate(-316deg);
}

.rotated-317 {
  transform: rotate(317deg);
}

.rotated-n317 {
  transform: rotate(-317deg);
}

.rotated-318 {
  transform: rotate(318deg);
}

.rotated-n318 {
  transform: rotate(-318deg);
}

.rotated-319 {
  transform: rotate(319deg);
}

.rotated-n319 {
  transform: rotate(-319deg);
}

.rotated-320 {
  transform: rotate(320deg);
}

.rotated-n320 {
  transform: rotate(-320deg);
}

.rotated-321 {
  transform: rotate(321deg);
}

.rotated-n321 {
  transform: rotate(-321deg);
}

.rotated-322 {
  transform: rotate(322deg);
}

.rotated-n322 {
  transform: rotate(-322deg);
}

.rotated-323 {
  transform: rotate(323deg);
}

.rotated-n323 {
  transform: rotate(-323deg);
}

.rotated-324 {
  transform: rotate(324deg);
}

.rotated-n324 {
  transform: rotate(-324deg);
}

.rotated-325 {
  transform: rotate(325deg);
}

.rotated-n325 {
  transform: rotate(-325deg);
}

.rotated-326 {
  transform: rotate(326deg);
}

.rotated-n326 {
  transform: rotate(-326deg);
}

.rotated-327 {
  transform: rotate(327deg);
}

.rotated-n327 {
  transform: rotate(-327deg);
}

.rotated-328 {
  transform: rotate(328deg);
}

.rotated-n328 {
  transform: rotate(-328deg);
}

.rotated-329 {
  transform: rotate(329deg);
}

.rotated-n329 {
  transform: rotate(-329deg);
}

.rotated-330 {
  transform: rotate(330deg);
}

.rotated-n330 {
  transform: rotate(-330deg);
}

.rotated-331 {
  transform: rotate(331deg);
}

.rotated-n331 {
  transform: rotate(-331deg);
}

.rotated-332 {
  transform: rotate(332deg);
}

.rotated-n332 {
  transform: rotate(-332deg);
}

.rotated-333 {
  transform: rotate(333deg);
}

.rotated-n333 {
  transform: rotate(-333deg);
}

.rotated-334 {
  transform: rotate(334deg);
}

.rotated-n334 {
  transform: rotate(-334deg);
}

.rotated-335 {
  transform: rotate(335deg);
}

.rotated-n335 {
  transform: rotate(-335deg);
}

.rotated-336 {
  transform: rotate(336deg);
}

.rotated-n336 {
  transform: rotate(-336deg);
}

.rotated-337 {
  transform: rotate(337deg);
}

.rotated-n337 {
  transform: rotate(-337deg);
}

.rotated-338 {
  transform: rotate(338deg);
}

.rotated-n338 {
  transform: rotate(-338deg);
}

.rotated-339 {
  transform: rotate(339deg);
}

.rotated-n339 {
  transform: rotate(-339deg);
}

.rotated-340 {
  transform: rotate(340deg);
}

.rotated-n340 {
  transform: rotate(-340deg);
}

.rotated-341 {
  transform: rotate(341deg);
}

.rotated-n341 {
  transform: rotate(-341deg);
}

.rotated-342 {
  transform: rotate(342deg);
}

.rotated-n342 {
  transform: rotate(-342deg);
}

.rotated-343 {
  transform: rotate(343deg);
}

.rotated-n343 {
  transform: rotate(-343deg);
}

.rotated-344 {
  transform: rotate(344deg);
}

.rotated-n344 {
  transform: rotate(-344deg);
}

.rotated-345 {
  transform: rotate(345deg);
}

.rotated-n345 {
  transform: rotate(-345deg);
}

.rotated-346 {
  transform: rotate(346deg);
}

.rotated-n346 {
  transform: rotate(-346deg);
}

.rotated-347 {
  transform: rotate(347deg);
}

.rotated-n347 {
  transform: rotate(-347deg);
}

.rotated-348 {
  transform: rotate(348deg);
}

.rotated-n348 {
  transform: rotate(-348deg);
}

.rotated-349 {
  transform: rotate(349deg);
}

.rotated-n349 {
  transform: rotate(-349deg);
}

.rotated-350 {
  transform: rotate(350deg);
}

.rotated-n350 {
  transform: rotate(-350deg);
}

.rotated-351 {
  transform: rotate(351deg);
}

.rotated-n351 {
  transform: rotate(-351deg);
}

.rotated-352 {
  transform: rotate(352deg);
}

.rotated-n352 {
  transform: rotate(-352deg);
}

.rotated-353 {
  transform: rotate(353deg);
}

.rotated-n353 {
  transform: rotate(-353deg);
}

.rotated-354 {
  transform: rotate(354deg);
}

.rotated-n354 {
  transform: rotate(-354deg);
}

.rotated-355 {
  transform: rotate(355deg);
}

.rotated-n355 {
  transform: rotate(-355deg);
}

.rotated-356 {
  transform: rotate(356deg);
}

.rotated-n356 {
  transform: rotate(-356deg);
}

.rotated-357 {
  transform: rotate(357deg);
}

.rotated-n357 {
  transform: rotate(-357deg);
}

.rotated-358 {
  transform: rotate(358deg);
}

.rotated-n358 {
  transform: rotate(-358deg);
}

.rotated-359 {
  transform: rotate(359deg);
}

.rotated-n359 {
  transform: rotate(-359deg);
}

.rotated-360 {
  transform: rotate(360deg);
}

.rotated-n360 {
  transform: rotate(-360deg);
}

.t-0 {
  top: 0%;
}

.t-0px {
  top: 0px;
}

.tn-0px {
  top: -0px;
}

.t-1 {
  top: 1%;
}

.t-1px {
  top: 1px;
}

.tn-1px {
  top: -1px;
}

.t-2 {
  top: 2%;
}

.t-2px {
  top: 2px;
}

.tn-2px {
  top: -2px;
}

.t-3 {
  top: 3%;
}

.t-3px {
  top: 3px;
}

.tn-3px {
  top: -3px;
}

.t-4 {
  top: 4%;
}

.t-4px {
  top: 4px;
}

.tn-4px {
  top: -4px;
}

.t-5 {
  top: 5%;
}

.t-5px {
  top: 5px;
}

.tn-5px {
  top: -5px;
}

.t-6 {
  top: 6%;
}

.t-6px {
  top: 6px;
}

.tn-6px {
  top: -6px;
}

.t-7 {
  top: 7%;
}

.t-7px {
  top: 7px;
}

.tn-7px {
  top: -7px;
}

.t-8 {
  top: 8%;
}

.t-8px {
  top: 8px;
}

.tn-8px {
  top: -8px;
}

.t-9 {
  top: 9%;
}

.t-9px {
  top: 9px;
}

.tn-9px {
  top: -9px;
}

.t-10 {
  top: 10%;
}

.t-10px {
  top: 10px;
}

.tn-10px {
  top: -10px;
}

.t-11 {
  top: 11%;
}

.t-11px {
  top: 11px;
}

.tn-11px {
  top: -11px;
}

.t-12 {
  top: 12%;
}

.t-12px {
  top: 12px;
}

.tn-12px {
  top: -12px;
}

.t-13 {
  top: 13%;
}

.t-13px {
  top: 13px;
}

.tn-13px {
  top: -13px;
}

.t-14 {
  top: 14%;
}

.t-14px {
  top: 14px;
}

.tn-14px {
  top: -14px;
}

.t-15 {
  top: 15%;
}

.t-15px {
  top: 15px;
}

.tn-15px {
  top: -15px;
}

.t-16 {
  top: 16%;
}

.t-16px {
  top: 16px;
}

.tn-16px {
  top: -16px;
}

.t-17 {
  top: 17%;
}

.t-17px {
  top: 17px;
}

.tn-17px {
  top: -17px;
}

.t-18 {
  top: 18%;
}

.t-18px {
  top: 18px;
}

.tn-18px {
  top: -18px;
}

.t-19 {
  top: 19%;
}

.t-19px {
  top: 19px;
}

.tn-19px {
  top: -19px;
}

.t-20 {
  top: 20%;
}

.t-20px {
  top: 20px;
}

.tn-20px {
  top: -20px;
}

.t-21 {
  top: 21%;
}

.t-21px {
  top: 21px;
}

.tn-21px {
  top: -21px;
}

.t-22 {
  top: 22%;
}

.t-22px {
  top: 22px;
}

.tn-22px {
  top: -22px;
}

.t-23 {
  top: 23%;
}

.t-23px {
  top: 23px;
}

.tn-23px {
  top: -23px;
}

.t-24 {
  top: 24%;
}

.t-24px {
  top: 24px;
}

.tn-24px {
  top: -24px;
}

.t-25 {
  top: 25%;
}

.t-25px {
  top: 25px;
}

.tn-25px {
  top: -25px;
}

.t-26 {
  top: 26%;
}

.t-26px {
  top: 26px;
}

.tn-26px {
  top: -26px;
}

.t-27 {
  top: 27%;
}

.t-27px {
  top: 27px;
}

.tn-27px {
  top: -27px;
}

.t-28 {
  top: 28%;
}

.t-28px {
  top: 28px;
}

.tn-28px {
  top: -28px;
}

.t-29 {
  top: 29%;
}

.t-29px {
  top: 29px;
}

.tn-29px {
  top: -29px;
}

.t-30 {
  top: 30%;
}

.t-30px {
  top: 30px;
}

.tn-30px {
  top: -30px;
}

.t-31 {
  top: 31%;
}

.t-31px {
  top: 31px;
}

.tn-31px {
  top: -31px;
}

.t-32 {
  top: 32%;
}

.t-32px {
  top: 32px;
}

.tn-32px {
  top: -32px;
}

.t-33 {
  top: 33%;
}

.t-33px {
  top: 33px;
}

.tn-33px {
  top: -33px;
}

.t-34 {
  top: 34%;
}

.t-34px {
  top: 34px;
}

.tn-34px {
  top: -34px;
}

.t-35 {
  top: 35%;
}

.t-35px {
  top: 35px;
}

.tn-35px {
  top: -35px;
}

.t-36 {
  top: 36%;
}

.t-36px {
  top: 36px;
}

.tn-36px {
  top: -36px;
}

.t-37 {
  top: 37%;
}

.t-37px {
  top: 37px;
}

.tn-37px {
  top: -37px;
}

.t-38 {
  top: 38%;
}

.t-38px {
  top: 38px;
}

.tn-38px {
  top: -38px;
}

.t-39 {
  top: 39%;
}

.t-39px {
  top: 39px;
}

.tn-39px {
  top: -39px;
}

.t-40 {
  top: 40%;
}

.t-40px {
  top: 40px;
}

.tn-40px {
  top: -40px;
}

.t-41 {
  top: 41%;
}

.t-41px {
  top: 41px;
}

.tn-41px {
  top: -41px;
}

.t-42 {
  top: 42%;
}

.t-42px {
  top: 42px;
}

.tn-42px {
  top: -42px;
}

.t-43 {
  top: 43%;
}

.t-43px {
  top: 43px;
}

.tn-43px {
  top: -43px;
}

.t-44 {
  top: 44%;
}

.t-44px {
  top: 44px;
}

.tn-44px {
  top: -44px;
}

.t-45 {
  top: 45%;
}

.t-45px {
  top: 45px;
}

.tn-45px {
  top: -45px;
}

.t-46 {
  top: 46%;
}

.t-46px {
  top: 46px;
}

.tn-46px {
  top: -46px;
}

.t-47 {
  top: 47%;
}

.t-47px {
  top: 47px;
}

.tn-47px {
  top: -47px;
}

.t-48 {
  top: 48%;
}

.t-48px {
  top: 48px;
}

.tn-48px {
  top: -48px;
}

.t-49 {
  top: 49%;
}

.t-49px {
  top: 49px;
}

.tn-49px {
  top: -49px;
}

.t-50 {
  top: 50%;
}

.t-50px {
  top: 50px;
}

.tn-50px {
  top: -50px;
}

.t-51 {
  top: 51%;
}

.t-51px {
  top: 51px;
}

.tn-51px {
  top: -51px;
}

.t-52 {
  top: 52%;
}

.t-52px {
  top: 52px;
}

.tn-52px {
  top: -52px;
}

.t-53 {
  top: 53%;
}

.t-53px {
  top: 53px;
}

.tn-53px {
  top: -53px;
}

.t-54 {
  top: 54%;
}

.t-54px {
  top: 54px;
}

.tn-54px {
  top: -54px;
}

.t-55 {
  top: 55%;
}

.t-55px {
  top: 55px;
}

.tn-55px {
  top: -55px;
}

.t-56 {
  top: 56%;
}

.t-56px {
  top: 56px;
}

.tn-56px {
  top: -56px;
}

.t-57 {
  top: 57%;
}

.t-57px {
  top: 57px;
}

.tn-57px {
  top: -57px;
}

.t-58 {
  top: 58%;
}

.t-58px {
  top: 58px;
}

.tn-58px {
  top: -58px;
}

.t-59 {
  top: 59%;
}

.t-59px {
  top: 59px;
}

.tn-59px {
  top: -59px;
}

.t-60 {
  top: 60%;
}

.t-60px {
  top: 60px;
}

.tn-60px {
  top: -60px;
}

.t-61 {
  top: 61%;
}

.t-61px {
  top: 61px;
}

.tn-61px {
  top: -61px;
}

.t-62 {
  top: 62%;
}

.t-62px {
  top: 62px;
}

.tn-62px {
  top: -62px;
}

.t-63 {
  top: 63%;
}

.t-63px {
  top: 63px;
}

.tn-63px {
  top: -63px;
}

.t-64 {
  top: 64%;
}

.t-64px {
  top: 64px;
}

.tn-64px {
  top: -64px;
}

.t-65 {
  top: 65%;
}

.t-65px {
  top: 65px;
}

.tn-65px {
  top: -65px;
}

.t-66 {
  top: 66%;
}

.t-66px {
  top: 66px;
}

.tn-66px {
  top: -66px;
}

.t-67 {
  top: 67%;
}

.t-67px {
  top: 67px;
}

.tn-67px {
  top: -67px;
}

.t-68 {
  top: 68%;
}

.t-68px {
  top: 68px;
}

.tn-68px {
  top: -68px;
}

.t-69 {
  top: 69%;
}

.t-69px {
  top: 69px;
}

.tn-69px {
  top: -69px;
}

.t-70 {
  top: 70%;
}

.t-70px {
  top: 70px;
}

.tn-70px {
  top: -70px;
}

.t-71 {
  top: 71%;
}

.t-71px {
  top: 71px;
}

.tn-71px {
  top: -71px;
}

.t-72 {
  top: 72%;
}

.t-72px {
  top: 72px;
}

.tn-72px {
  top: -72px;
}

.t-73 {
  top: 73%;
}

.t-73px {
  top: 73px;
}

.tn-73px {
  top: -73px;
}

.t-74 {
  top: 74%;
}

.t-74px {
  top: 74px;
}

.tn-74px {
  top: -74px;
}

.t-75 {
  top: 75%;
}

.t-75px {
  top: 75px;
}

.tn-75px {
  top: -75px;
}

.t-76 {
  top: 76%;
}

.t-76px {
  top: 76px;
}

.tn-76px {
  top: -76px;
}

.t-77 {
  top: 77%;
}

.t-77px {
  top: 77px;
}

.tn-77px {
  top: -77px;
}

.t-78 {
  top: 78%;
}

.t-78px {
  top: 78px;
}

.tn-78px {
  top: -78px;
}

.t-79 {
  top: 79%;
}

.t-79px {
  top: 79px;
}

.tn-79px {
  top: -79px;
}

.t-80 {
  top: 80%;
}

.t-80px {
  top: 80px;
}

.tn-80px {
  top: -80px;
}

.t-81 {
  top: 81%;
}

.t-81px {
  top: 81px;
}

.tn-81px {
  top: -81px;
}

.t-82 {
  top: 82%;
}

.t-82px {
  top: 82px;
}

.tn-82px {
  top: -82px;
}

.t-83 {
  top: 83%;
}

.t-83px {
  top: 83px;
}

.tn-83px {
  top: -83px;
}

.t-84 {
  top: 84%;
}

.t-84px {
  top: 84px;
}

.tn-84px {
  top: -84px;
}

.t-85 {
  top: 85%;
}

.t-85px {
  top: 85px;
}

.tn-85px {
  top: -85px;
}

.t-86 {
  top: 86%;
}

.t-86px {
  top: 86px;
}

.tn-86px {
  top: -86px;
}

.t-87 {
  top: 87%;
}

.t-87px {
  top: 87px;
}

.tn-87px {
  top: -87px;
}

.t-88 {
  top: 88%;
}

.t-88px {
  top: 88px;
}

.tn-88px {
  top: -88px;
}

.t-89 {
  top: 89%;
}

.t-89px {
  top: 89px;
}

.tn-89px {
  top: -89px;
}

.t-90 {
  top: 90%;
}

.t-90px {
  top: 90px;
}

.tn-90px {
  top: -90px;
}

.t-91 {
  top: 91%;
}

.t-91px {
  top: 91px;
}

.tn-91px {
  top: -91px;
}

.t-92 {
  top: 92%;
}

.t-92px {
  top: 92px;
}

.tn-92px {
  top: -92px;
}

.t-93 {
  top: 93%;
}

.t-93px {
  top: 93px;
}

.tn-93px {
  top: -93px;
}

.t-94 {
  top: 94%;
}

.t-94px {
  top: 94px;
}

.tn-94px {
  top: -94px;
}

.t-95 {
  top: 95%;
}

.t-95px {
  top: 95px;
}

.tn-95px {
  top: -95px;
}

.t-96 {
  top: 96%;
}

.t-96px {
  top: 96px;
}

.tn-96px {
  top: -96px;
}

.t-97 {
  top: 97%;
}

.t-97px {
  top: 97px;
}

.tn-97px {
  top: -97px;
}

.t-98 {
  top: 98%;
}

.t-98px {
  top: 98px;
}

.tn-98px {
  top: -98px;
}

.t-99 {
  top: 99%;
}

.t-99px {
  top: 99px;
}

.tn-99px {
  top: -99px;
}

.t-100 {
  top: 100%;
}

.t-100px {
  top: 100px;
}

.tn-100px {
  top: -100px;
}

.l-0 {
  left: 0%;
}

.ln-0 {
  left: -0%;
}

.l-0px {
  left: 0px;
}

.ln-0px {
  left: -0px;
}

.l-1 {
  left: 1%;
}

.ln-1 {
  left: -1%;
}

.l-1px {
  left: 1px;
}

.ln-1px {
  left: -1px;
}

.l-2 {
  left: 2%;
}

.ln-2 {
  left: -2%;
}

.l-2px {
  left: 2px;
}

.ln-2px {
  left: -2px;
}

.l-3 {
  left: 3%;
}

.ln-3 {
  left: -3%;
}

.l-3px {
  left: 3px;
}

.ln-3px {
  left: -3px;
}

.l-4 {
  left: 4%;
}

.ln-4 {
  left: -4%;
}

.l-4px {
  left: 4px;
}

.ln-4px {
  left: -4px;
}

.l-5 {
  left: 5%;
}

.ln-5 {
  left: -5%;
}

.l-5px {
  left: 5px;
}

.ln-5px {
  left: -5px;
}

.l-6 {
  left: 6%;
}

.ln-6 {
  left: -6%;
}

.l-6px {
  left: 6px;
}

.ln-6px {
  left: -6px;
}

.l-7 {
  left: 7%;
}

.ln-7 {
  left: -7%;
}

.l-7px {
  left: 7px;
}

.ln-7px {
  left: -7px;
}

.l-8 {
  left: 8%;
}

.ln-8 {
  left: -8%;
}

.l-8px {
  left: 8px;
}

.ln-8px {
  left: -8px;
}

.l-9 {
  left: 9%;
}

.ln-9 {
  left: -9%;
}

.l-9px {
  left: 9px;
}

.ln-9px {
  left: -9px;
}

.l-10 {
  left: 10%;
}

.ln-10 {
  left: -10%;
}

.l-10px {
  left: 10px;
}

.ln-10px {
  left: -10px;
}

.l-11 {
  left: 11%;
}

.ln-11 {
  left: -11%;
}

.l-11px {
  left: 11px;
}

.ln-11px {
  left: -11px;
}

.l-12 {
  left: 12%;
}

.ln-12 {
  left: -12%;
}

.l-12px {
  left: 12px;
}

.ln-12px {
  left: -12px;
}

.l-13 {
  left: 13%;
}

.ln-13 {
  left: -13%;
}

.l-13px {
  left: 13px;
}

.ln-13px {
  left: -13px;
}

.l-14 {
  left: 14%;
}

.ln-14 {
  left: -14%;
}

.l-14px {
  left: 14px;
}

.ln-14px {
  left: -14px;
}

.l-15 {
  left: 15%;
}

.ln-15 {
  left: -15%;
}

.l-15px {
  left: 15px;
}

.ln-15px {
  left: -15px;
}

.l-16 {
  left: 16%;
}

.ln-16 {
  left: -16%;
}

.l-16px {
  left: 16px;
}

.ln-16px {
  left: -16px;
}

.l-17 {
  left: 17%;
}

.ln-17 {
  left: -17%;
}

.l-17px {
  left: 17px;
}

.ln-17px {
  left: -17px;
}

.l-18 {
  left: 18%;
}

.ln-18 {
  left: -18%;
}

.l-18px {
  left: 18px;
}

.ln-18px {
  left: -18px;
}

.l-19 {
  left: 19%;
}

.ln-19 {
  left: -19%;
}

.l-19px {
  left: 19px;
}

.ln-19px {
  left: -19px;
}

.l-20 {
  left: 20%;
}

.ln-20 {
  left: -20%;
}

.l-20px {
  left: 20px;
}

.ln-20px {
  left: -20px;
}

.l-21 {
  left: 21%;
}

.ln-21 {
  left: -21%;
}

.l-21px {
  left: 21px;
}

.ln-21px {
  left: -21px;
}

.l-22 {
  left: 22%;
}

.ln-22 {
  left: -22%;
}

.l-22px {
  left: 22px;
}

.ln-22px {
  left: -22px;
}

.l-23 {
  left: 23%;
}

.ln-23 {
  left: -23%;
}

.l-23px {
  left: 23px;
}

.ln-23px {
  left: -23px;
}

.l-24 {
  left: 24%;
}

.ln-24 {
  left: -24%;
}

.l-24px {
  left: 24px;
}

.ln-24px {
  left: -24px;
}

.l-25 {
  left: 25%;
}

.ln-25 {
  left: -25%;
}

.l-25px {
  left: 25px;
}

.ln-25px {
  left: -25px;
}

.l-26 {
  left: 26%;
}

.ln-26 {
  left: -26%;
}

.l-26px {
  left: 26px;
}

.ln-26px {
  left: -26px;
}

.l-27 {
  left: 27%;
}

.ln-27 {
  left: -27%;
}

.l-27px {
  left: 27px;
}

.ln-27px {
  left: -27px;
}

.l-28 {
  left: 28%;
}

.ln-28 {
  left: -28%;
}

.l-28px {
  left: 28px;
}

.ln-28px {
  left: -28px;
}

.l-29 {
  left: 29%;
}

.ln-29 {
  left: -29%;
}

.l-29px {
  left: 29px;
}

.ln-29px {
  left: -29px;
}

.l-30 {
  left: 30%;
}

.ln-30 {
  left: -30%;
}

.l-30px {
  left: 30px;
}

.ln-30px {
  left: -30px;
}

.l-31 {
  left: 31%;
}

.ln-31 {
  left: -31%;
}

.l-31px {
  left: 31px;
}

.ln-31px {
  left: -31px;
}

.l-32 {
  left: 32%;
}

.ln-32 {
  left: -32%;
}

.l-32px {
  left: 32px;
}

.ln-32px {
  left: -32px;
}

.l-33 {
  left: 33%;
}

.ln-33 {
  left: -33%;
}

.l-33px {
  left: 33px;
}

.ln-33px {
  left: -33px;
}

.l-34 {
  left: 34%;
}

.ln-34 {
  left: -34%;
}

.l-34px {
  left: 34px;
}

.ln-34px {
  left: -34px;
}

.l-35 {
  left: 35%;
}

.ln-35 {
  left: -35%;
}

.l-35px {
  left: 35px;
}

.ln-35px {
  left: -35px;
}

.l-36 {
  left: 36%;
}

.ln-36 {
  left: -36%;
}

.l-36px {
  left: 36px;
}

.ln-36px {
  left: -36px;
}

.l-37 {
  left: 37%;
}

.ln-37 {
  left: -37%;
}

.l-37px {
  left: 37px;
}

.ln-37px {
  left: -37px;
}

.l-38 {
  left: 38%;
}

.ln-38 {
  left: -38%;
}

.l-38px {
  left: 38px;
}

.ln-38px {
  left: -38px;
}

.l-39 {
  left: 39%;
}

.ln-39 {
  left: -39%;
}

.l-39px {
  left: 39px;
}

.ln-39px {
  left: -39px;
}

.l-40 {
  left: 40%;
}

.ln-40 {
  left: -40%;
}

.l-40px {
  left: 40px;
}

.ln-40px {
  left: -40px;
}

.l-41 {
  left: 41%;
}

.ln-41 {
  left: -41%;
}

.l-41px {
  left: 41px;
}

.ln-41px {
  left: -41px;
}

.l-42 {
  left: 42%;
}

.ln-42 {
  left: -42%;
}

.l-42px {
  left: 42px;
}

.ln-42px {
  left: -42px;
}

.l-43 {
  left: 43%;
}

.ln-43 {
  left: -43%;
}

.l-43px {
  left: 43px;
}

.ln-43px {
  left: -43px;
}

.l-44 {
  left: 44%;
}

.ln-44 {
  left: -44%;
}

.l-44px {
  left: 44px;
}

.ln-44px {
  left: -44px;
}

.l-45 {
  left: 45%;
}

.ln-45 {
  left: -45%;
}

.l-45px {
  left: 45px;
}

.ln-45px {
  left: -45px;
}

.l-46 {
  left: 46%;
}

.ln-46 {
  left: -46%;
}

.l-46px {
  left: 46px;
}

.ln-46px {
  left: -46px;
}

.l-47 {
  left: 47%;
}

.ln-47 {
  left: -47%;
}

.l-47px {
  left: 47px;
}

.ln-47px {
  left: -47px;
}

.l-48 {
  left: 48%;
}

.ln-48 {
  left: -48%;
}

.l-48px {
  left: 48px;
}

.ln-48px {
  left: -48px;
}

.l-49 {
  left: 49%;
}

.ln-49 {
  left: -49%;
}

.l-49px {
  left: 49px;
}

.ln-49px {
  left: -49px;
}

.l-50 {
  left: 50%;
}

.ln-50 {
  left: -50%;
}

.l-50px {
  left: 50px;
}

.ln-50px {
  left: -50px;
}

.l-51 {
  left: 51%;
}

.ln-51 {
  left: -51%;
}

.l-51px {
  left: 51px;
}

.ln-51px {
  left: -51px;
}

.l-52 {
  left: 52%;
}

.ln-52 {
  left: -52%;
}

.l-52px {
  left: 52px;
}

.ln-52px {
  left: -52px;
}

.l-53 {
  left: 53%;
}

.ln-53 {
  left: -53%;
}

.l-53px {
  left: 53px;
}

.ln-53px {
  left: -53px;
}

.l-54 {
  left: 54%;
}

.ln-54 {
  left: -54%;
}

.l-54px {
  left: 54px;
}

.ln-54px {
  left: -54px;
}

.l-55 {
  left: 55%;
}

.ln-55 {
  left: -55%;
}

.l-55px {
  left: 55px;
}

.ln-55px {
  left: -55px;
}

.l-56 {
  left: 56%;
}

.ln-56 {
  left: -56%;
}

.l-56px {
  left: 56px;
}

.ln-56px {
  left: -56px;
}

.l-57 {
  left: 57%;
}

.ln-57 {
  left: -57%;
}

.l-57px {
  left: 57px;
}

.ln-57px {
  left: -57px;
}

.l-58 {
  left: 58%;
}

.ln-58 {
  left: -58%;
}

.l-58px {
  left: 58px;
}

.ln-58px {
  left: -58px;
}

.l-59 {
  left: 59%;
}

.ln-59 {
  left: -59%;
}

.l-59px {
  left: 59px;
}

.ln-59px {
  left: -59px;
}

.l-60 {
  left: 60%;
}

.ln-60 {
  left: -60%;
}

.l-60px {
  left: 60px;
}

.ln-60px {
  left: -60px;
}

.l-61 {
  left: 61%;
}

.ln-61 {
  left: -61%;
}

.l-61px {
  left: 61px;
}

.ln-61px {
  left: -61px;
}

.l-62 {
  left: 62%;
}

.ln-62 {
  left: -62%;
}

.l-62px {
  left: 62px;
}

.ln-62px {
  left: -62px;
}

.l-63 {
  left: 63%;
}

.ln-63 {
  left: -63%;
}

.l-63px {
  left: 63px;
}

.ln-63px {
  left: -63px;
}

.l-64 {
  left: 64%;
}

.ln-64 {
  left: -64%;
}

.l-64px {
  left: 64px;
}

.ln-64px {
  left: -64px;
}

.l-65 {
  left: 65%;
}

.ln-65 {
  left: -65%;
}

.l-65px {
  left: 65px;
}

.ln-65px {
  left: -65px;
}

.l-66 {
  left: 66%;
}

.ln-66 {
  left: -66%;
}

.l-66px {
  left: 66px;
}

.ln-66px {
  left: -66px;
}

.l-67 {
  left: 67%;
}

.ln-67 {
  left: -67%;
}

.l-67px {
  left: 67px;
}

.ln-67px {
  left: -67px;
}

.l-68 {
  left: 68%;
}

.ln-68 {
  left: -68%;
}

.l-68px {
  left: 68px;
}

.ln-68px {
  left: -68px;
}

.l-69 {
  left: 69%;
}

.ln-69 {
  left: -69%;
}

.l-69px {
  left: 69px;
}

.ln-69px {
  left: -69px;
}

.l-70 {
  left: 70%;
}

.ln-70 {
  left: -70%;
}

.l-70px {
  left: 70px;
}

.ln-70px {
  left: -70px;
}

.l-71 {
  left: 71%;
}

.ln-71 {
  left: -71%;
}

.l-71px {
  left: 71px;
}

.ln-71px {
  left: -71px;
}

.l-72 {
  left: 72%;
}

.ln-72 {
  left: -72%;
}

.l-72px {
  left: 72px;
}

.ln-72px {
  left: -72px;
}

.l-73 {
  left: 73%;
}

.ln-73 {
  left: -73%;
}

.l-73px {
  left: 73px;
}

.ln-73px {
  left: -73px;
}

.l-74 {
  left: 74%;
}

.ln-74 {
  left: -74%;
}

.l-74px {
  left: 74px;
}

.ln-74px {
  left: -74px;
}

.l-75 {
  left: 75%;
}

.ln-75 {
  left: -75%;
}

.l-75px {
  left: 75px;
}

.ln-75px {
  left: -75px;
}

.l-76 {
  left: 76%;
}

.ln-76 {
  left: -76%;
}

.l-76px {
  left: 76px;
}

.ln-76px {
  left: -76px;
}

.l-77 {
  left: 77%;
}

.ln-77 {
  left: -77%;
}

.l-77px {
  left: 77px;
}

.ln-77px {
  left: -77px;
}

.l-78 {
  left: 78%;
}

.ln-78 {
  left: -78%;
}

.l-78px {
  left: 78px;
}

.ln-78px {
  left: -78px;
}

.l-79 {
  left: 79%;
}

.ln-79 {
  left: -79%;
}

.l-79px {
  left: 79px;
}

.ln-79px {
  left: -79px;
}

.l-80 {
  left: 80%;
}

.ln-80 {
  left: -80%;
}

.l-80px {
  left: 80px;
}

.ln-80px {
  left: -80px;
}

.l-81 {
  left: 81%;
}

.ln-81 {
  left: -81%;
}

.l-81px {
  left: 81px;
}

.ln-81px {
  left: -81px;
}

.l-82 {
  left: 82%;
}

.ln-82 {
  left: -82%;
}

.l-82px {
  left: 82px;
}

.ln-82px {
  left: -82px;
}

.l-83 {
  left: 83%;
}

.ln-83 {
  left: -83%;
}

.l-83px {
  left: 83px;
}

.ln-83px {
  left: -83px;
}

.l-84 {
  left: 84%;
}

.ln-84 {
  left: -84%;
}

.l-84px {
  left: 84px;
}

.ln-84px {
  left: -84px;
}

.l-85 {
  left: 85%;
}

.ln-85 {
  left: -85%;
}

.l-85px {
  left: 85px;
}

.ln-85px {
  left: -85px;
}

.l-86 {
  left: 86%;
}

.ln-86 {
  left: -86%;
}

.l-86px {
  left: 86px;
}

.ln-86px {
  left: -86px;
}

.l-87 {
  left: 87%;
}

.ln-87 {
  left: -87%;
}

.l-87px {
  left: 87px;
}

.ln-87px {
  left: -87px;
}

.l-88 {
  left: 88%;
}

.ln-88 {
  left: -88%;
}

.l-88px {
  left: 88px;
}

.ln-88px {
  left: -88px;
}

.l-89 {
  left: 89%;
}

.ln-89 {
  left: -89%;
}

.l-89px {
  left: 89px;
}

.ln-89px {
  left: -89px;
}

.l-90 {
  left: 90%;
}

.ln-90 {
  left: -90%;
}

.l-90px {
  left: 90px;
}

.ln-90px {
  left: -90px;
}

.l-91 {
  left: 91%;
}

.ln-91 {
  left: -91%;
}

.l-91px {
  left: 91px;
}

.ln-91px {
  left: -91px;
}

.l-92 {
  left: 92%;
}

.ln-92 {
  left: -92%;
}

.l-92px {
  left: 92px;
}

.ln-92px {
  left: -92px;
}

.l-93 {
  left: 93%;
}

.ln-93 {
  left: -93%;
}

.l-93px {
  left: 93px;
}

.ln-93px {
  left: -93px;
}

.l-94 {
  left: 94%;
}

.ln-94 {
  left: -94%;
}

.l-94px {
  left: 94px;
}

.ln-94px {
  left: -94px;
}

.l-95 {
  left: 95%;
}

.ln-95 {
  left: -95%;
}

.l-95px {
  left: 95px;
}

.ln-95px {
  left: -95px;
}

.l-96 {
  left: 96%;
}

.ln-96 {
  left: -96%;
}

.l-96px {
  left: 96px;
}

.ln-96px {
  left: -96px;
}

.l-97 {
  left: 97%;
}

.ln-97 {
  left: -97%;
}

.l-97px {
  left: 97px;
}

.ln-97px {
  left: -97px;
}

.l-98 {
  left: 98%;
}

.ln-98 {
  left: -98%;
}

.l-98px {
  left: 98px;
}

.ln-98px {
  left: -98px;
}

.l-99 {
  left: 99%;
}

.ln-99 {
  left: -99%;
}

.l-99px {
  left: 99px;
}

.ln-99px {
  left: -99px;
}

.l-100 {
  left: 100%;
}

.ln-100 {
  left: -100%;
}

.l-100px {
  left: 100px;
}

.ln-100px {
  left: -100px;
}

.r-0 {
  right: 0%;
}

.r-0px {
  right: 0px;
}

.rn-0px {
  right: -0px;
}

.r-1 {
  right: 1%;
}

.r-1px {
  right: 1px;
}

.rn-1px {
  right: -1px;
}

.r-2 {
  right: 2%;
}

.r-2px {
  right: 2px;
}

.rn-2px {
  right: -2px;
}

.r-3 {
  right: 3%;
}

.r-3px {
  right: 3px;
}

.rn-3px {
  right: -3px;
}

.r-4 {
  right: 4%;
}

.r-4px {
  right: 4px;
}

.rn-4px {
  right: -4px;
}

.r-5 {
  right: 5%;
}

.r-5px {
  right: 5px;
}

.rn-5px {
  right: -5px;
}

.r-6 {
  right: 6%;
}

.r-6px {
  right: 6px;
}

.rn-6px {
  right: -6px;
}

.r-7 {
  right: 7%;
}

.r-7px {
  right: 7px;
}

.rn-7px {
  right: -7px;
}

.r-8 {
  right: 8%;
}

.r-8px {
  right: 8px;
}

.rn-8px {
  right: -8px;
}

.r-9 {
  right: 9%;
}

.r-9px {
  right: 9px;
}

.rn-9px {
  right: -9px;
}

.r-10 {
  right: 10%;
}

.r-10px {
  right: 10px;
}

.rn-10px {
  right: -10px;
}

.r-11 {
  right: 11%;
}

.r-11px {
  right: 11px;
}

.rn-11px {
  right: -11px;
}

.r-12 {
  right: 12%;
}

.r-12px {
  right: 12px;
}

.rn-12px {
  right: -12px;
}

.r-13 {
  right: 13%;
}

.r-13px {
  right: 13px;
}

.rn-13px {
  right: -13px;
}

.r-14 {
  right: 14%;
}

.r-14px {
  right: 14px;
}

.rn-14px {
  right: -14px;
}

.r-15 {
  right: 15%;
}

.r-15px {
  right: 15px;
}

.rn-15px {
  right: -15px;
}

.r-16 {
  right: 16%;
}

.r-16px {
  right: 16px;
}

.rn-16px {
  right: -16px;
}

.r-17 {
  right: 17%;
}

.r-17px {
  right: 17px;
}

.rn-17px {
  right: -17px;
}

.r-18 {
  right: 18%;
}

.r-18px {
  right: 18px;
}

.rn-18px {
  right: -18px;
}

.r-19 {
  right: 19%;
}

.r-19px {
  right: 19px;
}

.rn-19px {
  right: -19px;
}

.r-20 {
  right: 20%;
}

.r-20px {
  right: 20px;
}

.rn-20px {
  right: -20px;
}

.r-21 {
  right: 21%;
}

.r-21px {
  right: 21px;
}

.rn-21px {
  right: -21px;
}

.r-22 {
  right: 22%;
}

.r-22px {
  right: 22px;
}

.rn-22px {
  right: -22px;
}

.r-23 {
  right: 23%;
}

.r-23px {
  right: 23px;
}

.rn-23px {
  right: -23px;
}

.r-24 {
  right: 24%;
}

.r-24px {
  right: 24px;
}

.rn-24px {
  right: -24px;
}

.r-25 {
  right: 25%;
}

.r-25px {
  right: 25px;
}

.rn-25px {
  right: -25px;
}

.r-26 {
  right: 26%;
}

.r-26px {
  right: 26px;
}

.rn-26px {
  right: -26px;
}

.r-27 {
  right: 27%;
}

.r-27px {
  right: 27px;
}

.rn-27px {
  right: -27px;
}

.r-28 {
  right: 28%;
}

.r-28px {
  right: 28px;
}

.rn-28px {
  right: -28px;
}

.r-29 {
  right: 29%;
}

.r-29px {
  right: 29px;
}

.rn-29px {
  right: -29px;
}

.r-30 {
  right: 30%;
}

.r-30px {
  right: 30px;
}

.rn-30px {
  right: -30px;
}

.r-31 {
  right: 31%;
}

.r-31px {
  right: 31px;
}

.rn-31px {
  right: -31px;
}

.r-32 {
  right: 32%;
}

.r-32px {
  right: 32px;
}

.rn-32px {
  right: -32px;
}

.r-33 {
  right: 33%;
}

.r-33px {
  right: 33px;
}

.rn-33px {
  right: -33px;
}

.r-34 {
  right: 34%;
}

.r-34px {
  right: 34px;
}

.rn-34px {
  right: -34px;
}

.r-35 {
  right: 35%;
}

.r-35px {
  right: 35px;
}

.rn-35px {
  right: -35px;
}

.r-36 {
  right: 36%;
}

.r-36px {
  right: 36px;
}

.rn-36px {
  right: -36px;
}

.r-37 {
  right: 37%;
}

.r-37px {
  right: 37px;
}

.rn-37px {
  right: -37px;
}

.r-38 {
  right: 38%;
}

.r-38px {
  right: 38px;
}

.rn-38px {
  right: -38px;
}

.r-39 {
  right: 39%;
}

.r-39px {
  right: 39px;
}

.rn-39px {
  right: -39px;
}

.r-40 {
  right: 40%;
}

.r-40px {
  right: 40px;
}

.rn-40px {
  right: -40px;
}

.r-41 {
  right: 41%;
}

.r-41px {
  right: 41px;
}

.rn-41px {
  right: -41px;
}

.r-42 {
  right: 42%;
}

.r-42px {
  right: 42px;
}

.rn-42px {
  right: -42px;
}

.r-43 {
  right: 43%;
}

.r-43px {
  right: 43px;
}

.rn-43px {
  right: -43px;
}

.r-44 {
  right: 44%;
}

.r-44px {
  right: 44px;
}

.rn-44px {
  right: -44px;
}

.r-45 {
  right: 45%;
}

.r-45px {
  right: 45px;
}

.rn-45px {
  right: -45px;
}

.r-46 {
  right: 46%;
}

.r-46px {
  right: 46px;
}

.rn-46px {
  right: -46px;
}

.r-47 {
  right: 47%;
}

.r-47px {
  right: 47px;
}

.rn-47px {
  right: -47px;
}

.r-48 {
  right: 48%;
}

.r-48px {
  right: 48px;
}

.rn-48px {
  right: -48px;
}

.r-49 {
  right: 49%;
}

.r-49px {
  right: 49px;
}

.rn-49px {
  right: -49px;
}

.r-50 {
  right: 50%;
}

.r-50px {
  right: 50px;
}

.rn-50px {
  right: -50px;
}

.r-51 {
  right: 51%;
}

.r-51px {
  right: 51px;
}

.rn-51px {
  right: -51px;
}

.r-52 {
  right: 52%;
}

.r-52px {
  right: 52px;
}

.rn-52px {
  right: -52px;
}

.r-53 {
  right: 53%;
}

.r-53px {
  right: 53px;
}

.rn-53px {
  right: -53px;
}

.r-54 {
  right: 54%;
}

.r-54px {
  right: 54px;
}

.rn-54px {
  right: -54px;
}

.r-55 {
  right: 55%;
}

.r-55px {
  right: 55px;
}

.rn-55px {
  right: -55px;
}

.r-56 {
  right: 56%;
}

.r-56px {
  right: 56px;
}

.rn-56px {
  right: -56px;
}

.r-57 {
  right: 57%;
}

.r-57px {
  right: 57px;
}

.rn-57px {
  right: -57px;
}

.r-58 {
  right: 58%;
}

.r-58px {
  right: 58px;
}

.rn-58px {
  right: -58px;
}

.r-59 {
  right: 59%;
}

.r-59px {
  right: 59px;
}

.rn-59px {
  right: -59px;
}

.r-60 {
  right: 60%;
}

.r-60px {
  right: 60px;
}

.rn-60px {
  right: -60px;
}

.r-61 {
  right: 61%;
}

.r-61px {
  right: 61px;
}

.rn-61px {
  right: -61px;
}

.r-62 {
  right: 62%;
}

.r-62px {
  right: 62px;
}

.rn-62px {
  right: -62px;
}

.r-63 {
  right: 63%;
}

.r-63px {
  right: 63px;
}

.rn-63px {
  right: -63px;
}

.r-64 {
  right: 64%;
}

.r-64px {
  right: 64px;
}

.rn-64px {
  right: -64px;
}

.r-65 {
  right: 65%;
}

.r-65px {
  right: 65px;
}

.rn-65px {
  right: -65px;
}

.r-66 {
  right: 66%;
}

.r-66px {
  right: 66px;
}

.rn-66px {
  right: -66px;
}

.r-67 {
  right: 67%;
}

.r-67px {
  right: 67px;
}

.rn-67px {
  right: -67px;
}

.r-68 {
  right: 68%;
}

.r-68px {
  right: 68px;
}

.rn-68px {
  right: -68px;
}

.r-69 {
  right: 69%;
}

.r-69px {
  right: 69px;
}

.rn-69px {
  right: -69px;
}

.r-70 {
  right: 70%;
}

.r-70px {
  right: 70px;
}

.rn-70px {
  right: -70px;
}

.r-71 {
  right: 71%;
}

.r-71px {
  right: 71px;
}

.rn-71px {
  right: -71px;
}

.r-72 {
  right: 72%;
}

.r-72px {
  right: 72px;
}

.rn-72px {
  right: -72px;
}

.r-73 {
  right: 73%;
}

.r-73px {
  right: 73px;
}

.rn-73px {
  right: -73px;
}

.r-74 {
  right: 74%;
}

.r-74px {
  right: 74px;
}

.rn-74px {
  right: -74px;
}

.r-75 {
  right: 75%;
}

.r-75px {
  right: 75px;
}

.rn-75px {
  right: -75px;
}

.r-76 {
  right: 76%;
}

.r-76px {
  right: 76px;
}

.rn-76px {
  right: -76px;
}

.r-77 {
  right: 77%;
}

.r-77px {
  right: 77px;
}

.rn-77px {
  right: -77px;
}

.r-78 {
  right: 78%;
}

.r-78px {
  right: 78px;
}

.rn-78px {
  right: -78px;
}

.r-79 {
  right: 79%;
}

.r-79px {
  right: 79px;
}

.rn-79px {
  right: -79px;
}

.r-80 {
  right: 80%;
}

.r-80px {
  right: 80px;
}

.rn-80px {
  right: -80px;
}

.r-81 {
  right: 81%;
}

.r-81px {
  right: 81px;
}

.rn-81px {
  right: -81px;
}

.r-82 {
  right: 82%;
}

.r-82px {
  right: 82px;
}

.rn-82px {
  right: -82px;
}

.r-83 {
  right: 83%;
}

.r-83px {
  right: 83px;
}

.rn-83px {
  right: -83px;
}

.r-84 {
  right: 84%;
}

.r-84px {
  right: 84px;
}

.rn-84px {
  right: -84px;
}

.r-85 {
  right: 85%;
}

.r-85px {
  right: 85px;
}

.rn-85px {
  right: -85px;
}

.r-86 {
  right: 86%;
}

.r-86px {
  right: 86px;
}

.rn-86px {
  right: -86px;
}

.r-87 {
  right: 87%;
}

.r-87px {
  right: 87px;
}

.rn-87px {
  right: -87px;
}

.r-88 {
  right: 88%;
}

.r-88px {
  right: 88px;
}

.rn-88px {
  right: -88px;
}

.r-89 {
  right: 89%;
}

.r-89px {
  right: 89px;
}

.rn-89px {
  right: -89px;
}

.r-90 {
  right: 90%;
}

.r-90px {
  right: 90px;
}

.rn-90px {
  right: -90px;
}

.r-91 {
  right: 91%;
}

.r-91px {
  right: 91px;
}

.rn-91px {
  right: -91px;
}

.r-92 {
  right: 92%;
}

.r-92px {
  right: 92px;
}

.rn-92px {
  right: -92px;
}

.r-93 {
  right: 93%;
}

.r-93px {
  right: 93px;
}

.rn-93px {
  right: -93px;
}

.r-94 {
  right: 94%;
}

.r-94px {
  right: 94px;
}

.rn-94px {
  right: -94px;
}

.r-95 {
  right: 95%;
}

.r-95px {
  right: 95px;
}

.rn-95px {
  right: -95px;
}

.r-96 {
  right: 96%;
}

.r-96px {
  right: 96px;
}

.rn-96px {
  right: -96px;
}

.r-97 {
  right: 97%;
}

.r-97px {
  right: 97px;
}

.rn-97px {
  right: -97px;
}

.r-98 {
  right: 98%;
}

.r-98px {
  right: 98px;
}

.rn-98px {
  right: -98px;
}

.r-99 {
  right: 99%;
}

.r-99px {
  right: 99px;
}

.rn-99px {
  right: -99px;
}

.r-100 {
  right: 100%;
}

.r-100px {
  right: 100px;
}

.rn-100px {
  right: -100px;
}

.w-0 {
  width: 0%;
}

.w-0px {
  width: 0px;
}

.w-1 {
  width: 1%;
}

.w-1px {
  width: 1px;
}

.w-2 {
  width: 2%;
}

.w-2px {
  width: 2px;
}

.w-3 {
  width: 3%;
}

.w-3px {
  width: 3px;
}

.w-4 {
  width: 4%;
}

.w-4px {
  width: 4px;
}

.w-5 {
  width: 5%;
}

.w-5px {
  width: 5px;
}

.w-6 {
  width: 6%;
}

.w-6px {
  width: 6px;
}

.w-7 {
  width: 7%;
}

.w-7px {
  width: 7px;
}

.w-8 {
  width: 8%;
}

.w-8px {
  width: 8px;
}

.w-9 {
  width: 9%;
}

.w-9px {
  width: 9px;
}

.w-10 {
  width: 10%;
}

.w-10px {
  width: 10px;
}

.w-11 {
  width: 11%;
}

.w-11px {
  width: 11px;
}

.w-12 {
  width: 12%;
}

.w-12px {
  width: 12px;
}

.w-13 {
  width: 13%;
}

.w-13px {
  width: 13px;
}

.w-14 {
  width: 14%;
}

.w-14px {
  width: 14px;
}

.w-15 {
  width: 15%;
}

.w-15px {
  width: 15px;
}

.w-16 {
  width: 16%;
}

.w-16px {
  width: 16px;
}

.w-17 {
  width: 17%;
}

.w-17px {
  width: 17px;
}

.w-18 {
  width: 18%;
}

.w-18px {
  width: 18px;
}

.w-19 {
  width: 19%;
}

.w-19px {
  width: 19px;
}

.w-20 {
  width: 20%;
}

.w-20px {
  width: 20px;
}

.w-21 {
  width: 21%;
}

.w-21px {
  width: 21px;
}

.w-22 {
  width: 22%;
}

.w-22px {
  width: 22px;
}

.w-23 {
  width: 23%;
}

.w-23px {
  width: 23px;
}

.w-24 {
  width: 24%;
}

.w-24px {
  width: 24px;
}

.w-25 {
  width: 25%;
}

.w-25px {
  width: 25px;
}

.w-26 {
  width: 26%;
}

.w-26px {
  width: 26px;
}

.w-27 {
  width: 27%;
}

.w-27px {
  width: 27px;
}

.w-28 {
  width: 28%;
}

.w-28px {
  width: 28px;
}

.w-29 {
  width: 29%;
}

.w-29px {
  width: 29px;
}

.w-30 {
  width: 30%;
}

.w-30px {
  width: 30px;
}

.w-31 {
  width: 31%;
}

.w-31px {
  width: 31px;
}

.w-32 {
  width: 32%;
}

.w-32px {
  width: 32px;
}

.w-33 {
  width: 33%;
}

.w-33px {
  width: 33px;
}

.w-34 {
  width: 34%;
}

.w-34px {
  width: 34px;
}

.w-35 {
  width: 35%;
}

.w-35px {
  width: 35px;
}

.w-36 {
  width: 36%;
}

.w-36px {
  width: 36px;
}

.w-37 {
  width: 37%;
}

.w-37px {
  width: 37px;
}

.w-38 {
  width: 38%;
}

.w-38px {
  width: 38px;
}

.w-39 {
  width: 39%;
}

.w-39px {
  width: 39px;
}

.w-40 {
  width: 40%;
}

.w-40px {
  width: 40px;
}

.w-41 {
  width: 41%;
}

.w-41px {
  width: 41px;
}

.w-42 {
  width: 42%;
}

.w-42px {
  width: 42px;
}

.w-43 {
  width: 43%;
}

.w-43px {
  width: 43px;
}

.w-44 {
  width: 44%;
}

.w-44px {
  width: 44px;
}

.w-45 {
  width: 45%;
}

.w-45px {
  width: 45px;
}

.w-46 {
  width: 46%;
}

.w-46px {
  width: 46px;
}

.w-47 {
  width: 47%;
}

.w-47px {
  width: 47px;
}

.w-48 {
  width: 48%;
}

.w-48px {
  width: 48px;
}

.w-49 {
  width: 49%;
}

.w-49px {
  width: 49px;
}

.w-50 {
  width: 50%;
}

.w-50px {
  width: 50px;
}

.w-51 {
  width: 51%;
}

.w-51px {
  width: 51px;
}

.w-52 {
  width: 52%;
}

.w-52px {
  width: 52px;
}

.w-53 {
  width: 53%;
}

.w-53px {
  width: 53px;
}

.w-54 {
  width: 54%;
}

.w-54px {
  width: 54px;
}

.w-55 {
  width: 55%;
}

.w-55px {
  width: 55px;
}

.w-56 {
  width: 56%;
}

.w-56px {
  width: 56px;
}

.w-57 {
  width: 57%;
}

.w-57px {
  width: 57px;
}

.w-58 {
  width: 58%;
}

.w-58px {
  width: 58px;
}

.w-59 {
  width: 59%;
}

.w-59px {
  width: 59px;
}

.w-60 {
  width: 60%;
}

.w-60px {
  width: 60px;
}

.w-61 {
  width: 61%;
}

.w-61px {
  width: 61px;
}

.w-62 {
  width: 62%;
}

.w-62px {
  width: 62px;
}

.w-63 {
  width: 63%;
}

.w-63px {
  width: 63px;
}

.w-64 {
  width: 64%;
}

.w-64px {
  width: 64px;
}

.w-65 {
  width: 65%;
}

.w-65px {
  width: 65px;
}

.w-66 {
  width: 66%;
}

.w-66px {
  width: 66px;
}

.w-67 {
  width: 67%;
}

.w-67px {
  width: 67px;
}

.w-68 {
  width: 68%;
}

.w-68px {
  width: 68px;
}

.w-69 {
  width: 69%;
}

.w-69px {
  width: 69px;
}

.w-70 {
  width: 70%;
}

.w-70px {
  width: 70px;
}

.w-71 {
  width: 71%;
}

.w-71px {
  width: 71px;
}

.w-72 {
  width: 72%;
}

.w-72px {
  width: 72px;
}

.w-73 {
  width: 73%;
}

.w-73px {
  width: 73px;
}

.w-74 {
  width: 74%;
}

.w-74px {
  width: 74px;
}

.w-75 {
  width: 75%;
}

.w-75px {
  width: 75px;
}

.w-76 {
  width: 76%;
}

.w-76px {
  width: 76px;
}

.w-77 {
  width: 77%;
}

.w-77px {
  width: 77px;
}

.w-78 {
  width: 78%;
}

.w-78px {
  width: 78px;
}

.w-79 {
  width: 79%;
}

.w-79px {
  width: 79px;
}

.w-80 {
  width: 80%;
}

.w-80px {
  width: 80px;
}

.w-81 {
  width: 81%;
}

.w-81px {
  width: 81px;
}

.w-82 {
  width: 82%;
}

.w-82px {
  width: 82px;
}

.w-83 {
  width: 83%;
}

.w-83px {
  width: 83px;
}

.w-84 {
  width: 84%;
}

.w-84px {
  width: 84px;
}

.w-85 {
  width: 85%;
}

.w-85px {
  width: 85px;
}

.w-86 {
  width: 86%;
}

.w-86px {
  width: 86px;
}

.w-87 {
  width: 87%;
}

.w-87px {
  width: 87px;
}

.w-88 {
  width: 88%;
}

.w-88px {
  width: 88px;
}

.w-89 {
  width: 89%;
}

.w-89px {
  width: 89px;
}

.w-90 {
  width: 90%;
}

.w-90px {
  width: 90px;
}

.w-91 {
  width: 91%;
}

.w-91px {
  width: 91px;
}

.w-92 {
  width: 92%;
}

.w-92px {
  width: 92px;
}

.w-93 {
  width: 93%;
}

.w-93px {
  width: 93px;
}

.w-94 {
  width: 94%;
}

.w-94px {
  width: 94px;
}

.w-95 {
  width: 95%;
}

.w-95px {
  width: 95px;
}

.w-96 {
  width: 96%;
}

.w-96px {
  width: 96px;
}

.w-97 {
  width: 97%;
}

.w-97px {
  width: 97px;
}

.w-98 {
  width: 98%;
}

.w-98px {
  width: 98px;
}

.w-99 {
  width: 99%;
}

.w-99px {
  width: 99px;
}

.w-100 {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.w-fit-content {
  width: fit-content;
}

.min-w-100 {
  min-width: 100%;
}

.w-xs-auto {
  width: auto !important;
}

.w-xs-0px {
  width: 0px !important;
}

.w-xs-1px {
  width: 1px !important;
}

.w-xs-2px {
  width: 2px !important;
}

.w-xs-3px {
  width: 3px !important;
}

.w-xs-4px {
  width: 4px !important;
}

.w-xs-5px {
  width: 5px !important;
}

.w-xs-6px {
  width: 6px !important;
}

.w-xs-7px {
  width: 7px !important;
}

.w-xs-8px {
  width: 8px !important;
}

.w-xs-9px {
  width: 9px !important;
}

.w-xs-10px {
  width: 10px !important;
}

.w-xs-11px {
  width: 11px !important;
}

.w-xs-12px {
  width: 12px !important;
}

.w-xs-13px {
  width: 13px !important;
}

.w-xs-14px {
  width: 14px !important;
}

.w-xs-15px {
  width: 15px !important;
}

.w-xs-16px {
  width: 16px !important;
}

.w-xs-17px {
  width: 17px !important;
}

.w-xs-18px {
  width: 18px !important;
}

.w-xs-19px {
  width: 19px !important;
}

.w-xs-20px {
  width: 20px !important;
}

.w-xs-21px {
  width: 21px !important;
}

.w-xs-22px {
  width: 22px !important;
}

.w-xs-23px {
  width: 23px !important;
}

.w-xs-24px {
  width: 24px !important;
}

.w-xs-25px {
  width: 25px !important;
}

.w-xs-26px {
  width: 26px !important;
}

.w-xs-27px {
  width: 27px !important;
}

.w-xs-28px {
  width: 28px !important;
}

.w-xs-29px {
  width: 29px !important;
}

.w-xs-30px {
  width: 30px !important;
}

.w-xs-31px {
  width: 31px !important;
}

.w-xs-32px {
  width: 32px !important;
}

.w-xs-33px {
  width: 33px !important;
}

.w-xs-34px {
  width: 34px !important;
}

.w-xs-35px {
  width: 35px !important;
}

.w-xs-36px {
  width: 36px !important;
}

.w-xs-37px {
  width: 37px !important;
}

.w-xs-38px {
  width: 38px !important;
}

.w-xs-39px {
  width: 39px !important;
}

.w-xs-40px {
  width: 40px !important;
}

.w-xs-41px {
  width: 41px !important;
}

.w-xs-42px {
  width: 42px !important;
}

.w-xs-43px {
  width: 43px !important;
}

.w-xs-44px {
  width: 44px !important;
}

.w-xs-45px {
  width: 45px !important;
}

.w-xs-46px {
  width: 46px !important;
}

.w-xs-47px {
  width: 47px !important;
}

.w-xs-48px {
  width: 48px !important;
}

.w-xs-49px {
  width: 49px !important;
}

.w-xs-50px {
  width: 50px !important;
}

.w-xs-51px {
  width: 51px !important;
}

.w-xs-52px {
  width: 52px !important;
}

.w-xs-53px {
  width: 53px !important;
}

.w-xs-54px {
  width: 54px !important;
}

.w-xs-55px {
  width: 55px !important;
}

.w-xs-56px {
  width: 56px !important;
}

.w-xs-57px {
  width: 57px !important;
}

.w-xs-58px {
  width: 58px !important;
}

.w-xs-59px {
  width: 59px !important;
}

.w-xs-60px {
  width: 60px !important;
}

.w-xs-61px {
  width: 61px !important;
}

.w-xs-62px {
  width: 62px !important;
}

.w-xs-63px {
  width: 63px !important;
}

.w-xs-64px {
  width: 64px !important;
}

.w-xs-65px {
  width: 65px !important;
}

.w-xs-66px {
  width: 66px !important;
}

.w-xs-67px {
  width: 67px !important;
}

.w-xs-68px {
  width: 68px !important;
}

.w-xs-69px {
  width: 69px !important;
}

.w-xs-70px {
  width: 70px !important;
}

.w-xs-71px {
  width: 71px !important;
}

.w-xs-72px {
  width: 72px !important;
}

.w-xs-73px {
  width: 73px !important;
}

.w-xs-74px {
  width: 74px !important;
}

.w-xs-75px {
  width: 75px !important;
}

.w-xs-76px {
  width: 76px !important;
}

.w-xs-77px {
  width: 77px !important;
}

.w-xs-78px {
  width: 78px !important;
}

.w-xs-79px {
  width: 79px !important;
}

.w-xs-80px {
  width: 80px !important;
}

.w-xs-81px {
  width: 81px !important;
}

.w-xs-82px {
  width: 82px !important;
}

.w-xs-83px {
  width: 83px !important;
}

.w-xs-84px {
  width: 84px !important;
}

.w-xs-85px {
  width: 85px !important;
}

.w-xs-86px {
  width: 86px !important;
}

.w-xs-87px {
  width: 87px !important;
}

.w-xs-88px {
  width: 88px !important;
}

.w-xs-89px {
  width: 89px !important;
}

.w-xs-90px {
  width: 90px !important;
}

.w-xs-91px {
  width: 91px !important;
}

.w-xs-92px {
  width: 92px !important;
}

.w-xs-93px {
  width: 93px !important;
}

.w-xs-94px {
  width: 94px !important;
}

.w-xs-95px {
  width: 95px !important;
}

.w-xs-96px {
  width: 96px !important;
}

.w-xs-97px {
  width: 97px !important;
}

.w-xs-98px {
  width: 98px !important;
}

.w-xs-99px {
  width: 99px !important;
}

.w-xs-100px {
  width: 100px !important;
}

.w-xs-101px {
  width: 101px !important;
}

.w-xs-102px {
  width: 102px !important;
}

.w-xs-103px {
  width: 103px !important;
}

.w-xs-104px {
  width: 104px !important;
}

.w-xs-105px {
  width: 105px !important;
}

.w-xs-106px {
  width: 106px !important;
}

.w-xs-107px {
  width: 107px !important;
}

.w-xs-108px {
  width: 108px !important;
}

.w-xs-109px {
  width: 109px !important;
}

.w-xs-110px {
  width: 110px !important;
}

.w-xs-111px {
  width: 111px !important;
}

.w-xs-112px {
  width: 112px !important;
}

.w-xs-113px {
  width: 113px !important;
}

.w-xs-114px {
  width: 114px !important;
}

.w-xs-115px {
  width: 115px !important;
}

.w-xs-116px {
  width: 116px !important;
}

.w-xs-117px {
  width: 117px !important;
}

.w-xs-118px {
  width: 118px !important;
}

.w-xs-119px {
  width: 119px !important;
}

.w-xs-120px {
  width: 120px !important;
}

.w-xs-121px {
  width: 121px !important;
}

.w-xs-122px {
  width: 122px !important;
}

.w-xs-123px {
  width: 123px !important;
}

.w-xs-124px {
  width: 124px !important;
}

.w-xs-125px {
  width: 125px !important;
}

.w-xs-126px {
  width: 126px !important;
}

.w-xs-127px {
  width: 127px !important;
}

.w-xs-128px {
  width: 128px !important;
}

.w-xs-129px {
  width: 129px !important;
}

.w-xs-130px {
  width: 130px !important;
}

.w-xs-131px {
  width: 131px !important;
}

.w-xs-132px {
  width: 132px !important;
}

.w-xs-133px {
  width: 133px !important;
}

.w-xs-134px {
  width: 134px !important;
}

.w-xs-135px {
  width: 135px !important;
}

.w-xs-136px {
  width: 136px !important;
}

.w-xs-137px {
  width: 137px !important;
}

.w-xs-138px {
  width: 138px !important;
}

.w-xs-139px {
  width: 139px !important;
}

.w-xs-140px {
  width: 140px !important;
}

.w-xs-141px {
  width: 141px !important;
}

.w-xs-142px {
  width: 142px !important;
}

.w-xs-143px {
  width: 143px !important;
}

.w-xs-144px {
  width: 144px !important;
}

.w-xs-145px {
  width: 145px !important;
}

.w-xs-146px {
  width: 146px !important;
}

.w-xs-147px {
  width: 147px !important;
}

.w-xs-148px {
  width: 148px !important;
}

.w-xs-149px {
  width: 149px !important;
}

.w-xs-150px {
  width: 150px !important;
}

.w-xs-151px {
  width: 151px !important;
}

.w-xs-152px {
  width: 152px !important;
}

.w-xs-153px {
  width: 153px !important;
}

.w-xs-154px {
  width: 154px !important;
}

.w-xs-155px {
  width: 155px !important;
}

.w-xs-156px {
  width: 156px !important;
}

.w-xs-157px {
  width: 157px !important;
}

.w-xs-158px {
  width: 158px !important;
}

.w-xs-159px {
  width: 159px !important;
}

.w-xs-160px {
  width: 160px !important;
}

.w-xs-161px {
  width: 161px !important;
}

.w-xs-162px {
  width: 162px !important;
}

.w-xs-163px {
  width: 163px !important;
}

.w-xs-164px {
  width: 164px !important;
}

.w-xs-165px {
  width: 165px !important;
}

.w-xs-166px {
  width: 166px !important;
}

.w-xs-167px {
  width: 167px !important;
}

.w-xs-168px {
  width: 168px !important;
}

.w-xs-169px {
  width: 169px !important;
}

.w-xs-170px {
  width: 170px !important;
}

.w-xs-171px {
  width: 171px !important;
}

.w-xs-172px {
  width: 172px !important;
}

.w-xs-173px {
  width: 173px !important;
}

.w-xs-174px {
  width: 174px !important;
}

.w-xs-175px {
  width: 175px !important;
}

.w-xs-176px {
  width: 176px !important;
}

.w-xs-177px {
  width: 177px !important;
}

.w-xs-178px {
  width: 178px !important;
}

.w-xs-179px {
  width: 179px !important;
}

.w-xs-180px {
  width: 180px !important;
}

.w-xs-181px {
  width: 181px !important;
}

.w-xs-182px {
  width: 182px !important;
}

.w-xs-183px {
  width: 183px !important;
}

.w-xs-184px {
  width: 184px !important;
}

.w-xs-185px {
  width: 185px !important;
}

.w-xs-186px {
  width: 186px !important;
}

.w-xs-187px {
  width: 187px !important;
}

.w-xs-188px {
  width: 188px !important;
}

.w-xs-189px {
  width: 189px !important;
}

.w-xs-190px {
  width: 190px !important;
}

.w-xs-191px {
  width: 191px !important;
}

.w-xs-192px {
  width: 192px !important;
}

.w-xs-193px {
  width: 193px !important;
}

.w-xs-194px {
  width: 194px !important;
}

.w-xs-195px {
  width: 195px !important;
}

.w-xs-196px {
  width: 196px !important;
}

.w-xs-197px {
  width: 197px !important;
}

.w-xs-198px {
  width: 198px !important;
}

.w-xs-199px {
  width: 199px !important;
}

.w-xs-200px {
  width: 200px !important;
}

.w-xs-201px {
  width: 201px !important;
}

.w-xs-202px {
  width: 202px !important;
}

.w-xs-203px {
  width: 203px !important;
}

.w-xs-204px {
  width: 204px !important;
}

.w-xs-205px {
  width: 205px !important;
}

.w-xs-206px {
  width: 206px !important;
}

.w-xs-207px {
  width: 207px !important;
}

.w-xs-208px {
  width: 208px !important;
}

.w-xs-209px {
  width: 209px !important;
}

.w-xs-210px {
  width: 210px !important;
}

.w-xs-211px {
  width: 211px !important;
}

.w-xs-212px {
  width: 212px !important;
}

.w-xs-213px {
  width: 213px !important;
}

.w-xs-214px {
  width: 214px !important;
}

.w-xs-215px {
  width: 215px !important;
}

.w-xs-216px {
  width: 216px !important;
}

.w-xs-217px {
  width: 217px !important;
}

.w-xs-218px {
  width: 218px !important;
}

.w-xs-219px {
  width: 219px !important;
}

.w-xs-220px {
  width: 220px !important;
}

.w-xs-221px {
  width: 221px !important;
}

.w-xs-222px {
  width: 222px !important;
}

.w-xs-223px {
  width: 223px !important;
}

.w-xs-224px {
  width: 224px !important;
}

.w-xs-225px {
  width: 225px !important;
}

.w-xs-226px {
  width: 226px !important;
}

.w-xs-227px {
  width: 227px !important;
}

.w-xs-228px {
  width: 228px !important;
}

.w-xs-229px {
  width: 229px !important;
}

.w-xs-230px {
  width: 230px !important;
}

.w-xs-231px {
  width: 231px !important;
}

.w-xs-232px {
  width: 232px !important;
}

.w-xs-233px {
  width: 233px !important;
}

.w-xs-234px {
  width: 234px !important;
}

.w-xs-235px {
  width: 235px !important;
}

.w-xs-236px {
  width: 236px !important;
}

.w-xs-237px {
  width: 237px !important;
}

.w-xs-238px {
  width: 238px !important;
}

.w-xs-239px {
  width: 239px !important;
}

.w-xs-240px {
  width: 240px !important;
}

.w-xs-241px {
  width: 241px !important;
}

.w-xs-242px {
  width: 242px !important;
}

.w-xs-243px {
  width: 243px !important;
}

.w-xs-244px {
  width: 244px !important;
}

.w-xs-245px {
  width: 245px !important;
}

.w-xs-246px {
  width: 246px !important;
}

.w-xs-247px {
  width: 247px !important;
}

.w-xs-248px {
  width: 248px !important;
}

.w-xs-249px {
  width: 249px !important;
}

.w-xs-250px {
  width: 250px !important;
}

.w-xs-251px {
  width: 251px !important;
}

.w-xs-252px {
  width: 252px !important;
}

.w-xs-253px {
  width: 253px !important;
}

.w-xs-254px {
  width: 254px !important;
}

.w-xs-255px {
  width: 255px !important;
}

.w-xs-256px {
  width: 256px !important;
}

.w-xs-257px {
  width: 257px !important;
}

.w-xs-258px {
  width: 258px !important;
}

.w-xs-259px {
  width: 259px !important;
}

.w-xs-260px {
  width: 260px !important;
}

.w-xs-261px {
  width: 261px !important;
}

.w-xs-262px {
  width: 262px !important;
}

.w-xs-263px {
  width: 263px !important;
}

.w-xs-264px {
  width: 264px !important;
}

.w-xs-265px {
  width: 265px !important;
}

.w-xs-266px {
  width: 266px !important;
}

.w-xs-267px {
  width: 267px !important;
}

.w-xs-268px {
  width: 268px !important;
}

.w-xs-269px {
  width: 269px !important;
}

.w-xs-270px {
  width: 270px !important;
}

.w-xs-271px {
  width: 271px !important;
}

.w-xs-272px {
  width: 272px !important;
}

.w-xs-273px {
  width: 273px !important;
}

.w-xs-274px {
  width: 274px !important;
}

.w-xs-275px {
  width: 275px !important;
}

.w-xs-276px {
  width: 276px !important;
}

.w-xs-277px {
  width: 277px !important;
}

.w-xs-278px {
  width: 278px !important;
}

.w-xs-279px {
  width: 279px !important;
}

.w-xs-280px {
  width: 280px !important;
}

.w-xs-281px {
  width: 281px !important;
}

.w-xs-282px {
  width: 282px !important;
}

.w-xs-283px {
  width: 283px !important;
}

.w-xs-284px {
  width: 284px !important;
}

.w-xs-285px {
  width: 285px !important;
}

.w-xs-286px {
  width: 286px !important;
}

.w-xs-287px {
  width: 287px !important;
}

.w-xs-288px {
  width: 288px !important;
}

.w-xs-289px {
  width: 289px !important;
}

.w-xs-290px {
  width: 290px !important;
}

.w-xs-291px {
  width: 291px !important;
}

.w-xs-292px {
  width: 292px !important;
}

.w-xs-293px {
  width: 293px !important;
}

.w-xs-294px {
  width: 294px !important;
}

.w-xs-295px {
  width: 295px !important;
}

.w-xs-296px {
  width: 296px !important;
}

.w-xs-297px {
  width: 297px !important;
}

.w-xs-298px {
  width: 298px !important;
}

.w-xs-299px {
  width: 299px !important;
}

.w-xs-300px {
  width: 300px !important;
}

.w-xs-301px {
  width: 301px !important;
}

.w-xs-302px {
  width: 302px !important;
}

.w-xs-303px {
  width: 303px !important;
}

.w-xs-304px {
  width: 304px !important;
}

.w-xs-305px {
  width: 305px !important;
}

.w-xs-306px {
  width: 306px !important;
}

.w-xs-307px {
  width: 307px !important;
}

.w-xs-308px {
  width: 308px !important;
}

.w-xs-309px {
  width: 309px !important;
}

.w-xs-310px {
  width: 310px !important;
}

.w-xs-311px {
  width: 311px !important;
}

.w-xs-312px {
  width: 312px !important;
}

.w-xs-313px {
  width: 313px !important;
}

.w-xs-314px {
  width: 314px !important;
}

.w-xs-315px {
  width: 315px !important;
}

.w-xs-316px {
  width: 316px !important;
}

.w-xs-317px {
  width: 317px !important;
}

.w-xs-318px {
  width: 318px !important;
}

.w-xs-319px {
  width: 319px !important;
}

.w-xs-320px {
  width: 320px !important;
}

.w-xs-321px {
  width: 321px !important;
}

.w-xs-322px {
  width: 322px !important;
}

.w-xs-323px {
  width: 323px !important;
}

.w-xs-324px {
  width: 324px !important;
}

.w-xs-325px {
  width: 325px !important;
}

.w-xs-326px {
  width: 326px !important;
}

.w-xs-327px {
  width: 327px !important;
}

.w-xs-328px {
  width: 328px !important;
}

.w-xs-329px {
  width: 329px !important;
}

.w-xs-330px {
  width: 330px !important;
}

.w-xs-331px {
  width: 331px !important;
}

.w-xs-332px {
  width: 332px !important;
}

.w-xs-333px {
  width: 333px !important;
}

.w-xs-334px {
  width: 334px !important;
}

.w-xs-335px {
  width: 335px !important;
}

.w-xs-336px {
  width: 336px !important;
}

.w-xs-337px {
  width: 337px !important;
}

.w-xs-338px {
  width: 338px !important;
}

.w-xs-339px {
  width: 339px !important;
}

.w-xs-340px {
  width: 340px !important;
}

.w-xs-341px {
  width: 341px !important;
}

.w-xs-342px {
  width: 342px !important;
}

.w-xs-343px {
  width: 343px !important;
}

.w-xs-344px {
  width: 344px !important;
}

.w-xs-345px {
  width: 345px !important;
}

.w-xs-346px {
  width: 346px !important;
}

.w-xs-347px {
  width: 347px !important;
}

.w-xs-348px {
  width: 348px !important;
}

.w-xs-349px {
  width: 349px !important;
}

.w-xs-350px {
  width: 350px !important;
}

.w-xs-351px {
  width: 351px !important;
}

.w-xs-352px {
  width: 352px !important;
}

.w-xs-353px {
  width: 353px !important;
}

.w-xs-354px {
  width: 354px !important;
}

.w-xs-355px {
  width: 355px !important;
}

.w-xs-356px {
  width: 356px !important;
}

.w-xs-357px {
  width: 357px !important;
}

.w-xs-358px {
  width: 358px !important;
}

.w-xs-359px {
  width: 359px !important;
}

.w-xs-360px {
  width: 360px !important;
}

.w-xs-361px {
  width: 361px !important;
}

.w-xs-362px {
  width: 362px !important;
}

.w-xs-363px {
  width: 363px !important;
}

.w-xs-364px {
  width: 364px !important;
}

.w-xs-365px {
  width: 365px !important;
}

.w-xs-366px {
  width: 366px !important;
}

.w-xs-367px {
  width: 367px !important;
}

.w-xs-368px {
  width: 368px !important;
}

.w-xs-369px {
  width: 369px !important;
}

.w-xs-370px {
  width: 370px !important;
}

.w-xs-371px {
  width: 371px !important;
}

.w-xs-372px {
  width: 372px !important;
}

.w-xs-373px {
  width: 373px !important;
}

.w-xs-374px {
  width: 374px !important;
}

.w-xs-375px {
  width: 375px !important;
}

.w-xs-376px {
  width: 376px !important;
}

.w-xs-377px {
  width: 377px !important;
}

.w-xs-378px {
  width: 378px !important;
}

.w-xs-379px {
  width: 379px !important;
}

.w-xs-380px {
  width: 380px !important;
}

.w-xs-381px {
  width: 381px !important;
}

.w-xs-382px {
  width: 382px !important;
}

.w-xs-383px {
  width: 383px !important;
}

.w-xs-384px {
  width: 384px !important;
}

.w-xs-385px {
  width: 385px !important;
}

.w-xs-386px {
  width: 386px !important;
}

.w-xs-387px {
  width: 387px !important;
}

.w-xs-388px {
  width: 388px !important;
}

.w-xs-389px {
  width: 389px !important;
}

.w-xs-390px {
  width: 390px !important;
}

.w-xs-391px {
  width: 391px !important;
}

.w-xs-392px {
  width: 392px !important;
}

.w-xs-393px {
  width: 393px !important;
}

.w-xs-394px {
  width: 394px !important;
}

.w-xs-395px {
  width: 395px !important;
}

.w-xs-396px {
  width: 396px !important;
}

.w-xs-397px {
  width: 397px !important;
}

.w-xs-398px {
  width: 398px !important;
}

.w-xs-399px {
  width: 399px !important;
}

.w-xs-400px {
  width: 400px !important;
}

.w-xs-401px {
  width: 401px !important;
}

.w-xs-402px {
  width: 402px !important;
}

.w-xs-403px {
  width: 403px !important;
}

.w-xs-404px {
  width: 404px !important;
}

.w-xs-405px {
  width: 405px !important;
}

.w-xs-406px {
  width: 406px !important;
}

.w-xs-407px {
  width: 407px !important;
}

.w-xs-408px {
  width: 408px !important;
}

.w-xs-409px {
  width: 409px !important;
}

.w-xs-410px {
  width: 410px !important;
}

.w-xs-411px {
  width: 411px !important;
}

.w-xs-412px {
  width: 412px !important;
}

.w-xs-413px {
  width: 413px !important;
}

.w-xs-414px {
  width: 414px !important;
}

.w-xs-415px {
  width: 415px !important;
}

.w-xs-416px {
  width: 416px !important;
}

.w-xs-417px {
  width: 417px !important;
}

.w-xs-418px {
  width: 418px !important;
}

.w-xs-419px {
  width: 419px !important;
}

.w-xs-420px {
  width: 420px !important;
}

.w-xs-421px {
  width: 421px !important;
}

.w-xs-422px {
  width: 422px !important;
}

.w-xs-423px {
  width: 423px !important;
}

.w-xs-424px {
  width: 424px !important;
}

.w-xs-425px {
  width: 425px !important;
}

.w-xs-426px {
  width: 426px !important;
}

.w-xs-427px {
  width: 427px !important;
}

.w-xs-428px {
  width: 428px !important;
}

.w-xs-429px {
  width: 429px !important;
}

.w-xs-430px {
  width: 430px !important;
}

.w-xs-431px {
  width: 431px !important;
}

.w-xs-432px {
  width: 432px !important;
}

.w-xs-433px {
  width: 433px !important;
}

.w-xs-434px {
  width: 434px !important;
}

.w-xs-435px {
  width: 435px !important;
}

.w-xs-436px {
  width: 436px !important;
}

.w-xs-437px {
  width: 437px !important;
}

.w-xs-438px {
  width: 438px !important;
}

.w-xs-439px {
  width: 439px !important;
}

.w-xs-440px {
  width: 440px !important;
}

.w-xs-441px {
  width: 441px !important;
}

.w-xs-442px {
  width: 442px !important;
}

.w-xs-443px {
  width: 443px !important;
}

.w-xs-444px {
  width: 444px !important;
}

.w-xs-445px {
  width: 445px !important;
}

.w-xs-446px {
  width: 446px !important;
}

.w-xs-447px {
  width: 447px !important;
}

.w-xs-448px {
  width: 448px !important;
}

.w-xs-449px {
  width: 449px !important;
}

.w-xs-450px {
  width: 450px !important;
}

.w-xs-451px {
  width: 451px !important;
}

.w-xs-452px {
  width: 452px !important;
}

.w-xs-453px {
  width: 453px !important;
}

.w-xs-454px {
  width: 454px !important;
}

.w-xs-455px {
  width: 455px !important;
}

.w-xs-456px {
  width: 456px !important;
}

.w-xs-457px {
  width: 457px !important;
}

.w-xs-458px {
  width: 458px !important;
}

.w-xs-459px {
  width: 459px !important;
}

.w-xs-460px {
  width: 460px !important;
}

.w-xs-461px {
  width: 461px !important;
}

.w-xs-462px {
  width: 462px !important;
}

.w-xs-463px {
  width: 463px !important;
}

.w-xs-464px {
  width: 464px !important;
}

.w-xs-465px {
  width: 465px !important;
}

.w-xs-466px {
  width: 466px !important;
}

.w-xs-467px {
  width: 467px !important;
}

.w-xs-468px {
  width: 468px !important;
}

.w-xs-469px {
  width: 469px !important;
}

.w-xs-470px {
  width: 470px !important;
}

.w-xs-471px {
  width: 471px !important;
}

.w-xs-472px {
  width: 472px !important;
}

.w-xs-473px {
  width: 473px !important;
}

.w-xs-474px {
  width: 474px !important;
}

.w-xs-475px {
  width: 475px !important;
}

.w-xs-476px {
  width: 476px !important;
}

.w-xs-477px {
  width: 477px !important;
}

.w-xs-478px {
  width: 478px !important;
}

.w-xs-479px {
  width: 479px !important;
}

.w-xs-480px {
  width: 480px !important;
}

.w-xs-481px {
  width: 481px !important;
}

.w-xs-482px {
  width: 482px !important;
}

.w-xs-483px {
  width: 483px !important;
}

.w-xs-484px {
  width: 484px !important;
}

.w-xs-485px {
  width: 485px !important;
}

.w-xs-486px {
  width: 486px !important;
}

.w-xs-487px {
  width: 487px !important;
}

.w-xs-488px {
  width: 488px !important;
}

.w-xs-489px {
  width: 489px !important;
}

.w-xs-490px {
  width: 490px !important;
}

.w-xs-491px {
  width: 491px !important;
}

.w-xs-492px {
  width: 492px !important;
}

.w-xs-493px {
  width: 493px !important;
}

.w-xs-494px {
  width: 494px !important;
}

.w-xs-495px {
  width: 495px !important;
}

.w-xs-496px {
  width: 496px !important;
}

.w-xs-497px {
  width: 497px !important;
}

.w-xs-498px {
  width: 498px !important;
}

.w-xs-499px {
  width: 499px !important;
}

.w-xs-500px {
  width: 500px !important;
}

.w-xs-501px {
  width: 501px !important;
}

.w-xs-502px {
  width: 502px !important;
}

.w-xs-503px {
  width: 503px !important;
}

.w-xs-504px {
  width: 504px !important;
}

.w-xs-505px {
  width: 505px !important;
}

.w-xs-506px {
  width: 506px !important;
}

.w-xs-507px {
  width: 507px !important;
}

.w-xs-508px {
  width: 508px !important;
}

.w-xs-509px {
  width: 509px !important;
}

.w-xs-510px {
  width: 510px !important;
}

.w-xs-511px {
  width: 511px !important;
}

.w-xs-512px {
  width: 512px !important;
}

.w-xs-513px {
  width: 513px !important;
}

.w-xs-514px {
  width: 514px !important;
}

.w-xs-515px {
  width: 515px !important;
}

.w-xs-516px {
  width: 516px !important;
}

.w-xs-517px {
  width: 517px !important;
}

.w-xs-518px {
  width: 518px !important;
}

.w-xs-519px {
  width: 519px !important;
}

.w-xs-520px {
  width: 520px !important;
}

.w-xs-521px {
  width: 521px !important;
}

.w-xs-522px {
  width: 522px !important;
}

.w-xs-523px {
  width: 523px !important;
}

.w-xs-524px {
  width: 524px !important;
}

.w-xs-525px {
  width: 525px !important;
}

.w-xs-526px {
  width: 526px !important;
}

.w-xs-527px {
  width: 527px !important;
}

.w-xs-528px {
  width: 528px !important;
}

.w-xs-529px {
  width: 529px !important;
}

.w-xs-530px {
  width: 530px !important;
}

.w-xs-531px {
  width: 531px !important;
}

.w-xs-532px {
  width: 532px !important;
}

.w-xs-533px {
  width: 533px !important;
}

.w-xs-534px {
  width: 534px !important;
}

.w-xs-535px {
  width: 535px !important;
}

.w-xs-536px {
  width: 536px !important;
}

.w-xs-537px {
  width: 537px !important;
}

.w-xs-538px {
  width: 538px !important;
}

.w-xs-539px {
  width: 539px !important;
}

.w-xs-540px {
  width: 540px !important;
}

.w-xs-541px {
  width: 541px !important;
}

.w-xs-542px {
  width: 542px !important;
}

.w-xs-543px {
  width: 543px !important;
}

.w-xs-544px {
  width: 544px !important;
}

.w-xs-545px {
  width: 545px !important;
}

.w-xs-546px {
  width: 546px !important;
}

.w-xs-547px {
  width: 547px !important;
}

.w-xs-548px {
  width: 548px !important;
}

.w-xs-549px {
  width: 549px !important;
}

.w-xs-550px {
  width: 550px !important;
}

.w-xs-551px {
  width: 551px !important;
}

.w-xs-552px {
  width: 552px !important;
}

.w-xs-553px {
  width: 553px !important;
}

.w-xs-554px {
  width: 554px !important;
}

.w-xs-555px {
  width: 555px !important;
}

.w-xs-556px {
  width: 556px !important;
}

.w-xs-557px {
  width: 557px !important;
}

.w-xs-558px {
  width: 558px !important;
}

.w-xs-559px {
  width: 559px !important;
}

.w-xs-560px {
  width: 560px !important;
}

.w-xs-561px {
  width: 561px !important;
}

.w-xs-562px {
  width: 562px !important;
}

.w-xs-563px {
  width: 563px !important;
}

.w-xs-564px {
  width: 564px !important;
}

.w-xs-565px {
  width: 565px !important;
}

.w-xs-566px {
  width: 566px !important;
}

.w-xs-567px {
  width: 567px !important;
}

.w-xs-568px {
  width: 568px !important;
}

.w-xs-569px {
  width: 569px !important;
}

.w-xs-570px {
  width: 570px !important;
}

.w-xs-571px {
  width: 571px !important;
}

.w-xs-572px {
  width: 572px !important;
}

.w-xs-573px {
  width: 573px !important;
}

.w-xs-574px {
  width: 574px !important;
}

.w-xs-575px {
  width: 575px !important;
}

.w-xs-576px {
  width: 576px !important;
}

.w-xs-577px {
  width: 577px !important;
}

.w-xs-578px {
  width: 578px !important;
}

.w-xs-579px {
  width: 579px !important;
}

.w-xs-580px {
  width: 580px !important;
}

.w-xs-581px {
  width: 581px !important;
}

.w-xs-582px {
  width: 582px !important;
}

.w-xs-583px {
  width: 583px !important;
}

.w-xs-584px {
  width: 584px !important;
}

.w-xs-585px {
  width: 585px !important;
}

.w-xs-586px {
  width: 586px !important;
}

.w-xs-587px {
  width: 587px !important;
}

.w-xs-588px {
  width: 588px !important;
}

.w-xs-589px {
  width: 589px !important;
}

.w-xs-590px {
  width: 590px !important;
}

.w-xs-591px {
  width: 591px !important;
}

.w-xs-592px {
  width: 592px !important;
}

.w-xs-593px {
  width: 593px !important;
}

.w-xs-594px {
  width: 594px !important;
}

.w-xs-595px {
  width: 595px !important;
}

.w-xs-596px {
  width: 596px !important;
}

.w-xs-597px {
  width: 597px !important;
}

.w-xs-598px {
  width: 598px !important;
}

.w-xs-599px {
  width: 599px !important;
}

.w-xs-600px {
  width: 600px !important;
}

.w-xs-601px {
  width: 601px !important;
}

.w-xs-602px {
  width: 602px !important;
}

.w-xs-603px {
  width: 603px !important;
}

.w-xs-604px {
  width: 604px !important;
}

.w-xs-605px {
  width: 605px !important;
}

.w-xs-606px {
  width: 606px !important;
}

.w-xs-607px {
  width: 607px !important;
}

.w-xs-608px {
  width: 608px !important;
}

.w-xs-609px {
  width: 609px !important;
}

.w-xs-610px {
  width: 610px !important;
}

.w-xs-611px {
  width: 611px !important;
}

.w-xs-612px {
  width: 612px !important;
}

.w-xs-613px {
  width: 613px !important;
}

.w-xs-614px {
  width: 614px !important;
}

.w-xs-615px {
  width: 615px !important;
}

.w-xs-616px {
  width: 616px !important;
}

.w-xs-617px {
  width: 617px !important;
}

.w-xs-618px {
  width: 618px !important;
}

.w-xs-619px {
  width: 619px !important;
}

.w-xs-620px {
  width: 620px !important;
}

.w-xs-621px {
  width: 621px !important;
}

.w-xs-622px {
  width: 622px !important;
}

.w-xs-623px {
  width: 623px !important;
}

.w-xs-624px {
  width: 624px !important;
}

.w-xs-625px {
  width: 625px !important;
}

.w-xs-626px {
  width: 626px !important;
}

.w-xs-627px {
  width: 627px !important;
}

.w-xs-628px {
  width: 628px !important;
}

.w-xs-629px {
  width: 629px !important;
}

.w-xs-630px {
  width: 630px !important;
}

.w-xs-631px {
  width: 631px !important;
}

.w-xs-632px {
  width: 632px !important;
}

.w-xs-633px {
  width: 633px !important;
}

.w-xs-634px {
  width: 634px !important;
}

.w-xs-635px {
  width: 635px !important;
}

.w-xs-636px {
  width: 636px !important;
}

.w-xs-637px {
  width: 637px !important;
}

.w-xs-638px {
  width: 638px !important;
}

.w-xs-639px {
  width: 639px !important;
}

.w-xs-640px {
  width: 640px !important;
}

.w-xs-641px {
  width: 641px !important;
}

.w-xs-642px {
  width: 642px !important;
}

.w-xs-643px {
  width: 643px !important;
}

.w-xs-644px {
  width: 644px !important;
}

.w-xs-645px {
  width: 645px !important;
}

.w-xs-646px {
  width: 646px !important;
}

.w-xs-647px {
  width: 647px !important;
}

.w-xs-648px {
  width: 648px !important;
}

.w-xs-649px {
  width: 649px !important;
}

.w-xs-650px {
  width: 650px !important;
}

.w-xs-651px {
  width: 651px !important;
}

.w-xs-652px {
  width: 652px !important;
}

.w-xs-653px {
  width: 653px !important;
}

.w-xs-654px {
  width: 654px !important;
}

.w-xs-655px {
  width: 655px !important;
}

.w-xs-656px {
  width: 656px !important;
}

.w-xs-657px {
  width: 657px !important;
}

.w-xs-658px {
  width: 658px !important;
}

.w-xs-659px {
  width: 659px !important;
}

.w-xs-660px {
  width: 660px !important;
}

.w-xs-661px {
  width: 661px !important;
}

.w-xs-662px {
  width: 662px !important;
}

.w-xs-663px {
  width: 663px !important;
}

.w-xs-664px {
  width: 664px !important;
}

.w-xs-665px {
  width: 665px !important;
}

.w-xs-666px {
  width: 666px !important;
}

.w-xs-667px {
  width: 667px !important;
}

.w-xs-668px {
  width: 668px !important;
}

.w-xs-669px {
  width: 669px !important;
}

.w-xs-670px {
  width: 670px !important;
}

.w-xs-671px {
  width: 671px !important;
}

.w-xs-672px {
  width: 672px !important;
}

.w-xs-673px {
  width: 673px !important;
}

.w-xs-674px {
  width: 674px !important;
}

.w-xs-675px {
  width: 675px !important;
}

.w-xs-676px {
  width: 676px !important;
}

.w-xs-677px {
  width: 677px !important;
}

.w-xs-678px {
  width: 678px !important;
}

.w-xs-679px {
  width: 679px !important;
}

.w-xs-680px {
  width: 680px !important;
}

.w-xs-681px {
  width: 681px !important;
}

.w-xs-682px {
  width: 682px !important;
}

.w-xs-683px {
  width: 683px !important;
}

.w-xs-684px {
  width: 684px !important;
}

.w-xs-685px {
  width: 685px !important;
}

.w-xs-686px {
  width: 686px !important;
}

.w-xs-687px {
  width: 687px !important;
}

.w-xs-688px {
  width: 688px !important;
}

.w-xs-689px {
  width: 689px !important;
}

.w-xs-690px {
  width: 690px !important;
}

.w-xs-691px {
  width: 691px !important;
}

.w-xs-692px {
  width: 692px !important;
}

.w-xs-693px {
  width: 693px !important;
}

.w-xs-694px {
  width: 694px !important;
}

.w-xs-695px {
  width: 695px !important;
}

.w-xs-696px {
  width: 696px !important;
}

.w-xs-697px {
  width: 697px !important;
}

.w-xs-698px {
  width: 698px !important;
}

.w-xs-699px {
  width: 699px !important;
}

.w-xs-700px {
  width: 700px !important;
}

.w-xs-701px {
  width: 701px !important;
}

.w-xs-702px {
  width: 702px !important;
}

.w-xs-703px {
  width: 703px !important;
}

.w-xs-704px {
  width: 704px !important;
}

.w-xs-705px {
  width: 705px !important;
}

.w-xs-706px {
  width: 706px !important;
}

.w-xs-707px {
  width: 707px !important;
}

.w-xs-708px {
  width: 708px !important;
}

.w-xs-709px {
  width: 709px !important;
}

.w-xs-710px {
  width: 710px !important;
}

.w-xs-711px {
  width: 711px !important;
}

.w-xs-712px {
  width: 712px !important;
}

.w-xs-713px {
  width: 713px !important;
}

.w-xs-714px {
  width: 714px !important;
}

.w-xs-715px {
  width: 715px !important;
}

.w-xs-716px {
  width: 716px !important;
}

.w-xs-717px {
  width: 717px !important;
}

.w-xs-718px {
  width: 718px !important;
}

.w-xs-719px {
  width: 719px !important;
}

.w-xs-720px {
  width: 720px !important;
}

.w-xs-721px {
  width: 721px !important;
}

.w-xs-722px {
  width: 722px !important;
}

.w-xs-723px {
  width: 723px !important;
}

.w-xs-724px {
  width: 724px !important;
}

.w-xs-725px {
  width: 725px !important;
}

.w-xs-726px {
  width: 726px !important;
}

.w-xs-727px {
  width: 727px !important;
}

.w-xs-728px {
  width: 728px !important;
}

.w-xs-729px {
  width: 729px !important;
}

.w-xs-730px {
  width: 730px !important;
}

.w-xs-731px {
  width: 731px !important;
}

.w-xs-732px {
  width: 732px !important;
}

.w-xs-733px {
  width: 733px !important;
}

.w-xs-734px {
  width: 734px !important;
}

.w-xs-735px {
  width: 735px !important;
}

.w-xs-736px {
  width: 736px !important;
}

.w-xs-737px {
  width: 737px !important;
}

.w-xs-738px {
  width: 738px !important;
}

.w-xs-739px {
  width: 739px !important;
}

.w-xs-740px {
  width: 740px !important;
}

.w-xs-741px {
  width: 741px !important;
}

.w-xs-742px {
  width: 742px !important;
}

.w-xs-743px {
  width: 743px !important;
}

.w-xs-744px {
  width: 744px !important;
}

.w-xs-745px {
  width: 745px !important;
}

.w-xs-746px {
  width: 746px !important;
}

.w-xs-747px {
  width: 747px !important;
}

.w-xs-748px {
  width: 748px !important;
}

.w-xs-749px {
  width: 749px !important;
}

.w-xs-750px {
  width: 750px !important;
}

.w-xs-751px {
  width: 751px !important;
}

.w-xs-752px {
  width: 752px !important;
}

.w-xs-753px {
  width: 753px !important;
}

.w-xs-754px {
  width: 754px !important;
}

.w-xs-755px {
  width: 755px !important;
}

.w-xs-756px {
  width: 756px !important;
}

.w-xs-757px {
  width: 757px !important;
}

.w-xs-758px {
  width: 758px !important;
}

.w-xs-759px {
  width: 759px !important;
}

.w-xs-760px {
  width: 760px !important;
}

.w-xs-761px {
  width: 761px !important;
}

.w-xs-762px {
  width: 762px !important;
}

.w-xs-763px {
  width: 763px !important;
}

.w-xs-764px {
  width: 764px !important;
}

.w-xs-765px {
  width: 765px !important;
}

.w-xs-766px {
  width: 766px !important;
}

.w-xs-767px {
  width: 767px !important;
}

.w-xs-768px {
  width: 768px !important;
}

.w-xs-769px {
  width: 769px !important;
}

.w-xs-770px {
  width: 770px !important;
}

.w-xs-771px {
  width: 771px !important;
}

.w-xs-772px {
  width: 772px !important;
}

.w-xs-773px {
  width: 773px !important;
}

.w-xs-774px {
  width: 774px !important;
}

.w-xs-775px {
  width: 775px !important;
}

.w-xs-776px {
  width: 776px !important;
}

.w-xs-777px {
  width: 777px !important;
}

.w-xs-778px {
  width: 778px !important;
}

.w-xs-779px {
  width: 779px !important;
}

.w-xs-780px {
  width: 780px !important;
}

.w-xs-781px {
  width: 781px !important;
}

.w-xs-782px {
  width: 782px !important;
}

.w-xs-783px {
  width: 783px !important;
}

.w-xs-784px {
  width: 784px !important;
}

.w-xs-785px {
  width: 785px !important;
}

.w-xs-786px {
  width: 786px !important;
}

.w-xs-787px {
  width: 787px !important;
}

.w-xs-788px {
  width: 788px !important;
}

.w-xs-789px {
  width: 789px !important;
}

.w-xs-790px {
  width: 790px !important;
}

.w-xs-791px {
  width: 791px !important;
}

.w-xs-792px {
  width: 792px !important;
}

.w-xs-793px {
  width: 793px !important;
}

.w-xs-794px {
  width: 794px !important;
}

.w-xs-795px {
  width: 795px !important;
}

.w-xs-796px {
  width: 796px !important;
}

.w-xs-797px {
  width: 797px !important;
}

.w-xs-798px {
  width: 798px !important;
}

.w-xs-799px {
  width: 799px !important;
}

.w-xs-800px {
  width: 800px !important;
}

.w-xs-801px {
  width: 801px !important;
}

.w-xs-802px {
  width: 802px !important;
}

.w-xs-803px {
  width: 803px !important;
}

.w-xs-804px {
  width: 804px !important;
}

.w-xs-805px {
  width: 805px !important;
}

.w-xs-806px {
  width: 806px !important;
}

.w-xs-807px {
  width: 807px !important;
}

.w-xs-808px {
  width: 808px !important;
}

.w-xs-809px {
  width: 809px !important;
}

.w-xs-810px {
  width: 810px !important;
}

.w-xs-811px {
  width: 811px !important;
}

.w-xs-812px {
  width: 812px !important;
}

.w-xs-813px {
  width: 813px !important;
}

.w-xs-814px {
  width: 814px !important;
}

.w-xs-815px {
  width: 815px !important;
}

.w-xs-816px {
  width: 816px !important;
}

.w-xs-817px {
  width: 817px !important;
}

.w-xs-818px {
  width: 818px !important;
}

.w-xs-819px {
  width: 819px !important;
}

.w-xs-820px {
  width: 820px !important;
}

.w-xs-821px {
  width: 821px !important;
}

.w-xs-822px {
  width: 822px !important;
}

.w-xs-823px {
  width: 823px !important;
}

.w-xs-824px {
  width: 824px !important;
}

.w-xs-825px {
  width: 825px !important;
}

.w-xs-826px {
  width: 826px !important;
}

.w-xs-827px {
  width: 827px !important;
}

.w-xs-828px {
  width: 828px !important;
}

.w-xs-829px {
  width: 829px !important;
}

.w-xs-830px {
  width: 830px !important;
}

.w-xs-831px {
  width: 831px !important;
}

.w-xs-832px {
  width: 832px !important;
}

.w-xs-833px {
  width: 833px !important;
}

.w-xs-834px {
  width: 834px !important;
}

.w-xs-835px {
  width: 835px !important;
}

.w-xs-836px {
  width: 836px !important;
}

.w-xs-837px {
  width: 837px !important;
}

.w-xs-838px {
  width: 838px !important;
}

.w-xs-839px {
  width: 839px !important;
}

.w-xs-840px {
  width: 840px !important;
}

.w-xs-841px {
  width: 841px !important;
}

.w-xs-842px {
  width: 842px !important;
}

.w-xs-843px {
  width: 843px !important;
}

.w-xs-844px {
  width: 844px !important;
}

.w-xs-845px {
  width: 845px !important;
}

.w-xs-846px {
  width: 846px !important;
}

.w-xs-847px {
  width: 847px !important;
}

.w-xs-848px {
  width: 848px !important;
}

.w-xs-849px {
  width: 849px !important;
}

.w-xs-850px {
  width: 850px !important;
}

.w-xs-851px {
  width: 851px !important;
}

.w-xs-852px {
  width: 852px !important;
}

.w-xs-853px {
  width: 853px !important;
}

.w-xs-854px {
  width: 854px !important;
}

.w-xs-855px {
  width: 855px !important;
}

.w-xs-856px {
  width: 856px !important;
}

.w-xs-857px {
  width: 857px !important;
}

.w-xs-858px {
  width: 858px !important;
}

.w-xs-859px {
  width: 859px !important;
}

.w-xs-860px {
  width: 860px !important;
}

.w-xs-861px {
  width: 861px !important;
}

.w-xs-862px {
  width: 862px !important;
}

.w-xs-863px {
  width: 863px !important;
}

.w-xs-864px {
  width: 864px !important;
}

.w-xs-865px {
  width: 865px !important;
}

.w-xs-866px {
  width: 866px !important;
}

.w-xs-867px {
  width: 867px !important;
}

.w-xs-868px {
  width: 868px !important;
}

.w-xs-869px {
  width: 869px !important;
}

.w-xs-870px {
  width: 870px !important;
}

.w-xs-871px {
  width: 871px !important;
}

.w-xs-872px {
  width: 872px !important;
}

.w-xs-873px {
  width: 873px !important;
}

.w-xs-874px {
  width: 874px !important;
}

.w-xs-875px {
  width: 875px !important;
}

.w-xs-876px {
  width: 876px !important;
}

.w-xs-877px {
  width: 877px !important;
}

.w-xs-878px {
  width: 878px !important;
}

.w-xs-879px {
  width: 879px !important;
}

.w-xs-880px {
  width: 880px !important;
}

.w-xs-881px {
  width: 881px !important;
}

.w-xs-882px {
  width: 882px !important;
}

.w-xs-883px {
  width: 883px !important;
}

.w-xs-884px {
  width: 884px !important;
}

.w-xs-885px {
  width: 885px !important;
}

.w-xs-886px {
  width: 886px !important;
}

.w-xs-887px {
  width: 887px !important;
}

.w-xs-888px {
  width: 888px !important;
}

.w-xs-889px {
  width: 889px !important;
}

.w-xs-890px {
  width: 890px !important;
}

.w-xs-891px {
  width: 891px !important;
}

.w-xs-892px {
  width: 892px !important;
}

.w-xs-893px {
  width: 893px !important;
}

.w-xs-894px {
  width: 894px !important;
}

.w-xs-895px {
  width: 895px !important;
}

.w-xs-896px {
  width: 896px !important;
}

.w-xs-897px {
  width: 897px !important;
}

.w-xs-898px {
  width: 898px !important;
}

.w-xs-899px {
  width: 899px !important;
}

.w-xs-900px {
  width: 900px !important;
}

.w-xs-901px {
  width: 901px !important;
}

.w-xs-902px {
  width: 902px !important;
}

.w-xs-903px {
  width: 903px !important;
}

.w-xs-904px {
  width: 904px !important;
}

.w-xs-905px {
  width: 905px !important;
}

.w-xs-906px {
  width: 906px !important;
}

.w-xs-907px {
  width: 907px !important;
}

.w-xs-908px {
  width: 908px !important;
}

.w-xs-909px {
  width: 909px !important;
}

.w-xs-910px {
  width: 910px !important;
}

.w-xs-911px {
  width: 911px !important;
}

.w-xs-912px {
  width: 912px !important;
}

.w-xs-913px {
  width: 913px !important;
}

.w-xs-914px {
  width: 914px !important;
}

.w-xs-915px {
  width: 915px !important;
}

.w-xs-916px {
  width: 916px !important;
}

.w-xs-917px {
  width: 917px !important;
}

.w-xs-918px {
  width: 918px !important;
}

.w-xs-919px {
  width: 919px !important;
}

.w-xs-920px {
  width: 920px !important;
}

.w-xs-921px {
  width: 921px !important;
}

.w-xs-922px {
  width: 922px !important;
}

.w-xs-923px {
  width: 923px !important;
}

.w-xs-924px {
  width: 924px !important;
}

.w-xs-925px {
  width: 925px !important;
}

.w-xs-926px {
  width: 926px !important;
}

.w-xs-927px {
  width: 927px !important;
}

.w-xs-928px {
  width: 928px !important;
}

.w-xs-929px {
  width: 929px !important;
}

.w-xs-930px {
  width: 930px !important;
}

.w-xs-931px {
  width: 931px !important;
}

.w-xs-932px {
  width: 932px !important;
}

.w-xs-933px {
  width: 933px !important;
}

.w-xs-934px {
  width: 934px !important;
}

.w-xs-935px {
  width: 935px !important;
}

.w-xs-936px {
  width: 936px !important;
}

.w-xs-937px {
  width: 937px !important;
}

.w-xs-938px {
  width: 938px !important;
}

.w-xs-939px {
  width: 939px !important;
}

.w-xs-940px {
  width: 940px !important;
}

.w-xs-941px {
  width: 941px !important;
}

.w-xs-942px {
  width: 942px !important;
}

.w-xs-943px {
  width: 943px !important;
}

.w-xs-944px {
  width: 944px !important;
}

.w-xs-945px {
  width: 945px !important;
}

.w-xs-946px {
  width: 946px !important;
}

.w-xs-947px {
  width: 947px !important;
}

.w-xs-948px {
  width: 948px !important;
}

.w-xs-949px {
  width: 949px !important;
}

.w-xs-950px {
  width: 950px !important;
}

.w-xs-951px {
  width: 951px !important;
}

.w-xs-952px {
  width: 952px !important;
}

.w-xs-953px {
  width: 953px !important;
}

.w-xs-954px {
  width: 954px !important;
}

.w-xs-955px {
  width: 955px !important;
}

.w-xs-956px {
  width: 956px !important;
}

.w-xs-957px {
  width: 957px !important;
}

.w-xs-958px {
  width: 958px !important;
}

.w-xs-959px {
  width: 959px !important;
}

.w-xs-960px {
  width: 960px !important;
}

.w-xs-961px {
  width: 961px !important;
}

.w-xs-962px {
  width: 962px !important;
}

.w-xs-963px {
  width: 963px !important;
}

.w-xs-964px {
  width: 964px !important;
}

.w-xs-965px {
  width: 965px !important;
}

.w-xs-966px {
  width: 966px !important;
}

.w-xs-967px {
  width: 967px !important;
}

.w-xs-968px {
  width: 968px !important;
}

.w-xs-969px {
  width: 969px !important;
}

.w-xs-970px {
  width: 970px !important;
}

.w-xs-971px {
  width: 971px !important;
}

.w-xs-972px {
  width: 972px !important;
}

.w-xs-973px {
  width: 973px !important;
}

.w-xs-974px {
  width: 974px !important;
}

.w-xs-975px {
  width: 975px !important;
}

.w-xs-976px {
  width: 976px !important;
}

.w-xs-977px {
  width: 977px !important;
}

.w-xs-978px {
  width: 978px !important;
}

.w-xs-979px {
  width: 979px !important;
}

.w-xs-980px {
  width: 980px !important;
}

.w-xs-981px {
  width: 981px !important;
}

.w-xs-982px {
  width: 982px !important;
}

.w-xs-983px {
  width: 983px !important;
}

.w-xs-984px {
  width: 984px !important;
}

.w-xs-985px {
  width: 985px !important;
}

.w-xs-986px {
  width: 986px !important;
}

.w-xs-987px {
  width: 987px !important;
}

.w-xs-988px {
  width: 988px !important;
}

.w-xs-989px {
  width: 989px !important;
}

.w-xs-990px {
  width: 990px !important;
}

.w-xs-991px {
  width: 991px !important;
}

.w-xs-992px {
  width: 992px !important;
}

.w-xs-993px {
  width: 993px !important;
}

.w-xs-994px {
  width: 994px !important;
}

.w-xs-995px {
  width: 995px !important;
}

.w-xs-996px {
  width: 996px !important;
}

.w-xs-997px {
  width: 997px !important;
}

.w-xs-998px {
  width: 998px !important;
}

.w-xs-999px {
  width: 999px !important;
}

.w-xs-1000px {
  width: 1000px !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-0px {
    width: 0px !important;
  }
  .w-sm-1px {
    width: 1px !important;
  }
  .w-sm-2px {
    width: 2px !important;
  }
  .w-sm-3px {
    width: 3px !important;
  }
  .w-sm-4px {
    width: 4px !important;
  }
  .w-sm-5px {
    width: 5px !important;
  }
  .w-sm-6px {
    width: 6px !important;
  }
  .w-sm-7px {
    width: 7px !important;
  }
  .w-sm-8px {
    width: 8px !important;
  }
  .w-sm-9px {
    width: 9px !important;
  }
  .w-sm-10px {
    width: 10px !important;
  }
  .w-sm-11px {
    width: 11px !important;
  }
  .w-sm-12px {
    width: 12px !important;
  }
  .w-sm-13px {
    width: 13px !important;
  }
  .w-sm-14px {
    width: 14px !important;
  }
  .w-sm-15px {
    width: 15px !important;
  }
  .w-sm-16px {
    width: 16px !important;
  }
  .w-sm-17px {
    width: 17px !important;
  }
  .w-sm-18px {
    width: 18px !important;
  }
  .w-sm-19px {
    width: 19px !important;
  }
  .w-sm-20px {
    width: 20px !important;
  }
  .w-sm-21px {
    width: 21px !important;
  }
  .w-sm-22px {
    width: 22px !important;
  }
  .w-sm-23px {
    width: 23px !important;
  }
  .w-sm-24px {
    width: 24px !important;
  }
  .w-sm-25px {
    width: 25px !important;
  }
  .w-sm-26px {
    width: 26px !important;
  }
  .w-sm-27px {
    width: 27px !important;
  }
  .w-sm-28px {
    width: 28px !important;
  }
  .w-sm-29px {
    width: 29px !important;
  }
  .w-sm-30px {
    width: 30px !important;
  }
  .w-sm-31px {
    width: 31px !important;
  }
  .w-sm-32px {
    width: 32px !important;
  }
  .w-sm-33px {
    width: 33px !important;
  }
  .w-sm-34px {
    width: 34px !important;
  }
  .w-sm-35px {
    width: 35px !important;
  }
  .w-sm-36px {
    width: 36px !important;
  }
  .w-sm-37px {
    width: 37px !important;
  }
  .w-sm-38px {
    width: 38px !important;
  }
  .w-sm-39px {
    width: 39px !important;
  }
  .w-sm-40px {
    width: 40px !important;
  }
  .w-sm-41px {
    width: 41px !important;
  }
  .w-sm-42px {
    width: 42px !important;
  }
  .w-sm-43px {
    width: 43px !important;
  }
  .w-sm-44px {
    width: 44px !important;
  }
  .w-sm-45px {
    width: 45px !important;
  }
  .w-sm-46px {
    width: 46px !important;
  }
  .w-sm-47px {
    width: 47px !important;
  }
  .w-sm-48px {
    width: 48px !important;
  }
  .w-sm-49px {
    width: 49px !important;
  }
  .w-sm-50px {
    width: 50px !important;
  }
  .w-sm-51px {
    width: 51px !important;
  }
  .w-sm-52px {
    width: 52px !important;
  }
  .w-sm-53px {
    width: 53px !important;
  }
  .w-sm-54px {
    width: 54px !important;
  }
  .w-sm-55px {
    width: 55px !important;
  }
  .w-sm-56px {
    width: 56px !important;
  }
  .w-sm-57px {
    width: 57px !important;
  }
  .w-sm-58px {
    width: 58px !important;
  }
  .w-sm-59px {
    width: 59px !important;
  }
  .w-sm-60px {
    width: 60px !important;
  }
  .w-sm-61px {
    width: 61px !important;
  }
  .w-sm-62px {
    width: 62px !important;
  }
  .w-sm-63px {
    width: 63px !important;
  }
  .w-sm-64px {
    width: 64px !important;
  }
  .w-sm-65px {
    width: 65px !important;
  }
  .w-sm-66px {
    width: 66px !important;
  }
  .w-sm-67px {
    width: 67px !important;
  }
  .w-sm-68px {
    width: 68px !important;
  }
  .w-sm-69px {
    width: 69px !important;
  }
  .w-sm-70px {
    width: 70px !important;
  }
  .w-sm-71px {
    width: 71px !important;
  }
  .w-sm-72px {
    width: 72px !important;
  }
  .w-sm-73px {
    width: 73px !important;
  }
  .w-sm-74px {
    width: 74px !important;
  }
  .w-sm-75px {
    width: 75px !important;
  }
  .w-sm-76px {
    width: 76px !important;
  }
  .w-sm-77px {
    width: 77px !important;
  }
  .w-sm-78px {
    width: 78px !important;
  }
  .w-sm-79px {
    width: 79px !important;
  }
  .w-sm-80px {
    width: 80px !important;
  }
  .w-sm-81px {
    width: 81px !important;
  }
  .w-sm-82px {
    width: 82px !important;
  }
  .w-sm-83px {
    width: 83px !important;
  }
  .w-sm-84px {
    width: 84px !important;
  }
  .w-sm-85px {
    width: 85px !important;
  }
  .w-sm-86px {
    width: 86px !important;
  }
  .w-sm-87px {
    width: 87px !important;
  }
  .w-sm-88px {
    width: 88px !important;
  }
  .w-sm-89px {
    width: 89px !important;
  }
  .w-sm-90px {
    width: 90px !important;
  }
  .w-sm-91px {
    width: 91px !important;
  }
  .w-sm-92px {
    width: 92px !important;
  }
  .w-sm-93px {
    width: 93px !important;
  }
  .w-sm-94px {
    width: 94px !important;
  }
  .w-sm-95px {
    width: 95px !important;
  }
  .w-sm-96px {
    width: 96px !important;
  }
  .w-sm-97px {
    width: 97px !important;
  }
  .w-sm-98px {
    width: 98px !important;
  }
  .w-sm-99px {
    width: 99px !important;
  }
  .w-sm-100px {
    width: 100px !important;
  }
  .w-sm-101px {
    width: 101px !important;
  }
  .w-sm-102px {
    width: 102px !important;
  }
  .w-sm-103px {
    width: 103px !important;
  }
  .w-sm-104px {
    width: 104px !important;
  }
  .w-sm-105px {
    width: 105px !important;
  }
  .w-sm-106px {
    width: 106px !important;
  }
  .w-sm-107px {
    width: 107px !important;
  }
  .w-sm-108px {
    width: 108px !important;
  }
  .w-sm-109px {
    width: 109px !important;
  }
  .w-sm-110px {
    width: 110px !important;
  }
  .w-sm-111px {
    width: 111px !important;
  }
  .w-sm-112px {
    width: 112px !important;
  }
  .w-sm-113px {
    width: 113px !important;
  }
  .w-sm-114px {
    width: 114px !important;
  }
  .w-sm-115px {
    width: 115px !important;
  }
  .w-sm-116px {
    width: 116px !important;
  }
  .w-sm-117px {
    width: 117px !important;
  }
  .w-sm-118px {
    width: 118px !important;
  }
  .w-sm-119px {
    width: 119px !important;
  }
  .w-sm-120px {
    width: 120px !important;
  }
  .w-sm-121px {
    width: 121px !important;
  }
  .w-sm-122px {
    width: 122px !important;
  }
  .w-sm-123px {
    width: 123px !important;
  }
  .w-sm-124px {
    width: 124px !important;
  }
  .w-sm-125px {
    width: 125px !important;
  }
  .w-sm-126px {
    width: 126px !important;
  }
  .w-sm-127px {
    width: 127px !important;
  }
  .w-sm-128px {
    width: 128px !important;
  }
  .w-sm-129px {
    width: 129px !important;
  }
  .w-sm-130px {
    width: 130px !important;
  }
  .w-sm-131px {
    width: 131px !important;
  }
  .w-sm-132px {
    width: 132px !important;
  }
  .w-sm-133px {
    width: 133px !important;
  }
  .w-sm-134px {
    width: 134px !important;
  }
  .w-sm-135px {
    width: 135px !important;
  }
  .w-sm-136px {
    width: 136px !important;
  }
  .w-sm-137px {
    width: 137px !important;
  }
  .w-sm-138px {
    width: 138px !important;
  }
  .w-sm-139px {
    width: 139px !important;
  }
  .w-sm-140px {
    width: 140px !important;
  }
  .w-sm-141px {
    width: 141px !important;
  }
  .w-sm-142px {
    width: 142px !important;
  }
  .w-sm-143px {
    width: 143px !important;
  }
  .w-sm-144px {
    width: 144px !important;
  }
  .w-sm-145px {
    width: 145px !important;
  }
  .w-sm-146px {
    width: 146px !important;
  }
  .w-sm-147px {
    width: 147px !important;
  }
  .w-sm-148px {
    width: 148px !important;
  }
  .w-sm-149px {
    width: 149px !important;
  }
  .w-sm-150px {
    width: 150px !important;
  }
  .w-sm-151px {
    width: 151px !important;
  }
  .w-sm-152px {
    width: 152px !important;
  }
  .w-sm-153px {
    width: 153px !important;
  }
  .w-sm-154px {
    width: 154px !important;
  }
  .w-sm-155px {
    width: 155px !important;
  }
  .w-sm-156px {
    width: 156px !important;
  }
  .w-sm-157px {
    width: 157px !important;
  }
  .w-sm-158px {
    width: 158px !important;
  }
  .w-sm-159px {
    width: 159px !important;
  }
  .w-sm-160px {
    width: 160px !important;
  }
  .w-sm-161px {
    width: 161px !important;
  }
  .w-sm-162px {
    width: 162px !important;
  }
  .w-sm-163px {
    width: 163px !important;
  }
  .w-sm-164px {
    width: 164px !important;
  }
  .w-sm-165px {
    width: 165px !important;
  }
  .w-sm-166px {
    width: 166px !important;
  }
  .w-sm-167px {
    width: 167px !important;
  }
  .w-sm-168px {
    width: 168px !important;
  }
  .w-sm-169px {
    width: 169px !important;
  }
  .w-sm-170px {
    width: 170px !important;
  }
  .w-sm-171px {
    width: 171px !important;
  }
  .w-sm-172px {
    width: 172px !important;
  }
  .w-sm-173px {
    width: 173px !important;
  }
  .w-sm-174px {
    width: 174px !important;
  }
  .w-sm-175px {
    width: 175px !important;
  }
  .w-sm-176px {
    width: 176px !important;
  }
  .w-sm-177px {
    width: 177px !important;
  }
  .w-sm-178px {
    width: 178px !important;
  }
  .w-sm-179px {
    width: 179px !important;
  }
  .w-sm-180px {
    width: 180px !important;
  }
  .w-sm-181px {
    width: 181px !important;
  }
  .w-sm-182px {
    width: 182px !important;
  }
  .w-sm-183px {
    width: 183px !important;
  }
  .w-sm-184px {
    width: 184px !important;
  }
  .w-sm-185px {
    width: 185px !important;
  }
  .w-sm-186px {
    width: 186px !important;
  }
  .w-sm-187px {
    width: 187px !important;
  }
  .w-sm-188px {
    width: 188px !important;
  }
  .w-sm-189px {
    width: 189px !important;
  }
  .w-sm-190px {
    width: 190px !important;
  }
  .w-sm-191px {
    width: 191px !important;
  }
  .w-sm-192px {
    width: 192px !important;
  }
  .w-sm-193px {
    width: 193px !important;
  }
  .w-sm-194px {
    width: 194px !important;
  }
  .w-sm-195px {
    width: 195px !important;
  }
  .w-sm-196px {
    width: 196px !important;
  }
  .w-sm-197px {
    width: 197px !important;
  }
  .w-sm-198px {
    width: 198px !important;
  }
  .w-sm-199px {
    width: 199px !important;
  }
  .w-sm-200px {
    width: 200px !important;
  }
  .w-sm-201px {
    width: 201px !important;
  }
  .w-sm-202px {
    width: 202px !important;
  }
  .w-sm-203px {
    width: 203px !important;
  }
  .w-sm-204px {
    width: 204px !important;
  }
  .w-sm-205px {
    width: 205px !important;
  }
  .w-sm-206px {
    width: 206px !important;
  }
  .w-sm-207px {
    width: 207px !important;
  }
  .w-sm-208px {
    width: 208px !important;
  }
  .w-sm-209px {
    width: 209px !important;
  }
  .w-sm-210px {
    width: 210px !important;
  }
  .w-sm-211px {
    width: 211px !important;
  }
  .w-sm-212px {
    width: 212px !important;
  }
  .w-sm-213px {
    width: 213px !important;
  }
  .w-sm-214px {
    width: 214px !important;
  }
  .w-sm-215px {
    width: 215px !important;
  }
  .w-sm-216px {
    width: 216px !important;
  }
  .w-sm-217px {
    width: 217px !important;
  }
  .w-sm-218px {
    width: 218px !important;
  }
  .w-sm-219px {
    width: 219px !important;
  }
  .w-sm-220px {
    width: 220px !important;
  }
  .w-sm-221px {
    width: 221px !important;
  }
  .w-sm-222px {
    width: 222px !important;
  }
  .w-sm-223px {
    width: 223px !important;
  }
  .w-sm-224px {
    width: 224px !important;
  }
  .w-sm-225px {
    width: 225px !important;
  }
  .w-sm-226px {
    width: 226px !important;
  }
  .w-sm-227px {
    width: 227px !important;
  }
  .w-sm-228px {
    width: 228px !important;
  }
  .w-sm-229px {
    width: 229px !important;
  }
  .w-sm-230px {
    width: 230px !important;
  }
  .w-sm-231px {
    width: 231px !important;
  }
  .w-sm-232px {
    width: 232px !important;
  }
  .w-sm-233px {
    width: 233px !important;
  }
  .w-sm-234px {
    width: 234px !important;
  }
  .w-sm-235px {
    width: 235px !important;
  }
  .w-sm-236px {
    width: 236px !important;
  }
  .w-sm-237px {
    width: 237px !important;
  }
  .w-sm-238px {
    width: 238px !important;
  }
  .w-sm-239px {
    width: 239px !important;
  }
  .w-sm-240px {
    width: 240px !important;
  }
  .w-sm-241px {
    width: 241px !important;
  }
  .w-sm-242px {
    width: 242px !important;
  }
  .w-sm-243px {
    width: 243px !important;
  }
  .w-sm-244px {
    width: 244px !important;
  }
  .w-sm-245px {
    width: 245px !important;
  }
  .w-sm-246px {
    width: 246px !important;
  }
  .w-sm-247px {
    width: 247px !important;
  }
  .w-sm-248px {
    width: 248px !important;
  }
  .w-sm-249px {
    width: 249px !important;
  }
  .w-sm-250px {
    width: 250px !important;
  }
  .w-sm-251px {
    width: 251px !important;
  }
  .w-sm-252px {
    width: 252px !important;
  }
  .w-sm-253px {
    width: 253px !important;
  }
  .w-sm-254px {
    width: 254px !important;
  }
  .w-sm-255px {
    width: 255px !important;
  }
  .w-sm-256px {
    width: 256px !important;
  }
  .w-sm-257px {
    width: 257px !important;
  }
  .w-sm-258px {
    width: 258px !important;
  }
  .w-sm-259px {
    width: 259px !important;
  }
  .w-sm-260px {
    width: 260px !important;
  }
  .w-sm-261px {
    width: 261px !important;
  }
  .w-sm-262px {
    width: 262px !important;
  }
  .w-sm-263px {
    width: 263px !important;
  }
  .w-sm-264px {
    width: 264px !important;
  }
  .w-sm-265px {
    width: 265px !important;
  }
  .w-sm-266px {
    width: 266px !important;
  }
  .w-sm-267px {
    width: 267px !important;
  }
  .w-sm-268px {
    width: 268px !important;
  }
  .w-sm-269px {
    width: 269px !important;
  }
  .w-sm-270px {
    width: 270px !important;
  }
  .w-sm-271px {
    width: 271px !important;
  }
  .w-sm-272px {
    width: 272px !important;
  }
  .w-sm-273px {
    width: 273px !important;
  }
  .w-sm-274px {
    width: 274px !important;
  }
  .w-sm-275px {
    width: 275px !important;
  }
  .w-sm-276px {
    width: 276px !important;
  }
  .w-sm-277px {
    width: 277px !important;
  }
  .w-sm-278px {
    width: 278px !important;
  }
  .w-sm-279px {
    width: 279px !important;
  }
  .w-sm-280px {
    width: 280px !important;
  }
  .w-sm-281px {
    width: 281px !important;
  }
  .w-sm-282px {
    width: 282px !important;
  }
  .w-sm-283px {
    width: 283px !important;
  }
  .w-sm-284px {
    width: 284px !important;
  }
  .w-sm-285px {
    width: 285px !important;
  }
  .w-sm-286px {
    width: 286px !important;
  }
  .w-sm-287px {
    width: 287px !important;
  }
  .w-sm-288px {
    width: 288px !important;
  }
  .w-sm-289px {
    width: 289px !important;
  }
  .w-sm-290px {
    width: 290px !important;
  }
  .w-sm-291px {
    width: 291px !important;
  }
  .w-sm-292px {
    width: 292px !important;
  }
  .w-sm-293px {
    width: 293px !important;
  }
  .w-sm-294px {
    width: 294px !important;
  }
  .w-sm-295px {
    width: 295px !important;
  }
  .w-sm-296px {
    width: 296px !important;
  }
  .w-sm-297px {
    width: 297px !important;
  }
  .w-sm-298px {
    width: 298px !important;
  }
  .w-sm-299px {
    width: 299px !important;
  }
  .w-sm-300px {
    width: 300px !important;
  }
  .w-sm-301px {
    width: 301px !important;
  }
  .w-sm-302px {
    width: 302px !important;
  }
  .w-sm-303px {
    width: 303px !important;
  }
  .w-sm-304px {
    width: 304px !important;
  }
  .w-sm-305px {
    width: 305px !important;
  }
  .w-sm-306px {
    width: 306px !important;
  }
  .w-sm-307px {
    width: 307px !important;
  }
  .w-sm-308px {
    width: 308px !important;
  }
  .w-sm-309px {
    width: 309px !important;
  }
  .w-sm-310px {
    width: 310px !important;
  }
  .w-sm-311px {
    width: 311px !important;
  }
  .w-sm-312px {
    width: 312px !important;
  }
  .w-sm-313px {
    width: 313px !important;
  }
  .w-sm-314px {
    width: 314px !important;
  }
  .w-sm-315px {
    width: 315px !important;
  }
  .w-sm-316px {
    width: 316px !important;
  }
  .w-sm-317px {
    width: 317px !important;
  }
  .w-sm-318px {
    width: 318px !important;
  }
  .w-sm-319px {
    width: 319px !important;
  }
  .w-sm-320px {
    width: 320px !important;
  }
  .w-sm-321px {
    width: 321px !important;
  }
  .w-sm-322px {
    width: 322px !important;
  }
  .w-sm-323px {
    width: 323px !important;
  }
  .w-sm-324px {
    width: 324px !important;
  }
  .w-sm-325px {
    width: 325px !important;
  }
  .w-sm-326px {
    width: 326px !important;
  }
  .w-sm-327px {
    width: 327px !important;
  }
  .w-sm-328px {
    width: 328px !important;
  }
  .w-sm-329px {
    width: 329px !important;
  }
  .w-sm-330px {
    width: 330px !important;
  }
  .w-sm-331px {
    width: 331px !important;
  }
  .w-sm-332px {
    width: 332px !important;
  }
  .w-sm-333px {
    width: 333px !important;
  }
  .w-sm-334px {
    width: 334px !important;
  }
  .w-sm-335px {
    width: 335px !important;
  }
  .w-sm-336px {
    width: 336px !important;
  }
  .w-sm-337px {
    width: 337px !important;
  }
  .w-sm-338px {
    width: 338px !important;
  }
  .w-sm-339px {
    width: 339px !important;
  }
  .w-sm-340px {
    width: 340px !important;
  }
  .w-sm-341px {
    width: 341px !important;
  }
  .w-sm-342px {
    width: 342px !important;
  }
  .w-sm-343px {
    width: 343px !important;
  }
  .w-sm-344px {
    width: 344px !important;
  }
  .w-sm-345px {
    width: 345px !important;
  }
  .w-sm-346px {
    width: 346px !important;
  }
  .w-sm-347px {
    width: 347px !important;
  }
  .w-sm-348px {
    width: 348px !important;
  }
  .w-sm-349px {
    width: 349px !important;
  }
  .w-sm-350px {
    width: 350px !important;
  }
  .w-sm-351px {
    width: 351px !important;
  }
  .w-sm-352px {
    width: 352px !important;
  }
  .w-sm-353px {
    width: 353px !important;
  }
  .w-sm-354px {
    width: 354px !important;
  }
  .w-sm-355px {
    width: 355px !important;
  }
  .w-sm-356px {
    width: 356px !important;
  }
  .w-sm-357px {
    width: 357px !important;
  }
  .w-sm-358px {
    width: 358px !important;
  }
  .w-sm-359px {
    width: 359px !important;
  }
  .w-sm-360px {
    width: 360px !important;
  }
  .w-sm-361px {
    width: 361px !important;
  }
  .w-sm-362px {
    width: 362px !important;
  }
  .w-sm-363px {
    width: 363px !important;
  }
  .w-sm-364px {
    width: 364px !important;
  }
  .w-sm-365px {
    width: 365px !important;
  }
  .w-sm-366px {
    width: 366px !important;
  }
  .w-sm-367px {
    width: 367px !important;
  }
  .w-sm-368px {
    width: 368px !important;
  }
  .w-sm-369px {
    width: 369px !important;
  }
  .w-sm-370px {
    width: 370px !important;
  }
  .w-sm-371px {
    width: 371px !important;
  }
  .w-sm-372px {
    width: 372px !important;
  }
  .w-sm-373px {
    width: 373px !important;
  }
  .w-sm-374px {
    width: 374px !important;
  }
  .w-sm-375px {
    width: 375px !important;
  }
  .w-sm-376px {
    width: 376px !important;
  }
  .w-sm-377px {
    width: 377px !important;
  }
  .w-sm-378px {
    width: 378px !important;
  }
  .w-sm-379px {
    width: 379px !important;
  }
  .w-sm-380px {
    width: 380px !important;
  }
  .w-sm-381px {
    width: 381px !important;
  }
  .w-sm-382px {
    width: 382px !important;
  }
  .w-sm-383px {
    width: 383px !important;
  }
  .w-sm-384px {
    width: 384px !important;
  }
  .w-sm-385px {
    width: 385px !important;
  }
  .w-sm-386px {
    width: 386px !important;
  }
  .w-sm-387px {
    width: 387px !important;
  }
  .w-sm-388px {
    width: 388px !important;
  }
  .w-sm-389px {
    width: 389px !important;
  }
  .w-sm-390px {
    width: 390px !important;
  }
  .w-sm-391px {
    width: 391px !important;
  }
  .w-sm-392px {
    width: 392px !important;
  }
  .w-sm-393px {
    width: 393px !important;
  }
  .w-sm-394px {
    width: 394px !important;
  }
  .w-sm-395px {
    width: 395px !important;
  }
  .w-sm-396px {
    width: 396px !important;
  }
  .w-sm-397px {
    width: 397px !important;
  }
  .w-sm-398px {
    width: 398px !important;
  }
  .w-sm-399px {
    width: 399px !important;
  }
  .w-sm-400px {
    width: 400px !important;
  }
  .w-sm-401px {
    width: 401px !important;
  }
  .w-sm-402px {
    width: 402px !important;
  }
  .w-sm-403px {
    width: 403px !important;
  }
  .w-sm-404px {
    width: 404px !important;
  }
  .w-sm-405px {
    width: 405px !important;
  }
  .w-sm-406px {
    width: 406px !important;
  }
  .w-sm-407px {
    width: 407px !important;
  }
  .w-sm-408px {
    width: 408px !important;
  }
  .w-sm-409px {
    width: 409px !important;
  }
  .w-sm-410px {
    width: 410px !important;
  }
  .w-sm-411px {
    width: 411px !important;
  }
  .w-sm-412px {
    width: 412px !important;
  }
  .w-sm-413px {
    width: 413px !important;
  }
  .w-sm-414px {
    width: 414px !important;
  }
  .w-sm-415px {
    width: 415px !important;
  }
  .w-sm-416px {
    width: 416px !important;
  }
  .w-sm-417px {
    width: 417px !important;
  }
  .w-sm-418px {
    width: 418px !important;
  }
  .w-sm-419px {
    width: 419px !important;
  }
  .w-sm-420px {
    width: 420px !important;
  }
  .w-sm-421px {
    width: 421px !important;
  }
  .w-sm-422px {
    width: 422px !important;
  }
  .w-sm-423px {
    width: 423px !important;
  }
  .w-sm-424px {
    width: 424px !important;
  }
  .w-sm-425px {
    width: 425px !important;
  }
  .w-sm-426px {
    width: 426px !important;
  }
  .w-sm-427px {
    width: 427px !important;
  }
  .w-sm-428px {
    width: 428px !important;
  }
  .w-sm-429px {
    width: 429px !important;
  }
  .w-sm-430px {
    width: 430px !important;
  }
  .w-sm-431px {
    width: 431px !important;
  }
  .w-sm-432px {
    width: 432px !important;
  }
  .w-sm-433px {
    width: 433px !important;
  }
  .w-sm-434px {
    width: 434px !important;
  }
  .w-sm-435px {
    width: 435px !important;
  }
  .w-sm-436px {
    width: 436px !important;
  }
  .w-sm-437px {
    width: 437px !important;
  }
  .w-sm-438px {
    width: 438px !important;
  }
  .w-sm-439px {
    width: 439px !important;
  }
  .w-sm-440px {
    width: 440px !important;
  }
  .w-sm-441px {
    width: 441px !important;
  }
  .w-sm-442px {
    width: 442px !important;
  }
  .w-sm-443px {
    width: 443px !important;
  }
  .w-sm-444px {
    width: 444px !important;
  }
  .w-sm-445px {
    width: 445px !important;
  }
  .w-sm-446px {
    width: 446px !important;
  }
  .w-sm-447px {
    width: 447px !important;
  }
  .w-sm-448px {
    width: 448px !important;
  }
  .w-sm-449px {
    width: 449px !important;
  }
  .w-sm-450px {
    width: 450px !important;
  }
  .w-sm-451px {
    width: 451px !important;
  }
  .w-sm-452px {
    width: 452px !important;
  }
  .w-sm-453px {
    width: 453px !important;
  }
  .w-sm-454px {
    width: 454px !important;
  }
  .w-sm-455px {
    width: 455px !important;
  }
  .w-sm-456px {
    width: 456px !important;
  }
  .w-sm-457px {
    width: 457px !important;
  }
  .w-sm-458px {
    width: 458px !important;
  }
  .w-sm-459px {
    width: 459px !important;
  }
  .w-sm-460px {
    width: 460px !important;
  }
  .w-sm-461px {
    width: 461px !important;
  }
  .w-sm-462px {
    width: 462px !important;
  }
  .w-sm-463px {
    width: 463px !important;
  }
  .w-sm-464px {
    width: 464px !important;
  }
  .w-sm-465px {
    width: 465px !important;
  }
  .w-sm-466px {
    width: 466px !important;
  }
  .w-sm-467px {
    width: 467px !important;
  }
  .w-sm-468px {
    width: 468px !important;
  }
  .w-sm-469px {
    width: 469px !important;
  }
  .w-sm-470px {
    width: 470px !important;
  }
  .w-sm-471px {
    width: 471px !important;
  }
  .w-sm-472px {
    width: 472px !important;
  }
  .w-sm-473px {
    width: 473px !important;
  }
  .w-sm-474px {
    width: 474px !important;
  }
  .w-sm-475px {
    width: 475px !important;
  }
  .w-sm-476px {
    width: 476px !important;
  }
  .w-sm-477px {
    width: 477px !important;
  }
  .w-sm-478px {
    width: 478px !important;
  }
  .w-sm-479px {
    width: 479px !important;
  }
  .w-sm-480px {
    width: 480px !important;
  }
  .w-sm-481px {
    width: 481px !important;
  }
  .w-sm-482px {
    width: 482px !important;
  }
  .w-sm-483px {
    width: 483px !important;
  }
  .w-sm-484px {
    width: 484px !important;
  }
  .w-sm-485px {
    width: 485px !important;
  }
  .w-sm-486px {
    width: 486px !important;
  }
  .w-sm-487px {
    width: 487px !important;
  }
  .w-sm-488px {
    width: 488px !important;
  }
  .w-sm-489px {
    width: 489px !important;
  }
  .w-sm-490px {
    width: 490px !important;
  }
  .w-sm-491px {
    width: 491px !important;
  }
  .w-sm-492px {
    width: 492px !important;
  }
  .w-sm-493px {
    width: 493px !important;
  }
  .w-sm-494px {
    width: 494px !important;
  }
  .w-sm-495px {
    width: 495px !important;
  }
  .w-sm-496px {
    width: 496px !important;
  }
  .w-sm-497px {
    width: 497px !important;
  }
  .w-sm-498px {
    width: 498px !important;
  }
  .w-sm-499px {
    width: 499px !important;
  }
  .w-sm-500px {
    width: 500px !important;
  }
  .w-sm-501px {
    width: 501px !important;
  }
  .w-sm-502px {
    width: 502px !important;
  }
  .w-sm-503px {
    width: 503px !important;
  }
  .w-sm-504px {
    width: 504px !important;
  }
  .w-sm-505px {
    width: 505px !important;
  }
  .w-sm-506px {
    width: 506px !important;
  }
  .w-sm-507px {
    width: 507px !important;
  }
  .w-sm-508px {
    width: 508px !important;
  }
  .w-sm-509px {
    width: 509px !important;
  }
  .w-sm-510px {
    width: 510px !important;
  }
  .w-sm-511px {
    width: 511px !important;
  }
  .w-sm-512px {
    width: 512px !important;
  }
  .w-sm-513px {
    width: 513px !important;
  }
  .w-sm-514px {
    width: 514px !important;
  }
  .w-sm-515px {
    width: 515px !important;
  }
  .w-sm-516px {
    width: 516px !important;
  }
  .w-sm-517px {
    width: 517px !important;
  }
  .w-sm-518px {
    width: 518px !important;
  }
  .w-sm-519px {
    width: 519px !important;
  }
  .w-sm-520px {
    width: 520px !important;
  }
  .w-sm-521px {
    width: 521px !important;
  }
  .w-sm-522px {
    width: 522px !important;
  }
  .w-sm-523px {
    width: 523px !important;
  }
  .w-sm-524px {
    width: 524px !important;
  }
  .w-sm-525px {
    width: 525px !important;
  }
  .w-sm-526px {
    width: 526px !important;
  }
  .w-sm-527px {
    width: 527px !important;
  }
  .w-sm-528px {
    width: 528px !important;
  }
  .w-sm-529px {
    width: 529px !important;
  }
  .w-sm-530px {
    width: 530px !important;
  }
  .w-sm-531px {
    width: 531px !important;
  }
  .w-sm-532px {
    width: 532px !important;
  }
  .w-sm-533px {
    width: 533px !important;
  }
  .w-sm-534px {
    width: 534px !important;
  }
  .w-sm-535px {
    width: 535px !important;
  }
  .w-sm-536px {
    width: 536px !important;
  }
  .w-sm-537px {
    width: 537px !important;
  }
  .w-sm-538px {
    width: 538px !important;
  }
  .w-sm-539px {
    width: 539px !important;
  }
  .w-sm-540px {
    width: 540px !important;
  }
  .w-sm-541px {
    width: 541px !important;
  }
  .w-sm-542px {
    width: 542px !important;
  }
  .w-sm-543px {
    width: 543px !important;
  }
  .w-sm-544px {
    width: 544px !important;
  }
  .w-sm-545px {
    width: 545px !important;
  }
  .w-sm-546px {
    width: 546px !important;
  }
  .w-sm-547px {
    width: 547px !important;
  }
  .w-sm-548px {
    width: 548px !important;
  }
  .w-sm-549px {
    width: 549px !important;
  }
  .w-sm-550px {
    width: 550px !important;
  }
  .w-sm-551px {
    width: 551px !important;
  }
  .w-sm-552px {
    width: 552px !important;
  }
  .w-sm-553px {
    width: 553px !important;
  }
  .w-sm-554px {
    width: 554px !important;
  }
  .w-sm-555px {
    width: 555px !important;
  }
  .w-sm-556px {
    width: 556px !important;
  }
  .w-sm-557px {
    width: 557px !important;
  }
  .w-sm-558px {
    width: 558px !important;
  }
  .w-sm-559px {
    width: 559px !important;
  }
  .w-sm-560px {
    width: 560px !important;
  }
  .w-sm-561px {
    width: 561px !important;
  }
  .w-sm-562px {
    width: 562px !important;
  }
  .w-sm-563px {
    width: 563px !important;
  }
  .w-sm-564px {
    width: 564px !important;
  }
  .w-sm-565px {
    width: 565px !important;
  }
  .w-sm-566px {
    width: 566px !important;
  }
  .w-sm-567px {
    width: 567px !important;
  }
  .w-sm-568px {
    width: 568px !important;
  }
  .w-sm-569px {
    width: 569px !important;
  }
  .w-sm-570px {
    width: 570px !important;
  }
  .w-sm-571px {
    width: 571px !important;
  }
  .w-sm-572px {
    width: 572px !important;
  }
  .w-sm-573px {
    width: 573px !important;
  }
  .w-sm-574px {
    width: 574px !important;
  }
  .w-sm-575px {
    width: 575px !important;
  }
  .w-sm-576px {
    width: 576px !important;
  }
  .w-sm-577px {
    width: 577px !important;
  }
  .w-sm-578px {
    width: 578px !important;
  }
  .w-sm-579px {
    width: 579px !important;
  }
  .w-sm-580px {
    width: 580px !important;
  }
  .w-sm-581px {
    width: 581px !important;
  }
  .w-sm-582px {
    width: 582px !important;
  }
  .w-sm-583px {
    width: 583px !important;
  }
  .w-sm-584px {
    width: 584px !important;
  }
  .w-sm-585px {
    width: 585px !important;
  }
  .w-sm-586px {
    width: 586px !important;
  }
  .w-sm-587px {
    width: 587px !important;
  }
  .w-sm-588px {
    width: 588px !important;
  }
  .w-sm-589px {
    width: 589px !important;
  }
  .w-sm-590px {
    width: 590px !important;
  }
  .w-sm-591px {
    width: 591px !important;
  }
  .w-sm-592px {
    width: 592px !important;
  }
  .w-sm-593px {
    width: 593px !important;
  }
  .w-sm-594px {
    width: 594px !important;
  }
  .w-sm-595px {
    width: 595px !important;
  }
  .w-sm-596px {
    width: 596px !important;
  }
  .w-sm-597px {
    width: 597px !important;
  }
  .w-sm-598px {
    width: 598px !important;
  }
  .w-sm-599px {
    width: 599px !important;
  }
  .w-sm-600px {
    width: 600px !important;
  }
  .w-sm-601px {
    width: 601px !important;
  }
  .w-sm-602px {
    width: 602px !important;
  }
  .w-sm-603px {
    width: 603px !important;
  }
  .w-sm-604px {
    width: 604px !important;
  }
  .w-sm-605px {
    width: 605px !important;
  }
  .w-sm-606px {
    width: 606px !important;
  }
  .w-sm-607px {
    width: 607px !important;
  }
  .w-sm-608px {
    width: 608px !important;
  }
  .w-sm-609px {
    width: 609px !important;
  }
  .w-sm-610px {
    width: 610px !important;
  }
  .w-sm-611px {
    width: 611px !important;
  }
  .w-sm-612px {
    width: 612px !important;
  }
  .w-sm-613px {
    width: 613px !important;
  }
  .w-sm-614px {
    width: 614px !important;
  }
  .w-sm-615px {
    width: 615px !important;
  }
  .w-sm-616px {
    width: 616px !important;
  }
  .w-sm-617px {
    width: 617px !important;
  }
  .w-sm-618px {
    width: 618px !important;
  }
  .w-sm-619px {
    width: 619px !important;
  }
  .w-sm-620px {
    width: 620px !important;
  }
  .w-sm-621px {
    width: 621px !important;
  }
  .w-sm-622px {
    width: 622px !important;
  }
  .w-sm-623px {
    width: 623px !important;
  }
  .w-sm-624px {
    width: 624px !important;
  }
  .w-sm-625px {
    width: 625px !important;
  }
  .w-sm-626px {
    width: 626px !important;
  }
  .w-sm-627px {
    width: 627px !important;
  }
  .w-sm-628px {
    width: 628px !important;
  }
  .w-sm-629px {
    width: 629px !important;
  }
  .w-sm-630px {
    width: 630px !important;
  }
  .w-sm-631px {
    width: 631px !important;
  }
  .w-sm-632px {
    width: 632px !important;
  }
  .w-sm-633px {
    width: 633px !important;
  }
  .w-sm-634px {
    width: 634px !important;
  }
  .w-sm-635px {
    width: 635px !important;
  }
  .w-sm-636px {
    width: 636px !important;
  }
  .w-sm-637px {
    width: 637px !important;
  }
  .w-sm-638px {
    width: 638px !important;
  }
  .w-sm-639px {
    width: 639px !important;
  }
  .w-sm-640px {
    width: 640px !important;
  }
  .w-sm-641px {
    width: 641px !important;
  }
  .w-sm-642px {
    width: 642px !important;
  }
  .w-sm-643px {
    width: 643px !important;
  }
  .w-sm-644px {
    width: 644px !important;
  }
  .w-sm-645px {
    width: 645px !important;
  }
  .w-sm-646px {
    width: 646px !important;
  }
  .w-sm-647px {
    width: 647px !important;
  }
  .w-sm-648px {
    width: 648px !important;
  }
  .w-sm-649px {
    width: 649px !important;
  }
  .w-sm-650px {
    width: 650px !important;
  }
  .w-sm-651px {
    width: 651px !important;
  }
  .w-sm-652px {
    width: 652px !important;
  }
  .w-sm-653px {
    width: 653px !important;
  }
  .w-sm-654px {
    width: 654px !important;
  }
  .w-sm-655px {
    width: 655px !important;
  }
  .w-sm-656px {
    width: 656px !important;
  }
  .w-sm-657px {
    width: 657px !important;
  }
  .w-sm-658px {
    width: 658px !important;
  }
  .w-sm-659px {
    width: 659px !important;
  }
  .w-sm-660px {
    width: 660px !important;
  }
  .w-sm-661px {
    width: 661px !important;
  }
  .w-sm-662px {
    width: 662px !important;
  }
  .w-sm-663px {
    width: 663px !important;
  }
  .w-sm-664px {
    width: 664px !important;
  }
  .w-sm-665px {
    width: 665px !important;
  }
  .w-sm-666px {
    width: 666px !important;
  }
  .w-sm-667px {
    width: 667px !important;
  }
  .w-sm-668px {
    width: 668px !important;
  }
  .w-sm-669px {
    width: 669px !important;
  }
  .w-sm-670px {
    width: 670px !important;
  }
  .w-sm-671px {
    width: 671px !important;
  }
  .w-sm-672px {
    width: 672px !important;
  }
  .w-sm-673px {
    width: 673px !important;
  }
  .w-sm-674px {
    width: 674px !important;
  }
  .w-sm-675px {
    width: 675px !important;
  }
  .w-sm-676px {
    width: 676px !important;
  }
  .w-sm-677px {
    width: 677px !important;
  }
  .w-sm-678px {
    width: 678px !important;
  }
  .w-sm-679px {
    width: 679px !important;
  }
  .w-sm-680px {
    width: 680px !important;
  }
  .w-sm-681px {
    width: 681px !important;
  }
  .w-sm-682px {
    width: 682px !important;
  }
  .w-sm-683px {
    width: 683px !important;
  }
  .w-sm-684px {
    width: 684px !important;
  }
  .w-sm-685px {
    width: 685px !important;
  }
  .w-sm-686px {
    width: 686px !important;
  }
  .w-sm-687px {
    width: 687px !important;
  }
  .w-sm-688px {
    width: 688px !important;
  }
  .w-sm-689px {
    width: 689px !important;
  }
  .w-sm-690px {
    width: 690px !important;
  }
  .w-sm-691px {
    width: 691px !important;
  }
  .w-sm-692px {
    width: 692px !important;
  }
  .w-sm-693px {
    width: 693px !important;
  }
  .w-sm-694px {
    width: 694px !important;
  }
  .w-sm-695px {
    width: 695px !important;
  }
  .w-sm-696px {
    width: 696px !important;
  }
  .w-sm-697px {
    width: 697px !important;
  }
  .w-sm-698px {
    width: 698px !important;
  }
  .w-sm-699px {
    width: 699px !important;
  }
  .w-sm-700px {
    width: 700px !important;
  }
  .w-sm-701px {
    width: 701px !important;
  }
  .w-sm-702px {
    width: 702px !important;
  }
  .w-sm-703px {
    width: 703px !important;
  }
  .w-sm-704px {
    width: 704px !important;
  }
  .w-sm-705px {
    width: 705px !important;
  }
  .w-sm-706px {
    width: 706px !important;
  }
  .w-sm-707px {
    width: 707px !important;
  }
  .w-sm-708px {
    width: 708px !important;
  }
  .w-sm-709px {
    width: 709px !important;
  }
  .w-sm-710px {
    width: 710px !important;
  }
  .w-sm-711px {
    width: 711px !important;
  }
  .w-sm-712px {
    width: 712px !important;
  }
  .w-sm-713px {
    width: 713px !important;
  }
  .w-sm-714px {
    width: 714px !important;
  }
  .w-sm-715px {
    width: 715px !important;
  }
  .w-sm-716px {
    width: 716px !important;
  }
  .w-sm-717px {
    width: 717px !important;
  }
  .w-sm-718px {
    width: 718px !important;
  }
  .w-sm-719px {
    width: 719px !important;
  }
  .w-sm-720px {
    width: 720px !important;
  }
  .w-sm-721px {
    width: 721px !important;
  }
  .w-sm-722px {
    width: 722px !important;
  }
  .w-sm-723px {
    width: 723px !important;
  }
  .w-sm-724px {
    width: 724px !important;
  }
  .w-sm-725px {
    width: 725px !important;
  }
  .w-sm-726px {
    width: 726px !important;
  }
  .w-sm-727px {
    width: 727px !important;
  }
  .w-sm-728px {
    width: 728px !important;
  }
  .w-sm-729px {
    width: 729px !important;
  }
  .w-sm-730px {
    width: 730px !important;
  }
  .w-sm-731px {
    width: 731px !important;
  }
  .w-sm-732px {
    width: 732px !important;
  }
  .w-sm-733px {
    width: 733px !important;
  }
  .w-sm-734px {
    width: 734px !important;
  }
  .w-sm-735px {
    width: 735px !important;
  }
  .w-sm-736px {
    width: 736px !important;
  }
  .w-sm-737px {
    width: 737px !important;
  }
  .w-sm-738px {
    width: 738px !important;
  }
  .w-sm-739px {
    width: 739px !important;
  }
  .w-sm-740px {
    width: 740px !important;
  }
  .w-sm-741px {
    width: 741px !important;
  }
  .w-sm-742px {
    width: 742px !important;
  }
  .w-sm-743px {
    width: 743px !important;
  }
  .w-sm-744px {
    width: 744px !important;
  }
  .w-sm-745px {
    width: 745px !important;
  }
  .w-sm-746px {
    width: 746px !important;
  }
  .w-sm-747px {
    width: 747px !important;
  }
  .w-sm-748px {
    width: 748px !important;
  }
  .w-sm-749px {
    width: 749px !important;
  }
  .w-sm-750px {
    width: 750px !important;
  }
  .w-sm-751px {
    width: 751px !important;
  }
  .w-sm-752px {
    width: 752px !important;
  }
  .w-sm-753px {
    width: 753px !important;
  }
  .w-sm-754px {
    width: 754px !important;
  }
  .w-sm-755px {
    width: 755px !important;
  }
  .w-sm-756px {
    width: 756px !important;
  }
  .w-sm-757px {
    width: 757px !important;
  }
  .w-sm-758px {
    width: 758px !important;
  }
  .w-sm-759px {
    width: 759px !important;
  }
  .w-sm-760px {
    width: 760px !important;
  }
  .w-sm-761px {
    width: 761px !important;
  }
  .w-sm-762px {
    width: 762px !important;
  }
  .w-sm-763px {
    width: 763px !important;
  }
  .w-sm-764px {
    width: 764px !important;
  }
  .w-sm-765px {
    width: 765px !important;
  }
  .w-sm-766px {
    width: 766px !important;
  }
  .w-sm-767px {
    width: 767px !important;
  }
  .w-sm-768px {
    width: 768px !important;
  }
  .w-sm-769px {
    width: 769px !important;
  }
  .w-sm-770px {
    width: 770px !important;
  }
  .w-sm-771px {
    width: 771px !important;
  }
  .w-sm-772px {
    width: 772px !important;
  }
  .w-sm-773px {
    width: 773px !important;
  }
  .w-sm-774px {
    width: 774px !important;
  }
  .w-sm-775px {
    width: 775px !important;
  }
  .w-sm-776px {
    width: 776px !important;
  }
  .w-sm-777px {
    width: 777px !important;
  }
  .w-sm-778px {
    width: 778px !important;
  }
  .w-sm-779px {
    width: 779px !important;
  }
  .w-sm-780px {
    width: 780px !important;
  }
  .w-sm-781px {
    width: 781px !important;
  }
  .w-sm-782px {
    width: 782px !important;
  }
  .w-sm-783px {
    width: 783px !important;
  }
  .w-sm-784px {
    width: 784px !important;
  }
  .w-sm-785px {
    width: 785px !important;
  }
  .w-sm-786px {
    width: 786px !important;
  }
  .w-sm-787px {
    width: 787px !important;
  }
  .w-sm-788px {
    width: 788px !important;
  }
  .w-sm-789px {
    width: 789px !important;
  }
  .w-sm-790px {
    width: 790px !important;
  }
  .w-sm-791px {
    width: 791px !important;
  }
  .w-sm-792px {
    width: 792px !important;
  }
  .w-sm-793px {
    width: 793px !important;
  }
  .w-sm-794px {
    width: 794px !important;
  }
  .w-sm-795px {
    width: 795px !important;
  }
  .w-sm-796px {
    width: 796px !important;
  }
  .w-sm-797px {
    width: 797px !important;
  }
  .w-sm-798px {
    width: 798px !important;
  }
  .w-sm-799px {
    width: 799px !important;
  }
  .w-sm-800px {
    width: 800px !important;
  }
  .w-sm-801px {
    width: 801px !important;
  }
  .w-sm-802px {
    width: 802px !important;
  }
  .w-sm-803px {
    width: 803px !important;
  }
  .w-sm-804px {
    width: 804px !important;
  }
  .w-sm-805px {
    width: 805px !important;
  }
  .w-sm-806px {
    width: 806px !important;
  }
  .w-sm-807px {
    width: 807px !important;
  }
  .w-sm-808px {
    width: 808px !important;
  }
  .w-sm-809px {
    width: 809px !important;
  }
  .w-sm-810px {
    width: 810px !important;
  }
  .w-sm-811px {
    width: 811px !important;
  }
  .w-sm-812px {
    width: 812px !important;
  }
  .w-sm-813px {
    width: 813px !important;
  }
  .w-sm-814px {
    width: 814px !important;
  }
  .w-sm-815px {
    width: 815px !important;
  }
  .w-sm-816px {
    width: 816px !important;
  }
  .w-sm-817px {
    width: 817px !important;
  }
  .w-sm-818px {
    width: 818px !important;
  }
  .w-sm-819px {
    width: 819px !important;
  }
  .w-sm-820px {
    width: 820px !important;
  }
  .w-sm-821px {
    width: 821px !important;
  }
  .w-sm-822px {
    width: 822px !important;
  }
  .w-sm-823px {
    width: 823px !important;
  }
  .w-sm-824px {
    width: 824px !important;
  }
  .w-sm-825px {
    width: 825px !important;
  }
  .w-sm-826px {
    width: 826px !important;
  }
  .w-sm-827px {
    width: 827px !important;
  }
  .w-sm-828px {
    width: 828px !important;
  }
  .w-sm-829px {
    width: 829px !important;
  }
  .w-sm-830px {
    width: 830px !important;
  }
  .w-sm-831px {
    width: 831px !important;
  }
  .w-sm-832px {
    width: 832px !important;
  }
  .w-sm-833px {
    width: 833px !important;
  }
  .w-sm-834px {
    width: 834px !important;
  }
  .w-sm-835px {
    width: 835px !important;
  }
  .w-sm-836px {
    width: 836px !important;
  }
  .w-sm-837px {
    width: 837px !important;
  }
  .w-sm-838px {
    width: 838px !important;
  }
  .w-sm-839px {
    width: 839px !important;
  }
  .w-sm-840px {
    width: 840px !important;
  }
  .w-sm-841px {
    width: 841px !important;
  }
  .w-sm-842px {
    width: 842px !important;
  }
  .w-sm-843px {
    width: 843px !important;
  }
  .w-sm-844px {
    width: 844px !important;
  }
  .w-sm-845px {
    width: 845px !important;
  }
  .w-sm-846px {
    width: 846px !important;
  }
  .w-sm-847px {
    width: 847px !important;
  }
  .w-sm-848px {
    width: 848px !important;
  }
  .w-sm-849px {
    width: 849px !important;
  }
  .w-sm-850px {
    width: 850px !important;
  }
  .w-sm-851px {
    width: 851px !important;
  }
  .w-sm-852px {
    width: 852px !important;
  }
  .w-sm-853px {
    width: 853px !important;
  }
  .w-sm-854px {
    width: 854px !important;
  }
  .w-sm-855px {
    width: 855px !important;
  }
  .w-sm-856px {
    width: 856px !important;
  }
  .w-sm-857px {
    width: 857px !important;
  }
  .w-sm-858px {
    width: 858px !important;
  }
  .w-sm-859px {
    width: 859px !important;
  }
  .w-sm-860px {
    width: 860px !important;
  }
  .w-sm-861px {
    width: 861px !important;
  }
  .w-sm-862px {
    width: 862px !important;
  }
  .w-sm-863px {
    width: 863px !important;
  }
  .w-sm-864px {
    width: 864px !important;
  }
  .w-sm-865px {
    width: 865px !important;
  }
  .w-sm-866px {
    width: 866px !important;
  }
  .w-sm-867px {
    width: 867px !important;
  }
  .w-sm-868px {
    width: 868px !important;
  }
  .w-sm-869px {
    width: 869px !important;
  }
  .w-sm-870px {
    width: 870px !important;
  }
  .w-sm-871px {
    width: 871px !important;
  }
  .w-sm-872px {
    width: 872px !important;
  }
  .w-sm-873px {
    width: 873px !important;
  }
  .w-sm-874px {
    width: 874px !important;
  }
  .w-sm-875px {
    width: 875px !important;
  }
  .w-sm-876px {
    width: 876px !important;
  }
  .w-sm-877px {
    width: 877px !important;
  }
  .w-sm-878px {
    width: 878px !important;
  }
  .w-sm-879px {
    width: 879px !important;
  }
  .w-sm-880px {
    width: 880px !important;
  }
  .w-sm-881px {
    width: 881px !important;
  }
  .w-sm-882px {
    width: 882px !important;
  }
  .w-sm-883px {
    width: 883px !important;
  }
  .w-sm-884px {
    width: 884px !important;
  }
  .w-sm-885px {
    width: 885px !important;
  }
  .w-sm-886px {
    width: 886px !important;
  }
  .w-sm-887px {
    width: 887px !important;
  }
  .w-sm-888px {
    width: 888px !important;
  }
  .w-sm-889px {
    width: 889px !important;
  }
  .w-sm-890px {
    width: 890px !important;
  }
  .w-sm-891px {
    width: 891px !important;
  }
  .w-sm-892px {
    width: 892px !important;
  }
  .w-sm-893px {
    width: 893px !important;
  }
  .w-sm-894px {
    width: 894px !important;
  }
  .w-sm-895px {
    width: 895px !important;
  }
  .w-sm-896px {
    width: 896px !important;
  }
  .w-sm-897px {
    width: 897px !important;
  }
  .w-sm-898px {
    width: 898px !important;
  }
  .w-sm-899px {
    width: 899px !important;
  }
  .w-sm-900px {
    width: 900px !important;
  }
  .w-sm-901px {
    width: 901px !important;
  }
  .w-sm-902px {
    width: 902px !important;
  }
  .w-sm-903px {
    width: 903px !important;
  }
  .w-sm-904px {
    width: 904px !important;
  }
  .w-sm-905px {
    width: 905px !important;
  }
  .w-sm-906px {
    width: 906px !important;
  }
  .w-sm-907px {
    width: 907px !important;
  }
  .w-sm-908px {
    width: 908px !important;
  }
  .w-sm-909px {
    width: 909px !important;
  }
  .w-sm-910px {
    width: 910px !important;
  }
  .w-sm-911px {
    width: 911px !important;
  }
  .w-sm-912px {
    width: 912px !important;
  }
  .w-sm-913px {
    width: 913px !important;
  }
  .w-sm-914px {
    width: 914px !important;
  }
  .w-sm-915px {
    width: 915px !important;
  }
  .w-sm-916px {
    width: 916px !important;
  }
  .w-sm-917px {
    width: 917px !important;
  }
  .w-sm-918px {
    width: 918px !important;
  }
  .w-sm-919px {
    width: 919px !important;
  }
  .w-sm-920px {
    width: 920px !important;
  }
  .w-sm-921px {
    width: 921px !important;
  }
  .w-sm-922px {
    width: 922px !important;
  }
  .w-sm-923px {
    width: 923px !important;
  }
  .w-sm-924px {
    width: 924px !important;
  }
  .w-sm-925px {
    width: 925px !important;
  }
  .w-sm-926px {
    width: 926px !important;
  }
  .w-sm-927px {
    width: 927px !important;
  }
  .w-sm-928px {
    width: 928px !important;
  }
  .w-sm-929px {
    width: 929px !important;
  }
  .w-sm-930px {
    width: 930px !important;
  }
  .w-sm-931px {
    width: 931px !important;
  }
  .w-sm-932px {
    width: 932px !important;
  }
  .w-sm-933px {
    width: 933px !important;
  }
  .w-sm-934px {
    width: 934px !important;
  }
  .w-sm-935px {
    width: 935px !important;
  }
  .w-sm-936px {
    width: 936px !important;
  }
  .w-sm-937px {
    width: 937px !important;
  }
  .w-sm-938px {
    width: 938px !important;
  }
  .w-sm-939px {
    width: 939px !important;
  }
  .w-sm-940px {
    width: 940px !important;
  }
  .w-sm-941px {
    width: 941px !important;
  }
  .w-sm-942px {
    width: 942px !important;
  }
  .w-sm-943px {
    width: 943px !important;
  }
  .w-sm-944px {
    width: 944px !important;
  }
  .w-sm-945px {
    width: 945px !important;
  }
  .w-sm-946px {
    width: 946px !important;
  }
  .w-sm-947px {
    width: 947px !important;
  }
  .w-sm-948px {
    width: 948px !important;
  }
  .w-sm-949px {
    width: 949px !important;
  }
  .w-sm-950px {
    width: 950px !important;
  }
  .w-sm-951px {
    width: 951px !important;
  }
  .w-sm-952px {
    width: 952px !important;
  }
  .w-sm-953px {
    width: 953px !important;
  }
  .w-sm-954px {
    width: 954px !important;
  }
  .w-sm-955px {
    width: 955px !important;
  }
  .w-sm-956px {
    width: 956px !important;
  }
  .w-sm-957px {
    width: 957px !important;
  }
  .w-sm-958px {
    width: 958px !important;
  }
  .w-sm-959px {
    width: 959px !important;
  }
  .w-sm-960px {
    width: 960px !important;
  }
  .w-sm-961px {
    width: 961px !important;
  }
  .w-sm-962px {
    width: 962px !important;
  }
  .w-sm-963px {
    width: 963px !important;
  }
  .w-sm-964px {
    width: 964px !important;
  }
  .w-sm-965px {
    width: 965px !important;
  }
  .w-sm-966px {
    width: 966px !important;
  }
  .w-sm-967px {
    width: 967px !important;
  }
  .w-sm-968px {
    width: 968px !important;
  }
  .w-sm-969px {
    width: 969px !important;
  }
  .w-sm-970px {
    width: 970px !important;
  }
  .w-sm-971px {
    width: 971px !important;
  }
  .w-sm-972px {
    width: 972px !important;
  }
  .w-sm-973px {
    width: 973px !important;
  }
  .w-sm-974px {
    width: 974px !important;
  }
  .w-sm-975px {
    width: 975px !important;
  }
  .w-sm-976px {
    width: 976px !important;
  }
  .w-sm-977px {
    width: 977px !important;
  }
  .w-sm-978px {
    width: 978px !important;
  }
  .w-sm-979px {
    width: 979px !important;
  }
  .w-sm-980px {
    width: 980px !important;
  }
  .w-sm-981px {
    width: 981px !important;
  }
  .w-sm-982px {
    width: 982px !important;
  }
  .w-sm-983px {
    width: 983px !important;
  }
  .w-sm-984px {
    width: 984px !important;
  }
  .w-sm-985px {
    width: 985px !important;
  }
  .w-sm-986px {
    width: 986px !important;
  }
  .w-sm-987px {
    width: 987px !important;
  }
  .w-sm-988px {
    width: 988px !important;
  }
  .w-sm-989px {
    width: 989px !important;
  }
  .w-sm-990px {
    width: 990px !important;
  }
  .w-sm-991px {
    width: 991px !important;
  }
  .w-sm-992px {
    width: 992px !important;
  }
  .w-sm-993px {
    width: 993px !important;
  }
  .w-sm-994px {
    width: 994px !important;
  }
  .w-sm-995px {
    width: 995px !important;
  }
  .w-sm-996px {
    width: 996px !important;
  }
  .w-sm-997px {
    width: 997px !important;
  }
  .w-sm-998px {
    width: 998px !important;
  }
  .w-sm-999px {
    width: 999px !important;
  }
  .w-sm-1000px {
    width: 1000px !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
  .w-md-0px {
    width: 0px !important;
  }
  .w-md-1px {
    width: 1px !important;
  }
  .w-md-2px {
    width: 2px !important;
  }
  .w-md-3px {
    width: 3px !important;
  }
  .w-md-4px {
    width: 4px !important;
  }
  .w-md-5px {
    width: 5px !important;
  }
  .w-md-6px {
    width: 6px !important;
  }
  .w-md-7px {
    width: 7px !important;
  }
  .w-md-8px {
    width: 8px !important;
  }
  .w-md-9px {
    width: 9px !important;
  }
  .w-md-10px {
    width: 10px !important;
  }
  .w-md-11px {
    width: 11px !important;
  }
  .w-md-12px {
    width: 12px !important;
  }
  .w-md-13px {
    width: 13px !important;
  }
  .w-md-14px {
    width: 14px !important;
  }
  .w-md-15px {
    width: 15px !important;
  }
  .w-md-16px {
    width: 16px !important;
  }
  .w-md-17px {
    width: 17px !important;
  }
  .w-md-18px {
    width: 18px !important;
  }
  .w-md-19px {
    width: 19px !important;
  }
  .w-md-20px {
    width: 20px !important;
  }
  .w-md-21px {
    width: 21px !important;
  }
  .w-md-22px {
    width: 22px !important;
  }
  .w-md-23px {
    width: 23px !important;
  }
  .w-md-24px {
    width: 24px !important;
  }
  .w-md-25px {
    width: 25px !important;
  }
  .w-md-26px {
    width: 26px !important;
  }
  .w-md-27px {
    width: 27px !important;
  }
  .w-md-28px {
    width: 28px !important;
  }
  .w-md-29px {
    width: 29px !important;
  }
  .w-md-30px {
    width: 30px !important;
  }
  .w-md-31px {
    width: 31px !important;
  }
  .w-md-32px {
    width: 32px !important;
  }
  .w-md-33px {
    width: 33px !important;
  }
  .w-md-34px {
    width: 34px !important;
  }
  .w-md-35px {
    width: 35px !important;
  }
  .w-md-36px {
    width: 36px !important;
  }
  .w-md-37px {
    width: 37px !important;
  }
  .w-md-38px {
    width: 38px !important;
  }
  .w-md-39px {
    width: 39px !important;
  }
  .w-md-40px {
    width: 40px !important;
  }
  .w-md-41px {
    width: 41px !important;
  }
  .w-md-42px {
    width: 42px !important;
  }
  .w-md-43px {
    width: 43px !important;
  }
  .w-md-44px {
    width: 44px !important;
  }
  .w-md-45px {
    width: 45px !important;
  }
  .w-md-46px {
    width: 46px !important;
  }
  .w-md-47px {
    width: 47px !important;
  }
  .w-md-48px {
    width: 48px !important;
  }
  .w-md-49px {
    width: 49px !important;
  }
  .w-md-50px {
    width: 50px !important;
  }
  .w-md-51px {
    width: 51px !important;
  }
  .w-md-52px {
    width: 52px !important;
  }
  .w-md-53px {
    width: 53px !important;
  }
  .w-md-54px {
    width: 54px !important;
  }
  .w-md-55px {
    width: 55px !important;
  }
  .w-md-56px {
    width: 56px !important;
  }
  .w-md-57px {
    width: 57px !important;
  }
  .w-md-58px {
    width: 58px !important;
  }
  .w-md-59px {
    width: 59px !important;
  }
  .w-md-60px {
    width: 60px !important;
  }
  .w-md-61px {
    width: 61px !important;
  }
  .w-md-62px {
    width: 62px !important;
  }
  .w-md-63px {
    width: 63px !important;
  }
  .w-md-64px {
    width: 64px !important;
  }
  .w-md-65px {
    width: 65px !important;
  }
  .w-md-66px {
    width: 66px !important;
  }
  .w-md-67px {
    width: 67px !important;
  }
  .w-md-68px {
    width: 68px !important;
  }
  .w-md-69px {
    width: 69px !important;
  }
  .w-md-70px {
    width: 70px !important;
  }
  .w-md-71px {
    width: 71px !important;
  }
  .w-md-72px {
    width: 72px !important;
  }
  .w-md-73px {
    width: 73px !important;
  }
  .w-md-74px {
    width: 74px !important;
  }
  .w-md-75px {
    width: 75px !important;
  }
  .w-md-76px {
    width: 76px !important;
  }
  .w-md-77px {
    width: 77px !important;
  }
  .w-md-78px {
    width: 78px !important;
  }
  .w-md-79px {
    width: 79px !important;
  }
  .w-md-80px {
    width: 80px !important;
  }
  .w-md-81px {
    width: 81px !important;
  }
  .w-md-82px {
    width: 82px !important;
  }
  .w-md-83px {
    width: 83px !important;
  }
  .w-md-84px {
    width: 84px !important;
  }
  .w-md-85px {
    width: 85px !important;
  }
  .w-md-86px {
    width: 86px !important;
  }
  .w-md-87px {
    width: 87px !important;
  }
  .w-md-88px {
    width: 88px !important;
  }
  .w-md-89px {
    width: 89px !important;
  }
  .w-md-90px {
    width: 90px !important;
  }
  .w-md-91px {
    width: 91px !important;
  }
  .w-md-92px {
    width: 92px !important;
  }
  .w-md-93px {
    width: 93px !important;
  }
  .w-md-94px {
    width: 94px !important;
  }
  .w-md-95px {
    width: 95px !important;
  }
  .w-md-96px {
    width: 96px !important;
  }
  .w-md-97px {
    width: 97px !important;
  }
  .w-md-98px {
    width: 98px !important;
  }
  .w-md-99px {
    width: 99px !important;
  }
  .w-md-100px {
    width: 100px !important;
  }
  .w-md-101px {
    width: 101px !important;
  }
  .w-md-102px {
    width: 102px !important;
  }
  .w-md-103px {
    width: 103px !important;
  }
  .w-md-104px {
    width: 104px !important;
  }
  .w-md-105px {
    width: 105px !important;
  }
  .w-md-106px {
    width: 106px !important;
  }
  .w-md-107px {
    width: 107px !important;
  }
  .w-md-108px {
    width: 108px !important;
  }
  .w-md-109px {
    width: 109px !important;
  }
  .w-md-110px {
    width: 110px !important;
  }
  .w-md-111px {
    width: 111px !important;
  }
  .w-md-112px {
    width: 112px !important;
  }
  .w-md-113px {
    width: 113px !important;
  }
  .w-md-114px {
    width: 114px !important;
  }
  .w-md-115px {
    width: 115px !important;
  }
  .w-md-116px {
    width: 116px !important;
  }
  .w-md-117px {
    width: 117px !important;
  }
  .w-md-118px {
    width: 118px !important;
  }
  .w-md-119px {
    width: 119px !important;
  }
  .w-md-120px {
    width: 120px !important;
  }
  .w-md-121px {
    width: 121px !important;
  }
  .w-md-122px {
    width: 122px !important;
  }
  .w-md-123px {
    width: 123px !important;
  }
  .w-md-124px {
    width: 124px !important;
  }
  .w-md-125px {
    width: 125px !important;
  }
  .w-md-126px {
    width: 126px !important;
  }
  .w-md-127px {
    width: 127px !important;
  }
  .w-md-128px {
    width: 128px !important;
  }
  .w-md-129px {
    width: 129px !important;
  }
  .w-md-130px {
    width: 130px !important;
  }
  .w-md-131px {
    width: 131px !important;
  }
  .w-md-132px {
    width: 132px !important;
  }
  .w-md-133px {
    width: 133px !important;
  }
  .w-md-134px {
    width: 134px !important;
  }
  .w-md-135px {
    width: 135px !important;
  }
  .w-md-136px {
    width: 136px !important;
  }
  .w-md-137px {
    width: 137px !important;
  }
  .w-md-138px {
    width: 138px !important;
  }
  .w-md-139px {
    width: 139px !important;
  }
  .w-md-140px {
    width: 140px !important;
  }
  .w-md-141px {
    width: 141px !important;
  }
  .w-md-142px {
    width: 142px !important;
  }
  .w-md-143px {
    width: 143px !important;
  }
  .w-md-144px {
    width: 144px !important;
  }
  .w-md-145px {
    width: 145px !important;
  }
  .w-md-146px {
    width: 146px !important;
  }
  .w-md-147px {
    width: 147px !important;
  }
  .w-md-148px {
    width: 148px !important;
  }
  .w-md-149px {
    width: 149px !important;
  }
  .w-md-150px {
    width: 150px !important;
  }
  .w-md-151px {
    width: 151px !important;
  }
  .w-md-152px {
    width: 152px !important;
  }
  .w-md-153px {
    width: 153px !important;
  }
  .w-md-154px {
    width: 154px !important;
  }
  .w-md-155px {
    width: 155px !important;
  }
  .w-md-156px {
    width: 156px !important;
  }
  .w-md-157px {
    width: 157px !important;
  }
  .w-md-158px {
    width: 158px !important;
  }
  .w-md-159px {
    width: 159px !important;
  }
  .w-md-160px {
    width: 160px !important;
  }
  .w-md-161px {
    width: 161px !important;
  }
  .w-md-162px {
    width: 162px !important;
  }
  .w-md-163px {
    width: 163px !important;
  }
  .w-md-164px {
    width: 164px !important;
  }
  .w-md-165px {
    width: 165px !important;
  }
  .w-md-166px {
    width: 166px !important;
  }
  .w-md-167px {
    width: 167px !important;
  }
  .w-md-168px {
    width: 168px !important;
  }
  .w-md-169px {
    width: 169px !important;
  }
  .w-md-170px {
    width: 170px !important;
  }
  .w-md-171px {
    width: 171px !important;
  }
  .w-md-172px {
    width: 172px !important;
  }
  .w-md-173px {
    width: 173px !important;
  }
  .w-md-174px {
    width: 174px !important;
  }
  .w-md-175px {
    width: 175px !important;
  }
  .w-md-176px {
    width: 176px !important;
  }
  .w-md-177px {
    width: 177px !important;
  }
  .w-md-178px {
    width: 178px !important;
  }
  .w-md-179px {
    width: 179px !important;
  }
  .w-md-180px {
    width: 180px !important;
  }
  .w-md-181px {
    width: 181px !important;
  }
  .w-md-182px {
    width: 182px !important;
  }
  .w-md-183px {
    width: 183px !important;
  }
  .w-md-184px {
    width: 184px !important;
  }
  .w-md-185px {
    width: 185px !important;
  }
  .w-md-186px {
    width: 186px !important;
  }
  .w-md-187px {
    width: 187px !important;
  }
  .w-md-188px {
    width: 188px !important;
  }
  .w-md-189px {
    width: 189px !important;
  }
  .w-md-190px {
    width: 190px !important;
  }
  .w-md-191px {
    width: 191px !important;
  }
  .w-md-192px {
    width: 192px !important;
  }
  .w-md-193px {
    width: 193px !important;
  }
  .w-md-194px {
    width: 194px !important;
  }
  .w-md-195px {
    width: 195px !important;
  }
  .w-md-196px {
    width: 196px !important;
  }
  .w-md-197px {
    width: 197px !important;
  }
  .w-md-198px {
    width: 198px !important;
  }
  .w-md-199px {
    width: 199px !important;
  }
  .w-md-200px {
    width: 200px !important;
  }
  .w-md-201px {
    width: 201px !important;
  }
  .w-md-202px {
    width: 202px !important;
  }
  .w-md-203px {
    width: 203px !important;
  }
  .w-md-204px {
    width: 204px !important;
  }
  .w-md-205px {
    width: 205px !important;
  }
  .w-md-206px {
    width: 206px !important;
  }
  .w-md-207px {
    width: 207px !important;
  }
  .w-md-208px {
    width: 208px !important;
  }
  .w-md-209px {
    width: 209px !important;
  }
  .w-md-210px {
    width: 210px !important;
  }
  .w-md-211px {
    width: 211px !important;
  }
  .w-md-212px {
    width: 212px !important;
  }
  .w-md-213px {
    width: 213px !important;
  }
  .w-md-214px {
    width: 214px !important;
  }
  .w-md-215px {
    width: 215px !important;
  }
  .w-md-216px {
    width: 216px !important;
  }
  .w-md-217px {
    width: 217px !important;
  }
  .w-md-218px {
    width: 218px !important;
  }
  .w-md-219px {
    width: 219px !important;
  }
  .w-md-220px {
    width: 220px !important;
  }
  .w-md-221px {
    width: 221px !important;
  }
  .w-md-222px {
    width: 222px !important;
  }
  .w-md-223px {
    width: 223px !important;
  }
  .w-md-224px {
    width: 224px !important;
  }
  .w-md-225px {
    width: 225px !important;
  }
  .w-md-226px {
    width: 226px !important;
  }
  .w-md-227px {
    width: 227px !important;
  }
  .w-md-228px {
    width: 228px !important;
  }
  .w-md-229px {
    width: 229px !important;
  }
  .w-md-230px {
    width: 230px !important;
  }
  .w-md-231px {
    width: 231px !important;
  }
  .w-md-232px {
    width: 232px !important;
  }
  .w-md-233px {
    width: 233px !important;
  }
  .w-md-234px {
    width: 234px !important;
  }
  .w-md-235px {
    width: 235px !important;
  }
  .w-md-236px {
    width: 236px !important;
  }
  .w-md-237px {
    width: 237px !important;
  }
  .w-md-238px {
    width: 238px !important;
  }
  .w-md-239px {
    width: 239px !important;
  }
  .w-md-240px {
    width: 240px !important;
  }
  .w-md-241px {
    width: 241px !important;
  }
  .w-md-242px {
    width: 242px !important;
  }
  .w-md-243px {
    width: 243px !important;
  }
  .w-md-244px {
    width: 244px !important;
  }
  .w-md-245px {
    width: 245px !important;
  }
  .w-md-246px {
    width: 246px !important;
  }
  .w-md-247px {
    width: 247px !important;
  }
  .w-md-248px {
    width: 248px !important;
  }
  .w-md-249px {
    width: 249px !important;
  }
  .w-md-250px {
    width: 250px !important;
  }
  .w-md-251px {
    width: 251px !important;
  }
  .w-md-252px {
    width: 252px !important;
  }
  .w-md-253px {
    width: 253px !important;
  }
  .w-md-254px {
    width: 254px !important;
  }
  .w-md-255px {
    width: 255px !important;
  }
  .w-md-256px {
    width: 256px !important;
  }
  .w-md-257px {
    width: 257px !important;
  }
  .w-md-258px {
    width: 258px !important;
  }
  .w-md-259px {
    width: 259px !important;
  }
  .w-md-260px {
    width: 260px !important;
  }
  .w-md-261px {
    width: 261px !important;
  }
  .w-md-262px {
    width: 262px !important;
  }
  .w-md-263px {
    width: 263px !important;
  }
  .w-md-264px {
    width: 264px !important;
  }
  .w-md-265px {
    width: 265px !important;
  }
  .w-md-266px {
    width: 266px !important;
  }
  .w-md-267px {
    width: 267px !important;
  }
  .w-md-268px {
    width: 268px !important;
  }
  .w-md-269px {
    width: 269px !important;
  }
  .w-md-270px {
    width: 270px !important;
  }
  .w-md-271px {
    width: 271px !important;
  }
  .w-md-272px {
    width: 272px !important;
  }
  .w-md-273px {
    width: 273px !important;
  }
  .w-md-274px {
    width: 274px !important;
  }
  .w-md-275px {
    width: 275px !important;
  }
  .w-md-276px {
    width: 276px !important;
  }
  .w-md-277px {
    width: 277px !important;
  }
  .w-md-278px {
    width: 278px !important;
  }
  .w-md-279px {
    width: 279px !important;
  }
  .w-md-280px {
    width: 280px !important;
  }
  .w-md-281px {
    width: 281px !important;
  }
  .w-md-282px {
    width: 282px !important;
  }
  .w-md-283px {
    width: 283px !important;
  }
  .w-md-284px {
    width: 284px !important;
  }
  .w-md-285px {
    width: 285px !important;
  }
  .w-md-286px {
    width: 286px !important;
  }
  .w-md-287px {
    width: 287px !important;
  }
  .w-md-288px {
    width: 288px !important;
  }
  .w-md-289px {
    width: 289px !important;
  }
  .w-md-290px {
    width: 290px !important;
  }
  .w-md-291px {
    width: 291px !important;
  }
  .w-md-292px {
    width: 292px !important;
  }
  .w-md-293px {
    width: 293px !important;
  }
  .w-md-294px {
    width: 294px !important;
  }
  .w-md-295px {
    width: 295px !important;
  }
  .w-md-296px {
    width: 296px !important;
  }
  .w-md-297px {
    width: 297px !important;
  }
  .w-md-298px {
    width: 298px !important;
  }
  .w-md-299px {
    width: 299px !important;
  }
  .w-md-300px {
    width: 300px !important;
  }
  .w-md-301px {
    width: 301px !important;
  }
  .w-md-302px {
    width: 302px !important;
  }
  .w-md-303px {
    width: 303px !important;
  }
  .w-md-304px {
    width: 304px !important;
  }
  .w-md-305px {
    width: 305px !important;
  }
  .w-md-306px {
    width: 306px !important;
  }
  .w-md-307px {
    width: 307px !important;
  }
  .w-md-308px {
    width: 308px !important;
  }
  .w-md-309px {
    width: 309px !important;
  }
  .w-md-310px {
    width: 310px !important;
  }
  .w-md-311px {
    width: 311px !important;
  }
  .w-md-312px {
    width: 312px !important;
  }
  .w-md-313px {
    width: 313px !important;
  }
  .w-md-314px {
    width: 314px !important;
  }
  .w-md-315px {
    width: 315px !important;
  }
  .w-md-316px {
    width: 316px !important;
  }
  .w-md-317px {
    width: 317px !important;
  }
  .w-md-318px {
    width: 318px !important;
  }
  .w-md-319px {
    width: 319px !important;
  }
  .w-md-320px {
    width: 320px !important;
  }
  .w-md-321px {
    width: 321px !important;
  }
  .w-md-322px {
    width: 322px !important;
  }
  .w-md-323px {
    width: 323px !important;
  }
  .w-md-324px {
    width: 324px !important;
  }
  .w-md-325px {
    width: 325px !important;
  }
  .w-md-326px {
    width: 326px !important;
  }
  .w-md-327px {
    width: 327px !important;
  }
  .w-md-328px {
    width: 328px !important;
  }
  .w-md-329px {
    width: 329px !important;
  }
  .w-md-330px {
    width: 330px !important;
  }
  .w-md-331px {
    width: 331px !important;
  }
  .w-md-332px {
    width: 332px !important;
  }
  .w-md-333px {
    width: 333px !important;
  }
  .w-md-334px {
    width: 334px !important;
  }
  .w-md-335px {
    width: 335px !important;
  }
  .w-md-336px {
    width: 336px !important;
  }
  .w-md-337px {
    width: 337px !important;
  }
  .w-md-338px {
    width: 338px !important;
  }
  .w-md-339px {
    width: 339px !important;
  }
  .w-md-340px {
    width: 340px !important;
  }
  .w-md-341px {
    width: 341px !important;
  }
  .w-md-342px {
    width: 342px !important;
  }
  .w-md-343px {
    width: 343px !important;
  }
  .w-md-344px {
    width: 344px !important;
  }
  .w-md-345px {
    width: 345px !important;
  }
  .w-md-346px {
    width: 346px !important;
  }
  .w-md-347px {
    width: 347px !important;
  }
  .w-md-348px {
    width: 348px !important;
  }
  .w-md-349px {
    width: 349px !important;
  }
  .w-md-350px {
    width: 350px !important;
  }
  .w-md-351px {
    width: 351px !important;
  }
  .w-md-352px {
    width: 352px !important;
  }
  .w-md-353px {
    width: 353px !important;
  }
  .w-md-354px {
    width: 354px !important;
  }
  .w-md-355px {
    width: 355px !important;
  }
  .w-md-356px {
    width: 356px !important;
  }
  .w-md-357px {
    width: 357px !important;
  }
  .w-md-358px {
    width: 358px !important;
  }
  .w-md-359px {
    width: 359px !important;
  }
  .w-md-360px {
    width: 360px !important;
  }
  .w-md-361px {
    width: 361px !important;
  }
  .w-md-362px {
    width: 362px !important;
  }
  .w-md-363px {
    width: 363px !important;
  }
  .w-md-364px {
    width: 364px !important;
  }
  .w-md-365px {
    width: 365px !important;
  }
  .w-md-366px {
    width: 366px !important;
  }
  .w-md-367px {
    width: 367px !important;
  }
  .w-md-368px {
    width: 368px !important;
  }
  .w-md-369px {
    width: 369px !important;
  }
  .w-md-370px {
    width: 370px !important;
  }
  .w-md-371px {
    width: 371px !important;
  }
  .w-md-372px {
    width: 372px !important;
  }
  .w-md-373px {
    width: 373px !important;
  }
  .w-md-374px {
    width: 374px !important;
  }
  .w-md-375px {
    width: 375px !important;
  }
  .w-md-376px {
    width: 376px !important;
  }
  .w-md-377px {
    width: 377px !important;
  }
  .w-md-378px {
    width: 378px !important;
  }
  .w-md-379px {
    width: 379px !important;
  }
  .w-md-380px {
    width: 380px !important;
  }
  .w-md-381px {
    width: 381px !important;
  }
  .w-md-382px {
    width: 382px !important;
  }
  .w-md-383px {
    width: 383px !important;
  }
  .w-md-384px {
    width: 384px !important;
  }
  .w-md-385px {
    width: 385px !important;
  }
  .w-md-386px {
    width: 386px !important;
  }
  .w-md-387px {
    width: 387px !important;
  }
  .w-md-388px {
    width: 388px !important;
  }
  .w-md-389px {
    width: 389px !important;
  }
  .w-md-390px {
    width: 390px !important;
  }
  .w-md-391px {
    width: 391px !important;
  }
  .w-md-392px {
    width: 392px !important;
  }
  .w-md-393px {
    width: 393px !important;
  }
  .w-md-394px {
    width: 394px !important;
  }
  .w-md-395px {
    width: 395px !important;
  }
  .w-md-396px {
    width: 396px !important;
  }
  .w-md-397px {
    width: 397px !important;
  }
  .w-md-398px {
    width: 398px !important;
  }
  .w-md-399px {
    width: 399px !important;
  }
  .w-md-400px {
    width: 400px !important;
  }
  .w-md-401px {
    width: 401px !important;
  }
  .w-md-402px {
    width: 402px !important;
  }
  .w-md-403px {
    width: 403px !important;
  }
  .w-md-404px {
    width: 404px !important;
  }
  .w-md-405px {
    width: 405px !important;
  }
  .w-md-406px {
    width: 406px !important;
  }
  .w-md-407px {
    width: 407px !important;
  }
  .w-md-408px {
    width: 408px !important;
  }
  .w-md-409px {
    width: 409px !important;
  }
  .w-md-410px {
    width: 410px !important;
  }
  .w-md-411px {
    width: 411px !important;
  }
  .w-md-412px {
    width: 412px !important;
  }
  .w-md-413px {
    width: 413px !important;
  }
  .w-md-414px {
    width: 414px !important;
  }
  .w-md-415px {
    width: 415px !important;
  }
  .w-md-416px {
    width: 416px !important;
  }
  .w-md-417px {
    width: 417px !important;
  }
  .w-md-418px {
    width: 418px !important;
  }
  .w-md-419px {
    width: 419px !important;
  }
  .w-md-420px {
    width: 420px !important;
  }
  .w-md-421px {
    width: 421px !important;
  }
  .w-md-422px {
    width: 422px !important;
  }
  .w-md-423px {
    width: 423px !important;
  }
  .w-md-424px {
    width: 424px !important;
  }
  .w-md-425px {
    width: 425px !important;
  }
  .w-md-426px {
    width: 426px !important;
  }
  .w-md-427px {
    width: 427px !important;
  }
  .w-md-428px {
    width: 428px !important;
  }
  .w-md-429px {
    width: 429px !important;
  }
  .w-md-430px {
    width: 430px !important;
  }
  .w-md-431px {
    width: 431px !important;
  }
  .w-md-432px {
    width: 432px !important;
  }
  .w-md-433px {
    width: 433px !important;
  }
  .w-md-434px {
    width: 434px !important;
  }
  .w-md-435px {
    width: 435px !important;
  }
  .w-md-436px {
    width: 436px !important;
  }
  .w-md-437px {
    width: 437px !important;
  }
  .w-md-438px {
    width: 438px !important;
  }
  .w-md-439px {
    width: 439px !important;
  }
  .w-md-440px {
    width: 440px !important;
  }
  .w-md-441px {
    width: 441px !important;
  }
  .w-md-442px {
    width: 442px !important;
  }
  .w-md-443px {
    width: 443px !important;
  }
  .w-md-444px {
    width: 444px !important;
  }
  .w-md-445px {
    width: 445px !important;
  }
  .w-md-446px {
    width: 446px !important;
  }
  .w-md-447px {
    width: 447px !important;
  }
  .w-md-448px {
    width: 448px !important;
  }
  .w-md-449px {
    width: 449px !important;
  }
  .w-md-450px {
    width: 450px !important;
  }
  .w-md-451px {
    width: 451px !important;
  }
  .w-md-452px {
    width: 452px !important;
  }
  .w-md-453px {
    width: 453px !important;
  }
  .w-md-454px {
    width: 454px !important;
  }
  .w-md-455px {
    width: 455px !important;
  }
  .w-md-456px {
    width: 456px !important;
  }
  .w-md-457px {
    width: 457px !important;
  }
  .w-md-458px {
    width: 458px !important;
  }
  .w-md-459px {
    width: 459px !important;
  }
  .w-md-460px {
    width: 460px !important;
  }
  .w-md-461px {
    width: 461px !important;
  }
  .w-md-462px {
    width: 462px !important;
  }
  .w-md-463px {
    width: 463px !important;
  }
  .w-md-464px {
    width: 464px !important;
  }
  .w-md-465px {
    width: 465px !important;
  }
  .w-md-466px {
    width: 466px !important;
  }
  .w-md-467px {
    width: 467px !important;
  }
  .w-md-468px {
    width: 468px !important;
  }
  .w-md-469px {
    width: 469px !important;
  }
  .w-md-470px {
    width: 470px !important;
  }
  .w-md-471px {
    width: 471px !important;
  }
  .w-md-472px {
    width: 472px !important;
  }
  .w-md-473px {
    width: 473px !important;
  }
  .w-md-474px {
    width: 474px !important;
  }
  .w-md-475px {
    width: 475px !important;
  }
  .w-md-476px {
    width: 476px !important;
  }
  .w-md-477px {
    width: 477px !important;
  }
  .w-md-478px {
    width: 478px !important;
  }
  .w-md-479px {
    width: 479px !important;
  }
  .w-md-480px {
    width: 480px !important;
  }
  .w-md-481px {
    width: 481px !important;
  }
  .w-md-482px {
    width: 482px !important;
  }
  .w-md-483px {
    width: 483px !important;
  }
  .w-md-484px {
    width: 484px !important;
  }
  .w-md-485px {
    width: 485px !important;
  }
  .w-md-486px {
    width: 486px !important;
  }
  .w-md-487px {
    width: 487px !important;
  }
  .w-md-488px {
    width: 488px !important;
  }
  .w-md-489px {
    width: 489px !important;
  }
  .w-md-490px {
    width: 490px !important;
  }
  .w-md-491px {
    width: 491px !important;
  }
  .w-md-492px {
    width: 492px !important;
  }
  .w-md-493px {
    width: 493px !important;
  }
  .w-md-494px {
    width: 494px !important;
  }
  .w-md-495px {
    width: 495px !important;
  }
  .w-md-496px {
    width: 496px !important;
  }
  .w-md-497px {
    width: 497px !important;
  }
  .w-md-498px {
    width: 498px !important;
  }
  .w-md-499px {
    width: 499px !important;
  }
  .w-md-500px {
    width: 500px !important;
  }
  .w-md-501px {
    width: 501px !important;
  }
  .w-md-502px {
    width: 502px !important;
  }
  .w-md-503px {
    width: 503px !important;
  }
  .w-md-504px {
    width: 504px !important;
  }
  .w-md-505px {
    width: 505px !important;
  }
  .w-md-506px {
    width: 506px !important;
  }
  .w-md-507px {
    width: 507px !important;
  }
  .w-md-508px {
    width: 508px !important;
  }
  .w-md-509px {
    width: 509px !important;
  }
  .w-md-510px {
    width: 510px !important;
  }
  .w-md-511px {
    width: 511px !important;
  }
  .w-md-512px {
    width: 512px !important;
  }
  .w-md-513px {
    width: 513px !important;
  }
  .w-md-514px {
    width: 514px !important;
  }
  .w-md-515px {
    width: 515px !important;
  }
  .w-md-516px {
    width: 516px !important;
  }
  .w-md-517px {
    width: 517px !important;
  }
  .w-md-518px {
    width: 518px !important;
  }
  .w-md-519px {
    width: 519px !important;
  }
  .w-md-520px {
    width: 520px !important;
  }
  .w-md-521px {
    width: 521px !important;
  }
  .w-md-522px {
    width: 522px !important;
  }
  .w-md-523px {
    width: 523px !important;
  }
  .w-md-524px {
    width: 524px !important;
  }
  .w-md-525px {
    width: 525px !important;
  }
  .w-md-526px {
    width: 526px !important;
  }
  .w-md-527px {
    width: 527px !important;
  }
  .w-md-528px {
    width: 528px !important;
  }
  .w-md-529px {
    width: 529px !important;
  }
  .w-md-530px {
    width: 530px !important;
  }
  .w-md-531px {
    width: 531px !important;
  }
  .w-md-532px {
    width: 532px !important;
  }
  .w-md-533px {
    width: 533px !important;
  }
  .w-md-534px {
    width: 534px !important;
  }
  .w-md-535px {
    width: 535px !important;
  }
  .w-md-536px {
    width: 536px !important;
  }
  .w-md-537px {
    width: 537px !important;
  }
  .w-md-538px {
    width: 538px !important;
  }
  .w-md-539px {
    width: 539px !important;
  }
  .w-md-540px {
    width: 540px !important;
  }
  .w-md-541px {
    width: 541px !important;
  }
  .w-md-542px {
    width: 542px !important;
  }
  .w-md-543px {
    width: 543px !important;
  }
  .w-md-544px {
    width: 544px !important;
  }
  .w-md-545px {
    width: 545px !important;
  }
  .w-md-546px {
    width: 546px !important;
  }
  .w-md-547px {
    width: 547px !important;
  }
  .w-md-548px {
    width: 548px !important;
  }
  .w-md-549px {
    width: 549px !important;
  }
  .w-md-550px {
    width: 550px !important;
  }
  .w-md-551px {
    width: 551px !important;
  }
  .w-md-552px {
    width: 552px !important;
  }
  .w-md-553px {
    width: 553px !important;
  }
  .w-md-554px {
    width: 554px !important;
  }
  .w-md-555px {
    width: 555px !important;
  }
  .w-md-556px {
    width: 556px !important;
  }
  .w-md-557px {
    width: 557px !important;
  }
  .w-md-558px {
    width: 558px !important;
  }
  .w-md-559px {
    width: 559px !important;
  }
  .w-md-560px {
    width: 560px !important;
  }
  .w-md-561px {
    width: 561px !important;
  }
  .w-md-562px {
    width: 562px !important;
  }
  .w-md-563px {
    width: 563px !important;
  }
  .w-md-564px {
    width: 564px !important;
  }
  .w-md-565px {
    width: 565px !important;
  }
  .w-md-566px {
    width: 566px !important;
  }
  .w-md-567px {
    width: 567px !important;
  }
  .w-md-568px {
    width: 568px !important;
  }
  .w-md-569px {
    width: 569px !important;
  }
  .w-md-570px {
    width: 570px !important;
  }
  .w-md-571px {
    width: 571px !important;
  }
  .w-md-572px {
    width: 572px !important;
  }
  .w-md-573px {
    width: 573px !important;
  }
  .w-md-574px {
    width: 574px !important;
  }
  .w-md-575px {
    width: 575px !important;
  }
  .w-md-576px {
    width: 576px !important;
  }
  .w-md-577px {
    width: 577px !important;
  }
  .w-md-578px {
    width: 578px !important;
  }
  .w-md-579px {
    width: 579px !important;
  }
  .w-md-580px {
    width: 580px !important;
  }
  .w-md-581px {
    width: 581px !important;
  }
  .w-md-582px {
    width: 582px !important;
  }
  .w-md-583px {
    width: 583px !important;
  }
  .w-md-584px {
    width: 584px !important;
  }
  .w-md-585px {
    width: 585px !important;
  }
  .w-md-586px {
    width: 586px !important;
  }
  .w-md-587px {
    width: 587px !important;
  }
  .w-md-588px {
    width: 588px !important;
  }
  .w-md-589px {
    width: 589px !important;
  }
  .w-md-590px {
    width: 590px !important;
  }
  .w-md-591px {
    width: 591px !important;
  }
  .w-md-592px {
    width: 592px !important;
  }
  .w-md-593px {
    width: 593px !important;
  }
  .w-md-594px {
    width: 594px !important;
  }
  .w-md-595px {
    width: 595px !important;
  }
  .w-md-596px {
    width: 596px !important;
  }
  .w-md-597px {
    width: 597px !important;
  }
  .w-md-598px {
    width: 598px !important;
  }
  .w-md-599px {
    width: 599px !important;
  }
  .w-md-600px {
    width: 600px !important;
  }
  .w-md-601px {
    width: 601px !important;
  }
  .w-md-602px {
    width: 602px !important;
  }
  .w-md-603px {
    width: 603px !important;
  }
  .w-md-604px {
    width: 604px !important;
  }
  .w-md-605px {
    width: 605px !important;
  }
  .w-md-606px {
    width: 606px !important;
  }
  .w-md-607px {
    width: 607px !important;
  }
  .w-md-608px {
    width: 608px !important;
  }
  .w-md-609px {
    width: 609px !important;
  }
  .w-md-610px {
    width: 610px !important;
  }
  .w-md-611px {
    width: 611px !important;
  }
  .w-md-612px {
    width: 612px !important;
  }
  .w-md-613px {
    width: 613px !important;
  }
  .w-md-614px {
    width: 614px !important;
  }
  .w-md-615px {
    width: 615px !important;
  }
  .w-md-616px {
    width: 616px !important;
  }
  .w-md-617px {
    width: 617px !important;
  }
  .w-md-618px {
    width: 618px !important;
  }
  .w-md-619px {
    width: 619px !important;
  }
  .w-md-620px {
    width: 620px !important;
  }
  .w-md-621px {
    width: 621px !important;
  }
  .w-md-622px {
    width: 622px !important;
  }
  .w-md-623px {
    width: 623px !important;
  }
  .w-md-624px {
    width: 624px !important;
  }
  .w-md-625px {
    width: 625px !important;
  }
  .w-md-626px {
    width: 626px !important;
  }
  .w-md-627px {
    width: 627px !important;
  }
  .w-md-628px {
    width: 628px !important;
  }
  .w-md-629px {
    width: 629px !important;
  }
  .w-md-630px {
    width: 630px !important;
  }
  .w-md-631px {
    width: 631px !important;
  }
  .w-md-632px {
    width: 632px !important;
  }
  .w-md-633px {
    width: 633px !important;
  }
  .w-md-634px {
    width: 634px !important;
  }
  .w-md-635px {
    width: 635px !important;
  }
  .w-md-636px {
    width: 636px !important;
  }
  .w-md-637px {
    width: 637px !important;
  }
  .w-md-638px {
    width: 638px !important;
  }
  .w-md-639px {
    width: 639px !important;
  }
  .w-md-640px {
    width: 640px !important;
  }
  .w-md-641px {
    width: 641px !important;
  }
  .w-md-642px {
    width: 642px !important;
  }
  .w-md-643px {
    width: 643px !important;
  }
  .w-md-644px {
    width: 644px !important;
  }
  .w-md-645px {
    width: 645px !important;
  }
  .w-md-646px {
    width: 646px !important;
  }
  .w-md-647px {
    width: 647px !important;
  }
  .w-md-648px {
    width: 648px !important;
  }
  .w-md-649px {
    width: 649px !important;
  }
  .w-md-650px {
    width: 650px !important;
  }
  .w-md-651px {
    width: 651px !important;
  }
  .w-md-652px {
    width: 652px !important;
  }
  .w-md-653px {
    width: 653px !important;
  }
  .w-md-654px {
    width: 654px !important;
  }
  .w-md-655px {
    width: 655px !important;
  }
  .w-md-656px {
    width: 656px !important;
  }
  .w-md-657px {
    width: 657px !important;
  }
  .w-md-658px {
    width: 658px !important;
  }
  .w-md-659px {
    width: 659px !important;
  }
  .w-md-660px {
    width: 660px !important;
  }
  .w-md-661px {
    width: 661px !important;
  }
  .w-md-662px {
    width: 662px !important;
  }
  .w-md-663px {
    width: 663px !important;
  }
  .w-md-664px {
    width: 664px !important;
  }
  .w-md-665px {
    width: 665px !important;
  }
  .w-md-666px {
    width: 666px !important;
  }
  .w-md-667px {
    width: 667px !important;
  }
  .w-md-668px {
    width: 668px !important;
  }
  .w-md-669px {
    width: 669px !important;
  }
  .w-md-670px {
    width: 670px !important;
  }
  .w-md-671px {
    width: 671px !important;
  }
  .w-md-672px {
    width: 672px !important;
  }
  .w-md-673px {
    width: 673px !important;
  }
  .w-md-674px {
    width: 674px !important;
  }
  .w-md-675px {
    width: 675px !important;
  }
  .w-md-676px {
    width: 676px !important;
  }
  .w-md-677px {
    width: 677px !important;
  }
  .w-md-678px {
    width: 678px !important;
  }
  .w-md-679px {
    width: 679px !important;
  }
  .w-md-680px {
    width: 680px !important;
  }
  .w-md-681px {
    width: 681px !important;
  }
  .w-md-682px {
    width: 682px !important;
  }
  .w-md-683px {
    width: 683px !important;
  }
  .w-md-684px {
    width: 684px !important;
  }
  .w-md-685px {
    width: 685px !important;
  }
  .w-md-686px {
    width: 686px !important;
  }
  .w-md-687px {
    width: 687px !important;
  }
  .w-md-688px {
    width: 688px !important;
  }
  .w-md-689px {
    width: 689px !important;
  }
  .w-md-690px {
    width: 690px !important;
  }
  .w-md-691px {
    width: 691px !important;
  }
  .w-md-692px {
    width: 692px !important;
  }
  .w-md-693px {
    width: 693px !important;
  }
  .w-md-694px {
    width: 694px !important;
  }
  .w-md-695px {
    width: 695px !important;
  }
  .w-md-696px {
    width: 696px !important;
  }
  .w-md-697px {
    width: 697px !important;
  }
  .w-md-698px {
    width: 698px !important;
  }
  .w-md-699px {
    width: 699px !important;
  }
  .w-md-700px {
    width: 700px !important;
  }
  .w-md-701px {
    width: 701px !important;
  }
  .w-md-702px {
    width: 702px !important;
  }
  .w-md-703px {
    width: 703px !important;
  }
  .w-md-704px {
    width: 704px !important;
  }
  .w-md-705px {
    width: 705px !important;
  }
  .w-md-706px {
    width: 706px !important;
  }
  .w-md-707px {
    width: 707px !important;
  }
  .w-md-708px {
    width: 708px !important;
  }
  .w-md-709px {
    width: 709px !important;
  }
  .w-md-710px {
    width: 710px !important;
  }
  .w-md-711px {
    width: 711px !important;
  }
  .w-md-712px {
    width: 712px !important;
  }
  .w-md-713px {
    width: 713px !important;
  }
  .w-md-714px {
    width: 714px !important;
  }
  .w-md-715px {
    width: 715px !important;
  }
  .w-md-716px {
    width: 716px !important;
  }
  .w-md-717px {
    width: 717px !important;
  }
  .w-md-718px {
    width: 718px !important;
  }
  .w-md-719px {
    width: 719px !important;
  }
  .w-md-720px {
    width: 720px !important;
  }
  .w-md-721px {
    width: 721px !important;
  }
  .w-md-722px {
    width: 722px !important;
  }
  .w-md-723px {
    width: 723px !important;
  }
  .w-md-724px {
    width: 724px !important;
  }
  .w-md-725px {
    width: 725px !important;
  }
  .w-md-726px {
    width: 726px !important;
  }
  .w-md-727px {
    width: 727px !important;
  }
  .w-md-728px {
    width: 728px !important;
  }
  .w-md-729px {
    width: 729px !important;
  }
  .w-md-730px {
    width: 730px !important;
  }
  .w-md-731px {
    width: 731px !important;
  }
  .w-md-732px {
    width: 732px !important;
  }
  .w-md-733px {
    width: 733px !important;
  }
  .w-md-734px {
    width: 734px !important;
  }
  .w-md-735px {
    width: 735px !important;
  }
  .w-md-736px {
    width: 736px !important;
  }
  .w-md-737px {
    width: 737px !important;
  }
  .w-md-738px {
    width: 738px !important;
  }
  .w-md-739px {
    width: 739px !important;
  }
  .w-md-740px {
    width: 740px !important;
  }
  .w-md-741px {
    width: 741px !important;
  }
  .w-md-742px {
    width: 742px !important;
  }
  .w-md-743px {
    width: 743px !important;
  }
  .w-md-744px {
    width: 744px !important;
  }
  .w-md-745px {
    width: 745px !important;
  }
  .w-md-746px {
    width: 746px !important;
  }
  .w-md-747px {
    width: 747px !important;
  }
  .w-md-748px {
    width: 748px !important;
  }
  .w-md-749px {
    width: 749px !important;
  }
  .w-md-750px {
    width: 750px !important;
  }
  .w-md-751px {
    width: 751px !important;
  }
  .w-md-752px {
    width: 752px !important;
  }
  .w-md-753px {
    width: 753px !important;
  }
  .w-md-754px {
    width: 754px !important;
  }
  .w-md-755px {
    width: 755px !important;
  }
  .w-md-756px {
    width: 756px !important;
  }
  .w-md-757px {
    width: 757px !important;
  }
  .w-md-758px {
    width: 758px !important;
  }
  .w-md-759px {
    width: 759px !important;
  }
  .w-md-760px {
    width: 760px !important;
  }
  .w-md-761px {
    width: 761px !important;
  }
  .w-md-762px {
    width: 762px !important;
  }
  .w-md-763px {
    width: 763px !important;
  }
  .w-md-764px {
    width: 764px !important;
  }
  .w-md-765px {
    width: 765px !important;
  }
  .w-md-766px {
    width: 766px !important;
  }
  .w-md-767px {
    width: 767px !important;
  }
  .w-md-768px {
    width: 768px !important;
  }
  .w-md-769px {
    width: 769px !important;
  }
  .w-md-770px {
    width: 770px !important;
  }
  .w-md-771px {
    width: 771px !important;
  }
  .w-md-772px {
    width: 772px !important;
  }
  .w-md-773px {
    width: 773px !important;
  }
  .w-md-774px {
    width: 774px !important;
  }
  .w-md-775px {
    width: 775px !important;
  }
  .w-md-776px {
    width: 776px !important;
  }
  .w-md-777px {
    width: 777px !important;
  }
  .w-md-778px {
    width: 778px !important;
  }
  .w-md-779px {
    width: 779px !important;
  }
  .w-md-780px {
    width: 780px !important;
  }
  .w-md-781px {
    width: 781px !important;
  }
  .w-md-782px {
    width: 782px !important;
  }
  .w-md-783px {
    width: 783px !important;
  }
  .w-md-784px {
    width: 784px !important;
  }
  .w-md-785px {
    width: 785px !important;
  }
  .w-md-786px {
    width: 786px !important;
  }
  .w-md-787px {
    width: 787px !important;
  }
  .w-md-788px {
    width: 788px !important;
  }
  .w-md-789px {
    width: 789px !important;
  }
  .w-md-790px {
    width: 790px !important;
  }
  .w-md-791px {
    width: 791px !important;
  }
  .w-md-792px {
    width: 792px !important;
  }
  .w-md-793px {
    width: 793px !important;
  }
  .w-md-794px {
    width: 794px !important;
  }
  .w-md-795px {
    width: 795px !important;
  }
  .w-md-796px {
    width: 796px !important;
  }
  .w-md-797px {
    width: 797px !important;
  }
  .w-md-798px {
    width: 798px !important;
  }
  .w-md-799px {
    width: 799px !important;
  }
  .w-md-800px {
    width: 800px !important;
  }
  .w-md-801px {
    width: 801px !important;
  }
  .w-md-802px {
    width: 802px !important;
  }
  .w-md-803px {
    width: 803px !important;
  }
  .w-md-804px {
    width: 804px !important;
  }
  .w-md-805px {
    width: 805px !important;
  }
  .w-md-806px {
    width: 806px !important;
  }
  .w-md-807px {
    width: 807px !important;
  }
  .w-md-808px {
    width: 808px !important;
  }
  .w-md-809px {
    width: 809px !important;
  }
  .w-md-810px {
    width: 810px !important;
  }
  .w-md-811px {
    width: 811px !important;
  }
  .w-md-812px {
    width: 812px !important;
  }
  .w-md-813px {
    width: 813px !important;
  }
  .w-md-814px {
    width: 814px !important;
  }
  .w-md-815px {
    width: 815px !important;
  }
  .w-md-816px {
    width: 816px !important;
  }
  .w-md-817px {
    width: 817px !important;
  }
  .w-md-818px {
    width: 818px !important;
  }
  .w-md-819px {
    width: 819px !important;
  }
  .w-md-820px {
    width: 820px !important;
  }
  .w-md-821px {
    width: 821px !important;
  }
  .w-md-822px {
    width: 822px !important;
  }
  .w-md-823px {
    width: 823px !important;
  }
  .w-md-824px {
    width: 824px !important;
  }
  .w-md-825px {
    width: 825px !important;
  }
  .w-md-826px {
    width: 826px !important;
  }
  .w-md-827px {
    width: 827px !important;
  }
  .w-md-828px {
    width: 828px !important;
  }
  .w-md-829px {
    width: 829px !important;
  }
  .w-md-830px {
    width: 830px !important;
  }
  .w-md-831px {
    width: 831px !important;
  }
  .w-md-832px {
    width: 832px !important;
  }
  .w-md-833px {
    width: 833px !important;
  }
  .w-md-834px {
    width: 834px !important;
  }
  .w-md-835px {
    width: 835px !important;
  }
  .w-md-836px {
    width: 836px !important;
  }
  .w-md-837px {
    width: 837px !important;
  }
  .w-md-838px {
    width: 838px !important;
  }
  .w-md-839px {
    width: 839px !important;
  }
  .w-md-840px {
    width: 840px !important;
  }
  .w-md-841px {
    width: 841px !important;
  }
  .w-md-842px {
    width: 842px !important;
  }
  .w-md-843px {
    width: 843px !important;
  }
  .w-md-844px {
    width: 844px !important;
  }
  .w-md-845px {
    width: 845px !important;
  }
  .w-md-846px {
    width: 846px !important;
  }
  .w-md-847px {
    width: 847px !important;
  }
  .w-md-848px {
    width: 848px !important;
  }
  .w-md-849px {
    width: 849px !important;
  }
  .w-md-850px {
    width: 850px !important;
  }
  .w-md-851px {
    width: 851px !important;
  }
  .w-md-852px {
    width: 852px !important;
  }
  .w-md-853px {
    width: 853px !important;
  }
  .w-md-854px {
    width: 854px !important;
  }
  .w-md-855px {
    width: 855px !important;
  }
  .w-md-856px {
    width: 856px !important;
  }
  .w-md-857px {
    width: 857px !important;
  }
  .w-md-858px {
    width: 858px !important;
  }
  .w-md-859px {
    width: 859px !important;
  }
  .w-md-860px {
    width: 860px !important;
  }
  .w-md-861px {
    width: 861px !important;
  }
  .w-md-862px {
    width: 862px !important;
  }
  .w-md-863px {
    width: 863px !important;
  }
  .w-md-864px {
    width: 864px !important;
  }
  .w-md-865px {
    width: 865px !important;
  }
  .w-md-866px {
    width: 866px !important;
  }
  .w-md-867px {
    width: 867px !important;
  }
  .w-md-868px {
    width: 868px !important;
  }
  .w-md-869px {
    width: 869px !important;
  }
  .w-md-870px {
    width: 870px !important;
  }
  .w-md-871px {
    width: 871px !important;
  }
  .w-md-872px {
    width: 872px !important;
  }
  .w-md-873px {
    width: 873px !important;
  }
  .w-md-874px {
    width: 874px !important;
  }
  .w-md-875px {
    width: 875px !important;
  }
  .w-md-876px {
    width: 876px !important;
  }
  .w-md-877px {
    width: 877px !important;
  }
  .w-md-878px {
    width: 878px !important;
  }
  .w-md-879px {
    width: 879px !important;
  }
  .w-md-880px {
    width: 880px !important;
  }
  .w-md-881px {
    width: 881px !important;
  }
  .w-md-882px {
    width: 882px !important;
  }
  .w-md-883px {
    width: 883px !important;
  }
  .w-md-884px {
    width: 884px !important;
  }
  .w-md-885px {
    width: 885px !important;
  }
  .w-md-886px {
    width: 886px !important;
  }
  .w-md-887px {
    width: 887px !important;
  }
  .w-md-888px {
    width: 888px !important;
  }
  .w-md-889px {
    width: 889px !important;
  }
  .w-md-890px {
    width: 890px !important;
  }
  .w-md-891px {
    width: 891px !important;
  }
  .w-md-892px {
    width: 892px !important;
  }
  .w-md-893px {
    width: 893px !important;
  }
  .w-md-894px {
    width: 894px !important;
  }
  .w-md-895px {
    width: 895px !important;
  }
  .w-md-896px {
    width: 896px !important;
  }
  .w-md-897px {
    width: 897px !important;
  }
  .w-md-898px {
    width: 898px !important;
  }
  .w-md-899px {
    width: 899px !important;
  }
  .w-md-900px {
    width: 900px !important;
  }
  .w-md-901px {
    width: 901px !important;
  }
  .w-md-902px {
    width: 902px !important;
  }
  .w-md-903px {
    width: 903px !important;
  }
  .w-md-904px {
    width: 904px !important;
  }
  .w-md-905px {
    width: 905px !important;
  }
  .w-md-906px {
    width: 906px !important;
  }
  .w-md-907px {
    width: 907px !important;
  }
  .w-md-908px {
    width: 908px !important;
  }
  .w-md-909px {
    width: 909px !important;
  }
  .w-md-910px {
    width: 910px !important;
  }
  .w-md-911px {
    width: 911px !important;
  }
  .w-md-912px {
    width: 912px !important;
  }
  .w-md-913px {
    width: 913px !important;
  }
  .w-md-914px {
    width: 914px !important;
  }
  .w-md-915px {
    width: 915px !important;
  }
  .w-md-916px {
    width: 916px !important;
  }
  .w-md-917px {
    width: 917px !important;
  }
  .w-md-918px {
    width: 918px !important;
  }
  .w-md-919px {
    width: 919px !important;
  }
  .w-md-920px {
    width: 920px !important;
  }
  .w-md-921px {
    width: 921px !important;
  }
  .w-md-922px {
    width: 922px !important;
  }
  .w-md-923px {
    width: 923px !important;
  }
  .w-md-924px {
    width: 924px !important;
  }
  .w-md-925px {
    width: 925px !important;
  }
  .w-md-926px {
    width: 926px !important;
  }
  .w-md-927px {
    width: 927px !important;
  }
  .w-md-928px {
    width: 928px !important;
  }
  .w-md-929px {
    width: 929px !important;
  }
  .w-md-930px {
    width: 930px !important;
  }
  .w-md-931px {
    width: 931px !important;
  }
  .w-md-932px {
    width: 932px !important;
  }
  .w-md-933px {
    width: 933px !important;
  }
  .w-md-934px {
    width: 934px !important;
  }
  .w-md-935px {
    width: 935px !important;
  }
  .w-md-936px {
    width: 936px !important;
  }
  .w-md-937px {
    width: 937px !important;
  }
  .w-md-938px {
    width: 938px !important;
  }
  .w-md-939px {
    width: 939px !important;
  }
  .w-md-940px {
    width: 940px !important;
  }
  .w-md-941px {
    width: 941px !important;
  }
  .w-md-942px {
    width: 942px !important;
  }
  .w-md-943px {
    width: 943px !important;
  }
  .w-md-944px {
    width: 944px !important;
  }
  .w-md-945px {
    width: 945px !important;
  }
  .w-md-946px {
    width: 946px !important;
  }
  .w-md-947px {
    width: 947px !important;
  }
  .w-md-948px {
    width: 948px !important;
  }
  .w-md-949px {
    width: 949px !important;
  }
  .w-md-950px {
    width: 950px !important;
  }
  .w-md-951px {
    width: 951px !important;
  }
  .w-md-952px {
    width: 952px !important;
  }
  .w-md-953px {
    width: 953px !important;
  }
  .w-md-954px {
    width: 954px !important;
  }
  .w-md-955px {
    width: 955px !important;
  }
  .w-md-956px {
    width: 956px !important;
  }
  .w-md-957px {
    width: 957px !important;
  }
  .w-md-958px {
    width: 958px !important;
  }
  .w-md-959px {
    width: 959px !important;
  }
  .w-md-960px {
    width: 960px !important;
  }
  .w-md-961px {
    width: 961px !important;
  }
  .w-md-962px {
    width: 962px !important;
  }
  .w-md-963px {
    width: 963px !important;
  }
  .w-md-964px {
    width: 964px !important;
  }
  .w-md-965px {
    width: 965px !important;
  }
  .w-md-966px {
    width: 966px !important;
  }
  .w-md-967px {
    width: 967px !important;
  }
  .w-md-968px {
    width: 968px !important;
  }
  .w-md-969px {
    width: 969px !important;
  }
  .w-md-970px {
    width: 970px !important;
  }
  .w-md-971px {
    width: 971px !important;
  }
  .w-md-972px {
    width: 972px !important;
  }
  .w-md-973px {
    width: 973px !important;
  }
  .w-md-974px {
    width: 974px !important;
  }
  .w-md-975px {
    width: 975px !important;
  }
  .w-md-976px {
    width: 976px !important;
  }
  .w-md-977px {
    width: 977px !important;
  }
  .w-md-978px {
    width: 978px !important;
  }
  .w-md-979px {
    width: 979px !important;
  }
  .w-md-980px {
    width: 980px !important;
  }
  .w-md-981px {
    width: 981px !important;
  }
  .w-md-982px {
    width: 982px !important;
  }
  .w-md-983px {
    width: 983px !important;
  }
  .w-md-984px {
    width: 984px !important;
  }
  .w-md-985px {
    width: 985px !important;
  }
  .w-md-986px {
    width: 986px !important;
  }
  .w-md-987px {
    width: 987px !important;
  }
  .w-md-988px {
    width: 988px !important;
  }
  .w-md-989px {
    width: 989px !important;
  }
  .w-md-990px {
    width: 990px !important;
  }
  .w-md-991px {
    width: 991px !important;
  }
  .w-md-992px {
    width: 992px !important;
  }
  .w-md-993px {
    width: 993px !important;
  }
  .w-md-994px {
    width: 994px !important;
  }
  .w-md-995px {
    width: 995px !important;
  }
  .w-md-996px {
    width: 996px !important;
  }
  .w-md-997px {
    width: 997px !important;
  }
  .w-md-998px {
    width: 998px !important;
  }
  .w-md-999px {
    width: 999px !important;
  }
  .w-md-1000px {
    width: 1000px !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-0px {
    width: 0px !important;
  }
  .w-lg-1px {
    width: 1px !important;
  }
  .w-lg-2px {
    width: 2px !important;
  }
  .w-lg-3px {
    width: 3px !important;
  }
  .w-lg-4px {
    width: 4px !important;
  }
  .w-lg-5px {
    width: 5px !important;
  }
  .w-lg-6px {
    width: 6px !important;
  }
  .w-lg-7px {
    width: 7px !important;
  }
  .w-lg-8px {
    width: 8px !important;
  }
  .w-lg-9px {
    width: 9px !important;
  }
  .w-lg-10px {
    width: 10px !important;
  }
  .w-lg-11px {
    width: 11px !important;
  }
  .w-lg-12px {
    width: 12px !important;
  }
  .w-lg-13px {
    width: 13px !important;
  }
  .w-lg-14px {
    width: 14px !important;
  }
  .w-lg-15px {
    width: 15px !important;
  }
  .w-lg-16px {
    width: 16px !important;
  }
  .w-lg-17px {
    width: 17px !important;
  }
  .w-lg-18px {
    width: 18px !important;
  }
  .w-lg-19px {
    width: 19px !important;
  }
  .w-lg-20px {
    width: 20px !important;
  }
  .w-lg-21px {
    width: 21px !important;
  }
  .w-lg-22px {
    width: 22px !important;
  }
  .w-lg-23px {
    width: 23px !important;
  }
  .w-lg-24px {
    width: 24px !important;
  }
  .w-lg-25px {
    width: 25px !important;
  }
  .w-lg-26px {
    width: 26px !important;
  }
  .w-lg-27px {
    width: 27px !important;
  }
  .w-lg-28px {
    width: 28px !important;
  }
  .w-lg-29px {
    width: 29px !important;
  }
  .w-lg-30px {
    width: 30px !important;
  }
  .w-lg-31px {
    width: 31px !important;
  }
  .w-lg-32px {
    width: 32px !important;
  }
  .w-lg-33px {
    width: 33px !important;
  }
  .w-lg-34px {
    width: 34px !important;
  }
  .w-lg-35px {
    width: 35px !important;
  }
  .w-lg-36px {
    width: 36px !important;
  }
  .w-lg-37px {
    width: 37px !important;
  }
  .w-lg-38px {
    width: 38px !important;
  }
  .w-lg-39px {
    width: 39px !important;
  }
  .w-lg-40px {
    width: 40px !important;
  }
  .w-lg-41px {
    width: 41px !important;
  }
  .w-lg-42px {
    width: 42px !important;
  }
  .w-lg-43px {
    width: 43px !important;
  }
  .w-lg-44px {
    width: 44px !important;
  }
  .w-lg-45px {
    width: 45px !important;
  }
  .w-lg-46px {
    width: 46px !important;
  }
  .w-lg-47px {
    width: 47px !important;
  }
  .w-lg-48px {
    width: 48px !important;
  }
  .w-lg-49px {
    width: 49px !important;
  }
  .w-lg-50px {
    width: 50px !important;
  }
  .w-lg-51px {
    width: 51px !important;
  }
  .w-lg-52px {
    width: 52px !important;
  }
  .w-lg-53px {
    width: 53px !important;
  }
  .w-lg-54px {
    width: 54px !important;
  }
  .w-lg-55px {
    width: 55px !important;
  }
  .w-lg-56px {
    width: 56px !important;
  }
  .w-lg-57px {
    width: 57px !important;
  }
  .w-lg-58px {
    width: 58px !important;
  }
  .w-lg-59px {
    width: 59px !important;
  }
  .w-lg-60px {
    width: 60px !important;
  }
  .w-lg-61px {
    width: 61px !important;
  }
  .w-lg-62px {
    width: 62px !important;
  }
  .w-lg-63px {
    width: 63px !important;
  }
  .w-lg-64px {
    width: 64px !important;
  }
  .w-lg-65px {
    width: 65px !important;
  }
  .w-lg-66px {
    width: 66px !important;
  }
  .w-lg-67px {
    width: 67px !important;
  }
  .w-lg-68px {
    width: 68px !important;
  }
  .w-lg-69px {
    width: 69px !important;
  }
  .w-lg-70px {
    width: 70px !important;
  }
  .w-lg-71px {
    width: 71px !important;
  }
  .w-lg-72px {
    width: 72px !important;
  }
  .w-lg-73px {
    width: 73px !important;
  }
  .w-lg-74px {
    width: 74px !important;
  }
  .w-lg-75px {
    width: 75px !important;
  }
  .w-lg-76px {
    width: 76px !important;
  }
  .w-lg-77px {
    width: 77px !important;
  }
  .w-lg-78px {
    width: 78px !important;
  }
  .w-lg-79px {
    width: 79px !important;
  }
  .w-lg-80px {
    width: 80px !important;
  }
  .w-lg-81px {
    width: 81px !important;
  }
  .w-lg-82px {
    width: 82px !important;
  }
  .w-lg-83px {
    width: 83px !important;
  }
  .w-lg-84px {
    width: 84px !important;
  }
  .w-lg-85px {
    width: 85px !important;
  }
  .w-lg-86px {
    width: 86px !important;
  }
  .w-lg-87px {
    width: 87px !important;
  }
  .w-lg-88px {
    width: 88px !important;
  }
  .w-lg-89px {
    width: 89px !important;
  }
  .w-lg-90px {
    width: 90px !important;
  }
  .w-lg-91px {
    width: 91px !important;
  }
  .w-lg-92px {
    width: 92px !important;
  }
  .w-lg-93px {
    width: 93px !important;
  }
  .w-lg-94px {
    width: 94px !important;
  }
  .w-lg-95px {
    width: 95px !important;
  }
  .w-lg-96px {
    width: 96px !important;
  }
  .w-lg-97px {
    width: 97px !important;
  }
  .w-lg-98px {
    width: 98px !important;
  }
  .w-lg-99px {
    width: 99px !important;
  }
  .w-lg-100px {
    width: 100px !important;
  }
  .w-lg-101px {
    width: 101px !important;
  }
  .w-lg-102px {
    width: 102px !important;
  }
  .w-lg-103px {
    width: 103px !important;
  }
  .w-lg-104px {
    width: 104px !important;
  }
  .w-lg-105px {
    width: 105px !important;
  }
  .w-lg-106px {
    width: 106px !important;
  }
  .w-lg-107px {
    width: 107px !important;
  }
  .w-lg-108px {
    width: 108px !important;
  }
  .w-lg-109px {
    width: 109px !important;
  }
  .w-lg-110px {
    width: 110px !important;
  }
  .w-lg-111px {
    width: 111px !important;
  }
  .w-lg-112px {
    width: 112px !important;
  }
  .w-lg-113px {
    width: 113px !important;
  }
  .w-lg-114px {
    width: 114px !important;
  }
  .w-lg-115px {
    width: 115px !important;
  }
  .w-lg-116px {
    width: 116px !important;
  }
  .w-lg-117px {
    width: 117px !important;
  }
  .w-lg-118px {
    width: 118px !important;
  }
  .w-lg-119px {
    width: 119px !important;
  }
  .w-lg-120px {
    width: 120px !important;
  }
  .w-lg-121px {
    width: 121px !important;
  }
  .w-lg-122px {
    width: 122px !important;
  }
  .w-lg-123px {
    width: 123px !important;
  }
  .w-lg-124px {
    width: 124px !important;
  }
  .w-lg-125px {
    width: 125px !important;
  }
  .w-lg-126px {
    width: 126px !important;
  }
  .w-lg-127px {
    width: 127px !important;
  }
  .w-lg-128px {
    width: 128px !important;
  }
  .w-lg-129px {
    width: 129px !important;
  }
  .w-lg-130px {
    width: 130px !important;
  }
  .w-lg-131px {
    width: 131px !important;
  }
  .w-lg-132px {
    width: 132px !important;
  }
  .w-lg-133px {
    width: 133px !important;
  }
  .w-lg-134px {
    width: 134px !important;
  }
  .w-lg-135px {
    width: 135px !important;
  }
  .w-lg-136px {
    width: 136px !important;
  }
  .w-lg-137px {
    width: 137px !important;
  }
  .w-lg-138px {
    width: 138px !important;
  }
  .w-lg-139px {
    width: 139px !important;
  }
  .w-lg-140px {
    width: 140px !important;
  }
  .w-lg-141px {
    width: 141px !important;
  }
  .w-lg-142px {
    width: 142px !important;
  }
  .w-lg-143px {
    width: 143px !important;
  }
  .w-lg-144px {
    width: 144px !important;
  }
  .w-lg-145px {
    width: 145px !important;
  }
  .w-lg-146px {
    width: 146px !important;
  }
  .w-lg-147px {
    width: 147px !important;
  }
  .w-lg-148px {
    width: 148px !important;
  }
  .w-lg-149px {
    width: 149px !important;
  }
  .w-lg-150px {
    width: 150px !important;
  }
  .w-lg-151px {
    width: 151px !important;
  }
  .w-lg-152px {
    width: 152px !important;
  }
  .w-lg-153px {
    width: 153px !important;
  }
  .w-lg-154px {
    width: 154px !important;
  }
  .w-lg-155px {
    width: 155px !important;
  }
  .w-lg-156px {
    width: 156px !important;
  }
  .w-lg-157px {
    width: 157px !important;
  }
  .w-lg-158px {
    width: 158px !important;
  }
  .w-lg-159px {
    width: 159px !important;
  }
  .w-lg-160px {
    width: 160px !important;
  }
  .w-lg-161px {
    width: 161px !important;
  }
  .w-lg-162px {
    width: 162px !important;
  }
  .w-lg-163px {
    width: 163px !important;
  }
  .w-lg-164px {
    width: 164px !important;
  }
  .w-lg-165px {
    width: 165px !important;
  }
  .w-lg-166px {
    width: 166px !important;
  }
  .w-lg-167px {
    width: 167px !important;
  }
  .w-lg-168px {
    width: 168px !important;
  }
  .w-lg-169px {
    width: 169px !important;
  }
  .w-lg-170px {
    width: 170px !important;
  }
  .w-lg-171px {
    width: 171px !important;
  }
  .w-lg-172px {
    width: 172px !important;
  }
  .w-lg-173px {
    width: 173px !important;
  }
  .w-lg-174px {
    width: 174px !important;
  }
  .w-lg-175px {
    width: 175px !important;
  }
  .w-lg-176px {
    width: 176px !important;
  }
  .w-lg-177px {
    width: 177px !important;
  }
  .w-lg-178px {
    width: 178px !important;
  }
  .w-lg-179px {
    width: 179px !important;
  }
  .w-lg-180px {
    width: 180px !important;
  }
  .w-lg-181px {
    width: 181px !important;
  }
  .w-lg-182px {
    width: 182px !important;
  }
  .w-lg-183px {
    width: 183px !important;
  }
  .w-lg-184px {
    width: 184px !important;
  }
  .w-lg-185px {
    width: 185px !important;
  }
  .w-lg-186px {
    width: 186px !important;
  }
  .w-lg-187px {
    width: 187px !important;
  }
  .w-lg-188px {
    width: 188px !important;
  }
  .w-lg-189px {
    width: 189px !important;
  }
  .w-lg-190px {
    width: 190px !important;
  }
  .w-lg-191px {
    width: 191px !important;
  }
  .w-lg-192px {
    width: 192px !important;
  }
  .w-lg-193px {
    width: 193px !important;
  }
  .w-lg-194px {
    width: 194px !important;
  }
  .w-lg-195px {
    width: 195px !important;
  }
  .w-lg-196px {
    width: 196px !important;
  }
  .w-lg-197px {
    width: 197px !important;
  }
  .w-lg-198px {
    width: 198px !important;
  }
  .w-lg-199px {
    width: 199px !important;
  }
  .w-lg-200px {
    width: 200px !important;
  }
  .w-lg-201px {
    width: 201px !important;
  }
  .w-lg-202px {
    width: 202px !important;
  }
  .w-lg-203px {
    width: 203px !important;
  }
  .w-lg-204px {
    width: 204px !important;
  }
  .w-lg-205px {
    width: 205px !important;
  }
  .w-lg-206px {
    width: 206px !important;
  }
  .w-lg-207px {
    width: 207px !important;
  }
  .w-lg-208px {
    width: 208px !important;
  }
  .w-lg-209px {
    width: 209px !important;
  }
  .w-lg-210px {
    width: 210px !important;
  }
  .w-lg-211px {
    width: 211px !important;
  }
  .w-lg-212px {
    width: 212px !important;
  }
  .w-lg-213px {
    width: 213px !important;
  }
  .w-lg-214px {
    width: 214px !important;
  }
  .w-lg-215px {
    width: 215px !important;
  }
  .w-lg-216px {
    width: 216px !important;
  }
  .w-lg-217px {
    width: 217px !important;
  }
  .w-lg-218px {
    width: 218px !important;
  }
  .w-lg-219px {
    width: 219px !important;
  }
  .w-lg-220px {
    width: 220px !important;
  }
  .w-lg-221px {
    width: 221px !important;
  }
  .w-lg-222px {
    width: 222px !important;
  }
  .w-lg-223px {
    width: 223px !important;
  }
  .w-lg-224px {
    width: 224px !important;
  }
  .w-lg-225px {
    width: 225px !important;
  }
  .w-lg-226px {
    width: 226px !important;
  }
  .w-lg-227px {
    width: 227px !important;
  }
  .w-lg-228px {
    width: 228px !important;
  }
  .w-lg-229px {
    width: 229px !important;
  }
  .w-lg-230px {
    width: 230px !important;
  }
  .w-lg-231px {
    width: 231px !important;
  }
  .w-lg-232px {
    width: 232px !important;
  }
  .w-lg-233px {
    width: 233px !important;
  }
  .w-lg-234px {
    width: 234px !important;
  }
  .w-lg-235px {
    width: 235px !important;
  }
  .w-lg-236px {
    width: 236px !important;
  }
  .w-lg-237px {
    width: 237px !important;
  }
  .w-lg-238px {
    width: 238px !important;
  }
  .w-lg-239px {
    width: 239px !important;
  }
  .w-lg-240px {
    width: 240px !important;
  }
  .w-lg-241px {
    width: 241px !important;
  }
  .w-lg-242px {
    width: 242px !important;
  }
  .w-lg-243px {
    width: 243px !important;
  }
  .w-lg-244px {
    width: 244px !important;
  }
  .w-lg-245px {
    width: 245px !important;
  }
  .w-lg-246px {
    width: 246px !important;
  }
  .w-lg-247px {
    width: 247px !important;
  }
  .w-lg-248px {
    width: 248px !important;
  }
  .w-lg-249px {
    width: 249px !important;
  }
  .w-lg-250px {
    width: 250px !important;
  }
  .w-lg-251px {
    width: 251px !important;
  }
  .w-lg-252px {
    width: 252px !important;
  }
  .w-lg-253px {
    width: 253px !important;
  }
  .w-lg-254px {
    width: 254px !important;
  }
  .w-lg-255px {
    width: 255px !important;
  }
  .w-lg-256px {
    width: 256px !important;
  }
  .w-lg-257px {
    width: 257px !important;
  }
  .w-lg-258px {
    width: 258px !important;
  }
  .w-lg-259px {
    width: 259px !important;
  }
  .w-lg-260px {
    width: 260px !important;
  }
  .w-lg-261px {
    width: 261px !important;
  }
  .w-lg-262px {
    width: 262px !important;
  }
  .w-lg-263px {
    width: 263px !important;
  }
  .w-lg-264px {
    width: 264px !important;
  }
  .w-lg-265px {
    width: 265px !important;
  }
  .w-lg-266px {
    width: 266px !important;
  }
  .w-lg-267px {
    width: 267px !important;
  }
  .w-lg-268px {
    width: 268px !important;
  }
  .w-lg-269px {
    width: 269px !important;
  }
  .w-lg-270px {
    width: 270px !important;
  }
  .w-lg-271px {
    width: 271px !important;
  }
  .w-lg-272px {
    width: 272px !important;
  }
  .w-lg-273px {
    width: 273px !important;
  }
  .w-lg-274px {
    width: 274px !important;
  }
  .w-lg-275px {
    width: 275px !important;
  }
  .w-lg-276px {
    width: 276px !important;
  }
  .w-lg-277px {
    width: 277px !important;
  }
  .w-lg-278px {
    width: 278px !important;
  }
  .w-lg-279px {
    width: 279px !important;
  }
  .w-lg-280px {
    width: 280px !important;
  }
  .w-lg-281px {
    width: 281px !important;
  }
  .w-lg-282px {
    width: 282px !important;
  }
  .w-lg-283px {
    width: 283px !important;
  }
  .w-lg-284px {
    width: 284px !important;
  }
  .w-lg-285px {
    width: 285px !important;
  }
  .w-lg-286px {
    width: 286px !important;
  }
  .w-lg-287px {
    width: 287px !important;
  }
  .w-lg-288px {
    width: 288px !important;
  }
  .w-lg-289px {
    width: 289px !important;
  }
  .w-lg-290px {
    width: 290px !important;
  }
  .w-lg-291px {
    width: 291px !important;
  }
  .w-lg-292px {
    width: 292px !important;
  }
  .w-lg-293px {
    width: 293px !important;
  }
  .w-lg-294px {
    width: 294px !important;
  }
  .w-lg-295px {
    width: 295px !important;
  }
  .w-lg-296px {
    width: 296px !important;
  }
  .w-lg-297px {
    width: 297px !important;
  }
  .w-lg-298px {
    width: 298px !important;
  }
  .w-lg-299px {
    width: 299px !important;
  }
  .w-lg-300px {
    width: 300px !important;
  }
  .w-lg-301px {
    width: 301px !important;
  }
  .w-lg-302px {
    width: 302px !important;
  }
  .w-lg-303px {
    width: 303px !important;
  }
  .w-lg-304px {
    width: 304px !important;
  }
  .w-lg-305px {
    width: 305px !important;
  }
  .w-lg-306px {
    width: 306px !important;
  }
  .w-lg-307px {
    width: 307px !important;
  }
  .w-lg-308px {
    width: 308px !important;
  }
  .w-lg-309px {
    width: 309px !important;
  }
  .w-lg-310px {
    width: 310px !important;
  }
  .w-lg-311px {
    width: 311px !important;
  }
  .w-lg-312px {
    width: 312px !important;
  }
  .w-lg-313px {
    width: 313px !important;
  }
  .w-lg-314px {
    width: 314px !important;
  }
  .w-lg-315px {
    width: 315px !important;
  }
  .w-lg-316px {
    width: 316px !important;
  }
  .w-lg-317px {
    width: 317px !important;
  }
  .w-lg-318px {
    width: 318px !important;
  }
  .w-lg-319px {
    width: 319px !important;
  }
  .w-lg-320px {
    width: 320px !important;
  }
  .w-lg-321px {
    width: 321px !important;
  }
  .w-lg-322px {
    width: 322px !important;
  }
  .w-lg-323px {
    width: 323px !important;
  }
  .w-lg-324px {
    width: 324px !important;
  }
  .w-lg-325px {
    width: 325px !important;
  }
  .w-lg-326px {
    width: 326px !important;
  }
  .w-lg-327px {
    width: 327px !important;
  }
  .w-lg-328px {
    width: 328px !important;
  }
  .w-lg-329px {
    width: 329px !important;
  }
  .w-lg-330px {
    width: 330px !important;
  }
  .w-lg-331px {
    width: 331px !important;
  }
  .w-lg-332px {
    width: 332px !important;
  }
  .w-lg-333px {
    width: 333px !important;
  }
  .w-lg-334px {
    width: 334px !important;
  }
  .w-lg-335px {
    width: 335px !important;
  }
  .w-lg-336px {
    width: 336px !important;
  }
  .w-lg-337px {
    width: 337px !important;
  }
  .w-lg-338px {
    width: 338px !important;
  }
  .w-lg-339px {
    width: 339px !important;
  }
  .w-lg-340px {
    width: 340px !important;
  }
  .w-lg-341px {
    width: 341px !important;
  }
  .w-lg-342px {
    width: 342px !important;
  }
  .w-lg-343px {
    width: 343px !important;
  }
  .w-lg-344px {
    width: 344px !important;
  }
  .w-lg-345px {
    width: 345px !important;
  }
  .w-lg-346px {
    width: 346px !important;
  }
  .w-lg-347px {
    width: 347px !important;
  }
  .w-lg-348px {
    width: 348px !important;
  }
  .w-lg-349px {
    width: 349px !important;
  }
  .w-lg-350px {
    width: 350px !important;
  }
  .w-lg-351px {
    width: 351px !important;
  }
  .w-lg-352px {
    width: 352px !important;
  }
  .w-lg-353px {
    width: 353px !important;
  }
  .w-lg-354px {
    width: 354px !important;
  }
  .w-lg-355px {
    width: 355px !important;
  }
  .w-lg-356px {
    width: 356px !important;
  }
  .w-lg-357px {
    width: 357px !important;
  }
  .w-lg-358px {
    width: 358px !important;
  }
  .w-lg-359px {
    width: 359px !important;
  }
  .w-lg-360px {
    width: 360px !important;
  }
  .w-lg-361px {
    width: 361px !important;
  }
  .w-lg-362px {
    width: 362px !important;
  }
  .w-lg-363px {
    width: 363px !important;
  }
  .w-lg-364px {
    width: 364px !important;
  }
  .w-lg-365px {
    width: 365px !important;
  }
  .w-lg-366px {
    width: 366px !important;
  }
  .w-lg-367px {
    width: 367px !important;
  }
  .w-lg-368px {
    width: 368px !important;
  }
  .w-lg-369px {
    width: 369px !important;
  }
  .w-lg-370px {
    width: 370px !important;
  }
  .w-lg-371px {
    width: 371px !important;
  }
  .w-lg-372px {
    width: 372px !important;
  }
  .w-lg-373px {
    width: 373px !important;
  }
  .w-lg-374px {
    width: 374px !important;
  }
  .w-lg-375px {
    width: 375px !important;
  }
  .w-lg-376px {
    width: 376px !important;
  }
  .w-lg-377px {
    width: 377px !important;
  }
  .w-lg-378px {
    width: 378px !important;
  }
  .w-lg-379px {
    width: 379px !important;
  }
  .w-lg-380px {
    width: 380px !important;
  }
  .w-lg-381px {
    width: 381px !important;
  }
  .w-lg-382px {
    width: 382px !important;
  }
  .w-lg-383px {
    width: 383px !important;
  }
  .w-lg-384px {
    width: 384px !important;
  }
  .w-lg-385px {
    width: 385px !important;
  }
  .w-lg-386px {
    width: 386px !important;
  }
  .w-lg-387px {
    width: 387px !important;
  }
  .w-lg-388px {
    width: 388px !important;
  }
  .w-lg-389px {
    width: 389px !important;
  }
  .w-lg-390px {
    width: 390px !important;
  }
  .w-lg-391px {
    width: 391px !important;
  }
  .w-lg-392px {
    width: 392px !important;
  }
  .w-lg-393px {
    width: 393px !important;
  }
  .w-lg-394px {
    width: 394px !important;
  }
  .w-lg-395px {
    width: 395px !important;
  }
  .w-lg-396px {
    width: 396px !important;
  }
  .w-lg-397px {
    width: 397px !important;
  }
  .w-lg-398px {
    width: 398px !important;
  }
  .w-lg-399px {
    width: 399px !important;
  }
  .w-lg-400px {
    width: 400px !important;
  }
  .w-lg-401px {
    width: 401px !important;
  }
  .w-lg-402px {
    width: 402px !important;
  }
  .w-lg-403px {
    width: 403px !important;
  }
  .w-lg-404px {
    width: 404px !important;
  }
  .w-lg-405px {
    width: 405px !important;
  }
  .w-lg-406px {
    width: 406px !important;
  }
  .w-lg-407px {
    width: 407px !important;
  }
  .w-lg-408px {
    width: 408px !important;
  }
  .w-lg-409px {
    width: 409px !important;
  }
  .w-lg-410px {
    width: 410px !important;
  }
  .w-lg-411px {
    width: 411px !important;
  }
  .w-lg-412px {
    width: 412px !important;
  }
  .w-lg-413px {
    width: 413px !important;
  }
  .w-lg-414px {
    width: 414px !important;
  }
  .w-lg-415px {
    width: 415px !important;
  }
  .w-lg-416px {
    width: 416px !important;
  }
  .w-lg-417px {
    width: 417px !important;
  }
  .w-lg-418px {
    width: 418px !important;
  }
  .w-lg-419px {
    width: 419px !important;
  }
  .w-lg-420px {
    width: 420px !important;
  }
  .w-lg-421px {
    width: 421px !important;
  }
  .w-lg-422px {
    width: 422px !important;
  }
  .w-lg-423px {
    width: 423px !important;
  }
  .w-lg-424px {
    width: 424px !important;
  }
  .w-lg-425px {
    width: 425px !important;
  }
  .w-lg-426px {
    width: 426px !important;
  }
  .w-lg-427px {
    width: 427px !important;
  }
  .w-lg-428px {
    width: 428px !important;
  }
  .w-lg-429px {
    width: 429px !important;
  }
  .w-lg-430px {
    width: 430px !important;
  }
  .w-lg-431px {
    width: 431px !important;
  }
  .w-lg-432px {
    width: 432px !important;
  }
  .w-lg-433px {
    width: 433px !important;
  }
  .w-lg-434px {
    width: 434px !important;
  }
  .w-lg-435px {
    width: 435px !important;
  }
  .w-lg-436px {
    width: 436px !important;
  }
  .w-lg-437px {
    width: 437px !important;
  }
  .w-lg-438px {
    width: 438px !important;
  }
  .w-lg-439px {
    width: 439px !important;
  }
  .w-lg-440px {
    width: 440px !important;
  }
  .w-lg-441px {
    width: 441px !important;
  }
  .w-lg-442px {
    width: 442px !important;
  }
  .w-lg-443px {
    width: 443px !important;
  }
  .w-lg-444px {
    width: 444px !important;
  }
  .w-lg-445px {
    width: 445px !important;
  }
  .w-lg-446px {
    width: 446px !important;
  }
  .w-lg-447px {
    width: 447px !important;
  }
  .w-lg-448px {
    width: 448px !important;
  }
  .w-lg-449px {
    width: 449px !important;
  }
  .w-lg-450px {
    width: 450px !important;
  }
  .w-lg-451px {
    width: 451px !important;
  }
  .w-lg-452px {
    width: 452px !important;
  }
  .w-lg-453px {
    width: 453px !important;
  }
  .w-lg-454px {
    width: 454px !important;
  }
  .w-lg-455px {
    width: 455px !important;
  }
  .w-lg-456px {
    width: 456px !important;
  }
  .w-lg-457px {
    width: 457px !important;
  }
  .w-lg-458px {
    width: 458px !important;
  }
  .w-lg-459px {
    width: 459px !important;
  }
  .w-lg-460px {
    width: 460px !important;
  }
  .w-lg-461px {
    width: 461px !important;
  }
  .w-lg-462px {
    width: 462px !important;
  }
  .w-lg-463px {
    width: 463px !important;
  }
  .w-lg-464px {
    width: 464px !important;
  }
  .w-lg-465px {
    width: 465px !important;
  }
  .w-lg-466px {
    width: 466px !important;
  }
  .w-lg-467px {
    width: 467px !important;
  }
  .w-lg-468px {
    width: 468px !important;
  }
  .w-lg-469px {
    width: 469px !important;
  }
  .w-lg-470px {
    width: 470px !important;
  }
  .w-lg-471px {
    width: 471px !important;
  }
  .w-lg-472px {
    width: 472px !important;
  }
  .w-lg-473px {
    width: 473px !important;
  }
  .w-lg-474px {
    width: 474px !important;
  }
  .w-lg-475px {
    width: 475px !important;
  }
  .w-lg-476px {
    width: 476px !important;
  }
  .w-lg-477px {
    width: 477px !important;
  }
  .w-lg-478px {
    width: 478px !important;
  }
  .w-lg-479px {
    width: 479px !important;
  }
  .w-lg-480px {
    width: 480px !important;
  }
  .w-lg-481px {
    width: 481px !important;
  }
  .w-lg-482px {
    width: 482px !important;
  }
  .w-lg-483px {
    width: 483px !important;
  }
  .w-lg-484px {
    width: 484px !important;
  }
  .w-lg-485px {
    width: 485px !important;
  }
  .w-lg-486px {
    width: 486px !important;
  }
  .w-lg-487px {
    width: 487px !important;
  }
  .w-lg-488px {
    width: 488px !important;
  }
  .w-lg-489px {
    width: 489px !important;
  }
  .w-lg-490px {
    width: 490px !important;
  }
  .w-lg-491px {
    width: 491px !important;
  }
  .w-lg-492px {
    width: 492px !important;
  }
  .w-lg-493px {
    width: 493px !important;
  }
  .w-lg-494px {
    width: 494px !important;
  }
  .w-lg-495px {
    width: 495px !important;
  }
  .w-lg-496px {
    width: 496px !important;
  }
  .w-lg-497px {
    width: 497px !important;
  }
  .w-lg-498px {
    width: 498px !important;
  }
  .w-lg-499px {
    width: 499px !important;
  }
  .w-lg-500px {
    width: 500px !important;
  }
  .w-lg-501px {
    width: 501px !important;
  }
  .w-lg-502px {
    width: 502px !important;
  }
  .w-lg-503px {
    width: 503px !important;
  }
  .w-lg-504px {
    width: 504px !important;
  }
  .w-lg-505px {
    width: 505px !important;
  }
  .w-lg-506px {
    width: 506px !important;
  }
  .w-lg-507px {
    width: 507px !important;
  }
  .w-lg-508px {
    width: 508px !important;
  }
  .w-lg-509px {
    width: 509px !important;
  }
  .w-lg-510px {
    width: 510px !important;
  }
  .w-lg-511px {
    width: 511px !important;
  }
  .w-lg-512px {
    width: 512px !important;
  }
  .w-lg-513px {
    width: 513px !important;
  }
  .w-lg-514px {
    width: 514px !important;
  }
  .w-lg-515px {
    width: 515px !important;
  }
  .w-lg-516px {
    width: 516px !important;
  }
  .w-lg-517px {
    width: 517px !important;
  }
  .w-lg-518px {
    width: 518px !important;
  }
  .w-lg-519px {
    width: 519px !important;
  }
  .w-lg-520px {
    width: 520px !important;
  }
  .w-lg-521px {
    width: 521px !important;
  }
  .w-lg-522px {
    width: 522px !important;
  }
  .w-lg-523px {
    width: 523px !important;
  }
  .w-lg-524px {
    width: 524px !important;
  }
  .w-lg-525px {
    width: 525px !important;
  }
  .w-lg-526px {
    width: 526px !important;
  }
  .w-lg-527px {
    width: 527px !important;
  }
  .w-lg-528px {
    width: 528px !important;
  }
  .w-lg-529px {
    width: 529px !important;
  }
  .w-lg-530px {
    width: 530px !important;
  }
  .w-lg-531px {
    width: 531px !important;
  }
  .w-lg-532px {
    width: 532px !important;
  }
  .w-lg-533px {
    width: 533px !important;
  }
  .w-lg-534px {
    width: 534px !important;
  }
  .w-lg-535px {
    width: 535px !important;
  }
  .w-lg-536px {
    width: 536px !important;
  }
  .w-lg-537px {
    width: 537px !important;
  }
  .w-lg-538px {
    width: 538px !important;
  }
  .w-lg-539px {
    width: 539px !important;
  }
  .w-lg-540px {
    width: 540px !important;
  }
  .w-lg-541px {
    width: 541px !important;
  }
  .w-lg-542px {
    width: 542px !important;
  }
  .w-lg-543px {
    width: 543px !important;
  }
  .w-lg-544px {
    width: 544px !important;
  }
  .w-lg-545px {
    width: 545px !important;
  }
  .w-lg-546px {
    width: 546px !important;
  }
  .w-lg-547px {
    width: 547px !important;
  }
  .w-lg-548px {
    width: 548px !important;
  }
  .w-lg-549px {
    width: 549px !important;
  }
  .w-lg-550px {
    width: 550px !important;
  }
  .w-lg-551px {
    width: 551px !important;
  }
  .w-lg-552px {
    width: 552px !important;
  }
  .w-lg-553px {
    width: 553px !important;
  }
  .w-lg-554px {
    width: 554px !important;
  }
  .w-lg-555px {
    width: 555px !important;
  }
  .w-lg-556px {
    width: 556px !important;
  }
  .w-lg-557px {
    width: 557px !important;
  }
  .w-lg-558px {
    width: 558px !important;
  }
  .w-lg-559px {
    width: 559px !important;
  }
  .w-lg-560px {
    width: 560px !important;
  }
  .w-lg-561px {
    width: 561px !important;
  }
  .w-lg-562px {
    width: 562px !important;
  }
  .w-lg-563px {
    width: 563px !important;
  }
  .w-lg-564px {
    width: 564px !important;
  }
  .w-lg-565px {
    width: 565px !important;
  }
  .w-lg-566px {
    width: 566px !important;
  }
  .w-lg-567px {
    width: 567px !important;
  }
  .w-lg-568px {
    width: 568px !important;
  }
  .w-lg-569px {
    width: 569px !important;
  }
  .w-lg-570px {
    width: 570px !important;
  }
  .w-lg-571px {
    width: 571px !important;
  }
  .w-lg-572px {
    width: 572px !important;
  }
  .w-lg-573px {
    width: 573px !important;
  }
  .w-lg-574px {
    width: 574px !important;
  }
  .w-lg-575px {
    width: 575px !important;
  }
  .w-lg-576px {
    width: 576px !important;
  }
  .w-lg-577px {
    width: 577px !important;
  }
  .w-lg-578px {
    width: 578px !important;
  }
  .w-lg-579px {
    width: 579px !important;
  }
  .w-lg-580px {
    width: 580px !important;
  }
  .w-lg-581px {
    width: 581px !important;
  }
  .w-lg-582px {
    width: 582px !important;
  }
  .w-lg-583px {
    width: 583px !important;
  }
  .w-lg-584px {
    width: 584px !important;
  }
  .w-lg-585px {
    width: 585px !important;
  }
  .w-lg-586px {
    width: 586px !important;
  }
  .w-lg-587px {
    width: 587px !important;
  }
  .w-lg-588px {
    width: 588px !important;
  }
  .w-lg-589px {
    width: 589px !important;
  }
  .w-lg-590px {
    width: 590px !important;
  }
  .w-lg-591px {
    width: 591px !important;
  }
  .w-lg-592px {
    width: 592px !important;
  }
  .w-lg-593px {
    width: 593px !important;
  }
  .w-lg-594px {
    width: 594px !important;
  }
  .w-lg-595px {
    width: 595px !important;
  }
  .w-lg-596px {
    width: 596px !important;
  }
  .w-lg-597px {
    width: 597px !important;
  }
  .w-lg-598px {
    width: 598px !important;
  }
  .w-lg-599px {
    width: 599px !important;
  }
  .w-lg-600px {
    width: 600px !important;
  }
  .w-lg-601px {
    width: 601px !important;
  }
  .w-lg-602px {
    width: 602px !important;
  }
  .w-lg-603px {
    width: 603px !important;
  }
  .w-lg-604px {
    width: 604px !important;
  }
  .w-lg-605px {
    width: 605px !important;
  }
  .w-lg-606px {
    width: 606px !important;
  }
  .w-lg-607px {
    width: 607px !important;
  }
  .w-lg-608px {
    width: 608px !important;
  }
  .w-lg-609px {
    width: 609px !important;
  }
  .w-lg-610px {
    width: 610px !important;
  }
  .w-lg-611px {
    width: 611px !important;
  }
  .w-lg-612px {
    width: 612px !important;
  }
  .w-lg-613px {
    width: 613px !important;
  }
  .w-lg-614px {
    width: 614px !important;
  }
  .w-lg-615px {
    width: 615px !important;
  }
  .w-lg-616px {
    width: 616px !important;
  }
  .w-lg-617px {
    width: 617px !important;
  }
  .w-lg-618px {
    width: 618px !important;
  }
  .w-lg-619px {
    width: 619px !important;
  }
  .w-lg-620px {
    width: 620px !important;
  }
  .w-lg-621px {
    width: 621px !important;
  }
  .w-lg-622px {
    width: 622px !important;
  }
  .w-lg-623px {
    width: 623px !important;
  }
  .w-lg-624px {
    width: 624px !important;
  }
  .w-lg-625px {
    width: 625px !important;
  }
  .w-lg-626px {
    width: 626px !important;
  }
  .w-lg-627px {
    width: 627px !important;
  }
  .w-lg-628px {
    width: 628px !important;
  }
  .w-lg-629px {
    width: 629px !important;
  }
  .w-lg-630px {
    width: 630px !important;
  }
  .w-lg-631px {
    width: 631px !important;
  }
  .w-lg-632px {
    width: 632px !important;
  }
  .w-lg-633px {
    width: 633px !important;
  }
  .w-lg-634px {
    width: 634px !important;
  }
  .w-lg-635px {
    width: 635px !important;
  }
  .w-lg-636px {
    width: 636px !important;
  }
  .w-lg-637px {
    width: 637px !important;
  }
  .w-lg-638px {
    width: 638px !important;
  }
  .w-lg-639px {
    width: 639px !important;
  }
  .w-lg-640px {
    width: 640px !important;
  }
  .w-lg-641px {
    width: 641px !important;
  }
  .w-lg-642px {
    width: 642px !important;
  }
  .w-lg-643px {
    width: 643px !important;
  }
  .w-lg-644px {
    width: 644px !important;
  }
  .w-lg-645px {
    width: 645px !important;
  }
  .w-lg-646px {
    width: 646px !important;
  }
  .w-lg-647px {
    width: 647px !important;
  }
  .w-lg-648px {
    width: 648px !important;
  }
  .w-lg-649px {
    width: 649px !important;
  }
  .w-lg-650px {
    width: 650px !important;
  }
  .w-lg-651px {
    width: 651px !important;
  }
  .w-lg-652px {
    width: 652px !important;
  }
  .w-lg-653px {
    width: 653px !important;
  }
  .w-lg-654px {
    width: 654px !important;
  }
  .w-lg-655px {
    width: 655px !important;
  }
  .w-lg-656px {
    width: 656px !important;
  }
  .w-lg-657px {
    width: 657px !important;
  }
  .w-lg-658px {
    width: 658px !important;
  }
  .w-lg-659px {
    width: 659px !important;
  }
  .w-lg-660px {
    width: 660px !important;
  }
  .w-lg-661px {
    width: 661px !important;
  }
  .w-lg-662px {
    width: 662px !important;
  }
  .w-lg-663px {
    width: 663px !important;
  }
  .w-lg-664px {
    width: 664px !important;
  }
  .w-lg-665px {
    width: 665px !important;
  }
  .w-lg-666px {
    width: 666px !important;
  }
  .w-lg-667px {
    width: 667px !important;
  }
  .w-lg-668px {
    width: 668px !important;
  }
  .w-lg-669px {
    width: 669px !important;
  }
  .w-lg-670px {
    width: 670px !important;
  }
  .w-lg-671px {
    width: 671px !important;
  }
  .w-lg-672px {
    width: 672px !important;
  }
  .w-lg-673px {
    width: 673px !important;
  }
  .w-lg-674px {
    width: 674px !important;
  }
  .w-lg-675px {
    width: 675px !important;
  }
  .w-lg-676px {
    width: 676px !important;
  }
  .w-lg-677px {
    width: 677px !important;
  }
  .w-lg-678px {
    width: 678px !important;
  }
  .w-lg-679px {
    width: 679px !important;
  }
  .w-lg-680px {
    width: 680px !important;
  }
  .w-lg-681px {
    width: 681px !important;
  }
  .w-lg-682px {
    width: 682px !important;
  }
  .w-lg-683px {
    width: 683px !important;
  }
  .w-lg-684px {
    width: 684px !important;
  }
  .w-lg-685px {
    width: 685px !important;
  }
  .w-lg-686px {
    width: 686px !important;
  }
  .w-lg-687px {
    width: 687px !important;
  }
  .w-lg-688px {
    width: 688px !important;
  }
  .w-lg-689px {
    width: 689px !important;
  }
  .w-lg-690px {
    width: 690px !important;
  }
  .w-lg-691px {
    width: 691px !important;
  }
  .w-lg-692px {
    width: 692px !important;
  }
  .w-lg-693px {
    width: 693px !important;
  }
  .w-lg-694px {
    width: 694px !important;
  }
  .w-lg-695px {
    width: 695px !important;
  }
  .w-lg-696px {
    width: 696px !important;
  }
  .w-lg-697px {
    width: 697px !important;
  }
  .w-lg-698px {
    width: 698px !important;
  }
  .w-lg-699px {
    width: 699px !important;
  }
  .w-lg-700px {
    width: 700px !important;
  }
  .w-lg-701px {
    width: 701px !important;
  }
  .w-lg-702px {
    width: 702px !important;
  }
  .w-lg-703px {
    width: 703px !important;
  }
  .w-lg-704px {
    width: 704px !important;
  }
  .w-lg-705px {
    width: 705px !important;
  }
  .w-lg-706px {
    width: 706px !important;
  }
  .w-lg-707px {
    width: 707px !important;
  }
  .w-lg-708px {
    width: 708px !important;
  }
  .w-lg-709px {
    width: 709px !important;
  }
  .w-lg-710px {
    width: 710px !important;
  }
  .w-lg-711px {
    width: 711px !important;
  }
  .w-lg-712px {
    width: 712px !important;
  }
  .w-lg-713px {
    width: 713px !important;
  }
  .w-lg-714px {
    width: 714px !important;
  }
  .w-lg-715px {
    width: 715px !important;
  }
  .w-lg-716px {
    width: 716px !important;
  }
  .w-lg-717px {
    width: 717px !important;
  }
  .w-lg-718px {
    width: 718px !important;
  }
  .w-lg-719px {
    width: 719px !important;
  }
  .w-lg-720px {
    width: 720px !important;
  }
  .w-lg-721px {
    width: 721px !important;
  }
  .w-lg-722px {
    width: 722px !important;
  }
  .w-lg-723px {
    width: 723px !important;
  }
  .w-lg-724px {
    width: 724px !important;
  }
  .w-lg-725px {
    width: 725px !important;
  }
  .w-lg-726px {
    width: 726px !important;
  }
  .w-lg-727px {
    width: 727px !important;
  }
  .w-lg-728px {
    width: 728px !important;
  }
  .w-lg-729px {
    width: 729px !important;
  }
  .w-lg-730px {
    width: 730px !important;
  }
  .w-lg-731px {
    width: 731px !important;
  }
  .w-lg-732px {
    width: 732px !important;
  }
  .w-lg-733px {
    width: 733px !important;
  }
  .w-lg-734px {
    width: 734px !important;
  }
  .w-lg-735px {
    width: 735px !important;
  }
  .w-lg-736px {
    width: 736px !important;
  }
  .w-lg-737px {
    width: 737px !important;
  }
  .w-lg-738px {
    width: 738px !important;
  }
  .w-lg-739px {
    width: 739px !important;
  }
  .w-lg-740px {
    width: 740px !important;
  }
  .w-lg-741px {
    width: 741px !important;
  }
  .w-lg-742px {
    width: 742px !important;
  }
  .w-lg-743px {
    width: 743px !important;
  }
  .w-lg-744px {
    width: 744px !important;
  }
  .w-lg-745px {
    width: 745px !important;
  }
  .w-lg-746px {
    width: 746px !important;
  }
  .w-lg-747px {
    width: 747px !important;
  }
  .w-lg-748px {
    width: 748px !important;
  }
  .w-lg-749px {
    width: 749px !important;
  }
  .w-lg-750px {
    width: 750px !important;
  }
  .w-lg-751px {
    width: 751px !important;
  }
  .w-lg-752px {
    width: 752px !important;
  }
  .w-lg-753px {
    width: 753px !important;
  }
  .w-lg-754px {
    width: 754px !important;
  }
  .w-lg-755px {
    width: 755px !important;
  }
  .w-lg-756px {
    width: 756px !important;
  }
  .w-lg-757px {
    width: 757px !important;
  }
  .w-lg-758px {
    width: 758px !important;
  }
  .w-lg-759px {
    width: 759px !important;
  }
  .w-lg-760px {
    width: 760px !important;
  }
  .w-lg-761px {
    width: 761px !important;
  }
  .w-lg-762px {
    width: 762px !important;
  }
  .w-lg-763px {
    width: 763px !important;
  }
  .w-lg-764px {
    width: 764px !important;
  }
  .w-lg-765px {
    width: 765px !important;
  }
  .w-lg-766px {
    width: 766px !important;
  }
  .w-lg-767px {
    width: 767px !important;
  }
  .w-lg-768px {
    width: 768px !important;
  }
  .w-lg-769px {
    width: 769px !important;
  }
  .w-lg-770px {
    width: 770px !important;
  }
  .w-lg-771px {
    width: 771px !important;
  }
  .w-lg-772px {
    width: 772px !important;
  }
  .w-lg-773px {
    width: 773px !important;
  }
  .w-lg-774px {
    width: 774px !important;
  }
  .w-lg-775px {
    width: 775px !important;
  }
  .w-lg-776px {
    width: 776px !important;
  }
  .w-lg-777px {
    width: 777px !important;
  }
  .w-lg-778px {
    width: 778px !important;
  }
  .w-lg-779px {
    width: 779px !important;
  }
  .w-lg-780px {
    width: 780px !important;
  }
  .w-lg-781px {
    width: 781px !important;
  }
  .w-lg-782px {
    width: 782px !important;
  }
  .w-lg-783px {
    width: 783px !important;
  }
  .w-lg-784px {
    width: 784px !important;
  }
  .w-lg-785px {
    width: 785px !important;
  }
  .w-lg-786px {
    width: 786px !important;
  }
  .w-lg-787px {
    width: 787px !important;
  }
  .w-lg-788px {
    width: 788px !important;
  }
  .w-lg-789px {
    width: 789px !important;
  }
  .w-lg-790px {
    width: 790px !important;
  }
  .w-lg-791px {
    width: 791px !important;
  }
  .w-lg-792px {
    width: 792px !important;
  }
  .w-lg-793px {
    width: 793px !important;
  }
  .w-lg-794px {
    width: 794px !important;
  }
  .w-lg-795px {
    width: 795px !important;
  }
  .w-lg-796px {
    width: 796px !important;
  }
  .w-lg-797px {
    width: 797px !important;
  }
  .w-lg-798px {
    width: 798px !important;
  }
  .w-lg-799px {
    width: 799px !important;
  }
  .w-lg-800px {
    width: 800px !important;
  }
  .w-lg-801px {
    width: 801px !important;
  }
  .w-lg-802px {
    width: 802px !important;
  }
  .w-lg-803px {
    width: 803px !important;
  }
  .w-lg-804px {
    width: 804px !important;
  }
  .w-lg-805px {
    width: 805px !important;
  }
  .w-lg-806px {
    width: 806px !important;
  }
  .w-lg-807px {
    width: 807px !important;
  }
  .w-lg-808px {
    width: 808px !important;
  }
  .w-lg-809px {
    width: 809px !important;
  }
  .w-lg-810px {
    width: 810px !important;
  }
  .w-lg-811px {
    width: 811px !important;
  }
  .w-lg-812px {
    width: 812px !important;
  }
  .w-lg-813px {
    width: 813px !important;
  }
  .w-lg-814px {
    width: 814px !important;
  }
  .w-lg-815px {
    width: 815px !important;
  }
  .w-lg-816px {
    width: 816px !important;
  }
  .w-lg-817px {
    width: 817px !important;
  }
  .w-lg-818px {
    width: 818px !important;
  }
  .w-lg-819px {
    width: 819px !important;
  }
  .w-lg-820px {
    width: 820px !important;
  }
  .w-lg-821px {
    width: 821px !important;
  }
  .w-lg-822px {
    width: 822px !important;
  }
  .w-lg-823px {
    width: 823px !important;
  }
  .w-lg-824px {
    width: 824px !important;
  }
  .w-lg-825px {
    width: 825px !important;
  }
  .w-lg-826px {
    width: 826px !important;
  }
  .w-lg-827px {
    width: 827px !important;
  }
  .w-lg-828px {
    width: 828px !important;
  }
  .w-lg-829px {
    width: 829px !important;
  }
  .w-lg-830px {
    width: 830px !important;
  }
  .w-lg-831px {
    width: 831px !important;
  }
  .w-lg-832px {
    width: 832px !important;
  }
  .w-lg-833px {
    width: 833px !important;
  }
  .w-lg-834px {
    width: 834px !important;
  }
  .w-lg-835px {
    width: 835px !important;
  }
  .w-lg-836px {
    width: 836px !important;
  }
  .w-lg-837px {
    width: 837px !important;
  }
  .w-lg-838px {
    width: 838px !important;
  }
  .w-lg-839px {
    width: 839px !important;
  }
  .w-lg-840px {
    width: 840px !important;
  }
  .w-lg-841px {
    width: 841px !important;
  }
  .w-lg-842px {
    width: 842px !important;
  }
  .w-lg-843px {
    width: 843px !important;
  }
  .w-lg-844px {
    width: 844px !important;
  }
  .w-lg-845px {
    width: 845px !important;
  }
  .w-lg-846px {
    width: 846px !important;
  }
  .w-lg-847px {
    width: 847px !important;
  }
  .w-lg-848px {
    width: 848px !important;
  }
  .w-lg-849px {
    width: 849px !important;
  }
  .w-lg-850px {
    width: 850px !important;
  }
  .w-lg-851px {
    width: 851px !important;
  }
  .w-lg-852px {
    width: 852px !important;
  }
  .w-lg-853px {
    width: 853px !important;
  }
  .w-lg-854px {
    width: 854px !important;
  }
  .w-lg-855px {
    width: 855px !important;
  }
  .w-lg-856px {
    width: 856px !important;
  }
  .w-lg-857px {
    width: 857px !important;
  }
  .w-lg-858px {
    width: 858px !important;
  }
  .w-lg-859px {
    width: 859px !important;
  }
  .w-lg-860px {
    width: 860px !important;
  }
  .w-lg-861px {
    width: 861px !important;
  }
  .w-lg-862px {
    width: 862px !important;
  }
  .w-lg-863px {
    width: 863px !important;
  }
  .w-lg-864px {
    width: 864px !important;
  }
  .w-lg-865px {
    width: 865px !important;
  }
  .w-lg-866px {
    width: 866px !important;
  }
  .w-lg-867px {
    width: 867px !important;
  }
  .w-lg-868px {
    width: 868px !important;
  }
  .w-lg-869px {
    width: 869px !important;
  }
  .w-lg-870px {
    width: 870px !important;
  }
  .w-lg-871px {
    width: 871px !important;
  }
  .w-lg-872px {
    width: 872px !important;
  }
  .w-lg-873px {
    width: 873px !important;
  }
  .w-lg-874px {
    width: 874px !important;
  }
  .w-lg-875px {
    width: 875px !important;
  }
  .w-lg-876px {
    width: 876px !important;
  }
  .w-lg-877px {
    width: 877px !important;
  }
  .w-lg-878px {
    width: 878px !important;
  }
  .w-lg-879px {
    width: 879px !important;
  }
  .w-lg-880px {
    width: 880px !important;
  }
  .w-lg-881px {
    width: 881px !important;
  }
  .w-lg-882px {
    width: 882px !important;
  }
  .w-lg-883px {
    width: 883px !important;
  }
  .w-lg-884px {
    width: 884px !important;
  }
  .w-lg-885px {
    width: 885px !important;
  }
  .w-lg-886px {
    width: 886px !important;
  }
  .w-lg-887px {
    width: 887px !important;
  }
  .w-lg-888px {
    width: 888px !important;
  }
  .w-lg-889px {
    width: 889px !important;
  }
  .w-lg-890px {
    width: 890px !important;
  }
  .w-lg-891px {
    width: 891px !important;
  }
  .w-lg-892px {
    width: 892px !important;
  }
  .w-lg-893px {
    width: 893px !important;
  }
  .w-lg-894px {
    width: 894px !important;
  }
  .w-lg-895px {
    width: 895px !important;
  }
  .w-lg-896px {
    width: 896px !important;
  }
  .w-lg-897px {
    width: 897px !important;
  }
  .w-lg-898px {
    width: 898px !important;
  }
  .w-lg-899px {
    width: 899px !important;
  }
  .w-lg-900px {
    width: 900px !important;
  }
  .w-lg-901px {
    width: 901px !important;
  }
  .w-lg-902px {
    width: 902px !important;
  }
  .w-lg-903px {
    width: 903px !important;
  }
  .w-lg-904px {
    width: 904px !important;
  }
  .w-lg-905px {
    width: 905px !important;
  }
  .w-lg-906px {
    width: 906px !important;
  }
  .w-lg-907px {
    width: 907px !important;
  }
  .w-lg-908px {
    width: 908px !important;
  }
  .w-lg-909px {
    width: 909px !important;
  }
  .w-lg-910px {
    width: 910px !important;
  }
  .w-lg-911px {
    width: 911px !important;
  }
  .w-lg-912px {
    width: 912px !important;
  }
  .w-lg-913px {
    width: 913px !important;
  }
  .w-lg-914px {
    width: 914px !important;
  }
  .w-lg-915px {
    width: 915px !important;
  }
  .w-lg-916px {
    width: 916px !important;
  }
  .w-lg-917px {
    width: 917px !important;
  }
  .w-lg-918px {
    width: 918px !important;
  }
  .w-lg-919px {
    width: 919px !important;
  }
  .w-lg-920px {
    width: 920px !important;
  }
  .w-lg-921px {
    width: 921px !important;
  }
  .w-lg-922px {
    width: 922px !important;
  }
  .w-lg-923px {
    width: 923px !important;
  }
  .w-lg-924px {
    width: 924px !important;
  }
  .w-lg-925px {
    width: 925px !important;
  }
  .w-lg-926px {
    width: 926px !important;
  }
  .w-lg-927px {
    width: 927px !important;
  }
  .w-lg-928px {
    width: 928px !important;
  }
  .w-lg-929px {
    width: 929px !important;
  }
  .w-lg-930px {
    width: 930px !important;
  }
  .w-lg-931px {
    width: 931px !important;
  }
  .w-lg-932px {
    width: 932px !important;
  }
  .w-lg-933px {
    width: 933px !important;
  }
  .w-lg-934px {
    width: 934px !important;
  }
  .w-lg-935px {
    width: 935px !important;
  }
  .w-lg-936px {
    width: 936px !important;
  }
  .w-lg-937px {
    width: 937px !important;
  }
  .w-lg-938px {
    width: 938px !important;
  }
  .w-lg-939px {
    width: 939px !important;
  }
  .w-lg-940px {
    width: 940px !important;
  }
  .w-lg-941px {
    width: 941px !important;
  }
  .w-lg-942px {
    width: 942px !important;
  }
  .w-lg-943px {
    width: 943px !important;
  }
  .w-lg-944px {
    width: 944px !important;
  }
  .w-lg-945px {
    width: 945px !important;
  }
  .w-lg-946px {
    width: 946px !important;
  }
  .w-lg-947px {
    width: 947px !important;
  }
  .w-lg-948px {
    width: 948px !important;
  }
  .w-lg-949px {
    width: 949px !important;
  }
  .w-lg-950px {
    width: 950px !important;
  }
  .w-lg-951px {
    width: 951px !important;
  }
  .w-lg-952px {
    width: 952px !important;
  }
  .w-lg-953px {
    width: 953px !important;
  }
  .w-lg-954px {
    width: 954px !important;
  }
  .w-lg-955px {
    width: 955px !important;
  }
  .w-lg-956px {
    width: 956px !important;
  }
  .w-lg-957px {
    width: 957px !important;
  }
  .w-lg-958px {
    width: 958px !important;
  }
  .w-lg-959px {
    width: 959px !important;
  }
  .w-lg-960px {
    width: 960px !important;
  }
  .w-lg-961px {
    width: 961px !important;
  }
  .w-lg-962px {
    width: 962px !important;
  }
  .w-lg-963px {
    width: 963px !important;
  }
  .w-lg-964px {
    width: 964px !important;
  }
  .w-lg-965px {
    width: 965px !important;
  }
  .w-lg-966px {
    width: 966px !important;
  }
  .w-lg-967px {
    width: 967px !important;
  }
  .w-lg-968px {
    width: 968px !important;
  }
  .w-lg-969px {
    width: 969px !important;
  }
  .w-lg-970px {
    width: 970px !important;
  }
  .w-lg-971px {
    width: 971px !important;
  }
  .w-lg-972px {
    width: 972px !important;
  }
  .w-lg-973px {
    width: 973px !important;
  }
  .w-lg-974px {
    width: 974px !important;
  }
  .w-lg-975px {
    width: 975px !important;
  }
  .w-lg-976px {
    width: 976px !important;
  }
  .w-lg-977px {
    width: 977px !important;
  }
  .w-lg-978px {
    width: 978px !important;
  }
  .w-lg-979px {
    width: 979px !important;
  }
  .w-lg-980px {
    width: 980px !important;
  }
  .w-lg-981px {
    width: 981px !important;
  }
  .w-lg-982px {
    width: 982px !important;
  }
  .w-lg-983px {
    width: 983px !important;
  }
  .w-lg-984px {
    width: 984px !important;
  }
  .w-lg-985px {
    width: 985px !important;
  }
  .w-lg-986px {
    width: 986px !important;
  }
  .w-lg-987px {
    width: 987px !important;
  }
  .w-lg-988px {
    width: 988px !important;
  }
  .w-lg-989px {
    width: 989px !important;
  }
  .w-lg-990px {
    width: 990px !important;
  }
  .w-lg-991px {
    width: 991px !important;
  }
  .w-lg-992px {
    width: 992px !important;
  }
  .w-lg-993px {
    width: 993px !important;
  }
  .w-lg-994px {
    width: 994px !important;
  }
  .w-lg-995px {
    width: 995px !important;
  }
  .w-lg-996px {
    width: 996px !important;
  }
  .w-lg-997px {
    width: 997px !important;
  }
  .w-lg-998px {
    width: 998px !important;
  }
  .w-lg-999px {
    width: 999px !important;
  }
  .w-lg-1000px {
    width: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-0px {
    width: 0px !important;
  }
  .w-xl-1px {
    width: 1px !important;
  }
  .w-xl-2px {
    width: 2px !important;
  }
  .w-xl-3px {
    width: 3px !important;
  }
  .w-xl-4px {
    width: 4px !important;
  }
  .w-xl-5px {
    width: 5px !important;
  }
  .w-xl-6px {
    width: 6px !important;
  }
  .w-xl-7px {
    width: 7px !important;
  }
  .w-xl-8px {
    width: 8px !important;
  }
  .w-xl-9px {
    width: 9px !important;
  }
  .w-xl-10px {
    width: 10px !important;
  }
  .w-xl-11px {
    width: 11px !important;
  }
  .w-xl-12px {
    width: 12px !important;
  }
  .w-xl-13px {
    width: 13px !important;
  }
  .w-xl-14px {
    width: 14px !important;
  }
  .w-xl-15px {
    width: 15px !important;
  }
  .w-xl-16px {
    width: 16px !important;
  }
  .w-xl-17px {
    width: 17px !important;
  }
  .w-xl-18px {
    width: 18px !important;
  }
  .w-xl-19px {
    width: 19px !important;
  }
  .w-xl-20px {
    width: 20px !important;
  }
  .w-xl-21px {
    width: 21px !important;
  }
  .w-xl-22px {
    width: 22px !important;
  }
  .w-xl-23px {
    width: 23px !important;
  }
  .w-xl-24px {
    width: 24px !important;
  }
  .w-xl-25px {
    width: 25px !important;
  }
  .w-xl-26px {
    width: 26px !important;
  }
  .w-xl-27px {
    width: 27px !important;
  }
  .w-xl-28px {
    width: 28px !important;
  }
  .w-xl-29px {
    width: 29px !important;
  }
  .w-xl-30px {
    width: 30px !important;
  }
  .w-xl-31px {
    width: 31px !important;
  }
  .w-xl-32px {
    width: 32px !important;
  }
  .w-xl-33px {
    width: 33px !important;
  }
  .w-xl-34px {
    width: 34px !important;
  }
  .w-xl-35px {
    width: 35px !important;
  }
  .w-xl-36px {
    width: 36px !important;
  }
  .w-xl-37px {
    width: 37px !important;
  }
  .w-xl-38px {
    width: 38px !important;
  }
  .w-xl-39px {
    width: 39px !important;
  }
  .w-xl-40px {
    width: 40px !important;
  }
  .w-xl-41px {
    width: 41px !important;
  }
  .w-xl-42px {
    width: 42px !important;
  }
  .w-xl-43px {
    width: 43px !important;
  }
  .w-xl-44px {
    width: 44px !important;
  }
  .w-xl-45px {
    width: 45px !important;
  }
  .w-xl-46px {
    width: 46px !important;
  }
  .w-xl-47px {
    width: 47px !important;
  }
  .w-xl-48px {
    width: 48px !important;
  }
  .w-xl-49px {
    width: 49px !important;
  }
  .w-xl-50px {
    width: 50px !important;
  }
  .w-xl-51px {
    width: 51px !important;
  }
  .w-xl-52px {
    width: 52px !important;
  }
  .w-xl-53px {
    width: 53px !important;
  }
  .w-xl-54px {
    width: 54px !important;
  }
  .w-xl-55px {
    width: 55px !important;
  }
  .w-xl-56px {
    width: 56px !important;
  }
  .w-xl-57px {
    width: 57px !important;
  }
  .w-xl-58px {
    width: 58px !important;
  }
  .w-xl-59px {
    width: 59px !important;
  }
  .w-xl-60px {
    width: 60px !important;
  }
  .w-xl-61px {
    width: 61px !important;
  }
  .w-xl-62px {
    width: 62px !important;
  }
  .w-xl-63px {
    width: 63px !important;
  }
  .w-xl-64px {
    width: 64px !important;
  }
  .w-xl-65px {
    width: 65px !important;
  }
  .w-xl-66px {
    width: 66px !important;
  }
  .w-xl-67px {
    width: 67px !important;
  }
  .w-xl-68px {
    width: 68px !important;
  }
  .w-xl-69px {
    width: 69px !important;
  }
  .w-xl-70px {
    width: 70px !important;
  }
  .w-xl-71px {
    width: 71px !important;
  }
  .w-xl-72px {
    width: 72px !important;
  }
  .w-xl-73px {
    width: 73px !important;
  }
  .w-xl-74px {
    width: 74px !important;
  }
  .w-xl-75px {
    width: 75px !important;
  }
  .w-xl-76px {
    width: 76px !important;
  }
  .w-xl-77px {
    width: 77px !important;
  }
  .w-xl-78px {
    width: 78px !important;
  }
  .w-xl-79px {
    width: 79px !important;
  }
  .w-xl-80px {
    width: 80px !important;
  }
  .w-xl-81px {
    width: 81px !important;
  }
  .w-xl-82px {
    width: 82px !important;
  }
  .w-xl-83px {
    width: 83px !important;
  }
  .w-xl-84px {
    width: 84px !important;
  }
  .w-xl-85px {
    width: 85px !important;
  }
  .w-xl-86px {
    width: 86px !important;
  }
  .w-xl-87px {
    width: 87px !important;
  }
  .w-xl-88px {
    width: 88px !important;
  }
  .w-xl-89px {
    width: 89px !important;
  }
  .w-xl-90px {
    width: 90px !important;
  }
  .w-xl-91px {
    width: 91px !important;
  }
  .w-xl-92px {
    width: 92px !important;
  }
  .w-xl-93px {
    width: 93px !important;
  }
  .w-xl-94px {
    width: 94px !important;
  }
  .w-xl-95px {
    width: 95px !important;
  }
  .w-xl-96px {
    width: 96px !important;
  }
  .w-xl-97px {
    width: 97px !important;
  }
  .w-xl-98px {
    width: 98px !important;
  }
  .w-xl-99px {
    width: 99px !important;
  }
  .w-xl-100px {
    width: 100px !important;
  }
  .w-xl-101px {
    width: 101px !important;
  }
  .w-xl-102px {
    width: 102px !important;
  }
  .w-xl-103px {
    width: 103px !important;
  }
  .w-xl-104px {
    width: 104px !important;
  }
  .w-xl-105px {
    width: 105px !important;
  }
  .w-xl-106px {
    width: 106px !important;
  }
  .w-xl-107px {
    width: 107px !important;
  }
  .w-xl-108px {
    width: 108px !important;
  }
  .w-xl-109px {
    width: 109px !important;
  }
  .w-xl-110px {
    width: 110px !important;
  }
  .w-xl-111px {
    width: 111px !important;
  }
  .w-xl-112px {
    width: 112px !important;
  }
  .w-xl-113px {
    width: 113px !important;
  }
  .w-xl-114px {
    width: 114px !important;
  }
  .w-xl-115px {
    width: 115px !important;
  }
  .w-xl-116px {
    width: 116px !important;
  }
  .w-xl-117px {
    width: 117px !important;
  }
  .w-xl-118px {
    width: 118px !important;
  }
  .w-xl-119px {
    width: 119px !important;
  }
  .w-xl-120px {
    width: 120px !important;
  }
  .w-xl-121px {
    width: 121px !important;
  }
  .w-xl-122px {
    width: 122px !important;
  }
  .w-xl-123px {
    width: 123px !important;
  }
  .w-xl-124px {
    width: 124px !important;
  }
  .w-xl-125px {
    width: 125px !important;
  }
  .w-xl-126px {
    width: 126px !important;
  }
  .w-xl-127px {
    width: 127px !important;
  }
  .w-xl-128px {
    width: 128px !important;
  }
  .w-xl-129px {
    width: 129px !important;
  }
  .w-xl-130px {
    width: 130px !important;
  }
  .w-xl-131px {
    width: 131px !important;
  }
  .w-xl-132px {
    width: 132px !important;
  }
  .w-xl-133px {
    width: 133px !important;
  }
  .w-xl-134px {
    width: 134px !important;
  }
  .w-xl-135px {
    width: 135px !important;
  }
  .w-xl-136px {
    width: 136px !important;
  }
  .w-xl-137px {
    width: 137px !important;
  }
  .w-xl-138px {
    width: 138px !important;
  }
  .w-xl-139px {
    width: 139px !important;
  }
  .w-xl-140px {
    width: 140px !important;
  }
  .w-xl-141px {
    width: 141px !important;
  }
  .w-xl-142px {
    width: 142px !important;
  }
  .w-xl-143px {
    width: 143px !important;
  }
  .w-xl-144px {
    width: 144px !important;
  }
  .w-xl-145px {
    width: 145px !important;
  }
  .w-xl-146px {
    width: 146px !important;
  }
  .w-xl-147px {
    width: 147px !important;
  }
  .w-xl-148px {
    width: 148px !important;
  }
  .w-xl-149px {
    width: 149px !important;
  }
  .w-xl-150px {
    width: 150px !important;
  }
  .w-xl-151px {
    width: 151px !important;
  }
  .w-xl-152px {
    width: 152px !important;
  }
  .w-xl-153px {
    width: 153px !important;
  }
  .w-xl-154px {
    width: 154px !important;
  }
  .w-xl-155px {
    width: 155px !important;
  }
  .w-xl-156px {
    width: 156px !important;
  }
  .w-xl-157px {
    width: 157px !important;
  }
  .w-xl-158px {
    width: 158px !important;
  }
  .w-xl-159px {
    width: 159px !important;
  }
  .w-xl-160px {
    width: 160px !important;
  }
  .w-xl-161px {
    width: 161px !important;
  }
  .w-xl-162px {
    width: 162px !important;
  }
  .w-xl-163px {
    width: 163px !important;
  }
  .w-xl-164px {
    width: 164px !important;
  }
  .w-xl-165px {
    width: 165px !important;
  }
  .w-xl-166px {
    width: 166px !important;
  }
  .w-xl-167px {
    width: 167px !important;
  }
  .w-xl-168px {
    width: 168px !important;
  }
  .w-xl-169px {
    width: 169px !important;
  }
  .w-xl-170px {
    width: 170px !important;
  }
  .w-xl-171px {
    width: 171px !important;
  }
  .w-xl-172px {
    width: 172px !important;
  }
  .w-xl-173px {
    width: 173px !important;
  }
  .w-xl-174px {
    width: 174px !important;
  }
  .w-xl-175px {
    width: 175px !important;
  }
  .w-xl-176px {
    width: 176px !important;
  }
  .w-xl-177px {
    width: 177px !important;
  }
  .w-xl-178px {
    width: 178px !important;
  }
  .w-xl-179px {
    width: 179px !important;
  }
  .w-xl-180px {
    width: 180px !important;
  }
  .w-xl-181px {
    width: 181px !important;
  }
  .w-xl-182px {
    width: 182px !important;
  }
  .w-xl-183px {
    width: 183px !important;
  }
  .w-xl-184px {
    width: 184px !important;
  }
  .w-xl-185px {
    width: 185px !important;
  }
  .w-xl-186px {
    width: 186px !important;
  }
  .w-xl-187px {
    width: 187px !important;
  }
  .w-xl-188px {
    width: 188px !important;
  }
  .w-xl-189px {
    width: 189px !important;
  }
  .w-xl-190px {
    width: 190px !important;
  }
  .w-xl-191px {
    width: 191px !important;
  }
  .w-xl-192px {
    width: 192px !important;
  }
  .w-xl-193px {
    width: 193px !important;
  }
  .w-xl-194px {
    width: 194px !important;
  }
  .w-xl-195px {
    width: 195px !important;
  }
  .w-xl-196px {
    width: 196px !important;
  }
  .w-xl-197px {
    width: 197px !important;
  }
  .w-xl-198px {
    width: 198px !important;
  }
  .w-xl-199px {
    width: 199px !important;
  }
  .w-xl-200px {
    width: 200px !important;
  }
  .w-xl-201px {
    width: 201px !important;
  }
  .w-xl-202px {
    width: 202px !important;
  }
  .w-xl-203px {
    width: 203px !important;
  }
  .w-xl-204px {
    width: 204px !important;
  }
  .w-xl-205px {
    width: 205px !important;
  }
  .w-xl-206px {
    width: 206px !important;
  }
  .w-xl-207px {
    width: 207px !important;
  }
  .w-xl-208px {
    width: 208px !important;
  }
  .w-xl-209px {
    width: 209px !important;
  }
  .w-xl-210px {
    width: 210px !important;
  }
  .w-xl-211px {
    width: 211px !important;
  }
  .w-xl-212px {
    width: 212px !important;
  }
  .w-xl-213px {
    width: 213px !important;
  }
  .w-xl-214px {
    width: 214px !important;
  }
  .w-xl-215px {
    width: 215px !important;
  }
  .w-xl-216px {
    width: 216px !important;
  }
  .w-xl-217px {
    width: 217px !important;
  }
  .w-xl-218px {
    width: 218px !important;
  }
  .w-xl-219px {
    width: 219px !important;
  }
  .w-xl-220px {
    width: 220px !important;
  }
  .w-xl-221px {
    width: 221px !important;
  }
  .w-xl-222px {
    width: 222px !important;
  }
  .w-xl-223px {
    width: 223px !important;
  }
  .w-xl-224px {
    width: 224px !important;
  }
  .w-xl-225px {
    width: 225px !important;
  }
  .w-xl-226px {
    width: 226px !important;
  }
  .w-xl-227px {
    width: 227px !important;
  }
  .w-xl-228px {
    width: 228px !important;
  }
  .w-xl-229px {
    width: 229px !important;
  }
  .w-xl-230px {
    width: 230px !important;
  }
  .w-xl-231px {
    width: 231px !important;
  }
  .w-xl-232px {
    width: 232px !important;
  }
  .w-xl-233px {
    width: 233px !important;
  }
  .w-xl-234px {
    width: 234px !important;
  }
  .w-xl-235px {
    width: 235px !important;
  }
  .w-xl-236px {
    width: 236px !important;
  }
  .w-xl-237px {
    width: 237px !important;
  }
  .w-xl-238px {
    width: 238px !important;
  }
  .w-xl-239px {
    width: 239px !important;
  }
  .w-xl-240px {
    width: 240px !important;
  }
  .w-xl-241px {
    width: 241px !important;
  }
  .w-xl-242px {
    width: 242px !important;
  }
  .w-xl-243px {
    width: 243px !important;
  }
  .w-xl-244px {
    width: 244px !important;
  }
  .w-xl-245px {
    width: 245px !important;
  }
  .w-xl-246px {
    width: 246px !important;
  }
  .w-xl-247px {
    width: 247px !important;
  }
  .w-xl-248px {
    width: 248px !important;
  }
  .w-xl-249px {
    width: 249px !important;
  }
  .w-xl-250px {
    width: 250px !important;
  }
  .w-xl-251px {
    width: 251px !important;
  }
  .w-xl-252px {
    width: 252px !important;
  }
  .w-xl-253px {
    width: 253px !important;
  }
  .w-xl-254px {
    width: 254px !important;
  }
  .w-xl-255px {
    width: 255px !important;
  }
  .w-xl-256px {
    width: 256px !important;
  }
  .w-xl-257px {
    width: 257px !important;
  }
  .w-xl-258px {
    width: 258px !important;
  }
  .w-xl-259px {
    width: 259px !important;
  }
  .w-xl-260px {
    width: 260px !important;
  }
  .w-xl-261px {
    width: 261px !important;
  }
  .w-xl-262px {
    width: 262px !important;
  }
  .w-xl-263px {
    width: 263px !important;
  }
  .w-xl-264px {
    width: 264px !important;
  }
  .w-xl-265px {
    width: 265px !important;
  }
  .w-xl-266px {
    width: 266px !important;
  }
  .w-xl-267px {
    width: 267px !important;
  }
  .w-xl-268px {
    width: 268px !important;
  }
  .w-xl-269px {
    width: 269px !important;
  }
  .w-xl-270px {
    width: 270px !important;
  }
  .w-xl-271px {
    width: 271px !important;
  }
  .w-xl-272px {
    width: 272px !important;
  }
  .w-xl-273px {
    width: 273px !important;
  }
  .w-xl-274px {
    width: 274px !important;
  }
  .w-xl-275px {
    width: 275px !important;
  }
  .w-xl-276px {
    width: 276px !important;
  }
  .w-xl-277px {
    width: 277px !important;
  }
  .w-xl-278px {
    width: 278px !important;
  }
  .w-xl-279px {
    width: 279px !important;
  }
  .w-xl-280px {
    width: 280px !important;
  }
  .w-xl-281px {
    width: 281px !important;
  }
  .w-xl-282px {
    width: 282px !important;
  }
  .w-xl-283px {
    width: 283px !important;
  }
  .w-xl-284px {
    width: 284px !important;
  }
  .w-xl-285px {
    width: 285px !important;
  }
  .w-xl-286px {
    width: 286px !important;
  }
  .w-xl-287px {
    width: 287px !important;
  }
  .w-xl-288px {
    width: 288px !important;
  }
  .w-xl-289px {
    width: 289px !important;
  }
  .w-xl-290px {
    width: 290px !important;
  }
  .w-xl-291px {
    width: 291px !important;
  }
  .w-xl-292px {
    width: 292px !important;
  }
  .w-xl-293px {
    width: 293px !important;
  }
  .w-xl-294px {
    width: 294px !important;
  }
  .w-xl-295px {
    width: 295px !important;
  }
  .w-xl-296px {
    width: 296px !important;
  }
  .w-xl-297px {
    width: 297px !important;
  }
  .w-xl-298px {
    width: 298px !important;
  }
  .w-xl-299px {
    width: 299px !important;
  }
  .w-xl-300px {
    width: 300px !important;
  }
  .w-xl-301px {
    width: 301px !important;
  }
  .w-xl-302px {
    width: 302px !important;
  }
  .w-xl-303px {
    width: 303px !important;
  }
  .w-xl-304px {
    width: 304px !important;
  }
  .w-xl-305px {
    width: 305px !important;
  }
  .w-xl-306px {
    width: 306px !important;
  }
  .w-xl-307px {
    width: 307px !important;
  }
  .w-xl-308px {
    width: 308px !important;
  }
  .w-xl-309px {
    width: 309px !important;
  }
  .w-xl-310px {
    width: 310px !important;
  }
  .w-xl-311px {
    width: 311px !important;
  }
  .w-xl-312px {
    width: 312px !important;
  }
  .w-xl-313px {
    width: 313px !important;
  }
  .w-xl-314px {
    width: 314px !important;
  }
  .w-xl-315px {
    width: 315px !important;
  }
  .w-xl-316px {
    width: 316px !important;
  }
  .w-xl-317px {
    width: 317px !important;
  }
  .w-xl-318px {
    width: 318px !important;
  }
  .w-xl-319px {
    width: 319px !important;
  }
  .w-xl-320px {
    width: 320px !important;
  }
  .w-xl-321px {
    width: 321px !important;
  }
  .w-xl-322px {
    width: 322px !important;
  }
  .w-xl-323px {
    width: 323px !important;
  }
  .w-xl-324px {
    width: 324px !important;
  }
  .w-xl-325px {
    width: 325px !important;
  }
  .w-xl-326px {
    width: 326px !important;
  }
  .w-xl-327px {
    width: 327px !important;
  }
  .w-xl-328px {
    width: 328px !important;
  }
  .w-xl-329px {
    width: 329px !important;
  }
  .w-xl-330px {
    width: 330px !important;
  }
  .w-xl-331px {
    width: 331px !important;
  }
  .w-xl-332px {
    width: 332px !important;
  }
  .w-xl-333px {
    width: 333px !important;
  }
  .w-xl-334px {
    width: 334px !important;
  }
  .w-xl-335px {
    width: 335px !important;
  }
  .w-xl-336px {
    width: 336px !important;
  }
  .w-xl-337px {
    width: 337px !important;
  }
  .w-xl-338px {
    width: 338px !important;
  }
  .w-xl-339px {
    width: 339px !important;
  }
  .w-xl-340px {
    width: 340px !important;
  }
  .w-xl-341px {
    width: 341px !important;
  }
  .w-xl-342px {
    width: 342px !important;
  }
  .w-xl-343px {
    width: 343px !important;
  }
  .w-xl-344px {
    width: 344px !important;
  }
  .w-xl-345px {
    width: 345px !important;
  }
  .w-xl-346px {
    width: 346px !important;
  }
  .w-xl-347px {
    width: 347px !important;
  }
  .w-xl-348px {
    width: 348px !important;
  }
  .w-xl-349px {
    width: 349px !important;
  }
  .w-xl-350px {
    width: 350px !important;
  }
  .w-xl-351px {
    width: 351px !important;
  }
  .w-xl-352px {
    width: 352px !important;
  }
  .w-xl-353px {
    width: 353px !important;
  }
  .w-xl-354px {
    width: 354px !important;
  }
  .w-xl-355px {
    width: 355px !important;
  }
  .w-xl-356px {
    width: 356px !important;
  }
  .w-xl-357px {
    width: 357px !important;
  }
  .w-xl-358px {
    width: 358px !important;
  }
  .w-xl-359px {
    width: 359px !important;
  }
  .w-xl-360px {
    width: 360px !important;
  }
  .w-xl-361px {
    width: 361px !important;
  }
  .w-xl-362px {
    width: 362px !important;
  }
  .w-xl-363px {
    width: 363px !important;
  }
  .w-xl-364px {
    width: 364px !important;
  }
  .w-xl-365px {
    width: 365px !important;
  }
  .w-xl-366px {
    width: 366px !important;
  }
  .w-xl-367px {
    width: 367px !important;
  }
  .w-xl-368px {
    width: 368px !important;
  }
  .w-xl-369px {
    width: 369px !important;
  }
  .w-xl-370px {
    width: 370px !important;
  }
  .w-xl-371px {
    width: 371px !important;
  }
  .w-xl-372px {
    width: 372px !important;
  }
  .w-xl-373px {
    width: 373px !important;
  }
  .w-xl-374px {
    width: 374px !important;
  }
  .w-xl-375px {
    width: 375px !important;
  }
  .w-xl-376px {
    width: 376px !important;
  }
  .w-xl-377px {
    width: 377px !important;
  }
  .w-xl-378px {
    width: 378px !important;
  }
  .w-xl-379px {
    width: 379px !important;
  }
  .w-xl-380px {
    width: 380px !important;
  }
  .w-xl-381px {
    width: 381px !important;
  }
  .w-xl-382px {
    width: 382px !important;
  }
  .w-xl-383px {
    width: 383px !important;
  }
  .w-xl-384px {
    width: 384px !important;
  }
  .w-xl-385px {
    width: 385px !important;
  }
  .w-xl-386px {
    width: 386px !important;
  }
  .w-xl-387px {
    width: 387px !important;
  }
  .w-xl-388px {
    width: 388px !important;
  }
  .w-xl-389px {
    width: 389px !important;
  }
  .w-xl-390px {
    width: 390px !important;
  }
  .w-xl-391px {
    width: 391px !important;
  }
  .w-xl-392px {
    width: 392px !important;
  }
  .w-xl-393px {
    width: 393px !important;
  }
  .w-xl-394px {
    width: 394px !important;
  }
  .w-xl-395px {
    width: 395px !important;
  }
  .w-xl-396px {
    width: 396px !important;
  }
  .w-xl-397px {
    width: 397px !important;
  }
  .w-xl-398px {
    width: 398px !important;
  }
  .w-xl-399px {
    width: 399px !important;
  }
  .w-xl-400px {
    width: 400px !important;
  }
  .w-xl-401px {
    width: 401px !important;
  }
  .w-xl-402px {
    width: 402px !important;
  }
  .w-xl-403px {
    width: 403px !important;
  }
  .w-xl-404px {
    width: 404px !important;
  }
  .w-xl-405px {
    width: 405px !important;
  }
  .w-xl-406px {
    width: 406px !important;
  }
  .w-xl-407px {
    width: 407px !important;
  }
  .w-xl-408px {
    width: 408px !important;
  }
  .w-xl-409px {
    width: 409px !important;
  }
  .w-xl-410px {
    width: 410px !important;
  }
  .w-xl-411px {
    width: 411px !important;
  }
  .w-xl-412px {
    width: 412px !important;
  }
  .w-xl-413px {
    width: 413px !important;
  }
  .w-xl-414px {
    width: 414px !important;
  }
  .w-xl-415px {
    width: 415px !important;
  }
  .w-xl-416px {
    width: 416px !important;
  }
  .w-xl-417px {
    width: 417px !important;
  }
  .w-xl-418px {
    width: 418px !important;
  }
  .w-xl-419px {
    width: 419px !important;
  }
  .w-xl-420px {
    width: 420px !important;
  }
  .w-xl-421px {
    width: 421px !important;
  }
  .w-xl-422px {
    width: 422px !important;
  }
  .w-xl-423px {
    width: 423px !important;
  }
  .w-xl-424px {
    width: 424px !important;
  }
  .w-xl-425px {
    width: 425px !important;
  }
  .w-xl-426px {
    width: 426px !important;
  }
  .w-xl-427px {
    width: 427px !important;
  }
  .w-xl-428px {
    width: 428px !important;
  }
  .w-xl-429px {
    width: 429px !important;
  }
  .w-xl-430px {
    width: 430px !important;
  }
  .w-xl-431px {
    width: 431px !important;
  }
  .w-xl-432px {
    width: 432px !important;
  }
  .w-xl-433px {
    width: 433px !important;
  }
  .w-xl-434px {
    width: 434px !important;
  }
  .w-xl-435px {
    width: 435px !important;
  }
  .w-xl-436px {
    width: 436px !important;
  }
  .w-xl-437px {
    width: 437px !important;
  }
  .w-xl-438px {
    width: 438px !important;
  }
  .w-xl-439px {
    width: 439px !important;
  }
  .w-xl-440px {
    width: 440px !important;
  }
  .w-xl-441px {
    width: 441px !important;
  }
  .w-xl-442px {
    width: 442px !important;
  }
  .w-xl-443px {
    width: 443px !important;
  }
  .w-xl-444px {
    width: 444px !important;
  }
  .w-xl-445px {
    width: 445px !important;
  }
  .w-xl-446px {
    width: 446px !important;
  }
  .w-xl-447px {
    width: 447px !important;
  }
  .w-xl-448px {
    width: 448px !important;
  }
  .w-xl-449px {
    width: 449px !important;
  }
  .w-xl-450px {
    width: 450px !important;
  }
  .w-xl-451px {
    width: 451px !important;
  }
  .w-xl-452px {
    width: 452px !important;
  }
  .w-xl-453px {
    width: 453px !important;
  }
  .w-xl-454px {
    width: 454px !important;
  }
  .w-xl-455px {
    width: 455px !important;
  }
  .w-xl-456px {
    width: 456px !important;
  }
  .w-xl-457px {
    width: 457px !important;
  }
  .w-xl-458px {
    width: 458px !important;
  }
  .w-xl-459px {
    width: 459px !important;
  }
  .w-xl-460px {
    width: 460px !important;
  }
  .w-xl-461px {
    width: 461px !important;
  }
  .w-xl-462px {
    width: 462px !important;
  }
  .w-xl-463px {
    width: 463px !important;
  }
  .w-xl-464px {
    width: 464px !important;
  }
  .w-xl-465px {
    width: 465px !important;
  }
  .w-xl-466px {
    width: 466px !important;
  }
  .w-xl-467px {
    width: 467px !important;
  }
  .w-xl-468px {
    width: 468px !important;
  }
  .w-xl-469px {
    width: 469px !important;
  }
  .w-xl-470px {
    width: 470px !important;
  }
  .w-xl-471px {
    width: 471px !important;
  }
  .w-xl-472px {
    width: 472px !important;
  }
  .w-xl-473px {
    width: 473px !important;
  }
  .w-xl-474px {
    width: 474px !important;
  }
  .w-xl-475px {
    width: 475px !important;
  }
  .w-xl-476px {
    width: 476px !important;
  }
  .w-xl-477px {
    width: 477px !important;
  }
  .w-xl-478px {
    width: 478px !important;
  }
  .w-xl-479px {
    width: 479px !important;
  }
  .w-xl-480px {
    width: 480px !important;
  }
  .w-xl-481px {
    width: 481px !important;
  }
  .w-xl-482px {
    width: 482px !important;
  }
  .w-xl-483px {
    width: 483px !important;
  }
  .w-xl-484px {
    width: 484px !important;
  }
  .w-xl-485px {
    width: 485px !important;
  }
  .w-xl-486px {
    width: 486px !important;
  }
  .w-xl-487px {
    width: 487px !important;
  }
  .w-xl-488px {
    width: 488px !important;
  }
  .w-xl-489px {
    width: 489px !important;
  }
  .w-xl-490px {
    width: 490px !important;
  }
  .w-xl-491px {
    width: 491px !important;
  }
  .w-xl-492px {
    width: 492px !important;
  }
  .w-xl-493px {
    width: 493px !important;
  }
  .w-xl-494px {
    width: 494px !important;
  }
  .w-xl-495px {
    width: 495px !important;
  }
  .w-xl-496px {
    width: 496px !important;
  }
  .w-xl-497px {
    width: 497px !important;
  }
  .w-xl-498px {
    width: 498px !important;
  }
  .w-xl-499px {
    width: 499px !important;
  }
  .w-xl-500px {
    width: 500px !important;
  }
  .w-xl-501px {
    width: 501px !important;
  }
  .w-xl-502px {
    width: 502px !important;
  }
  .w-xl-503px {
    width: 503px !important;
  }
  .w-xl-504px {
    width: 504px !important;
  }
  .w-xl-505px {
    width: 505px !important;
  }
  .w-xl-506px {
    width: 506px !important;
  }
  .w-xl-507px {
    width: 507px !important;
  }
  .w-xl-508px {
    width: 508px !important;
  }
  .w-xl-509px {
    width: 509px !important;
  }
  .w-xl-510px {
    width: 510px !important;
  }
  .w-xl-511px {
    width: 511px !important;
  }
  .w-xl-512px {
    width: 512px !important;
  }
  .w-xl-513px {
    width: 513px !important;
  }
  .w-xl-514px {
    width: 514px !important;
  }
  .w-xl-515px {
    width: 515px !important;
  }
  .w-xl-516px {
    width: 516px !important;
  }
  .w-xl-517px {
    width: 517px !important;
  }
  .w-xl-518px {
    width: 518px !important;
  }
  .w-xl-519px {
    width: 519px !important;
  }
  .w-xl-520px {
    width: 520px !important;
  }
  .w-xl-521px {
    width: 521px !important;
  }
  .w-xl-522px {
    width: 522px !important;
  }
  .w-xl-523px {
    width: 523px !important;
  }
  .w-xl-524px {
    width: 524px !important;
  }
  .w-xl-525px {
    width: 525px !important;
  }
  .w-xl-526px {
    width: 526px !important;
  }
  .w-xl-527px {
    width: 527px !important;
  }
  .w-xl-528px {
    width: 528px !important;
  }
  .w-xl-529px {
    width: 529px !important;
  }
  .w-xl-530px {
    width: 530px !important;
  }
  .w-xl-531px {
    width: 531px !important;
  }
  .w-xl-532px {
    width: 532px !important;
  }
  .w-xl-533px {
    width: 533px !important;
  }
  .w-xl-534px {
    width: 534px !important;
  }
  .w-xl-535px {
    width: 535px !important;
  }
  .w-xl-536px {
    width: 536px !important;
  }
  .w-xl-537px {
    width: 537px !important;
  }
  .w-xl-538px {
    width: 538px !important;
  }
  .w-xl-539px {
    width: 539px !important;
  }
  .w-xl-540px {
    width: 540px !important;
  }
  .w-xl-541px {
    width: 541px !important;
  }
  .w-xl-542px {
    width: 542px !important;
  }
  .w-xl-543px {
    width: 543px !important;
  }
  .w-xl-544px {
    width: 544px !important;
  }
  .w-xl-545px {
    width: 545px !important;
  }
  .w-xl-546px {
    width: 546px !important;
  }
  .w-xl-547px {
    width: 547px !important;
  }
  .w-xl-548px {
    width: 548px !important;
  }
  .w-xl-549px {
    width: 549px !important;
  }
  .w-xl-550px {
    width: 550px !important;
  }
  .w-xl-551px {
    width: 551px !important;
  }
  .w-xl-552px {
    width: 552px !important;
  }
  .w-xl-553px {
    width: 553px !important;
  }
  .w-xl-554px {
    width: 554px !important;
  }
  .w-xl-555px {
    width: 555px !important;
  }
  .w-xl-556px {
    width: 556px !important;
  }
  .w-xl-557px {
    width: 557px !important;
  }
  .w-xl-558px {
    width: 558px !important;
  }
  .w-xl-559px {
    width: 559px !important;
  }
  .w-xl-560px {
    width: 560px !important;
  }
  .w-xl-561px {
    width: 561px !important;
  }
  .w-xl-562px {
    width: 562px !important;
  }
  .w-xl-563px {
    width: 563px !important;
  }
  .w-xl-564px {
    width: 564px !important;
  }
  .w-xl-565px {
    width: 565px !important;
  }
  .w-xl-566px {
    width: 566px !important;
  }
  .w-xl-567px {
    width: 567px !important;
  }
  .w-xl-568px {
    width: 568px !important;
  }
  .w-xl-569px {
    width: 569px !important;
  }
  .w-xl-570px {
    width: 570px !important;
  }
  .w-xl-571px {
    width: 571px !important;
  }
  .w-xl-572px {
    width: 572px !important;
  }
  .w-xl-573px {
    width: 573px !important;
  }
  .w-xl-574px {
    width: 574px !important;
  }
  .w-xl-575px {
    width: 575px !important;
  }
  .w-xl-576px {
    width: 576px !important;
  }
  .w-xl-577px {
    width: 577px !important;
  }
  .w-xl-578px {
    width: 578px !important;
  }
  .w-xl-579px {
    width: 579px !important;
  }
  .w-xl-580px {
    width: 580px !important;
  }
  .w-xl-581px {
    width: 581px !important;
  }
  .w-xl-582px {
    width: 582px !important;
  }
  .w-xl-583px {
    width: 583px !important;
  }
  .w-xl-584px {
    width: 584px !important;
  }
  .w-xl-585px {
    width: 585px !important;
  }
  .w-xl-586px {
    width: 586px !important;
  }
  .w-xl-587px {
    width: 587px !important;
  }
  .w-xl-588px {
    width: 588px !important;
  }
  .w-xl-589px {
    width: 589px !important;
  }
  .w-xl-590px {
    width: 590px !important;
  }
  .w-xl-591px {
    width: 591px !important;
  }
  .w-xl-592px {
    width: 592px !important;
  }
  .w-xl-593px {
    width: 593px !important;
  }
  .w-xl-594px {
    width: 594px !important;
  }
  .w-xl-595px {
    width: 595px !important;
  }
  .w-xl-596px {
    width: 596px !important;
  }
  .w-xl-597px {
    width: 597px !important;
  }
  .w-xl-598px {
    width: 598px !important;
  }
  .w-xl-599px {
    width: 599px !important;
  }
  .w-xl-600px {
    width: 600px !important;
  }
  .w-xl-601px {
    width: 601px !important;
  }
  .w-xl-602px {
    width: 602px !important;
  }
  .w-xl-603px {
    width: 603px !important;
  }
  .w-xl-604px {
    width: 604px !important;
  }
  .w-xl-605px {
    width: 605px !important;
  }
  .w-xl-606px {
    width: 606px !important;
  }
  .w-xl-607px {
    width: 607px !important;
  }
  .w-xl-608px {
    width: 608px !important;
  }
  .w-xl-609px {
    width: 609px !important;
  }
  .w-xl-610px {
    width: 610px !important;
  }
  .w-xl-611px {
    width: 611px !important;
  }
  .w-xl-612px {
    width: 612px !important;
  }
  .w-xl-613px {
    width: 613px !important;
  }
  .w-xl-614px {
    width: 614px !important;
  }
  .w-xl-615px {
    width: 615px !important;
  }
  .w-xl-616px {
    width: 616px !important;
  }
  .w-xl-617px {
    width: 617px !important;
  }
  .w-xl-618px {
    width: 618px !important;
  }
  .w-xl-619px {
    width: 619px !important;
  }
  .w-xl-620px {
    width: 620px !important;
  }
  .w-xl-621px {
    width: 621px !important;
  }
  .w-xl-622px {
    width: 622px !important;
  }
  .w-xl-623px {
    width: 623px !important;
  }
  .w-xl-624px {
    width: 624px !important;
  }
  .w-xl-625px {
    width: 625px !important;
  }
  .w-xl-626px {
    width: 626px !important;
  }
  .w-xl-627px {
    width: 627px !important;
  }
  .w-xl-628px {
    width: 628px !important;
  }
  .w-xl-629px {
    width: 629px !important;
  }
  .w-xl-630px {
    width: 630px !important;
  }
  .w-xl-631px {
    width: 631px !important;
  }
  .w-xl-632px {
    width: 632px !important;
  }
  .w-xl-633px {
    width: 633px !important;
  }
  .w-xl-634px {
    width: 634px !important;
  }
  .w-xl-635px {
    width: 635px !important;
  }
  .w-xl-636px {
    width: 636px !important;
  }
  .w-xl-637px {
    width: 637px !important;
  }
  .w-xl-638px {
    width: 638px !important;
  }
  .w-xl-639px {
    width: 639px !important;
  }
  .w-xl-640px {
    width: 640px !important;
  }
  .w-xl-641px {
    width: 641px !important;
  }
  .w-xl-642px {
    width: 642px !important;
  }
  .w-xl-643px {
    width: 643px !important;
  }
  .w-xl-644px {
    width: 644px !important;
  }
  .w-xl-645px {
    width: 645px !important;
  }
  .w-xl-646px {
    width: 646px !important;
  }
  .w-xl-647px {
    width: 647px !important;
  }
  .w-xl-648px {
    width: 648px !important;
  }
  .w-xl-649px {
    width: 649px !important;
  }
  .w-xl-650px {
    width: 650px !important;
  }
  .w-xl-651px {
    width: 651px !important;
  }
  .w-xl-652px {
    width: 652px !important;
  }
  .w-xl-653px {
    width: 653px !important;
  }
  .w-xl-654px {
    width: 654px !important;
  }
  .w-xl-655px {
    width: 655px !important;
  }
  .w-xl-656px {
    width: 656px !important;
  }
  .w-xl-657px {
    width: 657px !important;
  }
  .w-xl-658px {
    width: 658px !important;
  }
  .w-xl-659px {
    width: 659px !important;
  }
  .w-xl-660px {
    width: 660px !important;
  }
  .w-xl-661px {
    width: 661px !important;
  }
  .w-xl-662px {
    width: 662px !important;
  }
  .w-xl-663px {
    width: 663px !important;
  }
  .w-xl-664px {
    width: 664px !important;
  }
  .w-xl-665px {
    width: 665px !important;
  }
  .w-xl-666px {
    width: 666px !important;
  }
  .w-xl-667px {
    width: 667px !important;
  }
  .w-xl-668px {
    width: 668px !important;
  }
  .w-xl-669px {
    width: 669px !important;
  }
  .w-xl-670px {
    width: 670px !important;
  }
  .w-xl-671px {
    width: 671px !important;
  }
  .w-xl-672px {
    width: 672px !important;
  }
  .w-xl-673px {
    width: 673px !important;
  }
  .w-xl-674px {
    width: 674px !important;
  }
  .w-xl-675px {
    width: 675px !important;
  }
  .w-xl-676px {
    width: 676px !important;
  }
  .w-xl-677px {
    width: 677px !important;
  }
  .w-xl-678px {
    width: 678px !important;
  }
  .w-xl-679px {
    width: 679px !important;
  }
  .w-xl-680px {
    width: 680px !important;
  }
  .w-xl-681px {
    width: 681px !important;
  }
  .w-xl-682px {
    width: 682px !important;
  }
  .w-xl-683px {
    width: 683px !important;
  }
  .w-xl-684px {
    width: 684px !important;
  }
  .w-xl-685px {
    width: 685px !important;
  }
  .w-xl-686px {
    width: 686px !important;
  }
  .w-xl-687px {
    width: 687px !important;
  }
  .w-xl-688px {
    width: 688px !important;
  }
  .w-xl-689px {
    width: 689px !important;
  }
  .w-xl-690px {
    width: 690px !important;
  }
  .w-xl-691px {
    width: 691px !important;
  }
  .w-xl-692px {
    width: 692px !important;
  }
  .w-xl-693px {
    width: 693px !important;
  }
  .w-xl-694px {
    width: 694px !important;
  }
  .w-xl-695px {
    width: 695px !important;
  }
  .w-xl-696px {
    width: 696px !important;
  }
  .w-xl-697px {
    width: 697px !important;
  }
  .w-xl-698px {
    width: 698px !important;
  }
  .w-xl-699px {
    width: 699px !important;
  }
  .w-xl-700px {
    width: 700px !important;
  }
  .w-xl-701px {
    width: 701px !important;
  }
  .w-xl-702px {
    width: 702px !important;
  }
  .w-xl-703px {
    width: 703px !important;
  }
  .w-xl-704px {
    width: 704px !important;
  }
  .w-xl-705px {
    width: 705px !important;
  }
  .w-xl-706px {
    width: 706px !important;
  }
  .w-xl-707px {
    width: 707px !important;
  }
  .w-xl-708px {
    width: 708px !important;
  }
  .w-xl-709px {
    width: 709px !important;
  }
  .w-xl-710px {
    width: 710px !important;
  }
  .w-xl-711px {
    width: 711px !important;
  }
  .w-xl-712px {
    width: 712px !important;
  }
  .w-xl-713px {
    width: 713px !important;
  }
  .w-xl-714px {
    width: 714px !important;
  }
  .w-xl-715px {
    width: 715px !important;
  }
  .w-xl-716px {
    width: 716px !important;
  }
  .w-xl-717px {
    width: 717px !important;
  }
  .w-xl-718px {
    width: 718px !important;
  }
  .w-xl-719px {
    width: 719px !important;
  }
  .w-xl-720px {
    width: 720px !important;
  }
  .w-xl-721px {
    width: 721px !important;
  }
  .w-xl-722px {
    width: 722px !important;
  }
  .w-xl-723px {
    width: 723px !important;
  }
  .w-xl-724px {
    width: 724px !important;
  }
  .w-xl-725px {
    width: 725px !important;
  }
  .w-xl-726px {
    width: 726px !important;
  }
  .w-xl-727px {
    width: 727px !important;
  }
  .w-xl-728px {
    width: 728px !important;
  }
  .w-xl-729px {
    width: 729px !important;
  }
  .w-xl-730px {
    width: 730px !important;
  }
  .w-xl-731px {
    width: 731px !important;
  }
  .w-xl-732px {
    width: 732px !important;
  }
  .w-xl-733px {
    width: 733px !important;
  }
  .w-xl-734px {
    width: 734px !important;
  }
  .w-xl-735px {
    width: 735px !important;
  }
  .w-xl-736px {
    width: 736px !important;
  }
  .w-xl-737px {
    width: 737px !important;
  }
  .w-xl-738px {
    width: 738px !important;
  }
  .w-xl-739px {
    width: 739px !important;
  }
  .w-xl-740px {
    width: 740px !important;
  }
  .w-xl-741px {
    width: 741px !important;
  }
  .w-xl-742px {
    width: 742px !important;
  }
  .w-xl-743px {
    width: 743px !important;
  }
  .w-xl-744px {
    width: 744px !important;
  }
  .w-xl-745px {
    width: 745px !important;
  }
  .w-xl-746px {
    width: 746px !important;
  }
  .w-xl-747px {
    width: 747px !important;
  }
  .w-xl-748px {
    width: 748px !important;
  }
  .w-xl-749px {
    width: 749px !important;
  }
  .w-xl-750px {
    width: 750px !important;
  }
  .w-xl-751px {
    width: 751px !important;
  }
  .w-xl-752px {
    width: 752px !important;
  }
  .w-xl-753px {
    width: 753px !important;
  }
  .w-xl-754px {
    width: 754px !important;
  }
  .w-xl-755px {
    width: 755px !important;
  }
  .w-xl-756px {
    width: 756px !important;
  }
  .w-xl-757px {
    width: 757px !important;
  }
  .w-xl-758px {
    width: 758px !important;
  }
  .w-xl-759px {
    width: 759px !important;
  }
  .w-xl-760px {
    width: 760px !important;
  }
  .w-xl-761px {
    width: 761px !important;
  }
  .w-xl-762px {
    width: 762px !important;
  }
  .w-xl-763px {
    width: 763px !important;
  }
  .w-xl-764px {
    width: 764px !important;
  }
  .w-xl-765px {
    width: 765px !important;
  }
  .w-xl-766px {
    width: 766px !important;
  }
  .w-xl-767px {
    width: 767px !important;
  }
  .w-xl-768px {
    width: 768px !important;
  }
  .w-xl-769px {
    width: 769px !important;
  }
  .w-xl-770px {
    width: 770px !important;
  }
  .w-xl-771px {
    width: 771px !important;
  }
  .w-xl-772px {
    width: 772px !important;
  }
  .w-xl-773px {
    width: 773px !important;
  }
  .w-xl-774px {
    width: 774px !important;
  }
  .w-xl-775px {
    width: 775px !important;
  }
  .w-xl-776px {
    width: 776px !important;
  }
  .w-xl-777px {
    width: 777px !important;
  }
  .w-xl-778px {
    width: 778px !important;
  }
  .w-xl-779px {
    width: 779px !important;
  }
  .w-xl-780px {
    width: 780px !important;
  }
  .w-xl-781px {
    width: 781px !important;
  }
  .w-xl-782px {
    width: 782px !important;
  }
  .w-xl-783px {
    width: 783px !important;
  }
  .w-xl-784px {
    width: 784px !important;
  }
  .w-xl-785px {
    width: 785px !important;
  }
  .w-xl-786px {
    width: 786px !important;
  }
  .w-xl-787px {
    width: 787px !important;
  }
  .w-xl-788px {
    width: 788px !important;
  }
  .w-xl-789px {
    width: 789px !important;
  }
  .w-xl-790px {
    width: 790px !important;
  }
  .w-xl-791px {
    width: 791px !important;
  }
  .w-xl-792px {
    width: 792px !important;
  }
  .w-xl-793px {
    width: 793px !important;
  }
  .w-xl-794px {
    width: 794px !important;
  }
  .w-xl-795px {
    width: 795px !important;
  }
  .w-xl-796px {
    width: 796px !important;
  }
  .w-xl-797px {
    width: 797px !important;
  }
  .w-xl-798px {
    width: 798px !important;
  }
  .w-xl-799px {
    width: 799px !important;
  }
  .w-xl-800px {
    width: 800px !important;
  }
  .w-xl-801px {
    width: 801px !important;
  }
  .w-xl-802px {
    width: 802px !important;
  }
  .w-xl-803px {
    width: 803px !important;
  }
  .w-xl-804px {
    width: 804px !important;
  }
  .w-xl-805px {
    width: 805px !important;
  }
  .w-xl-806px {
    width: 806px !important;
  }
  .w-xl-807px {
    width: 807px !important;
  }
  .w-xl-808px {
    width: 808px !important;
  }
  .w-xl-809px {
    width: 809px !important;
  }
  .w-xl-810px {
    width: 810px !important;
  }
  .w-xl-811px {
    width: 811px !important;
  }
  .w-xl-812px {
    width: 812px !important;
  }
  .w-xl-813px {
    width: 813px !important;
  }
  .w-xl-814px {
    width: 814px !important;
  }
  .w-xl-815px {
    width: 815px !important;
  }
  .w-xl-816px {
    width: 816px !important;
  }
  .w-xl-817px {
    width: 817px !important;
  }
  .w-xl-818px {
    width: 818px !important;
  }
  .w-xl-819px {
    width: 819px !important;
  }
  .w-xl-820px {
    width: 820px !important;
  }
  .w-xl-821px {
    width: 821px !important;
  }
  .w-xl-822px {
    width: 822px !important;
  }
  .w-xl-823px {
    width: 823px !important;
  }
  .w-xl-824px {
    width: 824px !important;
  }
  .w-xl-825px {
    width: 825px !important;
  }
  .w-xl-826px {
    width: 826px !important;
  }
  .w-xl-827px {
    width: 827px !important;
  }
  .w-xl-828px {
    width: 828px !important;
  }
  .w-xl-829px {
    width: 829px !important;
  }
  .w-xl-830px {
    width: 830px !important;
  }
  .w-xl-831px {
    width: 831px !important;
  }
  .w-xl-832px {
    width: 832px !important;
  }
  .w-xl-833px {
    width: 833px !important;
  }
  .w-xl-834px {
    width: 834px !important;
  }
  .w-xl-835px {
    width: 835px !important;
  }
  .w-xl-836px {
    width: 836px !important;
  }
  .w-xl-837px {
    width: 837px !important;
  }
  .w-xl-838px {
    width: 838px !important;
  }
  .w-xl-839px {
    width: 839px !important;
  }
  .w-xl-840px {
    width: 840px !important;
  }
  .w-xl-841px {
    width: 841px !important;
  }
  .w-xl-842px {
    width: 842px !important;
  }
  .w-xl-843px {
    width: 843px !important;
  }
  .w-xl-844px {
    width: 844px !important;
  }
  .w-xl-845px {
    width: 845px !important;
  }
  .w-xl-846px {
    width: 846px !important;
  }
  .w-xl-847px {
    width: 847px !important;
  }
  .w-xl-848px {
    width: 848px !important;
  }
  .w-xl-849px {
    width: 849px !important;
  }
  .w-xl-850px {
    width: 850px !important;
  }
  .w-xl-851px {
    width: 851px !important;
  }
  .w-xl-852px {
    width: 852px !important;
  }
  .w-xl-853px {
    width: 853px !important;
  }
  .w-xl-854px {
    width: 854px !important;
  }
  .w-xl-855px {
    width: 855px !important;
  }
  .w-xl-856px {
    width: 856px !important;
  }
  .w-xl-857px {
    width: 857px !important;
  }
  .w-xl-858px {
    width: 858px !important;
  }
  .w-xl-859px {
    width: 859px !important;
  }
  .w-xl-860px {
    width: 860px !important;
  }
  .w-xl-861px {
    width: 861px !important;
  }
  .w-xl-862px {
    width: 862px !important;
  }
  .w-xl-863px {
    width: 863px !important;
  }
  .w-xl-864px {
    width: 864px !important;
  }
  .w-xl-865px {
    width: 865px !important;
  }
  .w-xl-866px {
    width: 866px !important;
  }
  .w-xl-867px {
    width: 867px !important;
  }
  .w-xl-868px {
    width: 868px !important;
  }
  .w-xl-869px {
    width: 869px !important;
  }
  .w-xl-870px {
    width: 870px !important;
  }
  .w-xl-871px {
    width: 871px !important;
  }
  .w-xl-872px {
    width: 872px !important;
  }
  .w-xl-873px {
    width: 873px !important;
  }
  .w-xl-874px {
    width: 874px !important;
  }
  .w-xl-875px {
    width: 875px !important;
  }
  .w-xl-876px {
    width: 876px !important;
  }
  .w-xl-877px {
    width: 877px !important;
  }
  .w-xl-878px {
    width: 878px !important;
  }
  .w-xl-879px {
    width: 879px !important;
  }
  .w-xl-880px {
    width: 880px !important;
  }
  .w-xl-881px {
    width: 881px !important;
  }
  .w-xl-882px {
    width: 882px !important;
  }
  .w-xl-883px {
    width: 883px !important;
  }
  .w-xl-884px {
    width: 884px !important;
  }
  .w-xl-885px {
    width: 885px !important;
  }
  .w-xl-886px {
    width: 886px !important;
  }
  .w-xl-887px {
    width: 887px !important;
  }
  .w-xl-888px {
    width: 888px !important;
  }
  .w-xl-889px {
    width: 889px !important;
  }
  .w-xl-890px {
    width: 890px !important;
  }
  .w-xl-891px {
    width: 891px !important;
  }
  .w-xl-892px {
    width: 892px !important;
  }
  .w-xl-893px {
    width: 893px !important;
  }
  .w-xl-894px {
    width: 894px !important;
  }
  .w-xl-895px {
    width: 895px !important;
  }
  .w-xl-896px {
    width: 896px !important;
  }
  .w-xl-897px {
    width: 897px !important;
  }
  .w-xl-898px {
    width: 898px !important;
  }
  .w-xl-899px {
    width: 899px !important;
  }
  .w-xl-900px {
    width: 900px !important;
  }
  .w-xl-901px {
    width: 901px !important;
  }
  .w-xl-902px {
    width: 902px !important;
  }
  .w-xl-903px {
    width: 903px !important;
  }
  .w-xl-904px {
    width: 904px !important;
  }
  .w-xl-905px {
    width: 905px !important;
  }
  .w-xl-906px {
    width: 906px !important;
  }
  .w-xl-907px {
    width: 907px !important;
  }
  .w-xl-908px {
    width: 908px !important;
  }
  .w-xl-909px {
    width: 909px !important;
  }
  .w-xl-910px {
    width: 910px !important;
  }
  .w-xl-911px {
    width: 911px !important;
  }
  .w-xl-912px {
    width: 912px !important;
  }
  .w-xl-913px {
    width: 913px !important;
  }
  .w-xl-914px {
    width: 914px !important;
  }
  .w-xl-915px {
    width: 915px !important;
  }
  .w-xl-916px {
    width: 916px !important;
  }
  .w-xl-917px {
    width: 917px !important;
  }
  .w-xl-918px {
    width: 918px !important;
  }
  .w-xl-919px {
    width: 919px !important;
  }
  .w-xl-920px {
    width: 920px !important;
  }
  .w-xl-921px {
    width: 921px !important;
  }
  .w-xl-922px {
    width: 922px !important;
  }
  .w-xl-923px {
    width: 923px !important;
  }
  .w-xl-924px {
    width: 924px !important;
  }
  .w-xl-925px {
    width: 925px !important;
  }
  .w-xl-926px {
    width: 926px !important;
  }
  .w-xl-927px {
    width: 927px !important;
  }
  .w-xl-928px {
    width: 928px !important;
  }
  .w-xl-929px {
    width: 929px !important;
  }
  .w-xl-930px {
    width: 930px !important;
  }
  .w-xl-931px {
    width: 931px !important;
  }
  .w-xl-932px {
    width: 932px !important;
  }
  .w-xl-933px {
    width: 933px !important;
  }
  .w-xl-934px {
    width: 934px !important;
  }
  .w-xl-935px {
    width: 935px !important;
  }
  .w-xl-936px {
    width: 936px !important;
  }
  .w-xl-937px {
    width: 937px !important;
  }
  .w-xl-938px {
    width: 938px !important;
  }
  .w-xl-939px {
    width: 939px !important;
  }
  .w-xl-940px {
    width: 940px !important;
  }
  .w-xl-941px {
    width: 941px !important;
  }
  .w-xl-942px {
    width: 942px !important;
  }
  .w-xl-943px {
    width: 943px !important;
  }
  .w-xl-944px {
    width: 944px !important;
  }
  .w-xl-945px {
    width: 945px !important;
  }
  .w-xl-946px {
    width: 946px !important;
  }
  .w-xl-947px {
    width: 947px !important;
  }
  .w-xl-948px {
    width: 948px !important;
  }
  .w-xl-949px {
    width: 949px !important;
  }
  .w-xl-950px {
    width: 950px !important;
  }
  .w-xl-951px {
    width: 951px !important;
  }
  .w-xl-952px {
    width: 952px !important;
  }
  .w-xl-953px {
    width: 953px !important;
  }
  .w-xl-954px {
    width: 954px !important;
  }
  .w-xl-955px {
    width: 955px !important;
  }
  .w-xl-956px {
    width: 956px !important;
  }
  .w-xl-957px {
    width: 957px !important;
  }
  .w-xl-958px {
    width: 958px !important;
  }
  .w-xl-959px {
    width: 959px !important;
  }
  .w-xl-960px {
    width: 960px !important;
  }
  .w-xl-961px {
    width: 961px !important;
  }
  .w-xl-962px {
    width: 962px !important;
  }
  .w-xl-963px {
    width: 963px !important;
  }
  .w-xl-964px {
    width: 964px !important;
  }
  .w-xl-965px {
    width: 965px !important;
  }
  .w-xl-966px {
    width: 966px !important;
  }
  .w-xl-967px {
    width: 967px !important;
  }
  .w-xl-968px {
    width: 968px !important;
  }
  .w-xl-969px {
    width: 969px !important;
  }
  .w-xl-970px {
    width: 970px !important;
  }
  .w-xl-971px {
    width: 971px !important;
  }
  .w-xl-972px {
    width: 972px !important;
  }
  .w-xl-973px {
    width: 973px !important;
  }
  .w-xl-974px {
    width: 974px !important;
  }
  .w-xl-975px {
    width: 975px !important;
  }
  .w-xl-976px {
    width: 976px !important;
  }
  .w-xl-977px {
    width: 977px !important;
  }
  .w-xl-978px {
    width: 978px !important;
  }
  .w-xl-979px {
    width: 979px !important;
  }
  .w-xl-980px {
    width: 980px !important;
  }
  .w-xl-981px {
    width: 981px !important;
  }
  .w-xl-982px {
    width: 982px !important;
  }
  .w-xl-983px {
    width: 983px !important;
  }
  .w-xl-984px {
    width: 984px !important;
  }
  .w-xl-985px {
    width: 985px !important;
  }
  .w-xl-986px {
    width: 986px !important;
  }
  .w-xl-987px {
    width: 987px !important;
  }
  .w-xl-988px {
    width: 988px !important;
  }
  .w-xl-989px {
    width: 989px !important;
  }
  .w-xl-990px {
    width: 990px !important;
  }
  .w-xl-991px {
    width: 991px !important;
  }
  .w-xl-992px {
    width: 992px !important;
  }
  .w-xl-993px {
    width: 993px !important;
  }
  .w-xl-994px {
    width: 994px !important;
  }
  .w-xl-995px {
    width: 995px !important;
  }
  .w-xl-996px {
    width: 996px !important;
  }
  .w-xl-997px {
    width: 997px !important;
  }
  .w-xl-998px {
    width: 998px !important;
  }
  .w-xl-999px {
    width: 999px !important;
  }
  .w-xl-1000px {
    width: 1000px !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-0px {
    width: 0px !important;
  }
  .w-xxl-1px {
    width: 1px !important;
  }
  .w-xxl-2px {
    width: 2px !important;
  }
  .w-xxl-3px {
    width: 3px !important;
  }
  .w-xxl-4px {
    width: 4px !important;
  }
  .w-xxl-5px {
    width: 5px !important;
  }
  .w-xxl-6px {
    width: 6px !important;
  }
  .w-xxl-7px {
    width: 7px !important;
  }
  .w-xxl-8px {
    width: 8px !important;
  }
  .w-xxl-9px {
    width: 9px !important;
  }
  .w-xxl-10px {
    width: 10px !important;
  }
  .w-xxl-11px {
    width: 11px !important;
  }
  .w-xxl-12px {
    width: 12px !important;
  }
  .w-xxl-13px {
    width: 13px !important;
  }
  .w-xxl-14px {
    width: 14px !important;
  }
  .w-xxl-15px {
    width: 15px !important;
  }
  .w-xxl-16px {
    width: 16px !important;
  }
  .w-xxl-17px {
    width: 17px !important;
  }
  .w-xxl-18px {
    width: 18px !important;
  }
  .w-xxl-19px {
    width: 19px !important;
  }
  .w-xxl-20px {
    width: 20px !important;
  }
  .w-xxl-21px {
    width: 21px !important;
  }
  .w-xxl-22px {
    width: 22px !important;
  }
  .w-xxl-23px {
    width: 23px !important;
  }
  .w-xxl-24px {
    width: 24px !important;
  }
  .w-xxl-25px {
    width: 25px !important;
  }
  .w-xxl-26px {
    width: 26px !important;
  }
  .w-xxl-27px {
    width: 27px !important;
  }
  .w-xxl-28px {
    width: 28px !important;
  }
  .w-xxl-29px {
    width: 29px !important;
  }
  .w-xxl-30px {
    width: 30px !important;
  }
  .w-xxl-31px {
    width: 31px !important;
  }
  .w-xxl-32px {
    width: 32px !important;
  }
  .w-xxl-33px {
    width: 33px !important;
  }
  .w-xxl-34px {
    width: 34px !important;
  }
  .w-xxl-35px {
    width: 35px !important;
  }
  .w-xxl-36px {
    width: 36px !important;
  }
  .w-xxl-37px {
    width: 37px !important;
  }
  .w-xxl-38px {
    width: 38px !important;
  }
  .w-xxl-39px {
    width: 39px !important;
  }
  .w-xxl-40px {
    width: 40px !important;
  }
  .w-xxl-41px {
    width: 41px !important;
  }
  .w-xxl-42px {
    width: 42px !important;
  }
  .w-xxl-43px {
    width: 43px !important;
  }
  .w-xxl-44px {
    width: 44px !important;
  }
  .w-xxl-45px {
    width: 45px !important;
  }
  .w-xxl-46px {
    width: 46px !important;
  }
  .w-xxl-47px {
    width: 47px !important;
  }
  .w-xxl-48px {
    width: 48px !important;
  }
  .w-xxl-49px {
    width: 49px !important;
  }
  .w-xxl-50px {
    width: 50px !important;
  }
  .w-xxl-51px {
    width: 51px !important;
  }
  .w-xxl-52px {
    width: 52px !important;
  }
  .w-xxl-53px {
    width: 53px !important;
  }
  .w-xxl-54px {
    width: 54px !important;
  }
  .w-xxl-55px {
    width: 55px !important;
  }
  .w-xxl-56px {
    width: 56px !important;
  }
  .w-xxl-57px {
    width: 57px !important;
  }
  .w-xxl-58px {
    width: 58px !important;
  }
  .w-xxl-59px {
    width: 59px !important;
  }
  .w-xxl-60px {
    width: 60px !important;
  }
  .w-xxl-61px {
    width: 61px !important;
  }
  .w-xxl-62px {
    width: 62px !important;
  }
  .w-xxl-63px {
    width: 63px !important;
  }
  .w-xxl-64px {
    width: 64px !important;
  }
  .w-xxl-65px {
    width: 65px !important;
  }
  .w-xxl-66px {
    width: 66px !important;
  }
  .w-xxl-67px {
    width: 67px !important;
  }
  .w-xxl-68px {
    width: 68px !important;
  }
  .w-xxl-69px {
    width: 69px !important;
  }
  .w-xxl-70px {
    width: 70px !important;
  }
  .w-xxl-71px {
    width: 71px !important;
  }
  .w-xxl-72px {
    width: 72px !important;
  }
  .w-xxl-73px {
    width: 73px !important;
  }
  .w-xxl-74px {
    width: 74px !important;
  }
  .w-xxl-75px {
    width: 75px !important;
  }
  .w-xxl-76px {
    width: 76px !important;
  }
  .w-xxl-77px {
    width: 77px !important;
  }
  .w-xxl-78px {
    width: 78px !important;
  }
  .w-xxl-79px {
    width: 79px !important;
  }
  .w-xxl-80px {
    width: 80px !important;
  }
  .w-xxl-81px {
    width: 81px !important;
  }
  .w-xxl-82px {
    width: 82px !important;
  }
  .w-xxl-83px {
    width: 83px !important;
  }
  .w-xxl-84px {
    width: 84px !important;
  }
  .w-xxl-85px {
    width: 85px !important;
  }
  .w-xxl-86px {
    width: 86px !important;
  }
  .w-xxl-87px {
    width: 87px !important;
  }
  .w-xxl-88px {
    width: 88px !important;
  }
  .w-xxl-89px {
    width: 89px !important;
  }
  .w-xxl-90px {
    width: 90px !important;
  }
  .w-xxl-91px {
    width: 91px !important;
  }
  .w-xxl-92px {
    width: 92px !important;
  }
  .w-xxl-93px {
    width: 93px !important;
  }
  .w-xxl-94px {
    width: 94px !important;
  }
  .w-xxl-95px {
    width: 95px !important;
  }
  .w-xxl-96px {
    width: 96px !important;
  }
  .w-xxl-97px {
    width: 97px !important;
  }
  .w-xxl-98px {
    width: 98px !important;
  }
  .w-xxl-99px {
    width: 99px !important;
  }
  .w-xxl-100px {
    width: 100px !important;
  }
  .w-xxl-101px {
    width: 101px !important;
  }
  .w-xxl-102px {
    width: 102px !important;
  }
  .w-xxl-103px {
    width: 103px !important;
  }
  .w-xxl-104px {
    width: 104px !important;
  }
  .w-xxl-105px {
    width: 105px !important;
  }
  .w-xxl-106px {
    width: 106px !important;
  }
  .w-xxl-107px {
    width: 107px !important;
  }
  .w-xxl-108px {
    width: 108px !important;
  }
  .w-xxl-109px {
    width: 109px !important;
  }
  .w-xxl-110px {
    width: 110px !important;
  }
  .w-xxl-111px {
    width: 111px !important;
  }
  .w-xxl-112px {
    width: 112px !important;
  }
  .w-xxl-113px {
    width: 113px !important;
  }
  .w-xxl-114px {
    width: 114px !important;
  }
  .w-xxl-115px {
    width: 115px !important;
  }
  .w-xxl-116px {
    width: 116px !important;
  }
  .w-xxl-117px {
    width: 117px !important;
  }
  .w-xxl-118px {
    width: 118px !important;
  }
  .w-xxl-119px {
    width: 119px !important;
  }
  .w-xxl-120px {
    width: 120px !important;
  }
  .w-xxl-121px {
    width: 121px !important;
  }
  .w-xxl-122px {
    width: 122px !important;
  }
  .w-xxl-123px {
    width: 123px !important;
  }
  .w-xxl-124px {
    width: 124px !important;
  }
  .w-xxl-125px {
    width: 125px !important;
  }
  .w-xxl-126px {
    width: 126px !important;
  }
  .w-xxl-127px {
    width: 127px !important;
  }
  .w-xxl-128px {
    width: 128px !important;
  }
  .w-xxl-129px {
    width: 129px !important;
  }
  .w-xxl-130px {
    width: 130px !important;
  }
  .w-xxl-131px {
    width: 131px !important;
  }
  .w-xxl-132px {
    width: 132px !important;
  }
  .w-xxl-133px {
    width: 133px !important;
  }
  .w-xxl-134px {
    width: 134px !important;
  }
  .w-xxl-135px {
    width: 135px !important;
  }
  .w-xxl-136px {
    width: 136px !important;
  }
  .w-xxl-137px {
    width: 137px !important;
  }
  .w-xxl-138px {
    width: 138px !important;
  }
  .w-xxl-139px {
    width: 139px !important;
  }
  .w-xxl-140px {
    width: 140px !important;
  }
  .w-xxl-141px {
    width: 141px !important;
  }
  .w-xxl-142px {
    width: 142px !important;
  }
  .w-xxl-143px {
    width: 143px !important;
  }
  .w-xxl-144px {
    width: 144px !important;
  }
  .w-xxl-145px {
    width: 145px !important;
  }
  .w-xxl-146px {
    width: 146px !important;
  }
  .w-xxl-147px {
    width: 147px !important;
  }
  .w-xxl-148px {
    width: 148px !important;
  }
  .w-xxl-149px {
    width: 149px !important;
  }
  .w-xxl-150px {
    width: 150px !important;
  }
  .w-xxl-151px {
    width: 151px !important;
  }
  .w-xxl-152px {
    width: 152px !important;
  }
  .w-xxl-153px {
    width: 153px !important;
  }
  .w-xxl-154px {
    width: 154px !important;
  }
  .w-xxl-155px {
    width: 155px !important;
  }
  .w-xxl-156px {
    width: 156px !important;
  }
  .w-xxl-157px {
    width: 157px !important;
  }
  .w-xxl-158px {
    width: 158px !important;
  }
  .w-xxl-159px {
    width: 159px !important;
  }
  .w-xxl-160px {
    width: 160px !important;
  }
  .w-xxl-161px {
    width: 161px !important;
  }
  .w-xxl-162px {
    width: 162px !important;
  }
  .w-xxl-163px {
    width: 163px !important;
  }
  .w-xxl-164px {
    width: 164px !important;
  }
  .w-xxl-165px {
    width: 165px !important;
  }
  .w-xxl-166px {
    width: 166px !important;
  }
  .w-xxl-167px {
    width: 167px !important;
  }
  .w-xxl-168px {
    width: 168px !important;
  }
  .w-xxl-169px {
    width: 169px !important;
  }
  .w-xxl-170px {
    width: 170px !important;
  }
  .w-xxl-171px {
    width: 171px !important;
  }
  .w-xxl-172px {
    width: 172px !important;
  }
  .w-xxl-173px {
    width: 173px !important;
  }
  .w-xxl-174px {
    width: 174px !important;
  }
  .w-xxl-175px {
    width: 175px !important;
  }
  .w-xxl-176px {
    width: 176px !important;
  }
  .w-xxl-177px {
    width: 177px !important;
  }
  .w-xxl-178px {
    width: 178px !important;
  }
  .w-xxl-179px {
    width: 179px !important;
  }
  .w-xxl-180px {
    width: 180px !important;
  }
  .w-xxl-181px {
    width: 181px !important;
  }
  .w-xxl-182px {
    width: 182px !important;
  }
  .w-xxl-183px {
    width: 183px !important;
  }
  .w-xxl-184px {
    width: 184px !important;
  }
  .w-xxl-185px {
    width: 185px !important;
  }
  .w-xxl-186px {
    width: 186px !important;
  }
  .w-xxl-187px {
    width: 187px !important;
  }
  .w-xxl-188px {
    width: 188px !important;
  }
  .w-xxl-189px {
    width: 189px !important;
  }
  .w-xxl-190px {
    width: 190px !important;
  }
  .w-xxl-191px {
    width: 191px !important;
  }
  .w-xxl-192px {
    width: 192px !important;
  }
  .w-xxl-193px {
    width: 193px !important;
  }
  .w-xxl-194px {
    width: 194px !important;
  }
  .w-xxl-195px {
    width: 195px !important;
  }
  .w-xxl-196px {
    width: 196px !important;
  }
  .w-xxl-197px {
    width: 197px !important;
  }
  .w-xxl-198px {
    width: 198px !important;
  }
  .w-xxl-199px {
    width: 199px !important;
  }
  .w-xxl-200px {
    width: 200px !important;
  }
  .w-xxl-201px {
    width: 201px !important;
  }
  .w-xxl-202px {
    width: 202px !important;
  }
  .w-xxl-203px {
    width: 203px !important;
  }
  .w-xxl-204px {
    width: 204px !important;
  }
  .w-xxl-205px {
    width: 205px !important;
  }
  .w-xxl-206px {
    width: 206px !important;
  }
  .w-xxl-207px {
    width: 207px !important;
  }
  .w-xxl-208px {
    width: 208px !important;
  }
  .w-xxl-209px {
    width: 209px !important;
  }
  .w-xxl-210px {
    width: 210px !important;
  }
  .w-xxl-211px {
    width: 211px !important;
  }
  .w-xxl-212px {
    width: 212px !important;
  }
  .w-xxl-213px {
    width: 213px !important;
  }
  .w-xxl-214px {
    width: 214px !important;
  }
  .w-xxl-215px {
    width: 215px !important;
  }
  .w-xxl-216px {
    width: 216px !important;
  }
  .w-xxl-217px {
    width: 217px !important;
  }
  .w-xxl-218px {
    width: 218px !important;
  }
  .w-xxl-219px {
    width: 219px !important;
  }
  .w-xxl-220px {
    width: 220px !important;
  }
  .w-xxl-221px {
    width: 221px !important;
  }
  .w-xxl-222px {
    width: 222px !important;
  }
  .w-xxl-223px {
    width: 223px !important;
  }
  .w-xxl-224px {
    width: 224px !important;
  }
  .w-xxl-225px {
    width: 225px !important;
  }
  .w-xxl-226px {
    width: 226px !important;
  }
  .w-xxl-227px {
    width: 227px !important;
  }
  .w-xxl-228px {
    width: 228px !important;
  }
  .w-xxl-229px {
    width: 229px !important;
  }
  .w-xxl-230px {
    width: 230px !important;
  }
  .w-xxl-231px {
    width: 231px !important;
  }
  .w-xxl-232px {
    width: 232px !important;
  }
  .w-xxl-233px {
    width: 233px !important;
  }
  .w-xxl-234px {
    width: 234px !important;
  }
  .w-xxl-235px {
    width: 235px !important;
  }
  .w-xxl-236px {
    width: 236px !important;
  }
  .w-xxl-237px {
    width: 237px !important;
  }
  .w-xxl-238px {
    width: 238px !important;
  }
  .w-xxl-239px {
    width: 239px !important;
  }
  .w-xxl-240px {
    width: 240px !important;
  }
  .w-xxl-241px {
    width: 241px !important;
  }
  .w-xxl-242px {
    width: 242px !important;
  }
  .w-xxl-243px {
    width: 243px !important;
  }
  .w-xxl-244px {
    width: 244px !important;
  }
  .w-xxl-245px {
    width: 245px !important;
  }
  .w-xxl-246px {
    width: 246px !important;
  }
  .w-xxl-247px {
    width: 247px !important;
  }
  .w-xxl-248px {
    width: 248px !important;
  }
  .w-xxl-249px {
    width: 249px !important;
  }
  .w-xxl-250px {
    width: 250px !important;
  }
  .w-xxl-251px {
    width: 251px !important;
  }
  .w-xxl-252px {
    width: 252px !important;
  }
  .w-xxl-253px {
    width: 253px !important;
  }
  .w-xxl-254px {
    width: 254px !important;
  }
  .w-xxl-255px {
    width: 255px !important;
  }
  .w-xxl-256px {
    width: 256px !important;
  }
  .w-xxl-257px {
    width: 257px !important;
  }
  .w-xxl-258px {
    width: 258px !important;
  }
  .w-xxl-259px {
    width: 259px !important;
  }
  .w-xxl-260px {
    width: 260px !important;
  }
  .w-xxl-261px {
    width: 261px !important;
  }
  .w-xxl-262px {
    width: 262px !important;
  }
  .w-xxl-263px {
    width: 263px !important;
  }
  .w-xxl-264px {
    width: 264px !important;
  }
  .w-xxl-265px {
    width: 265px !important;
  }
  .w-xxl-266px {
    width: 266px !important;
  }
  .w-xxl-267px {
    width: 267px !important;
  }
  .w-xxl-268px {
    width: 268px !important;
  }
  .w-xxl-269px {
    width: 269px !important;
  }
  .w-xxl-270px {
    width: 270px !important;
  }
  .w-xxl-271px {
    width: 271px !important;
  }
  .w-xxl-272px {
    width: 272px !important;
  }
  .w-xxl-273px {
    width: 273px !important;
  }
  .w-xxl-274px {
    width: 274px !important;
  }
  .w-xxl-275px {
    width: 275px !important;
  }
  .w-xxl-276px {
    width: 276px !important;
  }
  .w-xxl-277px {
    width: 277px !important;
  }
  .w-xxl-278px {
    width: 278px !important;
  }
  .w-xxl-279px {
    width: 279px !important;
  }
  .w-xxl-280px {
    width: 280px !important;
  }
  .w-xxl-281px {
    width: 281px !important;
  }
  .w-xxl-282px {
    width: 282px !important;
  }
  .w-xxl-283px {
    width: 283px !important;
  }
  .w-xxl-284px {
    width: 284px !important;
  }
  .w-xxl-285px {
    width: 285px !important;
  }
  .w-xxl-286px {
    width: 286px !important;
  }
  .w-xxl-287px {
    width: 287px !important;
  }
  .w-xxl-288px {
    width: 288px !important;
  }
  .w-xxl-289px {
    width: 289px !important;
  }
  .w-xxl-290px {
    width: 290px !important;
  }
  .w-xxl-291px {
    width: 291px !important;
  }
  .w-xxl-292px {
    width: 292px !important;
  }
  .w-xxl-293px {
    width: 293px !important;
  }
  .w-xxl-294px {
    width: 294px !important;
  }
  .w-xxl-295px {
    width: 295px !important;
  }
  .w-xxl-296px {
    width: 296px !important;
  }
  .w-xxl-297px {
    width: 297px !important;
  }
  .w-xxl-298px {
    width: 298px !important;
  }
  .w-xxl-299px {
    width: 299px !important;
  }
  .w-xxl-300px {
    width: 300px !important;
  }
  .w-xxl-301px {
    width: 301px !important;
  }
  .w-xxl-302px {
    width: 302px !important;
  }
  .w-xxl-303px {
    width: 303px !important;
  }
  .w-xxl-304px {
    width: 304px !important;
  }
  .w-xxl-305px {
    width: 305px !important;
  }
  .w-xxl-306px {
    width: 306px !important;
  }
  .w-xxl-307px {
    width: 307px !important;
  }
  .w-xxl-308px {
    width: 308px !important;
  }
  .w-xxl-309px {
    width: 309px !important;
  }
  .w-xxl-310px {
    width: 310px !important;
  }
  .w-xxl-311px {
    width: 311px !important;
  }
  .w-xxl-312px {
    width: 312px !important;
  }
  .w-xxl-313px {
    width: 313px !important;
  }
  .w-xxl-314px {
    width: 314px !important;
  }
  .w-xxl-315px {
    width: 315px !important;
  }
  .w-xxl-316px {
    width: 316px !important;
  }
  .w-xxl-317px {
    width: 317px !important;
  }
  .w-xxl-318px {
    width: 318px !important;
  }
  .w-xxl-319px {
    width: 319px !important;
  }
  .w-xxl-320px {
    width: 320px !important;
  }
  .w-xxl-321px {
    width: 321px !important;
  }
  .w-xxl-322px {
    width: 322px !important;
  }
  .w-xxl-323px {
    width: 323px !important;
  }
  .w-xxl-324px {
    width: 324px !important;
  }
  .w-xxl-325px {
    width: 325px !important;
  }
  .w-xxl-326px {
    width: 326px !important;
  }
  .w-xxl-327px {
    width: 327px !important;
  }
  .w-xxl-328px {
    width: 328px !important;
  }
  .w-xxl-329px {
    width: 329px !important;
  }
  .w-xxl-330px {
    width: 330px !important;
  }
  .w-xxl-331px {
    width: 331px !important;
  }
  .w-xxl-332px {
    width: 332px !important;
  }
  .w-xxl-333px {
    width: 333px !important;
  }
  .w-xxl-334px {
    width: 334px !important;
  }
  .w-xxl-335px {
    width: 335px !important;
  }
  .w-xxl-336px {
    width: 336px !important;
  }
  .w-xxl-337px {
    width: 337px !important;
  }
  .w-xxl-338px {
    width: 338px !important;
  }
  .w-xxl-339px {
    width: 339px !important;
  }
  .w-xxl-340px {
    width: 340px !important;
  }
  .w-xxl-341px {
    width: 341px !important;
  }
  .w-xxl-342px {
    width: 342px !important;
  }
  .w-xxl-343px {
    width: 343px !important;
  }
  .w-xxl-344px {
    width: 344px !important;
  }
  .w-xxl-345px {
    width: 345px !important;
  }
  .w-xxl-346px {
    width: 346px !important;
  }
  .w-xxl-347px {
    width: 347px !important;
  }
  .w-xxl-348px {
    width: 348px !important;
  }
  .w-xxl-349px {
    width: 349px !important;
  }
  .w-xxl-350px {
    width: 350px !important;
  }
  .w-xxl-351px {
    width: 351px !important;
  }
  .w-xxl-352px {
    width: 352px !important;
  }
  .w-xxl-353px {
    width: 353px !important;
  }
  .w-xxl-354px {
    width: 354px !important;
  }
  .w-xxl-355px {
    width: 355px !important;
  }
  .w-xxl-356px {
    width: 356px !important;
  }
  .w-xxl-357px {
    width: 357px !important;
  }
  .w-xxl-358px {
    width: 358px !important;
  }
  .w-xxl-359px {
    width: 359px !important;
  }
  .w-xxl-360px {
    width: 360px !important;
  }
  .w-xxl-361px {
    width: 361px !important;
  }
  .w-xxl-362px {
    width: 362px !important;
  }
  .w-xxl-363px {
    width: 363px !important;
  }
  .w-xxl-364px {
    width: 364px !important;
  }
  .w-xxl-365px {
    width: 365px !important;
  }
  .w-xxl-366px {
    width: 366px !important;
  }
  .w-xxl-367px {
    width: 367px !important;
  }
  .w-xxl-368px {
    width: 368px !important;
  }
  .w-xxl-369px {
    width: 369px !important;
  }
  .w-xxl-370px {
    width: 370px !important;
  }
  .w-xxl-371px {
    width: 371px !important;
  }
  .w-xxl-372px {
    width: 372px !important;
  }
  .w-xxl-373px {
    width: 373px !important;
  }
  .w-xxl-374px {
    width: 374px !important;
  }
  .w-xxl-375px {
    width: 375px !important;
  }
  .w-xxl-376px {
    width: 376px !important;
  }
  .w-xxl-377px {
    width: 377px !important;
  }
  .w-xxl-378px {
    width: 378px !important;
  }
  .w-xxl-379px {
    width: 379px !important;
  }
  .w-xxl-380px {
    width: 380px !important;
  }
  .w-xxl-381px {
    width: 381px !important;
  }
  .w-xxl-382px {
    width: 382px !important;
  }
  .w-xxl-383px {
    width: 383px !important;
  }
  .w-xxl-384px {
    width: 384px !important;
  }
  .w-xxl-385px {
    width: 385px !important;
  }
  .w-xxl-386px {
    width: 386px !important;
  }
  .w-xxl-387px {
    width: 387px !important;
  }
  .w-xxl-388px {
    width: 388px !important;
  }
  .w-xxl-389px {
    width: 389px !important;
  }
  .w-xxl-390px {
    width: 390px !important;
  }
  .w-xxl-391px {
    width: 391px !important;
  }
  .w-xxl-392px {
    width: 392px !important;
  }
  .w-xxl-393px {
    width: 393px !important;
  }
  .w-xxl-394px {
    width: 394px !important;
  }
  .w-xxl-395px {
    width: 395px !important;
  }
  .w-xxl-396px {
    width: 396px !important;
  }
  .w-xxl-397px {
    width: 397px !important;
  }
  .w-xxl-398px {
    width: 398px !important;
  }
  .w-xxl-399px {
    width: 399px !important;
  }
  .w-xxl-400px {
    width: 400px !important;
  }
  .w-xxl-401px {
    width: 401px !important;
  }
  .w-xxl-402px {
    width: 402px !important;
  }
  .w-xxl-403px {
    width: 403px !important;
  }
  .w-xxl-404px {
    width: 404px !important;
  }
  .w-xxl-405px {
    width: 405px !important;
  }
  .w-xxl-406px {
    width: 406px !important;
  }
  .w-xxl-407px {
    width: 407px !important;
  }
  .w-xxl-408px {
    width: 408px !important;
  }
  .w-xxl-409px {
    width: 409px !important;
  }
  .w-xxl-410px {
    width: 410px !important;
  }
  .w-xxl-411px {
    width: 411px !important;
  }
  .w-xxl-412px {
    width: 412px !important;
  }
  .w-xxl-413px {
    width: 413px !important;
  }
  .w-xxl-414px {
    width: 414px !important;
  }
  .w-xxl-415px {
    width: 415px !important;
  }
  .w-xxl-416px {
    width: 416px !important;
  }
  .w-xxl-417px {
    width: 417px !important;
  }
  .w-xxl-418px {
    width: 418px !important;
  }
  .w-xxl-419px {
    width: 419px !important;
  }
  .w-xxl-420px {
    width: 420px !important;
  }
  .w-xxl-421px {
    width: 421px !important;
  }
  .w-xxl-422px {
    width: 422px !important;
  }
  .w-xxl-423px {
    width: 423px !important;
  }
  .w-xxl-424px {
    width: 424px !important;
  }
  .w-xxl-425px {
    width: 425px !important;
  }
  .w-xxl-426px {
    width: 426px !important;
  }
  .w-xxl-427px {
    width: 427px !important;
  }
  .w-xxl-428px {
    width: 428px !important;
  }
  .w-xxl-429px {
    width: 429px !important;
  }
  .w-xxl-430px {
    width: 430px !important;
  }
  .w-xxl-431px {
    width: 431px !important;
  }
  .w-xxl-432px {
    width: 432px !important;
  }
  .w-xxl-433px {
    width: 433px !important;
  }
  .w-xxl-434px {
    width: 434px !important;
  }
  .w-xxl-435px {
    width: 435px !important;
  }
  .w-xxl-436px {
    width: 436px !important;
  }
  .w-xxl-437px {
    width: 437px !important;
  }
  .w-xxl-438px {
    width: 438px !important;
  }
  .w-xxl-439px {
    width: 439px !important;
  }
  .w-xxl-440px {
    width: 440px !important;
  }
  .w-xxl-441px {
    width: 441px !important;
  }
  .w-xxl-442px {
    width: 442px !important;
  }
  .w-xxl-443px {
    width: 443px !important;
  }
  .w-xxl-444px {
    width: 444px !important;
  }
  .w-xxl-445px {
    width: 445px !important;
  }
  .w-xxl-446px {
    width: 446px !important;
  }
  .w-xxl-447px {
    width: 447px !important;
  }
  .w-xxl-448px {
    width: 448px !important;
  }
  .w-xxl-449px {
    width: 449px !important;
  }
  .w-xxl-450px {
    width: 450px !important;
  }
  .w-xxl-451px {
    width: 451px !important;
  }
  .w-xxl-452px {
    width: 452px !important;
  }
  .w-xxl-453px {
    width: 453px !important;
  }
  .w-xxl-454px {
    width: 454px !important;
  }
  .w-xxl-455px {
    width: 455px !important;
  }
  .w-xxl-456px {
    width: 456px !important;
  }
  .w-xxl-457px {
    width: 457px !important;
  }
  .w-xxl-458px {
    width: 458px !important;
  }
  .w-xxl-459px {
    width: 459px !important;
  }
  .w-xxl-460px {
    width: 460px !important;
  }
  .w-xxl-461px {
    width: 461px !important;
  }
  .w-xxl-462px {
    width: 462px !important;
  }
  .w-xxl-463px {
    width: 463px !important;
  }
  .w-xxl-464px {
    width: 464px !important;
  }
  .w-xxl-465px {
    width: 465px !important;
  }
  .w-xxl-466px {
    width: 466px !important;
  }
  .w-xxl-467px {
    width: 467px !important;
  }
  .w-xxl-468px {
    width: 468px !important;
  }
  .w-xxl-469px {
    width: 469px !important;
  }
  .w-xxl-470px {
    width: 470px !important;
  }
  .w-xxl-471px {
    width: 471px !important;
  }
  .w-xxl-472px {
    width: 472px !important;
  }
  .w-xxl-473px {
    width: 473px !important;
  }
  .w-xxl-474px {
    width: 474px !important;
  }
  .w-xxl-475px {
    width: 475px !important;
  }
  .w-xxl-476px {
    width: 476px !important;
  }
  .w-xxl-477px {
    width: 477px !important;
  }
  .w-xxl-478px {
    width: 478px !important;
  }
  .w-xxl-479px {
    width: 479px !important;
  }
  .w-xxl-480px {
    width: 480px !important;
  }
  .w-xxl-481px {
    width: 481px !important;
  }
  .w-xxl-482px {
    width: 482px !important;
  }
  .w-xxl-483px {
    width: 483px !important;
  }
  .w-xxl-484px {
    width: 484px !important;
  }
  .w-xxl-485px {
    width: 485px !important;
  }
  .w-xxl-486px {
    width: 486px !important;
  }
  .w-xxl-487px {
    width: 487px !important;
  }
  .w-xxl-488px {
    width: 488px !important;
  }
  .w-xxl-489px {
    width: 489px !important;
  }
  .w-xxl-490px {
    width: 490px !important;
  }
  .w-xxl-491px {
    width: 491px !important;
  }
  .w-xxl-492px {
    width: 492px !important;
  }
  .w-xxl-493px {
    width: 493px !important;
  }
  .w-xxl-494px {
    width: 494px !important;
  }
  .w-xxl-495px {
    width: 495px !important;
  }
  .w-xxl-496px {
    width: 496px !important;
  }
  .w-xxl-497px {
    width: 497px !important;
  }
  .w-xxl-498px {
    width: 498px !important;
  }
  .w-xxl-499px {
    width: 499px !important;
  }
  .w-xxl-500px {
    width: 500px !important;
  }
  .w-xxl-501px {
    width: 501px !important;
  }
  .w-xxl-502px {
    width: 502px !important;
  }
  .w-xxl-503px {
    width: 503px !important;
  }
  .w-xxl-504px {
    width: 504px !important;
  }
  .w-xxl-505px {
    width: 505px !important;
  }
  .w-xxl-506px {
    width: 506px !important;
  }
  .w-xxl-507px {
    width: 507px !important;
  }
  .w-xxl-508px {
    width: 508px !important;
  }
  .w-xxl-509px {
    width: 509px !important;
  }
  .w-xxl-510px {
    width: 510px !important;
  }
  .w-xxl-511px {
    width: 511px !important;
  }
  .w-xxl-512px {
    width: 512px !important;
  }
  .w-xxl-513px {
    width: 513px !important;
  }
  .w-xxl-514px {
    width: 514px !important;
  }
  .w-xxl-515px {
    width: 515px !important;
  }
  .w-xxl-516px {
    width: 516px !important;
  }
  .w-xxl-517px {
    width: 517px !important;
  }
  .w-xxl-518px {
    width: 518px !important;
  }
  .w-xxl-519px {
    width: 519px !important;
  }
  .w-xxl-520px {
    width: 520px !important;
  }
  .w-xxl-521px {
    width: 521px !important;
  }
  .w-xxl-522px {
    width: 522px !important;
  }
  .w-xxl-523px {
    width: 523px !important;
  }
  .w-xxl-524px {
    width: 524px !important;
  }
  .w-xxl-525px {
    width: 525px !important;
  }
  .w-xxl-526px {
    width: 526px !important;
  }
  .w-xxl-527px {
    width: 527px !important;
  }
  .w-xxl-528px {
    width: 528px !important;
  }
  .w-xxl-529px {
    width: 529px !important;
  }
  .w-xxl-530px {
    width: 530px !important;
  }
  .w-xxl-531px {
    width: 531px !important;
  }
  .w-xxl-532px {
    width: 532px !important;
  }
  .w-xxl-533px {
    width: 533px !important;
  }
  .w-xxl-534px {
    width: 534px !important;
  }
  .w-xxl-535px {
    width: 535px !important;
  }
  .w-xxl-536px {
    width: 536px !important;
  }
  .w-xxl-537px {
    width: 537px !important;
  }
  .w-xxl-538px {
    width: 538px !important;
  }
  .w-xxl-539px {
    width: 539px !important;
  }
  .w-xxl-540px {
    width: 540px !important;
  }
  .w-xxl-541px {
    width: 541px !important;
  }
  .w-xxl-542px {
    width: 542px !important;
  }
  .w-xxl-543px {
    width: 543px !important;
  }
  .w-xxl-544px {
    width: 544px !important;
  }
  .w-xxl-545px {
    width: 545px !important;
  }
  .w-xxl-546px {
    width: 546px !important;
  }
  .w-xxl-547px {
    width: 547px !important;
  }
  .w-xxl-548px {
    width: 548px !important;
  }
  .w-xxl-549px {
    width: 549px !important;
  }
  .w-xxl-550px {
    width: 550px !important;
  }
  .w-xxl-551px {
    width: 551px !important;
  }
  .w-xxl-552px {
    width: 552px !important;
  }
  .w-xxl-553px {
    width: 553px !important;
  }
  .w-xxl-554px {
    width: 554px !important;
  }
  .w-xxl-555px {
    width: 555px !important;
  }
  .w-xxl-556px {
    width: 556px !important;
  }
  .w-xxl-557px {
    width: 557px !important;
  }
  .w-xxl-558px {
    width: 558px !important;
  }
  .w-xxl-559px {
    width: 559px !important;
  }
  .w-xxl-560px {
    width: 560px !important;
  }
  .w-xxl-561px {
    width: 561px !important;
  }
  .w-xxl-562px {
    width: 562px !important;
  }
  .w-xxl-563px {
    width: 563px !important;
  }
  .w-xxl-564px {
    width: 564px !important;
  }
  .w-xxl-565px {
    width: 565px !important;
  }
  .w-xxl-566px {
    width: 566px !important;
  }
  .w-xxl-567px {
    width: 567px !important;
  }
  .w-xxl-568px {
    width: 568px !important;
  }
  .w-xxl-569px {
    width: 569px !important;
  }
  .w-xxl-570px {
    width: 570px !important;
  }
  .w-xxl-571px {
    width: 571px !important;
  }
  .w-xxl-572px {
    width: 572px !important;
  }
  .w-xxl-573px {
    width: 573px !important;
  }
  .w-xxl-574px {
    width: 574px !important;
  }
  .w-xxl-575px {
    width: 575px !important;
  }
  .w-xxl-576px {
    width: 576px !important;
  }
  .w-xxl-577px {
    width: 577px !important;
  }
  .w-xxl-578px {
    width: 578px !important;
  }
  .w-xxl-579px {
    width: 579px !important;
  }
  .w-xxl-580px {
    width: 580px !important;
  }
  .w-xxl-581px {
    width: 581px !important;
  }
  .w-xxl-582px {
    width: 582px !important;
  }
  .w-xxl-583px {
    width: 583px !important;
  }
  .w-xxl-584px {
    width: 584px !important;
  }
  .w-xxl-585px {
    width: 585px !important;
  }
  .w-xxl-586px {
    width: 586px !important;
  }
  .w-xxl-587px {
    width: 587px !important;
  }
  .w-xxl-588px {
    width: 588px !important;
  }
  .w-xxl-589px {
    width: 589px !important;
  }
  .w-xxl-590px {
    width: 590px !important;
  }
  .w-xxl-591px {
    width: 591px !important;
  }
  .w-xxl-592px {
    width: 592px !important;
  }
  .w-xxl-593px {
    width: 593px !important;
  }
  .w-xxl-594px {
    width: 594px !important;
  }
  .w-xxl-595px {
    width: 595px !important;
  }
  .w-xxl-596px {
    width: 596px !important;
  }
  .w-xxl-597px {
    width: 597px !important;
  }
  .w-xxl-598px {
    width: 598px !important;
  }
  .w-xxl-599px {
    width: 599px !important;
  }
  .w-xxl-600px {
    width: 600px !important;
  }
  .w-xxl-601px {
    width: 601px !important;
  }
  .w-xxl-602px {
    width: 602px !important;
  }
  .w-xxl-603px {
    width: 603px !important;
  }
  .w-xxl-604px {
    width: 604px !important;
  }
  .w-xxl-605px {
    width: 605px !important;
  }
  .w-xxl-606px {
    width: 606px !important;
  }
  .w-xxl-607px {
    width: 607px !important;
  }
  .w-xxl-608px {
    width: 608px !important;
  }
  .w-xxl-609px {
    width: 609px !important;
  }
  .w-xxl-610px {
    width: 610px !important;
  }
  .w-xxl-611px {
    width: 611px !important;
  }
  .w-xxl-612px {
    width: 612px !important;
  }
  .w-xxl-613px {
    width: 613px !important;
  }
  .w-xxl-614px {
    width: 614px !important;
  }
  .w-xxl-615px {
    width: 615px !important;
  }
  .w-xxl-616px {
    width: 616px !important;
  }
  .w-xxl-617px {
    width: 617px !important;
  }
  .w-xxl-618px {
    width: 618px !important;
  }
  .w-xxl-619px {
    width: 619px !important;
  }
  .w-xxl-620px {
    width: 620px !important;
  }
  .w-xxl-621px {
    width: 621px !important;
  }
  .w-xxl-622px {
    width: 622px !important;
  }
  .w-xxl-623px {
    width: 623px !important;
  }
  .w-xxl-624px {
    width: 624px !important;
  }
  .w-xxl-625px {
    width: 625px !important;
  }
  .w-xxl-626px {
    width: 626px !important;
  }
  .w-xxl-627px {
    width: 627px !important;
  }
  .w-xxl-628px {
    width: 628px !important;
  }
  .w-xxl-629px {
    width: 629px !important;
  }
  .w-xxl-630px {
    width: 630px !important;
  }
  .w-xxl-631px {
    width: 631px !important;
  }
  .w-xxl-632px {
    width: 632px !important;
  }
  .w-xxl-633px {
    width: 633px !important;
  }
  .w-xxl-634px {
    width: 634px !important;
  }
  .w-xxl-635px {
    width: 635px !important;
  }
  .w-xxl-636px {
    width: 636px !important;
  }
  .w-xxl-637px {
    width: 637px !important;
  }
  .w-xxl-638px {
    width: 638px !important;
  }
  .w-xxl-639px {
    width: 639px !important;
  }
  .w-xxl-640px {
    width: 640px !important;
  }
  .w-xxl-641px {
    width: 641px !important;
  }
  .w-xxl-642px {
    width: 642px !important;
  }
  .w-xxl-643px {
    width: 643px !important;
  }
  .w-xxl-644px {
    width: 644px !important;
  }
  .w-xxl-645px {
    width: 645px !important;
  }
  .w-xxl-646px {
    width: 646px !important;
  }
  .w-xxl-647px {
    width: 647px !important;
  }
  .w-xxl-648px {
    width: 648px !important;
  }
  .w-xxl-649px {
    width: 649px !important;
  }
  .w-xxl-650px {
    width: 650px !important;
  }
  .w-xxl-651px {
    width: 651px !important;
  }
  .w-xxl-652px {
    width: 652px !important;
  }
  .w-xxl-653px {
    width: 653px !important;
  }
  .w-xxl-654px {
    width: 654px !important;
  }
  .w-xxl-655px {
    width: 655px !important;
  }
  .w-xxl-656px {
    width: 656px !important;
  }
  .w-xxl-657px {
    width: 657px !important;
  }
  .w-xxl-658px {
    width: 658px !important;
  }
  .w-xxl-659px {
    width: 659px !important;
  }
  .w-xxl-660px {
    width: 660px !important;
  }
  .w-xxl-661px {
    width: 661px !important;
  }
  .w-xxl-662px {
    width: 662px !important;
  }
  .w-xxl-663px {
    width: 663px !important;
  }
  .w-xxl-664px {
    width: 664px !important;
  }
  .w-xxl-665px {
    width: 665px !important;
  }
  .w-xxl-666px {
    width: 666px !important;
  }
  .w-xxl-667px {
    width: 667px !important;
  }
  .w-xxl-668px {
    width: 668px !important;
  }
  .w-xxl-669px {
    width: 669px !important;
  }
  .w-xxl-670px {
    width: 670px !important;
  }
  .w-xxl-671px {
    width: 671px !important;
  }
  .w-xxl-672px {
    width: 672px !important;
  }
  .w-xxl-673px {
    width: 673px !important;
  }
  .w-xxl-674px {
    width: 674px !important;
  }
  .w-xxl-675px {
    width: 675px !important;
  }
  .w-xxl-676px {
    width: 676px !important;
  }
  .w-xxl-677px {
    width: 677px !important;
  }
  .w-xxl-678px {
    width: 678px !important;
  }
  .w-xxl-679px {
    width: 679px !important;
  }
  .w-xxl-680px {
    width: 680px !important;
  }
  .w-xxl-681px {
    width: 681px !important;
  }
  .w-xxl-682px {
    width: 682px !important;
  }
  .w-xxl-683px {
    width: 683px !important;
  }
  .w-xxl-684px {
    width: 684px !important;
  }
  .w-xxl-685px {
    width: 685px !important;
  }
  .w-xxl-686px {
    width: 686px !important;
  }
  .w-xxl-687px {
    width: 687px !important;
  }
  .w-xxl-688px {
    width: 688px !important;
  }
  .w-xxl-689px {
    width: 689px !important;
  }
  .w-xxl-690px {
    width: 690px !important;
  }
  .w-xxl-691px {
    width: 691px !important;
  }
  .w-xxl-692px {
    width: 692px !important;
  }
  .w-xxl-693px {
    width: 693px !important;
  }
  .w-xxl-694px {
    width: 694px !important;
  }
  .w-xxl-695px {
    width: 695px !important;
  }
  .w-xxl-696px {
    width: 696px !important;
  }
  .w-xxl-697px {
    width: 697px !important;
  }
  .w-xxl-698px {
    width: 698px !important;
  }
  .w-xxl-699px {
    width: 699px !important;
  }
  .w-xxl-700px {
    width: 700px !important;
  }
  .w-xxl-701px {
    width: 701px !important;
  }
  .w-xxl-702px {
    width: 702px !important;
  }
  .w-xxl-703px {
    width: 703px !important;
  }
  .w-xxl-704px {
    width: 704px !important;
  }
  .w-xxl-705px {
    width: 705px !important;
  }
  .w-xxl-706px {
    width: 706px !important;
  }
  .w-xxl-707px {
    width: 707px !important;
  }
  .w-xxl-708px {
    width: 708px !important;
  }
  .w-xxl-709px {
    width: 709px !important;
  }
  .w-xxl-710px {
    width: 710px !important;
  }
  .w-xxl-711px {
    width: 711px !important;
  }
  .w-xxl-712px {
    width: 712px !important;
  }
  .w-xxl-713px {
    width: 713px !important;
  }
  .w-xxl-714px {
    width: 714px !important;
  }
  .w-xxl-715px {
    width: 715px !important;
  }
  .w-xxl-716px {
    width: 716px !important;
  }
  .w-xxl-717px {
    width: 717px !important;
  }
  .w-xxl-718px {
    width: 718px !important;
  }
  .w-xxl-719px {
    width: 719px !important;
  }
  .w-xxl-720px {
    width: 720px !important;
  }
  .w-xxl-721px {
    width: 721px !important;
  }
  .w-xxl-722px {
    width: 722px !important;
  }
  .w-xxl-723px {
    width: 723px !important;
  }
  .w-xxl-724px {
    width: 724px !important;
  }
  .w-xxl-725px {
    width: 725px !important;
  }
  .w-xxl-726px {
    width: 726px !important;
  }
  .w-xxl-727px {
    width: 727px !important;
  }
  .w-xxl-728px {
    width: 728px !important;
  }
  .w-xxl-729px {
    width: 729px !important;
  }
  .w-xxl-730px {
    width: 730px !important;
  }
  .w-xxl-731px {
    width: 731px !important;
  }
  .w-xxl-732px {
    width: 732px !important;
  }
  .w-xxl-733px {
    width: 733px !important;
  }
  .w-xxl-734px {
    width: 734px !important;
  }
  .w-xxl-735px {
    width: 735px !important;
  }
  .w-xxl-736px {
    width: 736px !important;
  }
  .w-xxl-737px {
    width: 737px !important;
  }
  .w-xxl-738px {
    width: 738px !important;
  }
  .w-xxl-739px {
    width: 739px !important;
  }
  .w-xxl-740px {
    width: 740px !important;
  }
  .w-xxl-741px {
    width: 741px !important;
  }
  .w-xxl-742px {
    width: 742px !important;
  }
  .w-xxl-743px {
    width: 743px !important;
  }
  .w-xxl-744px {
    width: 744px !important;
  }
  .w-xxl-745px {
    width: 745px !important;
  }
  .w-xxl-746px {
    width: 746px !important;
  }
  .w-xxl-747px {
    width: 747px !important;
  }
  .w-xxl-748px {
    width: 748px !important;
  }
  .w-xxl-749px {
    width: 749px !important;
  }
  .w-xxl-750px {
    width: 750px !important;
  }
  .w-xxl-751px {
    width: 751px !important;
  }
  .w-xxl-752px {
    width: 752px !important;
  }
  .w-xxl-753px {
    width: 753px !important;
  }
  .w-xxl-754px {
    width: 754px !important;
  }
  .w-xxl-755px {
    width: 755px !important;
  }
  .w-xxl-756px {
    width: 756px !important;
  }
  .w-xxl-757px {
    width: 757px !important;
  }
  .w-xxl-758px {
    width: 758px !important;
  }
  .w-xxl-759px {
    width: 759px !important;
  }
  .w-xxl-760px {
    width: 760px !important;
  }
  .w-xxl-761px {
    width: 761px !important;
  }
  .w-xxl-762px {
    width: 762px !important;
  }
  .w-xxl-763px {
    width: 763px !important;
  }
  .w-xxl-764px {
    width: 764px !important;
  }
  .w-xxl-765px {
    width: 765px !important;
  }
  .w-xxl-766px {
    width: 766px !important;
  }
  .w-xxl-767px {
    width: 767px !important;
  }
  .w-xxl-768px {
    width: 768px !important;
  }
  .w-xxl-769px {
    width: 769px !important;
  }
  .w-xxl-770px {
    width: 770px !important;
  }
  .w-xxl-771px {
    width: 771px !important;
  }
  .w-xxl-772px {
    width: 772px !important;
  }
  .w-xxl-773px {
    width: 773px !important;
  }
  .w-xxl-774px {
    width: 774px !important;
  }
  .w-xxl-775px {
    width: 775px !important;
  }
  .w-xxl-776px {
    width: 776px !important;
  }
  .w-xxl-777px {
    width: 777px !important;
  }
  .w-xxl-778px {
    width: 778px !important;
  }
  .w-xxl-779px {
    width: 779px !important;
  }
  .w-xxl-780px {
    width: 780px !important;
  }
  .w-xxl-781px {
    width: 781px !important;
  }
  .w-xxl-782px {
    width: 782px !important;
  }
  .w-xxl-783px {
    width: 783px !important;
  }
  .w-xxl-784px {
    width: 784px !important;
  }
  .w-xxl-785px {
    width: 785px !important;
  }
  .w-xxl-786px {
    width: 786px !important;
  }
  .w-xxl-787px {
    width: 787px !important;
  }
  .w-xxl-788px {
    width: 788px !important;
  }
  .w-xxl-789px {
    width: 789px !important;
  }
  .w-xxl-790px {
    width: 790px !important;
  }
  .w-xxl-791px {
    width: 791px !important;
  }
  .w-xxl-792px {
    width: 792px !important;
  }
  .w-xxl-793px {
    width: 793px !important;
  }
  .w-xxl-794px {
    width: 794px !important;
  }
  .w-xxl-795px {
    width: 795px !important;
  }
  .w-xxl-796px {
    width: 796px !important;
  }
  .w-xxl-797px {
    width: 797px !important;
  }
  .w-xxl-798px {
    width: 798px !important;
  }
  .w-xxl-799px {
    width: 799px !important;
  }
  .w-xxl-800px {
    width: 800px !important;
  }
  .w-xxl-801px {
    width: 801px !important;
  }
  .w-xxl-802px {
    width: 802px !important;
  }
  .w-xxl-803px {
    width: 803px !important;
  }
  .w-xxl-804px {
    width: 804px !important;
  }
  .w-xxl-805px {
    width: 805px !important;
  }
  .w-xxl-806px {
    width: 806px !important;
  }
  .w-xxl-807px {
    width: 807px !important;
  }
  .w-xxl-808px {
    width: 808px !important;
  }
  .w-xxl-809px {
    width: 809px !important;
  }
  .w-xxl-810px {
    width: 810px !important;
  }
  .w-xxl-811px {
    width: 811px !important;
  }
  .w-xxl-812px {
    width: 812px !important;
  }
  .w-xxl-813px {
    width: 813px !important;
  }
  .w-xxl-814px {
    width: 814px !important;
  }
  .w-xxl-815px {
    width: 815px !important;
  }
  .w-xxl-816px {
    width: 816px !important;
  }
  .w-xxl-817px {
    width: 817px !important;
  }
  .w-xxl-818px {
    width: 818px !important;
  }
  .w-xxl-819px {
    width: 819px !important;
  }
  .w-xxl-820px {
    width: 820px !important;
  }
  .w-xxl-821px {
    width: 821px !important;
  }
  .w-xxl-822px {
    width: 822px !important;
  }
  .w-xxl-823px {
    width: 823px !important;
  }
  .w-xxl-824px {
    width: 824px !important;
  }
  .w-xxl-825px {
    width: 825px !important;
  }
  .w-xxl-826px {
    width: 826px !important;
  }
  .w-xxl-827px {
    width: 827px !important;
  }
  .w-xxl-828px {
    width: 828px !important;
  }
  .w-xxl-829px {
    width: 829px !important;
  }
  .w-xxl-830px {
    width: 830px !important;
  }
  .w-xxl-831px {
    width: 831px !important;
  }
  .w-xxl-832px {
    width: 832px !important;
  }
  .w-xxl-833px {
    width: 833px !important;
  }
  .w-xxl-834px {
    width: 834px !important;
  }
  .w-xxl-835px {
    width: 835px !important;
  }
  .w-xxl-836px {
    width: 836px !important;
  }
  .w-xxl-837px {
    width: 837px !important;
  }
  .w-xxl-838px {
    width: 838px !important;
  }
  .w-xxl-839px {
    width: 839px !important;
  }
  .w-xxl-840px {
    width: 840px !important;
  }
  .w-xxl-841px {
    width: 841px !important;
  }
  .w-xxl-842px {
    width: 842px !important;
  }
  .w-xxl-843px {
    width: 843px !important;
  }
  .w-xxl-844px {
    width: 844px !important;
  }
  .w-xxl-845px {
    width: 845px !important;
  }
  .w-xxl-846px {
    width: 846px !important;
  }
  .w-xxl-847px {
    width: 847px !important;
  }
  .w-xxl-848px {
    width: 848px !important;
  }
  .w-xxl-849px {
    width: 849px !important;
  }
  .w-xxl-850px {
    width: 850px !important;
  }
  .w-xxl-851px {
    width: 851px !important;
  }
  .w-xxl-852px {
    width: 852px !important;
  }
  .w-xxl-853px {
    width: 853px !important;
  }
  .w-xxl-854px {
    width: 854px !important;
  }
  .w-xxl-855px {
    width: 855px !important;
  }
  .w-xxl-856px {
    width: 856px !important;
  }
  .w-xxl-857px {
    width: 857px !important;
  }
  .w-xxl-858px {
    width: 858px !important;
  }
  .w-xxl-859px {
    width: 859px !important;
  }
  .w-xxl-860px {
    width: 860px !important;
  }
  .w-xxl-861px {
    width: 861px !important;
  }
  .w-xxl-862px {
    width: 862px !important;
  }
  .w-xxl-863px {
    width: 863px !important;
  }
  .w-xxl-864px {
    width: 864px !important;
  }
  .w-xxl-865px {
    width: 865px !important;
  }
  .w-xxl-866px {
    width: 866px !important;
  }
  .w-xxl-867px {
    width: 867px !important;
  }
  .w-xxl-868px {
    width: 868px !important;
  }
  .w-xxl-869px {
    width: 869px !important;
  }
  .w-xxl-870px {
    width: 870px !important;
  }
  .w-xxl-871px {
    width: 871px !important;
  }
  .w-xxl-872px {
    width: 872px !important;
  }
  .w-xxl-873px {
    width: 873px !important;
  }
  .w-xxl-874px {
    width: 874px !important;
  }
  .w-xxl-875px {
    width: 875px !important;
  }
  .w-xxl-876px {
    width: 876px !important;
  }
  .w-xxl-877px {
    width: 877px !important;
  }
  .w-xxl-878px {
    width: 878px !important;
  }
  .w-xxl-879px {
    width: 879px !important;
  }
  .w-xxl-880px {
    width: 880px !important;
  }
  .w-xxl-881px {
    width: 881px !important;
  }
  .w-xxl-882px {
    width: 882px !important;
  }
  .w-xxl-883px {
    width: 883px !important;
  }
  .w-xxl-884px {
    width: 884px !important;
  }
  .w-xxl-885px {
    width: 885px !important;
  }
  .w-xxl-886px {
    width: 886px !important;
  }
  .w-xxl-887px {
    width: 887px !important;
  }
  .w-xxl-888px {
    width: 888px !important;
  }
  .w-xxl-889px {
    width: 889px !important;
  }
  .w-xxl-890px {
    width: 890px !important;
  }
  .w-xxl-891px {
    width: 891px !important;
  }
  .w-xxl-892px {
    width: 892px !important;
  }
  .w-xxl-893px {
    width: 893px !important;
  }
  .w-xxl-894px {
    width: 894px !important;
  }
  .w-xxl-895px {
    width: 895px !important;
  }
  .w-xxl-896px {
    width: 896px !important;
  }
  .w-xxl-897px {
    width: 897px !important;
  }
  .w-xxl-898px {
    width: 898px !important;
  }
  .w-xxl-899px {
    width: 899px !important;
  }
  .w-xxl-900px {
    width: 900px !important;
  }
  .w-xxl-901px {
    width: 901px !important;
  }
  .w-xxl-902px {
    width: 902px !important;
  }
  .w-xxl-903px {
    width: 903px !important;
  }
  .w-xxl-904px {
    width: 904px !important;
  }
  .w-xxl-905px {
    width: 905px !important;
  }
  .w-xxl-906px {
    width: 906px !important;
  }
  .w-xxl-907px {
    width: 907px !important;
  }
  .w-xxl-908px {
    width: 908px !important;
  }
  .w-xxl-909px {
    width: 909px !important;
  }
  .w-xxl-910px {
    width: 910px !important;
  }
  .w-xxl-911px {
    width: 911px !important;
  }
  .w-xxl-912px {
    width: 912px !important;
  }
  .w-xxl-913px {
    width: 913px !important;
  }
  .w-xxl-914px {
    width: 914px !important;
  }
  .w-xxl-915px {
    width: 915px !important;
  }
  .w-xxl-916px {
    width: 916px !important;
  }
  .w-xxl-917px {
    width: 917px !important;
  }
  .w-xxl-918px {
    width: 918px !important;
  }
  .w-xxl-919px {
    width: 919px !important;
  }
  .w-xxl-920px {
    width: 920px !important;
  }
  .w-xxl-921px {
    width: 921px !important;
  }
  .w-xxl-922px {
    width: 922px !important;
  }
  .w-xxl-923px {
    width: 923px !important;
  }
  .w-xxl-924px {
    width: 924px !important;
  }
  .w-xxl-925px {
    width: 925px !important;
  }
  .w-xxl-926px {
    width: 926px !important;
  }
  .w-xxl-927px {
    width: 927px !important;
  }
  .w-xxl-928px {
    width: 928px !important;
  }
  .w-xxl-929px {
    width: 929px !important;
  }
  .w-xxl-930px {
    width: 930px !important;
  }
  .w-xxl-931px {
    width: 931px !important;
  }
  .w-xxl-932px {
    width: 932px !important;
  }
  .w-xxl-933px {
    width: 933px !important;
  }
  .w-xxl-934px {
    width: 934px !important;
  }
  .w-xxl-935px {
    width: 935px !important;
  }
  .w-xxl-936px {
    width: 936px !important;
  }
  .w-xxl-937px {
    width: 937px !important;
  }
  .w-xxl-938px {
    width: 938px !important;
  }
  .w-xxl-939px {
    width: 939px !important;
  }
  .w-xxl-940px {
    width: 940px !important;
  }
  .w-xxl-941px {
    width: 941px !important;
  }
  .w-xxl-942px {
    width: 942px !important;
  }
  .w-xxl-943px {
    width: 943px !important;
  }
  .w-xxl-944px {
    width: 944px !important;
  }
  .w-xxl-945px {
    width: 945px !important;
  }
  .w-xxl-946px {
    width: 946px !important;
  }
  .w-xxl-947px {
    width: 947px !important;
  }
  .w-xxl-948px {
    width: 948px !important;
  }
  .w-xxl-949px {
    width: 949px !important;
  }
  .w-xxl-950px {
    width: 950px !important;
  }
  .w-xxl-951px {
    width: 951px !important;
  }
  .w-xxl-952px {
    width: 952px !important;
  }
  .w-xxl-953px {
    width: 953px !important;
  }
  .w-xxl-954px {
    width: 954px !important;
  }
  .w-xxl-955px {
    width: 955px !important;
  }
  .w-xxl-956px {
    width: 956px !important;
  }
  .w-xxl-957px {
    width: 957px !important;
  }
  .w-xxl-958px {
    width: 958px !important;
  }
  .w-xxl-959px {
    width: 959px !important;
  }
  .w-xxl-960px {
    width: 960px !important;
  }
  .w-xxl-961px {
    width: 961px !important;
  }
  .w-xxl-962px {
    width: 962px !important;
  }
  .w-xxl-963px {
    width: 963px !important;
  }
  .w-xxl-964px {
    width: 964px !important;
  }
  .w-xxl-965px {
    width: 965px !important;
  }
  .w-xxl-966px {
    width: 966px !important;
  }
  .w-xxl-967px {
    width: 967px !important;
  }
  .w-xxl-968px {
    width: 968px !important;
  }
  .w-xxl-969px {
    width: 969px !important;
  }
  .w-xxl-970px {
    width: 970px !important;
  }
  .w-xxl-971px {
    width: 971px !important;
  }
  .w-xxl-972px {
    width: 972px !important;
  }
  .w-xxl-973px {
    width: 973px !important;
  }
  .w-xxl-974px {
    width: 974px !important;
  }
  .w-xxl-975px {
    width: 975px !important;
  }
  .w-xxl-976px {
    width: 976px !important;
  }
  .w-xxl-977px {
    width: 977px !important;
  }
  .w-xxl-978px {
    width: 978px !important;
  }
  .w-xxl-979px {
    width: 979px !important;
  }
  .w-xxl-980px {
    width: 980px !important;
  }
  .w-xxl-981px {
    width: 981px !important;
  }
  .w-xxl-982px {
    width: 982px !important;
  }
  .w-xxl-983px {
    width: 983px !important;
  }
  .w-xxl-984px {
    width: 984px !important;
  }
  .w-xxl-985px {
    width: 985px !important;
  }
  .w-xxl-986px {
    width: 986px !important;
  }
  .w-xxl-987px {
    width: 987px !important;
  }
  .w-xxl-988px {
    width: 988px !important;
  }
  .w-xxl-989px {
    width: 989px !important;
  }
  .w-xxl-990px {
    width: 990px !important;
  }
  .w-xxl-991px {
    width: 991px !important;
  }
  .w-xxl-992px {
    width: 992px !important;
  }
  .w-xxl-993px {
    width: 993px !important;
  }
  .w-xxl-994px {
    width: 994px !important;
  }
  .w-xxl-995px {
    width: 995px !important;
  }
  .w-xxl-996px {
    width: 996px !important;
  }
  .w-xxl-997px {
    width: 997px !important;
  }
  .w-xxl-998px {
    width: 998px !important;
  }
  .w-xxl-999px {
    width: 999px !important;
  }
  .w-xxl-1000px {
    width: 1000px !important;
  }
}
/* purgecss start ignore */
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

#modalAvisGen .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
@media (min-width: 576px) {
  #modalAvisGen .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
}
#modalAvisGen .modal-dialog .rating {
  border: none;
}
#modalAvisGen .modal-dialog .rating i {
  font-size: 22px;
}
#modalAvisGen .modal-dialog .rating label {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}
#modalAvisGen .modal-dialog .rating label:hover {
  transform: scale(1.2);
  /*i {
      --bs-text-opacity: 1;
      color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
  }*/
}
#modalAvisGen .modal-dialog .rating input + label {
  color: #FFD700;
}
#modalAvisGen .modal-dialog .rating input:checked ~ input:not(:checked) ~ label {
  color: #ddd;
}
#modalAvisGen .modal-dialog .rating input:checked ~ input:not(:checked) + label:hover ~ label {
  color: #ddd;
}
#modalAvisGen .modal-dialog .rating:hover input + label, #modalAvisGen .modal-dialog .rating:hover input:checked + label {
  color: #FFD700;
}
#modalAvisGen .modal-dialog .rating:hover input:checked ~ input:not(:checked) ~ label:hover, #modalAvisGen .modal-dialog .rating:hover input:checked ~ input:not(:checked) + label {
  color: #FFD700;
}
#modalAvisGen .modal-dialog .rating:hover input:checked ~ input:not(:checked) ~ label:hover ~ label {
  color: #ddd !important;
}
#modalAvisGen .modal-dialog .rating input + label:hover ~ label {
  color: #ddd !important;
}

/* purgecss end ignore */
/* purgecss start ignore */
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%;
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .site-navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.site-navbar .toggle-button {
  left: 0px;
}
.site-navbar .site-menu-toggle:focus-visible {
  outline: none;
}
.site-navbar .site-logo {
  margin: 0;
  padding: 0;
  z-index: 3;
  margin-right: 3rem;
}
@media (max-width: 767.98px) {
  .site-navbar .site-logo {
    float: left;
    position: relative;
    margin-right: 0;
  }
}
.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid white !important;
}
.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
  display: inline;
}
.site-navbar .site-navigation .site-menu > li.database::after {
  border-left: none !important;
}
.site-navbar .site-navigation .site-menu > li > a {
  margin-left: 15px;
  margin-right: 15px;
  padding: 20px 0px;
  color: #414141 !important;
  display: inline-block;
  text-decoration: none !important;
  font-family: "AllianzNeo", sans-serif;
  font-size: 15px;
}
.site-navbar .site-navigation .site-menu > li > a.active {
  font-family: "AllianzNeoBold", sans-serif;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
  color: var(--bs-primary) !important;
}
.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}
.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\f282";
  font-size: 11px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: "bootstrap-icons";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown {
  visibility: hidden;
  opacity: 0;
  text-align: left;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: 0px;
  background-color: #003780;
  color: #FFF;
  position: fixed;
  left: 0;
  right: 0;
  top: 72px;
  margin: auto;
  width: 100%;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown.arrow-top {
  position: fixed;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown.arrow-top:before {
  display: none;
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  color: #FFF !important;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown a.active {
  background: #f8f9fa;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: inherit;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a {
  margin-right: 30px;
  padding: 20px 0px;
  display: block;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a:hover {
  color: #F7CAAB !important;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span i::before {
  content: " ";
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(84deg) brightness(101%) contrast(102%);
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.administration i::before {
  content: "\f62e";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.consommation i::before {
  content: "\f634";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.droit-penal i::before {
  content: url("/images/icones/scale-balanced.svg");
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.famille i::before {
  content: "\f4d0";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.fiscalite-impots i::before {
  content: "\f4e9";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.immobilier-construction i::before {
  content: "\f423";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.professionnels-et-entreprises i::before {
  content: "\f1dd";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.travail i::before {
  content: "\f1cc";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.administratives i::before {
  content: "\f62e";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.immobilier i::before {
  content: "\f423";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.automobilistes i::before {
  content: "\f7e1";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.consommateurs i::before {
  content: "\f634";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.entreprises i::before {
  content: "\f1dd";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li > a > span.familles i::before {
  content: "\f4d0";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > a::before {
  content: "\e315";
  right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > a i::before {
  vertical-align: 0;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children.has-children > a::before {
  content: "";
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > .dropdown {
  background-color: #f9f2ef;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > .dropdown li a {
  padding: 14px 0px;
  color: var(--bs-primary) !important;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > .dropdown li:hover a {
  color: #F89c97 !important;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > .dropdown li:not(:last-child) a::after {
  content: " ";
  border-left: 1px solid var(--bs-primary);
  position: relative;
  left: 14px;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children > .dropdown,
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children ul {
  left: 0;
  top: 136px;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:focus > a {
  color: #F89c97 !important;
}
.site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:hover > a > span i::before, .site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:active > a > span i::before, .site-navbar .site-navigation .site-menu .has-children > .dropdown li.has-children:focus > a > span i::before {
  filter: brightness(0) saturate(100%) invert(63%) sepia(42%) saturate(447%) hue-rotate(314deg) brightness(105%) contrast(94%);
}
.site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
  color: var(--bs-primary) !important;
}
.site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.site-navbar .site-navigation .site-menu.partenaire > li > a {
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 10px;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  left: 0;
  z-index: -9999;
  padding-top: 20px;
  background: #f9f2ef;
  height: 100vh;
  visibility: hidden;
  transform: translateX(-110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  height: 100%;
}
.offcanvas-menu .site-mobile-menu {
  transform: translateX(0%);
  visibility: visible !important;
  opacity: 1;
  z-index: 2000;
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #ced4da;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}
.site-mobile-menu .site-nav-wrap a:hover {
  color: var(--bs-primary);
}
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
.site-mobile-menu .site-nav-wrap li .nav-link.active {
  font-family: "AllianzNeoBold", sans-serif;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 5px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "bootstrap-icons";
  content: "\f282";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.site-mobile-menu .site-nav-wrap li:first-child {
  border-bottom: 1px solid #D9D9D9;
}
.site-mobile-menu .site-nav-wrap li > a {
  padding-left: 0;
  font-size: 15px;
}
.site-mobile-menu .site-nav-wrap li > a > span i::before {
  content: " ";
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
}
.site-mobile-menu .site-nav-wrap li > a > span.administration i::before {
  content: "\f62e";
}
.site-mobile-menu .site-nav-wrap li > a > span.consommation i::before {
  content: "\f634";
}
.site-mobile-menu .site-nav-wrap li > a > span.droit-penal i::before {
  content: url("/images/icones/scale-balanced.svg");
}
.site-mobile-menu .site-nav-wrap li > a > span.famille i::before {
  content: "\f4d0";
}
.site-mobile-menu .site-nav-wrap li > a > span.fiscalite-impots i::before {
  content: "\f4e9";
}
.site-mobile-menu .site-nav-wrap li > a > span.immobilier-construction i::before {
  content: "\f423";
}
.site-mobile-menu .site-nav-wrap li > a > span.professionnels-et-entreprises i::before {
  content: "\f1dd";
}
.site-mobile-menu .site-nav-wrap li > a > span.travail i::before {
  content: "\f1cc";
}
.site-mobile-menu .site-nav-wrap li > a > span.administratives i::before {
  content: "\f62e";
}
.site-mobile-menu .site-nav-wrap li > a > span.immobilier i::before {
  content: "\f423";
}
.site-mobile-menu .site-nav-wrap li > a > span.automobilistes i::before {
  content: "\f7e1";
}
.site-mobile-menu .site-nav-wrap li > a > span.consommateurs i::before {
  content: "\f634";
}
.site-mobile-menu .site-nav-wrap li > a > span.entreprises i::before {
  content: "\f1dd";
}
.site-mobile-menu .site-nav-wrap li > a > span.familles i::before {
  content: "\f4d0";
}
.site-mobile-menu .site-nav-wrap li ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap li ul:not(.show) {
  display: none;
}
.site-mobile-menu .site-nav-wrap li ul li {
  display: block;
  border-bottom: none !important;
}
.site-mobile-menu .site-nav-wrap li ul li ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap li ul li ul li {
  display: block;
}
.site-mobile-menu .site-nav-wrap[data-class=social] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class=social] > li {
  width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class=social] > li:first-child a {
  padding-left: 15px !important;
}

.sticky-wrapper {
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 81px;
  position: sticky;
  /*@include media-breakpoint-down(lg) {
      height: 62.0156px !important;
  }*/
}
.sticky-wrapper .site-navbar {
  background: #fff;
  transition: 0.3s box-shadow ease, 0.25s background-color linear;
}
.sticky-wrapper.is-sticky .site-navbar {
  background: #fff;
}
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: var(--bs-primary);
}
.sticky-wrapper .shrink {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@media (max-width: 767.98px) {
  .sticky-wrapper .shrink {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media (max-width: 1399px) {
  .site-navigation .site-menu > li.database .has-children .dropdown {
    top: 200px !important;
  }
}
/* purgecss end ignore */
.big-bandeau {
  min-height: 839px;
  overflow: hidden;
}
.big-bandeau.home {
  background-image: url("/images/01.webp");
}
.big-bandeau form .btn {
  width: 200px;
}
@media (max-width: 991.98px) {
  .big-bandeau {
    min-height: unset;
  }
  .big-bandeau.home {
    background-size: cover;
  }
  .big-bandeau.results.home {
    background-size: contain;
  }
}
@media (max-width: 575.98px) {
  .big-bandeau.home {
    background-size: 165% auto;
    background-image: url(/images/responsive-01.webp) !important;
    background-repeat: no-repeat;
    background-position: -150px 0px;
    background-color: #e6eef4;
  }
  .big-bandeau.home .container {
    margin-top: 325px;
    display: block;
    background: #e6eef4;
  }
}

#searchbar-home {
  background-repeat-y: no-repeat;
}
#searchbar-home .page-link {
  cursor: pointer;
}

.bandeau-account {
  position: relative;
  background-image: url(/images/account01.webp) !important;
  background-repeat: no-repeat;
  background-position: -450px 50%;
  background-size: cover;
  flex-grow: 1;
}
.bandeau-account:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #fbf2df, hsla(42, 78%, 86%, 0.65) 20%, hsla(42, 78%, 86%, 0.65) 40%, #fbf2df 60%, #fbf2df 100%);
}

#footer {
  /*position: fixed;
  left: 0;
  bottom: 0;*/
}
#footer a {
  text-decoration: none;
  color: #414141;
  transition: color 0.5s ease;
  letter-spacing: 1.26px;
}
#footer a:hover {
  color: var(--bs-primary);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  display: table;
  content: "";
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}
.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
  text-indent: -9999px;
}
.slick-slide a:hover {
  color: #414141;
  text-decoration: underline !important;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  right: -25px;
}
.slick-next:focus {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before {
  opacity: 1;
}
.slick-next:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:hover:before {
  opacity: 1;
}
.slick-next:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "→";
}

.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  left: -25px;
}
.slick-prev:focus {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-prev:focus:before {
  opacity: 1;
}
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-prev:hover:before {
  opacity: 1;
}
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "←";
}

.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}
[dir=rtl] .slick-prev:before {
  content: "→";
}
[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}
[dir=rtl] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus {
  outline: 0;
}
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

/* purgecss start ignore */
.wrapper {
  padding: 70px 0;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .wrapper.declaration {
    padding: 35px 0;
    padding-bottom: 22px;
  }
}
.wrapper .slick-dots.is-other {
  position: relative;
  bottom: 0;
  width: auto;
}
.wrapper .slick-dots.is-other .slick-dots {
  position: relative;
  bottom: 0;
  width: auto;
}
.wrapper .slick-dots.is-other .slick-dots li {
  width: 14px;
  margin: 0 14px;
  top: 1px;
}
.wrapper .slick-dots.is-other .slick-dots li button {
  width: 14px;
  height: 14px;
  transform: scale(0.4);
}
.wrapper .slick-dots.is-other .slick-dots li button::before {
  display: block;
  font-size: 20px;
  color: unset;
  border-radius: 50%;
  background-color: #D0DCE5;
  width: 14px;
  height: 14px;
}
.wrapper .slick-dots.is-other .slick-dots li.slick-active button {
  transform: scale(1);
}
.wrapper .slick-dots.is-other .slick-dots li.slick-active button::before {
  display: block;
  border: 0;
  background-color: var(--bs-primary);
}

.carousel {
  margin: 0px auto;
  overflow: hidden;
}
.carousel.listCat .cardIcon {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0 !important;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .carousel.listCat .cardIcon {
    opacity: 1;
    height: inherit !important;
  }
}
.carousel:not(.slick-initialized) {
  display: flex;
}
.carousel:not(.slick-initialized) .card {
  display: none;
}
.carousel:not(.slick-initialized) ~ .slider-controls {
  display: none;
}
.carousel.notslicked .card {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .carousel.notslicked:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .carousel.notslicked .card {
    display: block;
  }
}
.carousel .card {
  display: flex;
  transition: 0.4s ease-out;
}
.carousel .card:not(.carousel .card.discoverlist) img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  height: 240px !important;
  background-color: #f9f2ef;
}
.carousel .card:not(.carousel .card.discoverlist) img.hide-src {
  object-position: -99999px 99999px;
}
.carousel .card:not(.carousel .card.discoverlist) img.administratives {
  content: "";
  background-image: url("/images/icones/administratif.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.immobilier {
  content: "";
  background-image: url("/images/icones/immobilier.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.proprietaire {
  content: "";
  background-image: url("/images/icones/immobilier.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.travail {
  content: "";
  background-image: url("/images/icones/travail.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.automobilistes {
  content: "";
  background-image: url("/images/icones/automobiliste.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.consommateurs {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.assurances {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.telecoms {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.banques {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.service {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.bien {
  content: "";
  background-image: url("/images/icones/consommateur.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.entreprises {
  content: "";
  background-image: url("/images/icones/entreprise.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.familles {
  content: "";
  background-image: url("/images/icones/famille.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card:not(.carousel .card.discoverlist) img.etrangers {
  content: "";
  background-image: url("/images/icones/etranger.svg");
  width: 100%;
  height: inherit;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
}
.carousel .card .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.carousel .card .nbdoc {
  font-size: 75px;
  line-height: 1.1;
}
.carousel .card .price {
  color: #FFF;
}
.carousel .card:hover {
  background-color: #E6F4F6;
}
.carousel .card:hover .price {
  background-color: #FFF;
  color: var(--bs-primary);
  border: 1px solid var(--bs-primary);
}
.carousel .card:hover .card-title {
  text-decoration: underline;
}

.slick-track {
  display: flex !important;
  align-items: stretch;
  justify-content: center;
}

.slick-slide {
  margin: 10px;
  opacity: 0.8;
  transition: all 300ms ease;
  height: inherit !important;
}
.slick-slide.slick-active {
  opacity: 1;
}
.slick-slide img {
  width: 100%;
}

/* the slides */
.slick-slide {
  margin: 0 22px;
}

/* the parent */
.slick-list {
  margin: 0 -14px;
}

/*.slide-arrow{
    position: absolute;
    top: 50%;
    margin-top: -15px;
    &:hover {
        cursor: pointer;
    }
}*/
.prev-arrow,
.next-arrow {
  background: none;
  border: 0;
  z-index: 2;
}
.prev-arrow i,
.next-arrow i {
  font-size: 35px;
}

/*@include media-breakpoint-up(md) {
    .wrapper {
        .slick-dots {
            position: relative;
            bottom: 0;
            width: auto;

            li {
                width: 9px;
                //height: 100%;
                margin: 0 9px;

                button {
                    width: 9px;
                    height: 9px;

                    &::before {
                        font-size: 20px;
                        color: unset;
                        border-radius: 50%;
                        background-color: $colorDots;
                        width: 9px;
                        height: 9px;
                    }

                    &:hover {
                        &::before {
                            background-color: $primary-color;
                        }
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            border: 0;
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}*/
@media (max-width: 767.98px) {
  .wrapper {
    padding-top: 0rem;
    padding-bottom: 0rem;
    overflow-y: hidden;
    /*.slider-controls {
        &:not(.is-default) {
            padding: 0 2rem;
            position: relative;
            top: -108px;
            margin-bottom: -108px;
            overflow-y: hidden;
        }

        &.demarches {
            top: -70px;
            margin-bottom: 0;
        }
    }

    .slick-dots-container.demarches {
        top: 62px;
        position: relative;
    }*/
  }
  /*.slick-dots-container {
      width: 5.5rem;
      overflow: hidden;
      display: block;
      padding: 0;
      margin: 0.625rem auto;
      height: 0.875rem;
      position: relative;
      top: -18px;

      >ul {
          padding: 0;
          display: flex;
          transition: all 0.25s;
          position: relative;
          margin: 0;
          list-style: none;
          transform: translateX(0);
          align-items: center;
          bottom: unset;
          height: 100%;

          li {
              width: 0.625rem;
              height: 0.625rem;
              margin: 0 0.25rem;
              background-color: $colorDots;
              border: none;
              border-radius: 50%;

              button {
                  font-size: 0;
                  line-height: 0;
                  display: block;
                  width: 1.25rem;
                  height: 1.25rem;
                  padding: 0.3125rem;
                  cursor: pointer;
                  color: transparent;
                  border: 0;
                  outline: 0;
                  background: 0 0;
              }

              &.p-small-1,
              &.n-small-1 {
                  transform: scale(0.8);
              }

              &.slick-active {
                  transform: scale(1.3);
                  transform-origin: center;
                  background: $primary-color;
              }
          }
      }
  }*/
  /*/.slick-dots li button:before {
      display: none;
  }*/
}
.slider_dots {
  width: 200px;
  display: flex;
}

.slide img {
  display: block;
  width: 100%;
  height: auto;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots li.slick-active button {
  background-color: var(--bs-primary);
}
.slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: grey;
  text-indent: -9999px;
}

.slider_dots .slider_navigators {
  height: 14px !important;
  width: 14px !important;
  background-color: #D0DCE5;
  border-radius: 50%;
  margin: 10px;
  transform: scale(0.4);
  outline: none;
  cursor: pointer;
}

.slider_dots .slider_navigators.slick-active {
  transform: scale(0.7);
}

.slider_navigators.slick-active.slick-current {
  transform: scale(1);
  background-color: var(--bs-primary);
}

.main_slider {
  width: 100%;
}
.main_slider .slide img {
  display: block;
  width: 100%;
  height: auto;
}

/* purgecss end ignore */
/* purgecss start ignore */
.send-reporting-loader .blockUI {
  top: 0;
  left: 0;
}
.send-reporting-loader .blockMsg {
  width: 171px;
  margin-left: -86px !important;
  margin-top: -15px !important;
  z-index: 1011;
}
.send-reporting-loader .blockOverlay {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}
.send-reporting-loader .blockElement {
  left: 50%;
  top: 50%;
}

.loading {
  /* Absolute position */
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  /* Center */
  align-items: center;
  display: flex;
  justify-content: center;
}

/* purgecss end ignore */
/* purgecss start ignore */
.pagination .page-item.active a {
  background-color: transparent;
  color: var(--bs-primary) !important;
}
.pagination .page-item.active::after {
  content: "";
  border-bottom: 1px solid var(--bs-primary) !important;
  width: 10px;
  display: block;
  margin: auto;
}
.pagination .page-item.disabled {
  color: #D0DCE5;
}
.pagination .page-item.disabled i {
  color: #D0DCE5;
}
.pagination a {
  color: #A3B5C3 !important;
  cursor: pointer;
  font-size: 19px;
  border-radius: 0;
  background: none;
}
.pagination a.active {
  color: #003780 !important;
}
.pagination a i {
  color: #414141;
}
.pagination a:hover {
  background-color: transparent;
}
.pagination a:hover i {
  color: var(--bs-primary) !important;
}
.pagination a:focus {
  background-color: transparent;
  box-shadow: none;
}

/* purgecss end ignore */
.disclaimer {
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  position: relative;
}
.disclaimer .marquee {
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}