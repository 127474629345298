//Animations
@keyframes bounce {
    0% {
        transform: scale(1, 1) translate(0px, 0px);
    }

    30% {
        transform: scale(1, 0.8) translate(0px, 10px);
    }

    75% {
        transform: scale(1, 1.1) translate(0px, -25px);
    }

    100% {
        transform: scale(1, 1) translate(0px, 0px);
    }
}
//Animation 1 : Bounce
.animation1 {
    overflow-y: hidden;
    i {
        display: inline-block;
    }
    &:hover {
        i {
            animation: bounce 0.75s infinite;
        }
    }
}
//Animation 2 : left to right
.animation2 {
    i {
        display: inline-block;
        position: relative;
        transform: translateX(0);
        transition: transform .3s ease-in-out 0s;
    }
    &:hover {
        i {
            &.left {
                transform: translateX(-0.5rem);
            }
            &.right {
                transform: translateX(0.5rem);
            }
        }
    }
}
//Animation 3 : Up and border bottom hover
.animation3 {
    transition: all 0.25s ease-in;
    border-bottom: 5px solid transparent;

    @each $key, $value in $colors {
        //Background
        &.border-transparent-#{$key} {
            border-color: $value;
        }
    }

    &:hover {
        transform: translateY(-5px);
        border: none;
        border-bottom: 5px solid $primary-color;

        @each $key, $value in $colors {
            //Background
            &.bg-is-#{$key} {
                border-color: $value;
            }
            &.border-hover-#{$key} {
                border-color: $value!important;
            }
        }

    }
    &.bg-is-seventh {
        &:hover {
            border-color: $third-color;
        }
    }
    &.bg-is-eighth {
        &:hover {
            border-color: $third-color;
        }
    }
    &.bg-white {
        &:hover {
            border-color: $third-color;
        }
    }
}

// Card procedure animation
.animation4{
    .animate4{
        border: 2px solid transparent;
        border-left: none;
        transition: all ease-in-out .2s;
    }

    &:hover{
        .animate4{
            border-color: $third-color;

            a{
                color: $text;
            }
        }
    }

    @include media-breakpoint-down(lg){
        .animate4{
            border-left: 2px solid transparent;

            &:hover{
                border-left: 2px solid $third-color;
            }
        }
    }
}