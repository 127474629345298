//Pagination
/* purgecss start ignore */
$color_a : #A3B5C3;
$color_a_active : $fifth-color;
$color_disabled: #D0DCE5;

.pagination {
    .page-item {
        &.active {
            a {
                background-color: transparent;
                color: $primary-color!important;
            }
            &::after {
                content: "";
                border-bottom: 1px solid $primary-color!important;
                width: 10px;
                display: block;
                margin: auto;
            }
        }
        &.disabled {
            color: $color_disabled;
            i {
                color: $color_disabled;
            }
        }
    }
    a {
        color: $color_a!important;
        cursor:pointer;
        font-size: 19px;
        border-radius: 0;
        background: none;
        &.active {
            color: $color_a_active!important;
        }
        i {
            color: $text;
        }
        &:hover {
            background-color: transparent;
            i {
                color: $primary-color!important;
            }
        }
        &:focus {
            background-color: transparent;
            box-shadow: none;
        }
    }
}
/* purgecss end ignore */