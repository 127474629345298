//Header
/* purgecss start ignore */

$colorlink : #414141;
$demarches: (administration: '\F62E', consommation: '\F634', droit-penal: url('/images/icones/scale-balanced.svg'), famille: '\F4D0', fiscalite-impots: '\F4E9', immobilier-construction: '\F423', professionnels-et-entreprises: '\F1DD', travail: '\F1CC', administratives: '\F62E', immobilier: '\F423', automobilistes: '\F7E1', consommateurs: '\F634', entreprises: '\F1DD', familles: '\F4D0');

.site-navbar {

    margin-bottom: 0px;
    z-index: 1999;
    position: absolute;
    width: 100%;
    padding: 1rem;

    @include media-breakpoint-down(lg) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .toggle-button {
        //position: absolute;
        left: 0px;
    }

    .site-menu-toggle {
        &:focus-visible {
            outline: none;
        }
    }

    .site-logo {
        margin: 0;
        padding: 0;
        z-index: 3;
        margin-right: 3rem;

        @include media-breakpoint-down(md) {
            float: left;
            position: relative;
            margin-right: 0;
        }
    }


    .site-navigation {
        &.border-bottom {
            border-bottom: 1px solid lighten($gray-100, 80%) !important;
        }

        .site-menu {
            margin-bottom: 0;

            a {
                text-decoration: none !important;
                display: inline-block;
            }

            >li {
                display: inline;
                &.database {
                    &::after {
                        border-left: none!important;
                    }
                }
                >a {
                    margin-left: 15px;
                    margin-right: 15px;
                    padding: 20px 0px;
                    color: $colorlink !important;
                    display: inline-block;
                    text-decoration: none !important;
                    font-family: $neo;
                    font-size: 15px;

                    &.active {
                        font-family: $neoBold;
                    }

                    // border-bottom: 2px solid transparent;

                    &:hover {
                        color: $primary-color !important;

                    }
                }
            }

            .has-children {
                position: relative;

                >a {
                    position: relative;
                    padding-right: 20px;

                    &:before {
                        position: absolute;
                        content: "\F282";
                        font-size: 11px;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        font-family: 'bootstrap-icons';
                    }
                }

                >.dropdown {
                    visibility: hidden;
                    opacity: 0;
                    //top: 200%;
                    //position: absolute;
                    text-align: left;
                    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .1);
                    //padding: 0px 0;
                    margin-top: 20px;
                    margin-left: 0px;
                    background-color: $fifth-color;
                    //transition: 0.2s 0s;
                    color: #FFF;

                    /////
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 72px;
                    margin: auto;
                    width: 100%;
                    /////

                    &.arrow-top {
                        position: fixed;

                        &:before {
                            display: none;
                            bottom: 100%;
                            left: 20%;
                            border: solid transparent;
                            content: " ";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                        }

                        &:before {
                            border-color: rgba(136, 183, 213, 0);
                            border-bottom-color: $white;
                            border-width: 10px;
                            margin-left: -10px;
                        }
                    }


                    a {
                        // font-size: 16px;
                        text-transform: none;
                        letter-spacing: normal;
                        //transition: 0s all;
                        color: #FFF !important;

                        &.active {
                            background: $gray-100;
                        }
                    }

                    // .active {
                    // 	> a {
                    // 		color: $primary-color!important;
                    // 	}
                    // }
                    li {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        //min-width: 200px;
                        float: left;
                        width: inherit;

                        >a {
                            margin-right: 30px;
                            padding: 20px 0px;
                            display: block;

                            &:hover {
                                //background: $gray-100;
                                color: $twelveth-color !important;
                            }
                            > span {
                                i {
                                    &::before {
                                        content: ' ';
                                        background-size: 16px 16px;
                                        height: 16px;
                                        width: 16px;
                                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(84deg) brightness(101%) contrast(102%);
                                    }
                                }
                                @each $key, $value in $demarches {
                                    &.#{$key} {
                                        i {
                                            &::before {
                                                //background-image: url('/images/icones/#{$value}#{".svg"}');
                                                content: $value;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.has-children {
                            >a {
                                &::before {
                                    content: "\e315";
                                    right: 20px;
                                }

                                i {
                                    &::before {
                                        vertical-align: 0;
                                    }
                                }
                            }

                            &.has-children {
                                > a {
                                    &::before {
                                        content: "";
                                    }
                                }
                            }

                            >.dropdown {
                                background-color: $fourth-color;

                                li {
                                    a {
                                        padding: 14px 0px;
                                        color: $primary-color !important;

                                    }

                                    &:hover {
                                        a {
                                            color: $third-color !important;
                                        }
                                    }

                                    &:not(:last-child) {
                                        a {
                                            &::after {
                                                content: " ";
                                                border-left: 1px solid $primary-color;
                                                position: relative;
                                                left: 14px;
                                            }
                                        }
                                    }
                                }
                            }

                            >.dropdown,
                            ul {
                                left: 0;
                                top: 136px;
                            }

                            &:hover,
                            &:active,
                            &:focus {
                                >a {
                                    //background: $gray-100;
                                    color: $third-color !important;
                                    > span {
                                        i {
                                            &::before {
                                                filter: brightness(0) saturate(100%) invert(63%) sepia(42%) saturate(447%) hue-rotate(314deg) brightness(105%) contrast(94%);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    >a {
                        color: $primary-color !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    cursor: pointer;

                    >.dropdown {
                        transition-delay: 0s;
                        margin-top: 0px;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            &.partenaire {
                >li {
                    >a {
                        margin-left: 12px;
                        margin-right: 12px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}


// mobile menu
.site-mobile-menu {
    width: 300px;
    position: fixed;
    left: 0;
    z-index: -9999;
    padding-top: 20px;
    background: $fourth-color;
    height: calc(100vh);
    visibility: hidden;
    transform: translateX(-110%);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
    //transition: .3s all ease-in-out;
    opacity: 0;
    height: 100%;

    .offcanvas-menu & {
        transform: translateX(0%);
        visibility: visible!important;
        opacity: 1;
        z-index: 2000;
    }

    .site-mobile-menu-header {
        width: 100%;
        float: left;
        padding-left: 20px;
        padding-right: 20px;

        .site-mobile-menu-close {
            float: right;
            margin-top: 8px;

            span {
                font-size: 30px;
                display: inline-block;
                padding-left: 10px;
                padding-right: 0px;
                line-height: 1;
                cursor: pointer;
                transition: .3s all ease;

                &:hover {
                    color: $gray-400;
                }
            }
        }

        .site-mobile-menu-logo {
            float: left;
            margin-top: 10px;
            margin-left: 0px;

            a {
                display: inline-block;
                text-transform: uppercase;

                img {
                    max-width: 70px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .site-mobile-menu-body {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;
        padding: 0 20px 20px 20px;

        height: calc(100vh - 52px);
        //padding-bottom: 150px;

    }

    .site-nav-wrap {
        padding: 0;
        margin: 0;
        list-style: none;
        // float: left;

        // width: 100%;
        // height: 100%;
        position: relative;

        a {
            padding: 10px 20px;
            display: block;
            position: relative;
            color: $gray-900;

            &:hover {
                color: $primary-color;
            }
        }

        li {
            position: relative;
            display: block;

            .nav-link {
                &.active {
                    font-family: $neoBold;
                }
            }

            // &.active {
            // 	> a {
            // 		color: $primary-color;
            // 	}
            // }
        }

        .arrow-collapse {
            position: absolute;
            right: 0px;
            top: 5px;
            z-index: 20;
            width: 36px;
            height: 36px;
            text-align: center;
            cursor: pointer;
            border-radius: 50%;

            &:hover {
                background: $gray-100;
            }

            &:before {
                font-size: 12px;
                z-index: 20;
                font-family: 'bootstrap-icons';
                content: "\F282";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-180deg);
                transition: .3s all ease;
            }

            &.collapsed {
                &:before {
                    transform: translate(-50%, -50%);
                }
            }
        }

        li {
            display: block;
            position: relative;
            float: left;
            width: 100%;

            &:first-child {
                border-bottom: 1px solid #D9D9D9;
            }

            >a {
                padding-left: 0;
                font-size: 15px;
                > span {
                    i {
                        &::before {
                            content: ' ';
                            background-size: 16px 16px;
                            height: 16px;
                            width: 16px;
                        }
                    }
                    @each $key, $value in $demarches {
                        &.#{$key} {
                            i {
                                &::before {
                                    content: $value;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                &:not(.show) {
                    display: none;
                }
                li {
                    display: block;
                    border-bottom: none!important;

                    >a {}

                    ul {
                        padding: 0;
                        margin: 0;

                        li {
                            display: block;

                            >a {}
                        }
                    }
                }
            }
        }

        &[data-class="social"] {
            float: left;
            width: 100%;
            margin-top: 30px;
            padding-bottom: 5em;

            >li {
                width: auto;

                &:first-child {
                    a {
                        padding-left: 15px !important;
                    }
                }
            }
        }

    }


}


.sticky-wrapper {
    position: relative;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 81px;
    position: sticky;

    /*@include media-breakpoint-down(lg) {
        height: 62.0156px !important;
    }*/

    &+.site-blocks-cover {
        // margin-top: 140px;
        // margin-top: 96px;
    }

    .site-navbar {
        background: $white;
        transition: 0.3s box-shadow ease, 0.25s background-color linear;
    }

    &.is-sticky {
        .site-navbar {
            background: $white;

            // border-bottom: 1px solid transparent;
            //box-shadow: 4px 0 20px -5px rgba(0,0,0,.2);
            .site-logo {
                a {
                    color: $primary-color;
                }
            }
        }
    }

    .shrink {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        @include media-breakpoint-down(md) {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }
    }
}

@media (max-width: 1399px) {
    .site-navigation {
        .site-menu {
            > li {
                &.database {
                    .has-children {
                        .dropdown {
                            top:200px !important;
                        }
                    }
                }
            }
        }
    }
}
/* purgecss end ignore */