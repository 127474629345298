//Padding
@use "sass:math";

@for $i from 6 through 50 {
    .pt-#{$i} {
        padding-top: #{math.div($i, 2)+0.5}#{"rem"};
    }
    .pe-#{$i} {
        padding-right: #{math.div($i, 2)+0.5}#{"rem"};
    }
    .pb-#{$i} {
        padding-bottom: #{math.div($i, 2)+0.5}#{"rem"};
    }
    .ps-#{$i} {
        padding-left: #{math.div($i, 2)+0.5}#{"rem"};
    }
    .py-#{$i}px {
        padding-top: #{$i}#{"px"};
        padding-bottom: #{$i}#{"px"};
    }
    @include media-breakpoint-up(lg) {
        .pb-lg-#{$i} {
            padding-bottom: #{math.div($i, 2)+0.5}#{"rem"}!important;
        }
        .py-lg-#{$i}px {
            padding-top: #{$i}#{"px"}!important;
            padding-bottom: #{$i}#{"px"}!important;
        }
    }
}
